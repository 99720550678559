import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import isURL from 'validator/lib/isURL';

import {
  Box,
  Flex,
  Text,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@workshop/ui';

import { ImageUpload, LabelTextArea } from 'components/Common';

import { EmailConfigFormData } from './EmailConfig';

interface Props {
  image: string | null | undefined;
  options: {
    [key: string]: string;
  };
  isDisabled?: boolean;
  showAdvancedFields?: boolean;
}

interface PreviewState {
  [key: string]: string | null;
}

// The image upload requires a static height and width dimension
const aspectRatio = 0.5625; // height / width;
const uploadWidth = 355;
const uploadHeight = uploadWidth * aspectRatio;

const WelcomeEmailConfig: React.FC<Props> = ({
  image,
  options,
  isDisabled = true,
  showAdvancedFields = false,
}) => {
  const { register, setValue, errors } = useFormContext<EmailConfigFormData>();

  // TODO: Toggle downloads on/off
  // const [enabledDownloads, setEnabledDownlaods] = useState<string[]>();

  const [imagePreview, setImagePreview] = useState<string | null>(null);

  useEffect(() => {
    // Avoiding memory leaks by deleting the image preview on unmount
    return () => {
      imagePreview && URL.revokeObjectURL(imagePreview);
    };
  }, [imagePreview]);

  const onImageDrop = (name: string, acceptedFiles: File[]) => {
    setValue(name, acceptedFiles[0]);
    setImagePreview(URL.createObjectURL(acceptedFiles[0]));
  };

  const { welcomeEmail: welcomeEmailErrors } = errors;

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent="flex-stretch"
        paddingY="defaultPadding"
      >
        <Text color="text.muted" fontSize="sm" mb={4}>
          When a student joins the course, they will receive a welcome email
          containing instructions on how to access their class. Customise this
          email with your own image, introduction and downloads here.
        </Text>
        <ImageUpload
          id="welcomeEmail[image]"
          name="welcomeEmail[image]"
          label="Email Header Image"
          width="100%"
          maxWidth={uploadWidth}
          height={uploadHeight}
          backgroundColor="neutral.300"
          textColor="background.default"
          onDrop={onImageDrop}
          image={imagePreview || image || ''}
          isDisabled={isDisabled}
        />
        <Box flex={1} mt={4}>
          <LabelTextArea
            registerInputRef={register}
            id="welcomeEmail[text]"
            name="welcomeEmail[text]"
            label="Introduction"
            helpText="(Optional) This introduction will appear at the top of your course's welcome email."
            labelPosition="top"
            error={Boolean(welcomeEmailErrors?.text)}
            isDisabled={isDisabled}
          />
        </Box>
      </Flex>
      {showAdvancedFields && (
        <Flex flexDirection="column">
          <FormLabel color="text.muted" fontSize="md">
            Downloads:
          </FormLabel>
          <Text color="text.muted" fontSize="xs" mb={4}>
            (Optional) Provide students with a downloadable welcome pack, health
            and safety booklet and/or equipment list for your course by
            providing links to them here.
          </Text>
          <Flex flexDir="column" flex={1}>
            {Object.keys(options).map((optionKey) => {
              const isInvalid = Boolean(
                // @ts-ignore
                welcomeEmailErrors?.downloads?.[optionKey]
              );
              return (
                <Flex key={optionKey} mb="defaultMargin">
                  <Flex flex={1} mr="defaultMargin" alignItems="center">
                    <Text>{options[optionKey]}</Text>
                  </Flex>
                  <Flex flex={2}>
                    <Flex flex={1} flexDirection="column">
                      <FormControl isInvalid={isInvalid}>
                        <Input
                          ref={register({
                            validate: {
                              notUrl: (value: string) =>
                                value.length ? Boolean(isURL(value)) : true,
                            },
                          })}
                          name={`welcomeEmail[downloads]${optionKey}`}
                          placeholder="https://"
                          isDisabled={isDisabled}
                        />
                        <FormErrorMessage>
                          Please enter a valid URL
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    {/* <Checkbox ml="defaultMargin" /> */}
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default WelcomeEmailConfig;
