import React from 'react';
import { Text, Flex, Box, Card, Switch, chakra } from '@workshop/ui';

import navRoutes from 'navigation/Routes';

import { PLATFORM_DISPLAY_NAME } from 'constants/common';

import { analytics } from 'utils';

interface CookiesProps {}

const Cookies: React.FC<CookiesProps> = () => {
  const cookieChoice = localStorage.getItem('cookies');

  return (
    <Card padding="defaultPadding">
      <Flex flex={1}>
        <Box flex={1}>
          <Text color="text.muted">Allow cookies</Text>
          <Text fontSize="sm">
            {`${PLATFORM_DISPLAY_NAME} uses cookies to offer you a better experience. See our `}
            <chakra.a
              href={navRoutes.global.cookies.path()}
              target="_blank"
              rel="noreferrer noopener"
              fontWeight="semibold"
              color="text.primary"
              _focusVisible={{ outline: 'none' }}
            >
              Cookie Policy
            </chakra.a>
            {' for details.'}
          </Text>
        </Box>
        <Flex
          onClick={() => {
            const newChoice = cookieChoice === 'accept' ? 'reject' : 'accept';
            localStorage.setItem('cookies', newChoice);
            analytics.setOptOut(newChoice === 'reject');
            window.location.reload();
          }}
        >
          <Switch isChecked={cookieChoice === 'accept'} />
        </Flex>
      </Flex>
    </Card>
  );
};

export default Cookies;
