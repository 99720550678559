import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';

import { profileActions } from 'redux/actions/common';
import {
  useIsUserLoading,
  useDismissedInformationCards,
  useTeams,
  useCurrentTeamProfile,
  useCurrentTeam,
} from 'redux/selectors';

import { PLATFORM } from 'constants/env';
import { PRO_ORGS } from 'constants/organisation';

import { getParamFromUrl } from 'utils';

import { HomeCard, HomeCardAlt } from 'screens/common/Home';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import { Box } from '@workshop/ui';

import { InformationCard } from 'components/Common';
import { CreateOrgPopup } from 'components/CreateOrgPopup';

import navRoutes from 'navigation/Routes';

import { WelcomePopup } from './WelcomePopup';
import { ProWelcomePopup } from './ProWelcomePopup';

interface HomeScreenProps extends RouteComponentProps {}

const HomeScreen: React.FC<HomeScreenProps> = ({ location }) => {
  const isLoading = useIsUserLoading();
  const dismissedInformationCards = useDismissedInformationCards();
  const dispatch = useDispatch();
  const history = useHistory();
  const myTeams = useTeams();
  const hasTeams = myTeams.length > 0;

  const popupParam = getParamFromUrl(location, 'p');
  const [orgPopupIsOpen, setOrgPopupIsOpen] = useState(popupParam === 'new');
  const [welcomePopupIsOpen, setWelcomePopupIsOpen] = useState(false);
  const [proWelcomePopupIsOpen, setProWelcomePopupIsOpen] = useState(false);

  const currentTeamProfile = useCurrentTeamProfile();
  const currentTeam = useCurrentTeam();
  const isPro =
    PLATFORM === 'steppit' &&
    Boolean(
      currentTeamProfile?.isPro ||
        (currentTeam && PRO_ORGS.includes(currentTeam))
    );

  useEffect(() => {
    if (
      !isLoading &&
      (popupParam === 'welcome' ||
        !dismissedInformationCards.includes('welcome_popup'))
    ) {
      setWelcomePopupIsOpen(true);
    }
    if (
      !isLoading &&
      (popupParam === 'pro' ||
        (isPro && !dismissedInformationCards.includes('pro_welcome_popup')))
    ) {
      setProWelcomePopupIsOpen(true);
    }
  }, [dismissedInformationCards.length, isLoading, popupParam, isPro]);

  return (
    <ScreenWrapper>
      <Box mx="auto" px={{ base: 'defaultMargin', md: 0 }}>
        <InformationCard
          id={hasTeams ? 'home_screen' : 'learner_home_screen'}
          maxWidth={PLATFORM === 'steppit' ? '850px' : '500px'}
        />
      </Box>

      {PLATFORM === 'steppit' ? (
        <HomeCardAlt onNewOrgClick={() => setOrgPopupIsOpen(true)} />
      ) : (
        <HomeCard />
      )}

      {orgPopupIsOpen && (
        <CreateOrgPopup
          isOpen={orgPopupIsOpen}
          onClose={() => setOrgPopupIsOpen(false)}
        />
      )}
      <WelcomePopup
        isOpen={welcomePopupIsOpen}
        onClose={(openCreateOrg?: boolean, openChannel?: boolean) => {
          setWelcomePopupIsOpen(false);
          dispatch(
            profileActions.updateUserProfile(
              {
                dismissedInformationCards: [
                  ...dismissedInformationCards,
                  'welcome_popup',
                ],
              },
              { toast: { success: false, error: false } }
            )
          );
          if (openChannel) {
            history.push(navRoutes.cms.editChannel.path());
          } else {
            let searchParams = new URLSearchParams();
            searchParams.delete('p');
            history.push({
              pathname: location.pathname,
              search: searchParams.toString(),
            });
          }
          if (openCreateOrg) {
            setOrgPopupIsOpen(true);
          }
        }}
      />
      {PLATFORM === 'steppit' && (
        <ProWelcomePopup
          isOpen={proWelcomePopupIsOpen}
          onClose={() => {
            setProWelcomePopupIsOpen(false);
            dispatch(
              profileActions.updateUserProfile(
                {
                  dismissedInformationCards: [
                    ...dismissedInformationCards,
                    'pro_welcome_popup',
                  ],
                },
                { toast: { success: false, error: false } }
              )
            );
          }}
        />
      )}
    </ScreenWrapper>
  );
};

export default HomeScreen;
