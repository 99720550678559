import React, { useRef, useState, useEffect } from 'react';
import { MessageList, MessageBoxProps } from 'react-chat-elements';
import moment from 'moment';
import { useSpring, animated } from 'react-spring';
import { useLottie } from 'lottie-react';
import classnames from 'classnames';
import {
  Box,
  Button,
  LinkButton,
  Flex,
  Card,
  MdIcon,
  Text,
  Textarea,
  Spinner,
  Collapse,
  useColorModeValue,
  useTheme,
} from '@workshop/ui';
import { Post } from 'discourse-js';
import { parse } from 'node-html-parser';
import { FaGem, FaDiscord } from 'react-icons/fa';

import { PLATFORM, helpUrl, discordUrl } from 'constants/env';
import {
  ASSISTANT_TOOLS,
  AssistantToolSlug,
  AssistantTool,
} from 'constants/assistant';
import { PRO_ORGS } from 'constants/organisation';

import { AnimatedFlex, ProCta } from 'components/Common';
import { UserAvatar } from 'components/UserAvatar';
import { hooks } from 'utils';
import {
  useCurrentTeamProfile,
  useCurrentTeam,
  useUser,
} from 'redux/selectors';
import { useWindowDimensions } from 'utils/hooks/useDimensions';
import { uiAction } from 'redux/actions/common';

import navRoutes from 'navigation/Routes';

import assistantAnimation from 'assets/lottie/assistant.json';

import 'react-chat-elements/dist/main.css';
import './ChatWidget.css';

interface Message extends Omit<MessageBoxProps, 'date' | 'text'> {
  date: string;
  failedToSend?: boolean;
  fromUser?: boolean;
  isSending?: boolean;
  messageId?: number;
  /** The text stored in discourse */
  text: React.ReactNode;
  /** The text as a string - used for sending retry */
  textString?: string;
  user: {
    name: string;
    avatar?: string;
  };
}

interface ConversationWidgetProps {
  details: {
    userId?: number;
    discourseGroupTopicId?: number;
    title: string;
    conversationUser?: {
      username: string;
      isMentor?: boolean;
      avatar?: string;
    };
  };
  loadMorePosts?: (() => Promise<any>) | null;
  expanded?: boolean;
  isLoading?: boolean;
  messages: Message[];
  topicId?: number;
  unreadMessage?: boolean;
  isSetFullscreen?: boolean;
  onSubmit: (
    message: string,
    title: string,
    topicId?: number,
    conversationUserName?: string
  ) => Promise<Post | null>;
  onClose?: (userId?: number, discourseGroupTopicId?: number) => void;
  onToggleExpanded?: (topicId: number | undefined) => void;
  onToggleFullscreen?: (topicId: number | undefined) => void;
  onLoadTopic: (topicId: number) => Promise<any>;
}

/**
 * Convert a list of Message object into a list of MessageBoxProps objects
 * to be consumed by the MessageList component from react-chat-elements
 */
const getMessageListData = (messages: Message[]): MessageBoxProps[] =>
  messages.map((message, idx) => {
    const {
      date,
      failedToSend,
      fromUser,
      isSending,
      user: { name, avatar },
    } = message;

    const dateString = moment(date).format('h:mm a');

    const nextMsgDiffUser =
      idx + 1 < messages.length && messages[idx + 1].user.name !== name;

    const marginBottom = failedToSend || nextMsgDiffUser;

    // Show the date if
    // - this is the first message in the list OR
    // - the previous message ways on a different day
    const showDate =
      idx === 0 || !moment(messages[idx - 1].date).isSame(moment(date), 'day');

    const isLastMsg = idx + 1 >= messages.length;

    const nextMsgDiffDay =
      !isLastMsg && !moment(messages[idx + 1].date).isSame(moment(date), 'day');

    // Only show the avatar if:
    // - the message is from another user (i.e, not the user currently logged in)
    // AND:
    // - this is the last message in the list OR
    // - the next message in the list is from someone else OR
    // - we're showing the date for this message AND
    // - next message is for a different day
    const showAvatar =
      !fromUser &&
      (isLastMsg || nextMsgDiffUser || (showDate && nextMsgDiffDay));

    const userAvatar = showAvatar ? avatar || name : undefined;
    const letterItem =
      showAvatar && !avatar
        ? {
            id: name,
            letter: (
              <Flex
                backgroundColor="background.primary"
                w="25px"
                h="25px"
                borderRadius="20px"
                justifyContent="center"
                alignItems="center"
                fontSize="12px"
              >
                {name[0]}
              </Flex>
            ),
          }
        : undefined;

    /**
     * The react-chat-element library uses the following function to render
     * any extra custom content alongside each message
     *
     * We use to to render the spinner when sending or the date of the message
     */
    const renderAddCmp = () => {
      if (isSending) {
        return <Spinner className="spinner" size="xs" color="common.primary" />;
      }

      if (showDate) {
        return (
          <Box className="message-group-date" textAlign="center">
            <Text fontSize="xs" color="text.muted" marginY={2}>
              {moment(date).format('MMM D, YYYY')}
            </Text>
          </Box>
        );
      }

      return null;
    };

    return {
      ...message,
      avatar: userAvatar,
      className: classnames({
        'margin-bottom': marginBottom,
        'failed-message': failedToSend,
        'is-sending': isSending,
      }),
      date: undefined,
      dateString,
      letterItem,
      position: fromUser ? ('right' as const) : ('left' as const),
      type: 'text' as const,
      renderAddCmp,
      text: message.text || '',
    };
  });

const generateTempId = () => parseInt(Math.random().toString().split('.')[1]);

const ConversationWidget: React.FC<ConversationWidgetProps> = ({
  details: { title, userId, discourseGroupTopicId, conversationUser },
  expanded: isExpanded = false,
  isLoading: isLoadingProp,
  messages: messagesProps,
  topicId,
  unreadMessage,
  isSetFullscreen,
  onClose = () => null,
  onLoadTopic,
  onToggleExpanded = () => null,
  onSubmit,
  onToggleFullscreen,
  loadMorePosts,
}) => {
  const dispatch = hooks.useDispatch();

  const [topicLoaded, setTopicLoaded] = useState(false);
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [messages, setMessages] = useState(messagesProps);
  const [currentMessage, setCurrentMessage] = useState<string | null>(null);
  const [sentMessages, setSentMessages] = useState<{ [key: string]: Message }>(
    {}
  );

  const bottomContainerRef = useRef<HTMLDivElement>(null);

  const windowDimensions = useWindowDimensions();
  const fullscreeenHeight = windowDimensions.height - 280;

  const isFullscreen = isSetFullscreen && isExpanded;

  const animatedStyle = useSpring({
    config: { duration: 100 },
    height: isFullscreen
      ? `${fullscreeenHeight}px`
      : isExpanded
      ? '350px'
      : '0px',
    opacity: isExpanded ? 1 : 0,
  });

  const isLoading = isLoadingProp || !topicLoaded;

  useEffect(() => {
    /**
     * Scroll to the bottom of the container for the MessageList
     * when the widget gets expanded, and when a new message is sent/received.
     */
    if (isExpanded) {
      bottomContainerRef.current?.scrollIntoView();
    }
  }, [isExpanded, Object.keys(sentMessages).length, isLoading]);

  const loadTopic = async () => {
    topicId && (await onLoadTopic(topicId));
    setTopicLoaded(true);
  };

  useEffect(() => {
    if (isLoadingProp || !isExpanded) return;

    if (!userId && !discourseGroupTopicId) return;

    loadTopic();
  }, [isExpanded, isLoadingProp, topicId, userId, discourseGroupTopicId]);

  useEffect(() => {
    /**
     * Update the list of messages for this conversation after we're done sending
     * (so that the list of messages displayed always comes fresh from the redux store)
     */
    const isSending = Object.values(sentMessages).find((m) => m.isSending);
    if (isSending) return;

    if (messagesProps.length !== messages.length) {
      setMessages(messagesProps);
    }
  }, [messagesProps.length, sentMessages]);

  /**
   * A message can appear in both sentMessages and the list of messages coming from props
   * The sentMessages refers to any message that was sent using this ChatWidget and is used
   * as a placeholder until the message is successfully sent and gets returned by the backend
   * into the messages prop.
   *
   * Therefore the following filter is necessary to prevent a message from showing twice, i.e,
   * we only keep the message that came from the messages prop if possible.
   */
  const allMessages = [
    ...messages,
    ...Object.values(sentMessages).filter(
      ({ messageId }) => !messages.find((m) => m.messageId === messageId)
    ),
  ]
    .sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1))
    .sort((a, b) =>
      Boolean(a.failedToSend) === Boolean(b.failedToSend)
        ? 0
        : a.failedToSend
        ? 1
        : -1
    );

  const handleSubmit = async () => {
    if (!currentMessage) return;

    const tempMessageId = generateTempId();

    setSentMessages((state) => ({
      ...state,
      [tempMessageId]: {
        date: moment().format(),
        fromUser: true,
        isSending: true,
        messageId: tempMessageId,
        id: tempMessageId,
        text: currentMessage,
        textString: currentMessage,
        user: { name: '' },
      },
    }));

    setCurrentMessage(null);

    const post = await onSubmit(
      currentMessage,
      title,
      topicId,
      conversationUser?.username
    );

    setSentMessages((state) => ({
      ...state,
      [tempMessageId]: {
        ...state[tempMessageId],
        failedToSend: !Boolean(post),
        isSending: false,
        messageId: post?.id || tempMessageId,
        id: post?.id || tempMessageId,
      },
    }));
  };

  const handleRetry = async (message: Message) => {
    if (!message.textString || !message.messageId) return;

    const messageId = message.messageId;
    if (!sentMessages[message.messageId]) return;

    setSentMessages((state) => ({
      ...state,
      [messageId]: {
        ...state[messageId],
        date: moment().format(),
        fromUser: true,
        isSending: true,
        text: message.text,
        failedToSend: false,
      },
    }));

    const post = await onSubmit(
      message.textString,
      title,
      topicId,
      conversationUser?.username
    );

    setSentMessages((state) => ({
      ...state,
      [messageId]: {
        ...state[messageId],
        failedToSend: !Boolean(post),
        isSending: false,
        messageId: post?.id || messageId,
      },
    }));
  };

  const messageListData = getMessageListData(allMessages);

  const firstIncomingMessage = allMessages.find((m) => !m.fromUser);

  const avatar = discourseGroupTopicId ? (
    <UserAvatar size="2xs" userId={discourseGroupTopicId} name={title} />
  ) : conversationUser ? (
    <UserAvatar
      size="2xs"
      userId={conversationUser.username.length}
      name={conversationUser.username}
      avatarPicture={conversationUser.avatar}
    />
  ) : firstIncomingMessage?.user.avatar ? (
    <UserAvatar
      size="2xs"
      userId={firstIncomingMessage.user.name.length}
      name={firstIncomingMessage.user.name}
      avatarPicture={firstIncomingMessage.user.avatar}
    />
  ) : null;

  const handleToggleIsExpanded = () => {
    onToggleExpanded(topicId);

    if (!userId && !discourseGroupTopicId) return;

    // Collapse/Expand the widget by switching
    // the isExpanded state in the redux store
    const actionData = {
      title,
      username: conversationUser?.username,
      isExpanded: !isExpanded,
    } as const;

    let action;

    if (discourseGroupTopicId) {
      action = uiAction.toggleUserMessaging({
        ...actionData,
        discourseGroupTopicId,
      });
    } else if (userId) {
      action = uiAction.toggleUserMessaging({
        ...actionData,
        userId,
      });
    }

    action && dispatch(action);
  };

  const handleLoadMorePosts = async () => {
    if (!loadMorePosts || loadingPosts) return;

    setLoadingPosts(true);
    await loadMorePosts();
    setLoadingPosts(false);
  };

  return (
    <Flex
      borderTopRadius="lg"
      boxShadow="0px 10px 30px 0px rgba(0,0,0,0.15)"
      flexDir="column"
      w={isFullscreen ? '900px' : '300px'}
      maxWidth="90vw"
      backgroundColor="background.default"
    >
      <Flex
        alignItems="center"
        cursor="pointer"
        paddingX={2}
        paddingY={2}
        onClick={handleToggleIsExpanded}
      >
        <Flex position="relative">
          {avatar}
          {!isExpanded && unreadMessage && (
            <Box
              w="12px"
              h="12px"
              borderRadius="10px"
              backgroundColor="red.300"
              position="absolute"
              top="-4px"
              right="-4px"
            />
          )}
        </Flex>
        <Text ml={2} flex={1} fontWeight="semibold" fontSize="sm">
          {title}
        </Text>
        {isExpanded && onToggleFullscreen ? (
          <Box
            pr={2}
            onClick={(e) => {
              e.stopPropagation();
              onToggleFullscreen(isFullscreen ? undefined : topicId);
            }}
            _hover={{ opacity: 0.75 }}
          >
            <MdIcon
              color="common.primary"
              cursor="pointer"
              name={isFullscreen ? 'CloseFullscreen' : 'OpenInFull'}
            />
          </Box>
        ) : null}
        <MdIcon
          color="common.primary"
          cursor="pointer"
          name="Cancel"
          _hover={{ opacity: 0.75 }}
          onClick={(e) => {
            e.stopPropagation();
            onClose(userId, discourseGroupTopicId);
          }}
        />
      </Flex>
      <Flex
        flexDir="column"
        style={{ scrollbarWidth: 'none' }}
        className={
          isFullscreen ? 'fs-message-list-container' : 'message-list-container'
        }
      >
        {/* @ts-ignore */}
        <AnimatedFlex
          style={animatedStyle}
          flexDirection="column"
          overflowY="scroll"
          backgroundColor="background.tint1"
          position="relative"
          maxHeight="90vh"
        >
          {conversationUser?.isMentor && (
            <Flex backgroundColor="background.info">
              <Text
                color="text.info"
                fontSize="xs"
                margin={0}
                paddingX={2}
                paddingY={1}
              >
                When directly contacting your mentor you can expect a response
                within 3 working days
              </Text>
            </Flex>
          )}
          {(isLoading || loadingPosts) && (
            <Flex
              justifyContent="center"
              alignItems="center"
              pt={4}
              position="absolute"
              top="-10px"
              width="100%"
              zIndex={1}
            >
              <Spinner color="common.primary" />
            </Flex>
          )}
          {!messageListData.length && !Boolean(isLoading) && (
            <Text
              pt={2}
              paddingX={4}
              fontSize="sm"
              color="text.muted"
            >{`This is the beginning of your conversation with ${title}.`}</Text>
          )}
          <MessageList
            className="message-list"
            dataSource={messageListData}
            lockable
            // toBottomHeight={'100%'}
            onClick={(messageBox) => {
              const failedMessage = Object.values(sentMessages).find(
                (m) => m.id === messageBox.id
              );

              if (failedMessage) handleRetry(failedMessage);
            }}
            onScroll={(e) => {
              if (e.currentTarget.scrollTop <= 100) {
                handleLoadMorePosts();
              }
            }}
          />
          <Box ref={bottomContainerRef} />
        </AnimatedFlex>
        {isExpanded && (
          <Flex padding={2} backgroundColor="background.default">
            <Textarea
              placeholder="Write a message..."
              value={currentMessage || ''}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setCurrentMessage(e.target.value)
              }
              onKeyDown={(e) =>
                !isFullscreen &&
                !e.shiftKey &&
                e.key === 'Enter' &&
                handleSubmit() &&
                e.preventDefault()
              }
              mr={2}
              rows={isFullscreen ? 5 : 1}
            />
            <Button isDisabled={!currentMessage?.length} onClick={handleSubmit}>
              Send
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

interface AssistantWidgetToolProps extends AssistantTool {
  onOpen: () => void;
  locked: boolean;
}

const AssistantWidgetTool: React.FC<AssistantWidgetToolProps> = ({
  locked,
  onOpen,
  info,
  emoji,
  label,
}) => {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <Card
      mx={3}
      mb={2}
      flexDirection="column"
      borderRadius="sm"
      cursor="pointer"
      borderWidth={1}
      borderColor="background.default"
      _hover={{
        transform: 'scale(1.02)',
        borderColor: 'common.primary',
      }}
      transition="transform 0.3s, border-color 0.3s"
      onClick={() => {
        if (info) {
          setShowInfo(!showInfo);
        } else {
          onOpen();
        }
      }}
    >
      <Flex alignItems="center">
        <Text fontSize="lg">{emoji}</Text>
        <Flex boxSize={1.5} />
        <Text flex={1} fontWeight="semibold">
          {label}
        </Text>
        {info && !locked ? (
          <Button w={8} size="sm" icon="InfoOutline" variant="ghost" />
        ) : !locked ? (
          <Button w={8} size="sm" icon="Launch" variant="ghost" />
        ) : (
          <Button
            w={8}
            size="sm"
            leftIcon={
              <Box mr={-2}>
                <FaGem />
              </Box>
            }
            colorScheme="orange"
            variant="ghost"
            color="orange.500"
          />
        )}
      </Flex>
      {info && !locked ? (
        <Collapse in={showInfo}>
          <Text color="text.muted" fontSize="sm" pt={1}>
            {info}
          </Text>
        </Collapse>
      ) : null}
    </Card>
  );
};

const AssistantWidget: React.FC<{}> = ({}) => {
  const [assistantJson, setAssistantJson] = useState(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [currentMessage, setCurrentMessage] = useState<string | null>(null);
  const [sentMessages, setSentMessages] = useState<{ [key: string]: Message }>(
    {}
  );
  const [selectedToolSlug, setSelectedToolSlug] =
    useState<AssistantToolSlug | null>(null);
  const [triggerOpenProPopup, setTriggerOpenProPopup] = useState<
    boolean | undefined
  >(undefined);

  const currentRoute = hooks.useCurrentRoute();
  const user = useUser();

  const theme = useTheme();

  const currentTeamProfile = useCurrentTeamProfile();
  const currentTeam = useCurrentTeam();
  const isPro = Boolean(
    currentTeamProfile?.isPro || (currentTeam && PRO_ORGS.includes(currentTeam))
  );

  const proColor = useColorModeValue(
    theme.colors.orange[400],
    theme.colors.orange[100]
  );
  const proSecondaryColor = useColorModeValue(
    theme.colors.orange[50],
    theme.colors.orange[500]
  );

  const {
    View: lottieAssistant,
    playSegments,
    goToAndStop,
  } = useLottie({
    animationData: assistantJson,
    autoplay: false,
    initialSegment: [60, 61],
    style: {
      height: '2em',
      width: '2em',
    },
  });

  const toolsOnPage = currentRoute?.assistantTools;
  const availableTools = toolsOnPage?.map((t) => ASSISTANT_TOOLS[t]) || [];

  const aiAvailableForBox = false;
  const unreadMessage = false;
  const title = 'My Assistant';
  const isTrained = true;
  const isLoading = false;
  // const aiAvailable = true;
  const userName = user.name.split(' ')[0];

  const [{ jiggle }, animateJiggle] = useSpring(() => ({
    jiggle: 0,
    config: { tension: 150, friction: 9 },
  }));

  useEffect(() => {
    // @ts-ignore
    setAssistantJson(assistantAnimation);
    return () => setTriggerOpenProPopup(undefined);
  }, []);
  useEffect(() => {
    if (isExpanded) {
      playSegments(
        [
          [60, 241],
          [0, 241],
        ],
        true
      );
    } else {
      goToAndStop(60, false);
    }
  }, [isExpanded]);
  useEffect(() => {
    if (toolsOnPage) {
      animateJiggle({ jiggle: 1 });
    } else {
      animateJiggle({ jiggle: 0 });
    }
  }, [toolsOnPage]);

  const bottomContainerRef = useRef<HTMLDivElement>(null);

  const windowDimensions = useWindowDimensions();
  const fullscreeenHeight = windowDimensions.height - 280;

  const animatedStyle = useSpring({
    config: { duration: 100 },
    height: isFullscreen
      ? `${fullscreeenHeight}px`
      : isExpanded
      ? '500px'
      : '0px',
    opacity: isExpanded ? 1 : 0,
  });

  const avatar = lottieAssistant;

  const showChatBubble = aiAvailableForBox && !isExpanded;

  return (
    <Flex
      borderTopRadius="lg"
      boxShadow="0px 10px 30px 0px rgba(0,0,0,0.15)"
      flexDir="column"
      w={
        isFullscreen
          ? '900px'
          : isExpanded
          ? '400px'
          : showChatBubble
          ? '250px'
          : '200px'
      }
      maxWidth="90vw"
      backgroundColor="background.default"
      transition="width 0.3s"
    >
      <Flex
        alignItems="center"
        cursor="pointer"
        paddingX={2}
        paddingY={2}
        height={showChatBubble ? '4.5em' : '3em'}
        onClick={() => {
          if (isExpanded) {
            setIsExpanded(false);
            setIsFullscreen(false);
          } else {
            setIsExpanded(true);
          }
        }}
        transition="height 0.3s"
        position="relative"
      >
        <Flex
          position="relative"
          zIndex={1}
          alignSelf="flex-end"
          alignItems="center"
          justifyContent="center"
        >
          <Box zIndex={1}>{avatar}</Box>
          <Box
            position="absolute"
            bg="background.default"
            boxSize="2.4em"
            borderRadius="full"
          />
          {!isExpanded && unreadMessage && (
            <Box
              w="12px"
              h="12px"
              borderRadius="10px"
              backgroundColor="red.300"
              position="absolute"
              top="-4px"
              right="-4px"
              zIndex={1}
            />
          )}
        </Flex>
        <Flex
          flex={1}
          opacity={showChatBubble ? 0 : 1}
          transition="opacity 0.3s"
        >
          <Text ml={2} fontWeight="semibold" fontSize="sm">
            {title}
          </Text>
        </Flex>
        <Box position="absolute" left={6} right={5} pointerEvents="none">
          <Flex
            w="100%"
            opacity={showChatBubble ? 1 : 0}
            transform={`translateY(${showChatBubble ? 1 : 0.5})`}
            transition="opacity 0.3s, transform 0.3s"
          >
            <Flex
              bg="background.primary"
              borderRadius="md"
              p={2}
              paddingLeft={6}
              w="100%"
              overflow="hidden"
            >
              <Text color="text.primary" fontSize="sm" whiteSpace="nowrap">
                Need a hand? I can help!
              </Text>
            </Flex>
          </Flex>
        </Box>
        {isExpanded ? (
          <>
            <Box
              pr={2}
              onClick={(e) => {
                e.stopPropagation();
                setIsFullscreen(!isFullscreen);
              }}
              _hover={{ opacity: 0.75 }}
            >
              <MdIcon
                color="common.primary"
                cursor="pointer"
                name={isFullscreen ? 'CloseFullscreen' : 'OpenInFull'}
              />
            </Box>
            <MdIcon
              color="common.primary"
              cursor="pointer"
              name="Cancel"
              _hover={{ opacity: 0.75 }}
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(false);
                setIsFullscreen(false);
              }}
            />
          </>
        ) : (
          <animated.span
            style={{
              scale: jiggle.to({
                range: [0, 0.9, 1],
                output: [0.8, 1, 1.1],
              }),
              opacity: jiggle.to({
                range: [0.2, 0.8],
                output: [0, 1],
              }),
              rotate: jiggle.to({
                range: [0, 0.55, 1],
                output: ['0deg', '2deg', '-2deg'],
              }),
            }}
          >
            <Flex
              bg={proSecondaryColor}
              borderRadius="full"
              boxSize={6}
              alignItems="center"
              justifyContent="center"
              transform={`translateY(${aiAvailableForBox ? '-1em' : 0}) scale(${
                aiAvailableForBox ? 0.75 : 1
              })`}
              transition="transform 0.3s"
            >
              <MdIcon name="AutoAwesome" color={proColor} />
            </Flex>
          </animated.span>
        )}
      </Flex>
      <Flex
        flexDir="column"
        style={{ scrollbarWidth: 'none' }}
        className={
          isFullscreen ? 'fs-message-list-container' : 'message-list-container'
        }
      >
        {/* @ts-ignore */}
        <AnimatedFlex
          style={animatedStyle}
          flexDirection="column"
          overflowY="scroll"
          backgroundColor="background.tint1"
          position="relative"
          maxHeight="90vh"
        >
          {!isTrained && (
            <Flex backgroundColor="background.info">
              <Text
                color="text.info"
                fontSize="xs"
                margin={0}
                paddingX={2}
                paddingY={1}
              >
                Train me up for more bespoke assistance!
              </Text>
              <Button size="sm">Train Now</Button>
            </Flex>
          )}
          {isLoading && (
            <Flex
              justifyContent="center"
              alignItems="center"
              pt={4}
              position="absolute"
              top="-10px"
              width="100%"
              zIndex={1}
            >
              <Spinner color="common.primary" />
            </Flex>
          )}
          <Box w="100%" maxW="400px" mx="auto">
            <Flex
              bg="background.primary"
              p={3}
              m={3}
              mb={availableTools.length > 0 ? 6 : 12}
              justifyContent="center"
              textAlign="center"
              borderRadius="sm"
            >
              <Text color="text.primary">{`Hey ${userName}! 👋 How can I help?`}</Text>
            </Flex>
            {availableTools.length > 0 && (
              <Box mb={6}>
                <Flex
                  color={proColor}
                  alignItems="center"
                  justifyContent="center"
                  mb={3}
                >
                  <Flex
                    bg={proSecondaryColor}
                    borderRadius="full"
                    boxSize={6}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <MdIcon name="AutoAwesome" />
                  </Flex>
                  <Flex boxSize={1.5} />
                  <Text fontWeight="semibold" fontSize="sm">
                    Pro tools on this page
                  </Text>
                </Flex>
                {availableTools.map((t) => (
                  <AssistantWidgetTool
                    key={`assistant-tool-${t.slug}`}
                    onOpen={
                      isPro
                        ? () => setSelectedToolSlug(t.slug)
                        : () => setTriggerOpenProPopup(!triggerOpenProPopup)
                    }
                    locked={!isPro}
                    {...t}
                  />
                ))}
                <Flex justifyContent="center">
                  <LinkButton
                    to={navRoutes.cms.assistant.path()}
                    size="sm"
                    variant="ghost"
                    icon="ArrowForward"
                    iconPosition="right"
                  >
                    See all tools
                  </LinkButton>
                </Flex>
              </Box>
            )}

            <Flex flexDirection="column" alignItems="center" mx={3} mb={3}>
              <Text
                fontWeight="semibold"
                fontSize="sm"
                textAlign="center"
                color="text.muted"
                mb={3}
              >
                General support
              </Text>
              <LinkButton
                href={helpUrl}
                target="_blank"
                rel="noopener noreferrer"
                icon="Help"
                variant="outline"
                mb={3}
              >
                Open Help Center
              </LinkButton>
              <LinkButton
                href={discordUrl}
                target="_blank"
                rel="noopener noreferrer"
                variant="outline"
                mb={3}
              >
                <Flex>
                  <Flex
                    boxSize="icon"
                    alignItems="center"
                    justifyContent="center"
                    mr={1.5}
                  >
                    <FaDiscord />
                  </Flex>
                  <Text>Join Our Community</Text>
                </Flex>
              </LinkButton>
              {/* <Button variant="outline" mb={3}>
                I don't know what to do next
              </Button>
              <Button variant="outline" mb={3}>
                I have a question
              </Button>
              <Button
                variant="ghost"
                colorScheme="orange"
                icon="AutoAwesome"
                backgroundColor={proSecondaryColor}
                color={proColor}
                mb={3}
              >
                Let's chat
              </Button> */}
            </Flex>
            {availableTools.length === 0 && currentRoute?.slug !== 'assistant' && (
              <Flex justifyContent="center">
                <LinkButton
                  to={navRoutes.cms.assistant.path()}
                  size="sm"
                  variant="ghost"
                  icon="AutoAwesome"
                >
                  See Pro tools
                </LinkButton>
              </Flex>
            )}
          </Box>
        </AnimatedFlex>
      </Flex>
      {/* <AssistantModal
        isOpen={!!selectedToolSlug}
        onClose={() => setSelectedToolSlug(null)}
        toolSlug={selectedToolSlug}
      /> */}
      <Box height={0}>
        <ProCta
          hideCta
          hideProIcon
          label=""
          triggerOpenPopup={triggerOpenProPopup}
        />
      </Box>
    </Flex>
  );
};

interface ChatWidgetProps {
  conversations: Omit<ConversationWidgetProps, 'onSubmit' | 'onLoadTopic'>[];
  isLoading?: boolean;
  onSubmit: (
    message: string,
    title: string,
    topicId?: number,
    conversationUser?: string
  ) => Promise<Post | null>;
  onClose?: (userId?: number, discourseGroupTopicId?: number) => void;
  onToggleExpanded?: (topicId: number | undefined) => void;
  onLoadTopic: (topicId: number) => Promise<any>;
}

const ChatWidget: React.FC<ChatWidgetProps> = ({
  conversations,
  isLoading = false,
  onSubmit,
  onClose,
  onToggleExpanded,
  onLoadTopic,
}) => {
  const [fullscreenTopic, setFullscreenTopic] = useState<number | undefined>(
    undefined
  );
  const sortedConversations = [
    ...conversations.filter((c) => c.topicId !== fullscreenTopic),
    ...conversations.filter((c) => c.topicId === fullscreenTopic),
  ];
  return (
    <Flex
      position="fixed"
      bottom={0}
      right={0}
      alignItems="flex-end"
      zIndex={1200}
    >
      {sortedConversations.map((conversation) => (
        <Box marginX={2} key={conversation.details.title}>
          <ConversationWidget
            {...conversation}
            isLoading={isLoading}
            onSubmit={onSubmit}
            onClose={onClose}
            onToggleExpanded={onToggleExpanded}
            onLoadTopic={onLoadTopic}
            {...(PLATFORM === 'workshop' || conversation.topicId
              ? {
                  onToggleFullscreen: setFullscreenTopic,
                  isSetFullscreen: fullscreenTopic === conversation.topicId,
                }
              : {})}
          />
        </Box>
      ))}
      {PLATFORM === 'steppit' && (
        <Box marginX={2}>
          <AssistantWidget />
        </Box>
      )}
    </Flex>
  );
};

export default ChatWidget;
