import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { discourseImageResizeURL } from 'utils/urls';

import { GlobalState } from 'types';
import { PartialTopic } from 'screens/learner/ClassActivity';

import { discourseActions } from 'redux/actions/common';

import { RESULTS_PER_PAGE } from 'constants/discourse';

import { Flex, Button } from '@workshop/ui';

import { ActivityPopup } from 'components/ActivityPopup';

interface UserActivityModalProps {
  isOpen: boolean;
  onClose: () => void;
  username?: string;
  category: number;
  subcategory: number;
  cohortId: number;
}

const UserActivityModal: React.FC<UserActivityModalProps> = ({
  isOpen,
  onClose,
  username,
  category,
  subcategory,
  cohortId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [nextPageIsLoading, setNextPageIsLoading] = useState(false);
  const [currentResultsPage, setCurrentResultsPage] = useState(0);
  const [topicsList, setTopicsList] = useState<PartialTopic[]>([]);

  const dispatch = useDispatch();

  const categories = useSelector(
    (state: GlobalState) => state.discourse.categories
  );
  const members = useSelector((state: GlobalState) => state.discourse.members);
  const allTopics = useSelector((state: GlobalState) => state.discourse.topics);
  const cohorts = useSelector((state: GlobalState) => state.cms.cohort);
  const currentCategory = categories[subcategory];
  const currentMember = Object.values(members).find(
    (u) => u.username === username
  );
  const cohort = cohorts[cohortId];

  useEffect(() => {
    if (!username && currentCategory?.topicList.topics) {
      const topics = currentCategory?.topicList.topics
        .filter((topic) => topic.posters[0].userId !== -1)
        .sort((a, b) => (moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1))
        .map((topic) => {
          // Enhance the topic from discourse with details on the
          // original poster and with a resized image URL for improved
          // rendering performance of the image
          const originalPoster = currentCategory.users.find(
            (user) => user.id === topic.posters[0].userId
          );
          const imageUrl = topic.imageUrl
            ? discourseImageResizeURL(topic.imageUrl, 'medium')
            : null;

          return {
            ...topic,
            originalPoster,
            imageUrl,
          };
        });
      setTopicsList(topics);
    }
    if (username && currentMember) {
      if (currentMember.topics) {
        const topics = currentMember.topics
          .map((topicId) => {
            const topic = allTopics[topicId];
            const originalPoster = currentMember;
            const imageUrl = topic?.imageUrl
              ? discourseImageResizeURL(topic.imageUrl, 'medium')
              : null;

            return {
              ...topic,
              originalPoster,
              imageUrl,
            };
          })
          .filter((t) => t.categoryId === subcategory);
        setTopicsList(topics as PartialTopic[]);
      }
    }
  }, [
    currentCategory?.topicList.topics?.length,
    username,
    Object.values(allTopics).length,
    currentMember?.topics?.length,
  ]);

  const loadCategory = async (category: number, subcategory: number) => {
    setIsLoading(true);
    await dispatch(
      discourseActions.getSubcategory(category, subcategory, true, {
        order: 'created',
      })
    );
    setIsLoading(false);
  };

  const loadUserTopics = async (username: string) => {
    setIsLoading(true);
    await dispatch(
      discourseActions.getTopicsByUser(username, {
        // @ts-ignore
        order: 'created',
        category: subcategory,
      })
    );
    setIsLoading(false);
  };

  const loadNextPage = async (page: number) => {
    setNextPageIsLoading(true);
    if (username) {
      await dispatch(
        discourseActions.getTopicsByUser(username, {
          // @ts-ignore
          page,
          order: 'created',
          category: subcategory,
        })
      );
    } else {
      await dispatch(
        discourseActions.getSubcategory(category, subcategory, true, {
          page,
          order: 'created',
        })
      );
    }
    setCurrentResultsPage(page);
    setNextPageIsLoading(false);
  };

  useEffect(() => {
    if (username && category && subcategory) {
      loadUserTopics(username);
    }
    if (!username && category && subcategory) {
      loadCategory(category, subcategory);
    }
  }, [username, category, subcategory]);

  const totalNumTopics =
    (currentMember
      ? currentMember?.topics?.length
      : currentCategory?.topicList.topics.length) || 0;

  return (
    <ActivityPopup
      isOpen={isOpen}
      onClose={onClose}
      isCms
      topicsList={topicsList}
      isLoading={isLoading}
      titleBold={currentMember ? 'Posts' : 'Post Activity'}
      titleMuted={
        currentMember
          ? ` by ${currentMember.name}`
          : ` for ${`${cohort?.courseDetails.title}: ${moment(
              cohort?.startDate
            ).format("Do MMM [']YY")}${cohort?.label ? ' - ' : ''}${
              cohort?.label
            }`}`
      }
      openTopicsInNewTab
      footer={
        // If the number of results doesn't fill a single page,
        // or the next page to load is the same as the previously
        // loaded page, don't show the "show more" button
        <Flex justifyContent="center" alignItems="center">
          {isLoading ||
          totalNumTopics < RESULTS_PER_PAGE ||
          currentResultsPage ===
            Math.ceil(totalNumTopics / RESULTS_PER_PAGE) ? null : (
            <Button
              flex={1}
              icon="ExpandMore"
              variant="ghost"
              isLoading={nextPageIsLoading}
              onClick={() =>
                loadNextPage(Math.ceil(totalNumTopics / RESULTS_PER_PAGE))
              }
            >
              Show More
            </Button>
          )}
        </Flex>
      }
    />
  );
};

export default UserActivityModal;
