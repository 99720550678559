import React, { useState, useEffect, useRef } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';

import { CAP_CLIP_NAME } from 'constants/common';

import {
  Stack,
  Box,
  Flex,
  MdIcon,
  Text,
  Button,
  LightMode,
  useTheme,
} from '@workshop/ui';

import { EditModal } from 'components/Common/EditModal';
import {
  VideoClipsPlayer,
  StyledVideo,
  orientationStyleMapping,
  containerStyle,
} from 'components/VideoClipsPlayer';
import { AudioVisualizer } from 'components/AudioVisualizer';
import { Loading } from 'components/Loading';

import {
  MediaRecorderProps,
  MediaRecorderContentProps,
  StreamPreviewProps,
} from './types';

const StreamPreview: React.FC<StreamPreviewProps> = ({
  videoStream,
  audioStream,
  orientation,
  mediaType,
  muted,
  setMuted,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const theme = useTheme();

  const orientationStyles = orientationStyleMapping[orientation];

  useEffect(() => {
    if (videoRef.current && videoStream) {
      videoRef.current.srcObject = videoStream;
    }
  }, [videoStream]);

  if (!videoStream && !audioStream) {
    return null;
  }

  return (
    <Box {...orientationStyles} position="relative">
      <Flex
        {...containerStyle}
        alignItems="center"
        backgroundColor={
          mediaType === 'audio' ? 'background.primary' : 'background.tint2'
        }
        justifyContent="center"
        zIndex={0}
        borderRadius="md"
        overflow="hidden"
        // TODO: Only transform if video
        transform={mediaType === 'video' ? ['scale(-1, 1)'] : []}
      >
        {mediaType === 'audio' && audioStream ? (
          <Box paddingBottom={20}>
            <AudioVisualizer
              stream={audioStream}
              options={{
                barColor: theme.colors.common.primary,
                shadowColor: theme.colors.common.primaryTransparent,
              }}
            />
          </Box>
        ) : (
          <StyledVideo>
            <video ref={videoRef} autoPlay />
          </StyledVideo>
        )}
      </Flex>
      {mediaType !== 'audio' && (
        <Flex
          position="absolute"
          top={6}
          left={6}
          width={10}
          height={10}
          borderRadius="full"
          overflow="hidden"
          justifyContent="center"
          alignItems="center"
        >
          <LightMode>
            <Button
              position="absolute"
              icon={muted ? 'MicOff' : 'Mic'}
              borderRadius="full"
              w={10}
              colorScheme="blackAlpha"
              onClick={() => setMuted(!muted)}
            />
          </LightMode>
          <Box pointerEvents="none">
            {!muted && audioStream?.active && (
              <AudioVisualizer
                stream={audioStream}
                options={{
                  barColor: '#fff',
                  shadowColor: '#fff',
                }}
                size="sm"
              />
            )}
          </Box>
        </Flex>
      )}
    </Box>
  );
};

const MediaRecorderContent: React.FC<MediaRecorderContentProps> = ({
  mediaType,
  orientation,
  muted,
  isRecording,
  showPlayer,
  mediaUrl,
  closing,
  setOrientation,
  setMuted,
  setIsRecording,
  setShowPlayer,
  setMediaUrl,
  setBlob,
}) => {
  const videoSize =
    orientation === 'portrait'
      ? {
          aspectRatio: 9 / 16,
        }
      : {
          aspectRatio: 16 / 9,
        };
  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    previewStream,
    previewAudioStream,
    muteAudio,
    unMuteAudio,
  } = useReactMediaRecorder({
    video: mediaType === 'video' ? videoSize : false,
    audio: true,
    screen: mediaType === 'screen',
    askPermissionOnMount: true,
    stopStreamsOnStop: true,
    onStop: (blobUrl, blob) => setBlob(blob),
  });

  useEffect(() => {
    if (closing && !showPlayer) {
      // Needed for media stream to correctly unmount
      startRecording();
    }
  }, [closing, showPlayer]);

  useEffect(() => {
    return () => {
      stopRecording();
    };
  }, []);

  useEffect(() => {
    if (mediaBlobUrl) {
      setMediaUrl(mediaBlobUrl);
      setShowPlayer(true);
    }
  }, [mediaBlobUrl]);

  useEffect(() => {
    if (muted) {
      muteAudio();
    } else {
      unMuteAudio();
    }
  }, [muted]);

  const handleStartRecording = () => {
    startRecording();
    setIsRecording(true);
  };
  const handleStopRecording = () => {
    stopRecording();
    setIsRecording(false);
  };

  return (
    <Flex flexDirection="column">
      {previewStream || previewAudioStream ? (
        <Flex position="relative" justifyContent="center">
          <StreamPreview
            videoStream={previewStream}
            audioStream={previewAudioStream}
            orientation={orientation}
            mediaType={mediaType}
            muted={muted}
            setMuted={setMuted}
          />
          {mediaType === 'video' && (
            <Box position="absolute" top={6} right={6}>
              <LightMode>
                <Button
                  icon="ScreenRotation"
                  borderRadius="full"
                  w={10}
                  colorScheme="blackAlpha"
                  transform={[
                    orientation === 'portrait'
                      ? 'rotate(45deg)'
                      : 'rotate(135deg)',
                  ]}
                  onClick={() => {
                    stopRecording();
                    setOrientation(
                      orientation === 'portrait' ? 'landscape' : 'portrait'
                    );
                  }}
                />
              </LightMode>
            </Box>
          )}

          <Box position="absolute" bottom={12}>
            {isRecording ? (
              <Button icon="Cancel" onClick={() => handleStopRecording()}>
                Stop Recording
              </Button>
            ) : (
              <Button
                colorScheme="red"
                icon="FiberManualRecord"
                onClick={() => handleStartRecording()}
              >
                Start Recording
              </Button>
            )}
          </Box>
        </Flex>
      ) : (
        <Flex py={12}>
          <Loading />
        </Flex>
      )}
    </Flex>
  );
};

const MediaRecorder: React.FC<MediaRecorderProps> = ({
  isOpen,
  onClose,
  onSave,
  clipType,
}) => {
  const [orientation, setOrientation] = useState<'portrait' | 'landscape'>(
    'portrait'
  );
  const [muted, setMuted] = useState<boolean>(false);
  const [mediaType, setMediaType] = useState<'video' | 'audio' | 'screen'>(
    clipType
  );
  const [refreshing, setRefreshing] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);
  const [mediaUrl, setMediaUrl] = useState<string | undefined>(undefined);
  const [blob, setBlob] = useState<Blob | undefined>(undefined);
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setMediaType(clipType);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!showPlayer) {
      setRefreshing(true);
      // Required to restart stream
      setTimeout(() => setRefreshing(false), 100);
    }
  }, [orientation, mediaType, showPlayer, isOpen, clipType]);

  const mediaTypes: {
    name: string;
    icon: string;
    slug: 'video' | 'audio' | 'screen';
    disabled: boolean;
  }[] =
    clipType === 'video'
      ? [
          {
            name: 'Camera',
            icon: 'Videocam',
            slug: 'video',
            disabled: isRecording || showPlayer,
          },
          {
            name: 'Screen',
            icon: 'Monitor',
            slug: 'screen',
            disabled: isRecording || showPlayer,
          },
        ]
      : [
          {
            name: 'Audio',
            icon: muted ? 'MicOff' : 'Mic',
            slug: 'audio',
            disabled: isRecording || showPlayer || muted,
          },
        ];
  return (
    <EditModal
      title={`Record a ${CAP_CLIP_NAME}`}
      isOpen={isOpen}
      onClose={() => {
        setClosing(true);
        // Timeout needed to allow media stream to correctly unmount
        setTimeout(() => onClose(), 1);
      }}
      onSave={() => {
        onSave(blob);
        setClosing(true);
        // Timeout needed to allow media stream to correctly unmount
        setTimeout(() => onClose(), 1);
      }}
      onCancel={showPlayer ? () => setShowPlayer(false) : undefined}
      saveDisabled={!showPlayer}
      saveLabel={showPlayer ? 'Save' : ''}
      cancelLabel="Try Again"
      modalSize={
        mediaType === 'screen' ||
        (orientation === 'landscape' && mediaType === 'video')
          ? '3xl'
          : 'lg'
      }
    >
      {isOpen ? (
        <>
          <Stack direction={{ base: 'column', sm: 'row' }} mb={4}>
            {mediaTypes.map((mt) => (
              <Flex
                key={`mediaType-${mt.slug}`}
                flex={1}
                borderWidth={2}
                borderColor={
                  mt.slug === mediaType ? 'common.primary' : 'border.muted'
                }
                backgroundColor={
                  mt.slug === mediaType
                    ? 'background.default'
                    : 'background.tint1'
                }
                borderRadius="md"
                p={4}
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                _hover={{ backgroundColor: 'background.default' }}
                onClick={() => {
                  if (mt.slug === 'screen') {
                    setClosing(true);
                    setTimeout(() => {
                      setMediaType(mt.slug);
                      setClosing(false);
                    }, 1);
                  } else {
                    setMediaType(mt.slug);
                  }
                }}
                {...(mt.disabled
                  ? {
                      pointerEvents: 'none',
                      opacity: 0.5,
                    }
                  : {})}
              >
                <Box mb={1}>
                  <MdIcon name={mt.icon} boxSize={6} color="common.primary" />
                </Box>
                <Box>
                  <Text fontSize="md" fontWeight="bold" color="common.primary">
                    {mt.name}
                  </Text>
                </Box>
              </Flex>
            ))}
          </Stack>
          {refreshing ? null : (
            <>
              {!showPlayer && (
                <MediaRecorderContent
                  mediaType={mediaType}
                  orientation={
                    mediaType === 'video'
                      ? orientation
                      : mediaType === 'screen'
                      ? 'landscape'
                      : 'square'
                  }
                  muted={mediaType === 'audio' ? false : muted}
                  isRecording={isRecording}
                  showPlayer={showPlayer}
                  mediaUrl={mediaUrl}
                  closing={closing}
                  setOrientation={setOrientation}
                  setMuted={setMuted}
                  setIsRecording={setIsRecording}
                  setShowPlayer={setShowPlayer}
                  setMediaUrl={setMediaUrl}
                  setBlob={setBlob}
                />
              )}
              {showPlayer && mediaUrl && (
                <Flex borderRadius="md" overflow="hidden">
                  <VideoClipsPlayer
                    autoplay
                    autoPlayNext
                    isEditable={false}
                    clips={[
                      {
                        type: mediaType === 'audio' ? 'audio' : 'video',
                        id: '',
                        data: {
                          autoplay: true,
                          clip: {
                            src: mediaUrl,
                          },
                        },
                      },
                    ]}
                    orientation={
                      mediaType === 'video'
                        ? orientation
                        : mediaType === 'screen'
                        ? 'landscape'
                        : 'square'
                    }
                  />
                </Flex>
              )}
            </>
          )}
        </>
      ) : null}
    </EditModal>
  );
};

export default MediaRecorder;
