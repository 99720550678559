import { theme } from '@chakra-ui/react';
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';

import {
  Color,
  ColorDark,
  Palette,
  SteppitColor,
  SteppitColorDark,
  SteppitPalette,
  Size,
  Spacing,
} from '.';

import { PLATFORM } from 'constants/env';

const sharedColorKeys: (string | number)[] = [
  'transparent',
  'current',
  'black',
  'white',
  'whiteAlpha',
  'blackAlpha',
  'gray',
  // 'yellow',
  // 'teal',
  // 'cyan',
  'linkedin',
  'facebook',
  'messenger',
  'whatsapp',
  'twitter',
  'telegram',
];

type ThemeColors = typeof theme.colors;
type ThemeColorKey = keyof ThemeColors;

/**
 * Use the whitelist `sharedColorKeys` to generate a version of
 * the Chakra theme `colors` which only includes the whitelisted
 * values.
 *
 * These default values are later merged with our own color
 * defitions within the theme object.
 */
const sharedColors = Object.keys(theme.colors).reduce<ThemeColors>(
  (acc: ThemeColors, key) => {
    const themeColorKey = key as ThemeColorKey;
    if (sharedColorKeys.includes(themeColorKey)) {
      // @ts-ignore
      acc[themeColorKey] = theme.colors[key as ThemeColorKey];
    }
    return acc;
  },
  {} as ThemeColors
);

const Theme = {
  ...theme,
  config: {
    ...theme.config,
  },
  fonts: {
    body: 'Raleway, Helvetica Neue, Helvetica, Arial, sans-serif',
    heading: 'Raleway, Helvetica Neue, Helvetica, Arial, sans-serif',
    mono: '"Roboto Mono", monospace',
  },
  colors: {
    ...sharedColors,
    ...(PLATFORM === 'steppit' ? SteppitPalette : Palette),
    ...(PLATFORM === 'steppit' ? SteppitColor : Color),
  },
  sizes: Size,
  space: {
    ...theme.space,
    ...Spacing,
  },
  fontSizes: {
    '2xs': '10px',
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '36px',
    '5xl': '48px',
    '6xl': '64px',
  },
  radii: {
    ...theme.radii,
    sm: '5px',
    md: '10px',
    lg: '20px',
    xl: '40px',
  },
  shadows: {
    ...theme.shadows,
    md: '0px 5px 15px 0px rgba(0,0,0,0.05)',
    lg: '0px 10px 30px 0px rgba(0,0,0,0.05)',
  },
  letterSpacings: {
    ...theme.letterSpacings,
    title: '-0.75px',
  },
  breakpoints: {
    ...theme.breakpoints,
    sm: '374px',
    md: '768px',
    lg: '960px',
    xl: '1200px',
    '2xl': '1536px',
  },
  components: {
    ...theme.components,
    Steps,
  },
};

export const DarkTheme = {
  ...Theme,
  colors: {
    ...Theme.colors,
    ...(PLATFORM === 'steppit' ? SteppitColorDark : ColorDark),
  },
};

export default Theme;
