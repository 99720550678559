import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { organisationActions } from 'redux/actions/common';

import {
  Button,
  Card,
  Badge,
  Text,
  Link,
  Flex,
  Skeleton,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  VStack,
  MdIcon,
} from '@workshop/ui';

import { SubscriptionData } from 'types/common';

import { helpUrl } from 'constants/env';

interface SubscriptionManagementProps {
  activeSubscriptions: SubscriptionData[];
  isLoading?: boolean;
  showPortalLink?: boolean;
}

const SubscriptionManagement: React.FC<SubscriptionManagementProps> = ({
  activeSubscriptions,
  isLoading = false,
  showPortalLink = false,
}) => {
  const [isLinkLoading, setIsLinkLoading] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <Card>
        <Skeleton
          isLoaded={!isLoading}
          loadingStyle={{ width: '75%', height: 6 }}
          w="100%"
        >
          {activeSubscriptions.length ? (
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th px={3} py={2}>
                    Subscription
                  </Th>
                  <Th px={3} py={2}>
                    Status
                  </Th>
                  <Th></Th>
                </Tr>
              </Thead>
              {activeSubscriptions.map((subscription) => {
                const {
                  plan,
                  status,
                  cancelAtPeriodEnd,
                  cancelAt,
                  currentPeriodEnd,
                  canceledAt,
                } = subscription;
                return (
                  <Tbody>
                    <Tr>
                      <Td px={3} py={2} w={{ base: 'auto', md: '99%' }}>
                        <Flex flexDir="column" flex={1}>
                          <Text fontWeight="semibold">{plan.product.name}</Text>
                          <Text fontSize="sm">{plan.nickname}</Text>
                        </Flex>
                      </Td>
                      <Td px={3} py={2} sx={{ textWrap: 'nowrap' }}>
                        <VStack alignItems="flex-start" spacing={1}>
                          {status ? (
                            <Badge
                              variant="subtle"
                              colorScheme={
                                status === 'active' ? 'green' : 'blackAlpha'
                              }
                            >
                              {status}
                            </Badge>
                          ) : (
                            <Flex flex={1} />
                          )}
                        </VStack>
                      </Td>
                      <Td
                        px={3}
                        py={2}
                        sx={{ textWrap: { base: 'wrap', md: 'nowrap' } }}
                      >
                        <Flex>
                          <Text fontSize="sm" color="text.muted">
                            {cancelAtPeriodEnd && cancelAt
                              ? `Expires ${moment
                                  .unix(cancelAt)
                                  .format(`MMM Do [']YY`)}`
                              : canceledAt
                              ? `Expired ${moment
                                  .unix(canceledAt)
                                  .format(`MMM Do [']YY`)}`
                              : `Renews ${moment
                                  .unix(currentPeriodEnd)
                                  .format(`MMM Do [']YY`)}`}
                          </Text>
                        </Flex>
                      </Td>
                    </Tr>
                  </Tbody>
                );
              })}
            </Table>
          ) : (
            <Flex flexDir="column" p={2}>
              <Text fontWeight="semibold">
                You currently have no active subscriptions
              </Text>
              <Text fontSize="sm" color="text.muted">
                If you think this is incorrect, please{' '}
                <Link color="common.primary" href={helpUrl}>
                  get in touch
                </Link>
                .
              </Text>
            </Flex>
          )}
        </Skeleton>
      </Card>
      {showPortalLink && (
        <Flex
          justifyContent="flex-start"
          mt={5}
          mx={{ base: 'defaultMargin', md: 0 }}
        >
          <Button
            isLoading={isLoading || isLinkLoading}
            onClick={async () => {
              setIsLinkLoading(true);
              const res = await dispatch(
                organisationActions.fetchSubscriptions(true)
              );
              if (!res?.error) {
                // @ts-ignore
                if (res?.payload?.redirect) {
                  // @ts-ignore
                  return window.open(res.payload.redirect, '_self');
                }
              }
              setIsLinkLoading(false);
            }}
            icon="CardMembership"
            rightIcon={<MdIcon name="OpenInNew" fontSize="xs" />}
            colorScheme="green"
            variant="outline"
          >
            Manage Subscriptions
          </Button>
        </Flex>
      )}
    </>
  );
};

export default SubscriptionManagement;
