import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useForm, FormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Post, Topic } from 'discourse-js';
import moment from 'moment';

import { GlobalState } from 'types';

import {
  Box,
  Button,
  Flex,
  Input,
  MdIcon,
  Text,
  Textarea,
  useTheme,
} from '@workshop/ui';

import { discourseActions } from 'redux/actions/common';
import { journalActions } from 'redux/actions/learner';
import { useUser } from 'redux/selectors';

import { ImageUpload } from 'components/Common';

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

interface NewPostForm {
  image: File;
  title: string;
  description: string;
}

type SelectOption =
  | {
      value: string;
      label: string;
    }
  | null
  | undefined;

export interface NewPostCardProps {
  discourseCategoryId?: number;
  title?: string;
  onSubmitSuccess?: (post: Post | null) => void;
  onCancel?: () => void;
  buttonsPosition?: 'top' | 'bottom';
  buttonsAlign?: 'right' | 'center';
  submitButtonLabel?: string;
  fullWidth?: boolean;
  moduleProgressId?: number | null;
  tags?: string[] | null;
  isTitleRequired?: boolean;
  isTextOnly?: boolean;
  isAssessment?: boolean;
  moduleOptions?: {
    value: string;
    label: string;
    options: {
      value: string;
      label: string;
    }[];
  }[];
}

const NewPostCard: React.FC<NewPostCardProps> = ({
  onSubmitSuccess,
  onCancel,
  discourseCategoryId,
  title,
  buttonsPosition = 'top',
  buttonsAlign = 'right',
  submitButtonLabel = 'Post',
  fullWidth = false,
  moduleProgressId = null,
  tags = null,
  isTitleRequired = true,
  isTextOnly = false,
  isAssessment = false,
  moduleOptions,
}) => {
  const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedModule, setSelectedModule] = useState<SelectOption>(null);
  const formMethods = useForm<NewPostForm>({});
  const user = useUser();
  const theme = useTheme();

  const moduleProgress = useSelector((state: GlobalState) =>
    Object.values(state.learner.courseProgress.modules).find((mp) =>
      selectedModule
        ? mp.module === selectedModule.value
        : mp.id === moduleProgressId
    )
  );
  const module = useSelector((state: GlobalState) =>
    Object.values(state.learner.courses.modules).find((m) =>
      selectedModule
        ? m.slug === selectedModule.value
        : m.slug === moduleProgress?.module
    )
  );
  const unit = useSelector((state: GlobalState) =>
    Object.values(state.learner.courses.units).find(
      (u) => u.slug === module?.unit
    )
  );

  const currentModuleProgressId = moduleProgressId || moduleProgress?.id;
  const currentTags = tags
    ? tags
    : module && unit
    ? [`module-${module.index - 1}`, `unit-${unit.index - 1}`]
    : null;

  // Poll image response from discourse to sync up with journal
  const pollTopicUntilImageExists = async (
    id: number
  ): Promise<Topic | undefined> => {
    const topic = await dispatch(discourseActions.getTopic(id));
    if (topic) {
      const { imageUrl } = topic;
      if (imageUrl) {
        return topic;
      }
      await timeout(200);
      return pollTopicUntilImageExists(id);
    }
  };

  const {
    register,
    handleSubmit,
    errors,
    formState: { dirty, isValid },
    setValue,
    unregister,
    triggerValidation,
  } = formMethods;

  useEffect(() => {
    register('image', { required: !isTextOnly });

    return () => {
      unregister('image');
    };
  }, [register, unregister]);

  const onSubmit = handleSubmit(async (data) => {
    setIsSubmitting(true);

    let post = null;
    const title = module
      ? `${user.name || ''}: ${module.title} (${moment().unix()})`
      : data.title
      ? data.title
      : '';

    if (!isAssessment) {
      post = await dispatch(
        discourseActions.createTopic({
          raw: data.description,
          title,
          imageFile: data.image,
          category: discourseCategoryId || undefined,
          ...(currentTags ? { tags: currentTags } : {}),
        })
      );
    }

    // Poll image response from discourse to sync up with journal
    let migratedImageUrl: string | null = null;
    let topic: Topic | undefined | void;
    if (post) {
      if (data.image) {
        topic = await pollTopicUntilImageExists(post.topicId);
        if (topic) {
          const { imageUrl } = topic;
          migratedImageUrl = imageUrl;
        }
      } else {
        topic = await dispatch(discourseActions.getTopic(post.topicId));
      }
    }

    if ((post || isAssessment) && currentModuleProgressId) {
      await dispatch(
        journalActions.createModuleProgressJournalEntry({
          description: data.description,
          moduleProgress: currentModuleProgressId,
          ratingStrength: 3,
          ...(title ? { title } : {}),
          ...(post ? { discourseTopicId: post.topicId } : {}),
          ...(isAssessment ? { isAssessment, imageFile: data.image } : {}),
          ...(migratedImageUrl && topic ? { migratedImageUrl, topic } : {}),
        })
      );
    }

    (post || isAssessment) && onSubmitSuccess && onSubmitSuccess(post || null);

    setIsSubmitting(false);
  });

  const onImageDrop = async (name: string, acceptedFiles: File[]) => {
    setValue(name, acceptedFiles[0]);
    await setImagePreview(URL.createObjectURL(acceptedFiles[0]));
    triggerValidation();
  };

  return (
    <Box flex={1}>
      <FormContext {...formMethods}>
        <Flex
          flex={1}
          flexDirection={{ base: 'column', md: fullWidth ? 'column' : 'row' }}
        >
          {!isTextOnly && (
            <Flex mb={{ base: 2, md: fullWidth ? 2 : 0 }}>
              <ImageUpload
                id="new_post_image"
                backgroundColor="background.tint2"
                height="200px"
                name="image"
                width={{ base: '100%', md: fullWidth ? '100%' : '200px' }}
                image={imagePreview || ''}
                onDrop={onImageDrop}
                styleProps={{
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                }}
              />
            </Flex>
          )}
          <Flex
            flexDir="column"
            flex={1}
            paddingLeft={{ base: 0, md: fullWidth ? 0 : 2 }}
          >
            <Flex
              mb={title || buttonsPosition === 'top' ? 2 : 0}
              alignItems="center"
            >
              {title ? (
                <>
                  <Flex
                    alignItems="center"
                    borderRadius="xl"
                    boxSize="image.2xs"
                    background="background.tint2"
                    mr={2}
                    justifyContent="center"
                  >
                    <MdIcon boxSize="icon" name="People" color="icon.default" />
                  </Flex>
                  <Text fontWeight="semibold" mr={4} marginY={0}>
                    {title}
                  </Text>
                </>
              ) : null}
              <Box flex={1} />
              {buttonsPosition === 'top' && (
                <>
                  {onCancel ? (
                    <Button secondary onClick={onCancel} mr={2}>
                      Cancel
                    </Button>
                  ) : null}
                  <Button
                    isDisabled={isSubmitting || !dirty || !isEmpty(errors)}
                    isLoading={isSubmitting}
                    onClick={onSubmit}
                  >
                    Post
                  </Button>
                </>
              )}
            </Flex>
            {moduleOptions && (
              <Box mb={2}>
                <Select
                  options={moduleOptions}
                  value={selectedModule}
                  onChange={(module) => setSelectedModule(module)}
                  placeholder="Select session..."
                  styles={{
                    control: (base: React.CSSProperties) => ({
                      ...base,
                      cursor: 'pointer',
                      backgroundColor: theme.colors.background.default,
                      borderColor: theme.colors.border.muted,
                      borderRadius: theme.radii.md,
                    }),
                    placeholder: (base: React.CSSProperties) => ({
                      color: theme.colors.text.primary,
                    }),
                    menu: (base: React.CSSProperties) => ({
                      ...base,
                      backgroundColor: theme.colors.background.default,
                    }),
                    option: (base: React.CSSProperties) => ({
                      ...base,
                      cursor: 'pointer',
                    }),
                  }}
                />
              </Box>
            )}
            {Boolean(isTitleRequired && !module) && (
              <Input
                boxSizing="border-box"
                mb={2}
                name="title"
                placeholder="Add a title..."
                fontWeight="semibold"
                ref={register({
                  required: true,
                  validate: (val) => Boolean(val?.length),
                })}
                onChange={() => !isValid && triggerValidation()}
                backgroundColor="background.default"
                px={2}
              />
            )}
            <Textarea
              boxSizing="border-box"
              flex={1}
              name="description"
              placeholder="Write a description..."
              ref={register({
                required: true,
                validate: (val) => Boolean(val?.length),
              })}
              resize="none"
              onChange={() => !isValid && triggerValidation()}
              backgroundColor="background.default"
              px={2}
            />
          </Flex>
        </Flex>
        {buttonsPosition === 'bottom' && (
          <Flex
            mt={4}
            justifyContent={buttonsAlign === 'center' ? 'center' : 'flex-end'}
          >
            {onCancel ? (
              <Button
                secondary
                onClick={onCancel}
                mr={2}
                size={buttonsAlign === 'center' ? 'md' : 'sm'}
              >
                Cancel
              </Button>
            ) : null}
            <Button
              isDisabled={isSubmitting || !dirty || !isEmpty(errors)}
              isLoading={isSubmitting}
              onClick={onSubmit}
              size={buttonsAlign === 'center' ? 'md' : 'sm'}
              minWidth={buttonsAlign === 'center' ? '200px' : 0}
              icon={isAssessment ? 'AssignmentTurnedIn' : 'PostAdd'}
            >
              {submitButtonLabel}
            </Button>
          </Flex>
        )}
      </FormContext>
    </Box>
  );
};

export default NewPostCard;
