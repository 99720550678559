/**
 * Takes a number and splits it into a group of 3 digits, adding commas
 * between each group.
 *
 * e.g: commaSeparatedThousands(1001) => "1,001"
 */
function commaSeparatedThousands(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const normaliseMaxValue = (value: number, step: number) => {
  /**
   * We must normalise the maximum value to ensure consistent Y label spacing regardless of
   * the maximum value's magnitude (e.g. 0 to 10 has the same spacing as 0 to 1000)
   */
  let valueFloored = Math.floor(value / 10);
  while (valueFloored % step !== 0) {
    valueFloored += 1;
  }
  return valueFloored * 10;
};

const roundnum = (num: number, base: number = 10) =>
  base ** String(num).length / base;

export { commaSeparatedThousands, normaliseMaxValue, roundnum };
