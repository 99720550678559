import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { organisationActions } from 'redux/actions/common';

import { Button, Flex } from '@workshop/ui';

import { ConfirmModal } from 'components/Common';
import { CreateOrgPopup } from 'components/CreateOrgPopup';
import CreateLicenseModal from 'screens/cms/MyOrganisation/src/CreateLicenseModal';

import { HomeCardItemVariantProps } from './index';

const SchoolsLicensor: React.FC<HomeCardItemVariantProps> = ({
  variantId,
  isCurrentTeam,
  inviteMessage,
  inviteLandingPage,
  invitePageButtonLabel,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [showCreateLicense, setShowCreateLicense] = useState(false);
  const [orgPopupIsOpen, setOrgPopupIsOpen] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [defaultLicensee, setDefaultLicensee] = useState<number | null>(null);
  const openLicensePopup = async () => {
    await dispatch(organisationActions.setCurrentTeam(variantId || null));
    setShowCreateLicense(true);
  };
  const openNewOrgPopup = async () => {
    await dispatch(organisationActions.setCurrentTeam(variantId || null));
    setOrgPopupIsOpen(true);
  };
  return (
    <>
      <ConfirmModal
        body="Are you sure you want to exit this flow?"
        btnColor="blue"
        btnLabel="Yes, Exit"
        title=""
        isOpen={showConfirmModal}
        isLoading={false}
        onClose={() => setShowConfirmModal(false)}
        onClick={() => {
          setShowConfirmModal(false);
          setOrgPopupIsOpen(false);
        }}
      />
      {isCurrentTeam && (
        <Flex
          px={2}
          py={2}
          background="background.tint1"
          borderTopWidth={1}
          borderBottomWidth={1}
          borderColor="border.muted"
          flexDirection={{ base: 'column', sm: 'row' }}
          {...rest}
        >
          <Button
            onClick={() => openNewOrgPopup()}
            icon="AddBusiness"
            variant="outline"
            flex={{ base: 'initial', sm: 1 }}
          >
            Add a School
          </Button>
          <Flex p={1} />
          <Button
            onClick={() => openLicensePopup()}
            icon="NoteAdd"
            variant="outline"
            flex={{ base: 'initial', sm: 1 }}
          >
            Create Agreement
          </Button>
        </Flex>
      )}
      {orgPopupIsOpen && (
        <CreateOrgPopup
          isOpen={orgPopupIsOpen}
          onClose={(confirm = true) => {
            if (confirm) {
              setShowConfirmModal(true);
            } else {
              setOrgPopupIsOpen(false);
            }
          }}
          onComplete={(newTeamId: number) => {
            setDefaultLicensee(newTeamId);
            openLicensePopup();
          }}
          reassignOwner
          title="Add a New School"
          detailsLabel="School Details"
          nameInputLabel="What is the name of this school?"
          nameInputHelpText=""
          nameInputPlaceholder="School Name"
          nameInputErrorMessage="Please enter a name for this school"
          inviteDescription="Invite teachers and team members who will manage their own classes based on your courses. (Note: The school's 'Owner' will be able to invite new team members themselves)."
          inviteMessage={inviteMessage}
          inviteLandingPage={inviteLandingPage}
          invitePageButtonLabel={invitePageButtonLabel}
        />
      )}
      {showCreateLicense && (
        <CreateLicenseModal
          title="New Bulk Enrolment Agreement"
          isOpen
          onClose={() => setShowCreateLicense(false)}
          onCancel={() => setShowCreateLicense(false)}
          onSave={() => null}
          modalSize="lg"
          defaultSelectFromOrgs
          defaultLicensee={defaultLicensee || undefined}
          orgNaming="school"
          onClickCreateNewOrg={() => {
            setShowCreateLicense(false);
            openNewOrgPopup();
          }}
        />
      )}
    </>
  );
};

export default SchoolsLicensor;
