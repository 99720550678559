import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import isEmail from 'validator/lib/isEmail';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { analytics } from 'utils';

import { discourseActions } from 'redux/actions/common';
import { useUser, useDiscourseUser } from 'redux/selectors';

import navRoutes from 'navigation/Routes';

import { discourseUrl } from 'constants/env';

import { Input, Flex, Button, MdIcon, Text, Link } from '@workshop/ui';
import {
  EditCard,
  LabelInput,
  LabelSelect,
  LabelWrapper,
} from 'components/Common';
import { UserAvatar } from 'components/UserAvatar';

import { errorToastMessage } from 'redux/actions/common/ui';

interface AccountBasicsForm {
  email: string;
  name: string;
  country: string;
  dateOfBirth: string;
}

interface AccountBasicsProps {
  email: string;
  name: string;
  country: string;
  dateOfBirth: string;
  unverifiedEmail: string | null;
  isLoading?: boolean;
  countryOptions: {
    [key: string]: string;
  };
  onSubmit: (args: AccountBasicsForm) => void;
  onCancelEmailVerification: () => void;
  onResendVerificationEmail: () => void;
}

const AccountBasics: React.FC<AccountBasicsProps> = (props) => {
  const {
    countryOptions,
    isLoading = false,
    name,
    email,
    unverifiedEmail,
    country,
    dateOfBirth,
    onSubmit,
    onCancelEmailVerification,
    onResendVerificationEmail,
  } = props;
  const [state, setState] = useState({
    isSubmitting: false,
    isResending: false,
    isCancelling: false,
  });
  const [avatarLoading, setAvatarLoading] = useState(false);
  const dispatch = useDispatch();

  const user = useUser();
  const discourseUser = useDiscourseUser();

  const {
    register,
    handleSubmit,
    errors,
    clearError,
    formState: { dirty },
    reset,
    watch,
    setValue,
  } = useForm<AccountBasicsForm>({
    defaultValues: {
      email: unverifiedEmail || email,
      name,
      country,
      dateOfBirth,
    },
  });

  const countryValue = watch('country');
  const dobValue = watch('dateOfBirth');
  const userAge = dobValue
    ? moment(moment()).diff(dobValue, 'years')
    : undefined;

  useEffect(() => {
    if (countryValue === '-') {
      setValue('country', '');
    }
    if (countryValue.charAt(countryValue.length - 1) === '1') {
      setValue('country', countryValue.slice(0, 2));
    }
  }, [countryValue]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const onSubmitData = handleSubmit(async (data) => {
    if (data.dateOfBirth) {
      if (Boolean(userAge && userAge < 13)) {
        dispatch(
          errorToastMessage('You must be over 13 years old to continue.')
        );
        throw new Error();
      }
    }
    setState((prevState) => ({ ...prevState, isSubmitting: true }));
    await onSubmit(data);
    if (data.dateOfBirth) {
      analytics.track('Date of Birth Entered', {
        date_of_birth: data.dateOfBirth,
      });
    }
    reset(data);
    setState((prevState) => ({ ...prevState, isSubmitting: false }));
  });

  const handleCancelEmailVerification = async () => {
    setState((prevState) => ({ ...prevState, isCancelling: true }));
    await onCancelEmailVerification();
    setState((prevState) => ({ ...prevState, isCancelling: false }));
    // On cancelling email change we revert the email value
    // back to the original verified email
    reset({ email });
  };

  const handleResendVerificationEmail = async () => {
    setState((prevState) => ({ ...prevState, isResending: true }));
    await onResendVerificationEmail();
    setState((prevState) => ({ ...prevState, isResending: false }));
  };

  const onAvatarChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const files = e?.target?.files;

    if (!files?.length) return;

    // TODO : fix ts definition for createUpload body in discourse-js
    const upload = await dispatch(
      discourseActions.createUpload({
        // @ts-ignore
        'files[]': files[0],
        type: 'avatar',
        ...(discourseUser?.id ? { user_id: discourseUser.id } : {}),
      })
    );

    if (!upload) {
      dispatch(
        errorToastMessage(
          'Failed to upload image. Please refresh the page and try again.'
        )
      );
      return;
    }

    const success = await dispatch(
      discourseActions.pickAvatar(discourseUser.username, upload.id)
    );

    if (!success) {
      dispatch(
        errorToastMessage(
          'Failed to set avatar. Please refresh the page and try again.'
        )
      );
      return;
    }

    analytics.track('Profile Picture Uploaded');

    dispatch(discourseActions.getUser(user.username));
  };

  // If the users current email and unverified email are equal, then it
  // must mean that they haven't verified their original email address
  // used during registration.
  const isEmailInitial = email === unverifiedEmail;

  return (
    <EditCard
      onSave={onSubmitData}
      onCancel={() => {
        clearError();

        reset({
          email,
          name,
          country,
        });
      }}
      isUpdating={state.isSubmitting}
      saveDisabled={Boolean(
        !dirty || !isEmpty(errors) || Boolean(userAge && userAge < 13)
      )}
    >
      <Flex flexDir="column">
        <LabelWrapper label="Profile Picture">
          <Input
            ref={fileInputRef}
            id="userAvatar"
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={async (e) => {
              setAvatarLoading(true);
              await onAvatarChange(e);
              setAvatarLoading(false);
            }}
            name="userAvatar"
          />
          <UserAvatar
            canEdit
            name={user.name}
            userId={user.id}
            avatarPicture={
              Boolean(discourseUser?.avatarTemplate)
                ? `${discourseUrl}${discourseUser.avatarTemplate.replace(
                    '{size}',
                    '240'
                  )}`
                : ''
            }
            onClick={() => fileInputRef.current?.click()}
            isLoading={avatarLoading}
            size="md"
          />
        </LabelWrapper>
        <LabelInput
          registerInputRef={register({
            required: true,
            validate: {
              isEmail: (value: string) => Boolean(isEmail(value)),
            },
          })}
          id="email"
          name="email"
          label="Email"
          error={Boolean(errors.email)}
          errorMessage="Please enter a valid email address."
          isLoading={isLoading}
          loadingStyle={{ width: '75%' }}
          isDisabled={!!unverifiedEmail}
          helpText={
            isEmailInitial
              ? `Your email address is still pending verification.`
              : unverifiedEmail
              ? `${unverifiedEmail} is still pending verification. Until it is verified,` +
                ` all emails will be sent to ${email}. Check your email (${unverifiedEmail})` +
                ' to confirm your new email address.'
              : undefined
          }
          rightIcon={
            unverifiedEmail ? (
              <MdIcon color="text.warning" name="Warning" />
            ) : undefined
          }
        />
        {unverifiedEmail && (
          <Flex
            justifyContent="flex-end"
            mb="defaultMargin"
            mt={{ base: 6, sm: 3 }}
          >
            <Button
              onClick={handleResendVerificationEmail}
              size="sm"
              isLoading={state.isResending}
            >
              Resend Verification Email
            </Button>
            {!isEmailInitial && (
              <Button
                onClick={handleCancelEmailVerification}
                size="sm"
                isLoading={state.isCancelling}
                ml={2}
              >
                Cancel this change
              </Button>
            )}
          </Flex>
        )}
        <LabelInput
          id="name"
          name="name"
          label="Name"
          error={Boolean(errors.name)}
          errorMessage="Please enter a valid name."
          isLoading={isLoading}
          registerInputRef={register({ required: true })}
        />
        <LabelSelect
          id="country"
          name="country"
          label="Country"
          error={Boolean(errors.country)}
          errorMessage="This information is required."
          isLoading={isLoading}
          registerInputRef={register}
          options={countryOptions}
          defaultValue={country ? countryOptions[country] : undefined}
          unsorted
        />
        <LabelInput
          id="dateOfBirth"
          name="dateOfBirth"
          label="Date of Birth"
          placeholder="Please enter your birth date"
          error={Boolean(errors.dateOfBirth)}
          errorMessage="Please enter your birth date"
          registerInputRef={register}
          inputType="date"
        />
        {Boolean(userAge !== undefined && userAge < 13) && (
          <Text color="text.error" fontSize="sm">
            You must be over 13 years old to continue. Please see our{' '}
            <Link
              color="text.info"
              href={navRoutes.global.terms.path()}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </Link>{' '}
            for more information.
          </Text>
        )}
      </Flex>
    </EditCard>
  );
};

export default AccountBasics;
