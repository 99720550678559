// @ts-nocheck

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import * as Sentry from '@sentry/react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import styled, {
  Flex,
  Card,
  Spinner,
  Heading,
  // toaster,
  Text,
  Paragraph,
  Radio as Radiobutton,
  Button as ButtonReset,
  Color,
  Checkbox,
  Alert,
  Divider as Hr,
  Theme,
  Box,
  Input,
  MdIcon,
  chakra,
  useTheme,
  LinkButton,
} from '@workshop/ui';
import { Loading } from 'components/Loading';

import navRoutes from 'navigation/Routes';
import { PLATFORM_DISPLAY_NAME, PLATFORM_EMAIL } from 'constants/common';
import { helpUrl, PLATFORM } from 'constants/env';

import { analytics } from 'utils';
import { getCookie } from 'utils/index';

import { Dispatch } from 'types';

import * as api from './api';

import Collapsible from './Collapsible';

import './css/generic.css';
// import './css/landing.css';

import type {
  StripeConfig,
  Cohort,
  Countries,
  OrderItems,
  Bundle,
  Coupon,
} from './types';

const validateEmail = (email: string): boolean => {
  // eslint-disable-next-line
  const emailRe =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRe.test(email);
};

const getParamFromUrl = (search: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  const param = urlParams.get(search) ? urlParams.get(search) : null;
  return param;
};

const Spacing = {
  xsmall: Theme.space['2'],
  small: Theme.space['4'],
  medium: Theme.space['4'],
};

const Device = {
  mobileL: `(min-width: ${Theme.breakpoints.sm})`,
  tablet: `(min-width: ${Theme.breakpoints.md})`,
  laptop: `(min-width: ${Theme.breakpoints.lg})`,
  desktop: `(min-width: ${Theme.breakpoints.xl})`,
  raw: {
    tablet: parseInt(Theme.breakpoints.md.replace('px', '')),
  },
};

const Small = styled(Text)`
  font-size: 14px;
`;

const PageWrapper = styled(Flex)`
  padding: ${Spacing.small};
  margin: 0 auto;
  width: 100%;

  @media ${Device.mobileL} {
    max-width: 640px;
  }
  @media ${Device.tablet} {
    max-width: 960px;
  }
  @media ${Device.laptop} {
    max-width: 960px;
  }
  @media ${Device.desktop} {
    max-width: 1140px;
  }
`;
PageWrapper.displayName = 'PageWrapper';

const MainSection = styled(Flex)`
  @media ${Device.tablet} {
    padding-top: ${Spacing.small};
  }
  padding-bottom: ${Spacing.medium};
`;

const VisibleDesktopOnly = styled.div`
  display: none;

  @media ${Device.desktop} {
    display: block;
  }
`;

const MainWrapper = styled(Flex)`
  // max-width: 600px;
  // margin: 0 auto;
  // padding: 30px 0;
`;

const MainPane = styled.div`
  text-align: center;
  transition: width 0.3s ease-in-out;
  position: relative;

  /* Max width on mobile */
  width: 100%;
  margin-right: 0px;

  @media only screen and ${Device.tablet} {
    // Difference between width of main pane & width of summary pane
    margin-right: 20px;
  }

  @media only screen and ${Device.desktop} {
    // Difference between width of main pane & width of summary pane
    margin-right: 20px;
  }
`;

const Step = styled.div`
  text-align: left;
  margin: 1rem 0 0;
`;

const StepButton = styled(ButtonReset)`
  width: 100%;
  height: 56px;
`;

const ChangeButton = ({ visible, children, ...props }) => (
  <ButtonReset
    variant="outline"
    size="xs"
    cursor={visible ? 'pointer' : 'default'}
    opacity={visible ? 1 : 0}
    pointerEvents={visible ? 'auto' : 'none'}
    {...props}
  >
    {children}
  </ButtonReset>
);

const PlainButton = ButtonReset;

const CheckoutCard = styled(Card)`
  flex: 1;
  padding: 20px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  // Override Card default
  @media (max-width: 450px) {
    box-shadow: none;
    border-radius: 0px;
    margin-left: -${Spacing.small};
    margin-right: -${Spacing.small};
  }

  @media only screen and ${Device.tablet} {
    padding: 25px;
  }

  ${(props) =>
    props.disabled &&
    `background-color: ${props.theme.colors.background.tint1}`};

  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
`;

const SummaryPane = styled(Card)`
  overflow: hidden;

  // Override Card default
  @media (max-width: 450px) {
    margin-left: -${Spacing.small};
    margin-right: -${Spacing.small};
  }

  @media only screen and ${Device.tablet} {
    // display: block;
    width: 300px;
  }

  @media only screen and ${Device.desktop} {
    width: 430px;
  }
`;

const SummaryHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.muted};
`;

const Form = styled.form``;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: left;
`;

const ResponsiveHeading = styled(Heading)`
  padding: 0;
  font-weight: 600;

  @media only screen and ${Device.tablet} {
    padding: 0;
  }
`;

const CollapseLink = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text.primary};
  cursor: pointer;
  margin-top: ${Spacing.small};
  margin-bottom: ${Spacing.small};

  i {
    margin-left: ${Spacing.xsmall};
  }
  :hover,
  :focus {
    opacity: 0.85;
  }
`;

const Error = styled.div`
  display: inline-flex;
  height: ${(props) => (props.visible ? 'auto' : 0)};
  margin: ${(props) => (props.visible ? '0 auto 20px;' : '0 auto')};
  color: ${({ theme }) => theme.colors.text.error};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: ${(props) => (props.visible ? 'none' : 'translateY(10px)')};
  transition-property: opacity, transform;
  transition-duration: 0.35s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  background-size: 15px 15px;
`;

const BundleItemWrapper = styled.div`
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BundleImageWrapper = styled.div`
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 5px;
  margin: 10px 15px 10px 10px;
  position: relative;
  height: 100px;
  width: 100px;
`;

const BundleImage = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.colors.background.tint1};
`;

const BundleText = styled(Paragraph)`
  margin-bottom: 0;
`;

const BundleLabel = styled(Paragraph)`
  margin-bottom: 0;
  padding-left: 10px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
`;

const CouponForm = styled.form`
  display: flex;
  flex: 1;
`;

const CouponInput = styled(Input)`
  /* Shrink and grow as needed, but never shrink so
  small that we can't read the placeholder */
  flex: 1 0 8em;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const CouponSubmit = styled(ButtonReset)`
  /* Never shrink or grow */
  flex: 0 0 auto;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: ${({ theme }) => theme.radii.md};
  border-bottom-right-radius: ${({ theme }) => theme.radii.md};
`;

const CouponMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  i {
    color: ${({ error, theme }) =>
      error ? theme.colors.text.muted : theme.colors.text.success};
    margin-right: 5px;
  }
`;

const CouponMessage = styled.p`
  margin-bottom: 0;
  color: ${({ error, theme }) =>
    error ? theme.colors.text.muted : theme.colors.text.success};
  font-size: 14px;
`;

const MaskOverlay = styled.div`
  background-color: ${({ theme }) => theme.colors.background.default};
  opacity: 0.6;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  height: 100%;
  left: 0;
  position: absolute;
  text-indent: -9999px;
  top: 0;
  width: 100%;
`;

const CohortText = styled(Text)`
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
`;

const VisibleMobile = styled.div`
  display: block;
  @media ${Device.mobileL} {
    display: none;
  }
`;

const HiddenModile = styled.div`
  display: none;
  @media ${Device.mobileL} {
    display: block;
  }
`;

const FlexFill = styled(Box)`
  flex: 1;
`;

/* ------------------------------------------------------------------------- */

const S4 = () =>
  (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);

const UpcomingCohort = ({
  cohort,
  isChecked,
  spacesLimited,
  isCohort,
  soldOutComponent,
  waitingListIsVisible,
  waitingListLoading,
  email,
  onClickWaitingList,
  handleWaitingListSignup,
  handleCohortChange,
}: {
  cohort: Cohort;
  isChecked: boolean;
  spacesLimited: boolean;
  isCohort: boolean;
  waitingListIsVisible: boolean;
  waitingListLoading: boolean;
  soldOutComponent: React.ReactNode;
  email: string;
  onClickWaitingList: Function;
  handleWaitingListSignup: Function;
  handleCohortChange: Function;
}) => {
  const remainingPercentage =
    (cohort.spacesAvailable / cohort.spacesTotal) * 100;

  const spacesRemaining = (
    <>
      <chakra.span
        bg={
          remainingPercentage <= 50
            ? 'background.error'
            : remainingPercentage <= 70
            ? 'background.warning'
            : 'background.primary'
        }
        color={
          remainingPercentage <= 50
            ? 'text.error'
            : remainingPercentage <= 70
            ? 'text.warning'
            : 'text.primary'
        }
        borderRadius="full"
        fontSize="xs"
        px={2}
        py={1}
        textAlign="center"
      >
        {`${cohort.spacesAvailable} spaces remaining`}
      </chakra.span>
    </>
  );

  const waitingList = (
    <ButtonReset
      type="button"
      className="btn btn-outline-primary"
      onClick={onClickWaitingList}
    >
      {spacesLimited ? 'Join Waiting List' : 'Receive Updates'}
    </ButtonReset>
  );

  let label = `${moment(cohort.startDate).format('dddd Do MMMM YYYY')}${
    cohort.label ? ` - ${cohort.label}` : ''
  }`;

  let metaElement = spacesRemaining;

  const spacesAvailable = cohort.spacesAvailable > 0;

  // If the cohort isn't on sale, and it's in the future, and it
  // either has spaces available or doesn't care whether or not
  // spaces are available -> show the coming soon/waiting list
  // text
  if (
    !cohort.onSale &&
    moment(cohort.startDate).diff(moment()) > 0 &&
    (spacesAvailable || !spacesLimited)
  ) {
    label = `${label} - Coming soon`;
    metaElement = waitingList;
  } else if (!spacesAvailable && isCohort) {
    metaElement = soldOutComponent;
  } else if (!spacesLimited) {
    metaElement = null;
  }

  const isDisabled = !cohort.onSale || (!spacesAvailable && isCohort);

  return (
    <div key={`cohort-start-${cohort.id}`} className="cohort-item">
      <form
        className={`waitingList${waitingListIsVisible ? ' visible' : ''}`}
        method="post"
        onSubmit={handleWaitingListSignup}
      >
        <input
          type="email"
          className="form-control"
          placeholder="Email Address"
          name="email"
          required
          defaultValue={email}
        />
        <input type="hidden" name="cohort_id" value={cohort.id} />
        <ButtonReset
          type="submit"
          className="btn btn-outline-primary"
          isLoading={waitingListLoading}
        >
          Submit
        </ButtonReset>
      </form>
      <Radiobutton
        id={cohort.id}
        name="cohort-start"
        value={cohort.id}
        isDisabled={isDisabled}
        isChecked={isChecked}
        onChange={handleCohortChange}
        flex={1}
      >
        <CohortText>
          <chakra.span flex={1} pr={2}>
            {label}
          </chakra.span>
          {metaElement}
        </CohortText>
      </Radiobutton>
    </div>
  );
};

const PaymentSuccessStep = ({
  label,
  status,
}: {
  label: string;
  status: 'complete' | 'current' | 'upcoming';
}) => (
  <div style={{ textAlign: 'center', width: 100 }}>
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        style={{
          position: 'absolute',
          left: status === 'complete' ? 0 : 'auto',
          right: status === 'upcoming' ? 0 : 'auto',
          top: 0,
          bottom: 0,
          width: status === 'complete' || status === 'upcoming' ? '50%' : 0,
        }}
        bg="background.default"
      />
      <Box
        bg={
          status === 'complete'
            ? 'common.progress'
            : status === 'current'
            ? 'background.default'
            : 'background.tint4'
        }
        borderColor={
          status === 'complete' || status === 'current'
            ? 'common.progress'
            : 'background.tint4'
        }
        style={{
          height: 20,
          width: 20,
          borderRadius: 10,
          position: 'relative',
          borderWidth: 4,
          borderStyle: 'solid',
        }}
      />
    </div>
    <Paragraph
      style={{
        fontSize: 14,
        marginTop: 10,
      }}
      color={
        status === 'complete' || status === 'current'
          ? 'text.success'
          : 'text.muted'
      }
      fontWeight={status === 'current' ? 'bold' : 'semibold'}
    >
      {label}
    </Paragraph>
  </div>
);

const PaymentSuccessSteps = ({
  isAuthenticated,
}: {
  isAuthenticated: boolean;
}) => (
  <div style={{ position: 'relative', marginBottom: '1rem' }}>
    <div
      style={{
        display: 'flex',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 8,
      }}
    >
      <FlexFill bg="common.progress" style={{ height: 4 }} />
      <FlexFill bg="background.tint4" style={{ height: 4 }} />
    </div>

    <div style={{ display: 'flex', position: 'relative' }}>
      <PaymentSuccessStep label="Select your start date" status="complete" />
      <FlexFill />
      <PaymentSuccessStep
        label={
          isAuthenticated ? 'Check your emails' : 'Complete account signup'
        }
        status="current"
      />
      <FlexFill />
      <PaymentSuccessStep label="Access your course" status="upcoming" />
    </div>
  </div>
);

/* ------------------------------------------------------------------------- */

const RegisterForm = ({
  providerName,
  countries,
  name,
  email,
  password,
  country,
  marketingConsent,
  experiment,
  onChange,
  onCountryChange,
  handleRegister,
  toggleAuthForm,
  error = null,
  loading = false,
}: {
  providerName: string;
  countries: Countries;
  name: string;
  email: string;
  password: string;
  country: string;
  marketingConsent: boolean;
  experiment: boolean;
  onChange: Function;
  onCountryChange: Function;
  handleRegister: Function;
  toggleAuthForm: Function;
  error?: string;
  loading: boolean;
}) => {
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  return (
    <Form onSubmit={handleRegister}>
      <Paragraph
        mb={4}
      >{`You can create an account now or later – you'll need one to access the ${providerName} online course once your class begins.`}</Paragraph>
      {/* <Paragraph bold>Please create an account before continuing to payment:</Paragraph> */}
      <Section>
        <fieldset>
          <label htmlFor="registerName">
            <chakra.span>Name</chakra.span>
            <input
              id="registerName"
              name="name"
              className="field"
              placeholder="Full Name"
              required
              value={name}
              onChange={onChange}
            />
          </label>
          <label htmlFor="registerEmail">
            <chakra.span>Email</chakra.span>
            <input
              id="registerEmail"
              name="email"
              type="email"
              className="field"
              placeholder="mail@example.com"
              required
              value={email}
              onChange={onChange}
            />
          </label>
          <label htmlFor="registerPassword">
            <chakra.span>Password</chakra.span>
            <input
              id="registerPassword"
              name="password"
              type="password"
              className="field"
              placeholder="******"
              required
              value={password}
              onChange={onChange}
            />
          </label>
          <label htmlFor="registerCountry" className="select">
            <chakra.span>Country</chakra.span>
            <div
              id="auth-country"
              className={`country field ${country.toLowerCase()}`}
            >
              <select
                id="registerCountry"
                name="country"
                onChange={onCountryChange}
                value={country}
                style={{ width: '100%' }}
              >
                {Object.keys(countries).map((key) => (
                  <option key={key} value={key}>
                    {countries[key]}
                  </option>
                ))}
              </select>
            </div>
          </label>
        </fieldset>
        {<Error visible={error != null}>{error}</Error>}
        <Paragraph className="tip">
          If you're purchasing the course as a gift, sign up with your own
          details. You'll be able to change them at a later date.
        </Paragraph>

        <div className="formCheck">
          <Flex mb={2}>
            <Box mr={2}>
              <input
                id="registerMarketingConsent1"
                type="radio"
                className="form-check-input"
                name="marketingConsent"
                value="true"
                onChange={onChange}
                required
              />
            </Box>
            <Box>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                htmlFor="registerMarketingConsent1"
                className="form-check-label"
                style={{ fontSize: '14px' }}
              >
                {`👍 Send me discounts and updates from ${PLATFORM_DISPLAY_NAME}.`}
              </label>
            </Box>
          </Flex>
          <Flex mb={4}>
            <Box mr={2}>
              <input
                id="registerMarketingConsent0"
                type="radio"
                className="form-check-input"
                name="marketingConsent"
                value="false"
                onChange={onChange}
                required
              />
            </Box>
            <Box>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                htmlFor="registerMarketingConsent0"
                className="form-check-label"
                style={{ fontSize: '14px' }}
              >
                {`👎 I don't want discounts and updates from ${PLATFORM_DISPLAY_NAME}.`}
              </label>
            </Box>
          </Flex>
        </div>

        <Checkbox
          id="acceptedTerms"
          name="acceptedTerms"
          isChecked={acceptedTerms}
          mb={6}
          onChange={async (e) => setAcceptedTerms(e.target.checked)}
          required
        >
          <Text fontSize="xs">
            {`I understand that by signing up, I agree to ${PLATFORM_DISPLAY_NAME}'s `}
            <chakra.span color="text.info">
              <Link
                to={navRoutes.global.terms.path()}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </Link>
            </chakra.span>
            {` and `}
            <chakra.span color="text.info">
              <Link
                to={navRoutes.global.privacy.path()}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </Link>
            </chakra.span>
            {`, and confirm my age is 18+ or 13+ with a parent or guardian's consent.`}
          </Text>
        </Checkbox>
      </Section>
      <ButtonReset
        type="submit"
        className={`stripe-element`}
        isLoading={loading}
      >
        Sign Up
      </ButtonReset>
      <div className="authOptions">
        <Paragraph>Already have an account?</Paragraph>
        <Flex alignItems="center">
          <PlainButton
            size="sm"
            variant="outline"
            mx={1.5}
            onClick={(e) => toggleAuthForm(e, 'login')}
          >
            Log In
          </PlainButton>
          <Paragraph>or</Paragraph>
          <PlainButton
            size="sm"
            variant="outline"
            ml={1.5}
            onClick={(e) => toggleAuthForm(e, 'guest')}
          >
            Continue as Guest
          </PlainButton>
        </Flex>
      </div>
      <AnalyticsCheckoutStepViewed step={1} stepVariant="auth_signup" />
    </Form>
  );
};

/* ------------------------------------------------------------------------- */

const LoginForm = ({
  email,
  password,
  onChange,
  handleLogin,
  toggleAuthForm,
  resetPasswordUrl,
  error = null,
  loading = false,
}: {
  email: string;
  password: string;
  onChange: Function;
  handleLogin: Function;
  toggleAuthForm: Function;
  resetPasswordUrl: string;
  error?: string;
  loading: boolean;
}) => (
  <Form onSubmit={handleLogin}>
    {/* <Paragraph bold>Please create an account before continuing to payment:</Paragraph> */}
    <Section>
      <fieldset style={{ marginTop: '0.625rem' }}>
        <label htmlFor="loginEmail">
          <chakra.span>Email</chakra.span>
          <input
            id="loginEmail"
            name="email"
            type="email"
            className="field"
            placeholder="mail@example.com"
            required
            value={email}
            onChange={onChange}
          />
        </label>
        <label htmlFor="loginPassword">
          <chakra.span>Password</chakra.span>
          <input
            id="loginPassword"
            name="password"
            type="password"
            className="field"
            placeholder="******"
            required
            value={password}
            onChange={onChange}
          />
        </label>
      </fieldset>
      {<Error visible={error != null}>{error}</Error>}
    </Section>
    <ButtonReset
      type="submit"
      className={`stripe-element`}
      disabled={loading}
      isLoading={loading}
    >
      Log In
    </ButtonReset>
    <div className="authOptions" style={{ marginTop: 15, marginBottom: 15 }}>
      <Small textAlign="center" color="text.primary">
        <Link to={navRoutes.public.forgotPassword.path()}>
          Forgot Password?
        </Link>
      </Small>
    </div>
    <Hr />
    <div className="authOptions">
      <Paragraph>Don't have an account?</Paragraph>
      <Flex alignItems="center">
        <PlainButton
          size="sm"
          variant="outline"
          mx={1.5}
          onClick={(e) => toggleAuthForm(e, 'register')}
        >
          Sign Up
        </PlainButton>
        <Paragraph>or</Paragraph>
        <PlainButton
          size="sm"
          variant="outline"
          ml={1.5}
          onClick={(e) => toggleAuthForm(e, 'guest')}
        >
          Continue as Guest
        </PlainButton>
      </Flex>
    </div>
    <AnalyticsCheckoutStepViewed step={1} stepVariant="auth_signin" />
  </Form>
);

/* ------------------------------------------------------------------------- */

const GuestEmailForm = ({
  email,
  onChange,
  handleSubmit,
  toggleAuthForm,
  isAuthenticated = false,
  error = null,
  loading = false,
}: {
  email: string;
  onChange: Function;
  handleSubmit: Function;
  toggleAuthForm: Function;
  isAuthenticated: boolean;
  error?: string;
  loading: boolean;
}) => (
  <Form onSubmit={handleSubmit}>
    <Section>
      <fieldset style={{ marginTop: '0.625rem' }}>
        <label htmlFor="guestEmail">
          <chakra.span>Email</chakra.span>
          <input
            id="guestEmail"
            name="email"
            type="email"
            className="field"
            placeholder="mail@example.com"
            required
            value={email}
            onChange={onChange}
          />
        </label>
      </fieldset>
      <Error visible={error !== null}>{error}</Error>
    </Section>
    <ButtonReset
      type="submit"
      className={`stripe-element`}
      disabled={false}
      isLoading={loading}
    >
      Continue
    </ButtonReset>
    <div className="authOptions">
      <Paragraph>Already have an account?</Paragraph>
      <PlainButton
        size="sm"
        variant="outline"
        ml={1.5}
        onClick={(e) => toggleAuthForm(e, 'login')}
      >
        Log In
      </PlainButton>
    </div>
    {!isAuthenticated && (
      <AnalyticsCheckoutStepViewed step={1} stepVariant="auth_guest" />
    )}
  </Form>
);

const SecurePayment = ({
  visibleMobileOnly = false,
  hiddenMobile = false,
}: {
  visibleMobileOnly?: boolean;
  hiddenMobile?: boolean;
}) => {
  const component = (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginTop: visibleMobileOnly ? 10 : 0,
      }}
    >
      <MdIcon name="VerifiedUser" color="text.muted" mr={1} />
      <Paragraph
        fontSize="sm"
        color="text.muted"
        style={{ marginBottom: 0, fontWeight: 600 }}
      >
        Your payment is secure
      </Paragraph>
    </div>
  );

  if (visibleMobileOnly) {
    return <VisibleMobile>{component}</VisibleMobile>;
  }

  if (hiddenMobile) {
    return <HiddenModile>{component}</HiddenModile>;
  }

  return component;
};

/* ------------------------------------------------------------------------- */

const AnalyticsCheckoutStepViewed = ({
  step,
  stepVariant,
}: {
  step: number;
  stepVariant: string;
}) => {
  useEffect(() => {
    try {
      analytics.track('Checkout Step Viewed', {
        step,
        step_variant: stepVariant,
      });
    } catch (err) {
      try {
        Sentry.captureException(err);
      } catch (e) {
        console.warn('Sentry not installed');
      }
      console.warn('analytics not installed');
    }
  }, []);
  return null;
};

/* ------------------------------------------------------------------------- */

type PaymentFormProps = {
  csrftoken: string;
  authToken: string;
  stripe: Stripe | null | Promise<Stripe | null>;
  email: string;
  countries: Countries;
  config: StripeConfig;
  total: number;
  items: OrderItems;
  couponCode: string;
  providerName: string;
  onSuccess: (
    paymentId?: string,
    customerId?: string,
    chargeId?: string,
    isGift: boolean
  ) => void;
  dispatch: Dispatch;
};

type PaymentFormState = {
  name: string;
  country: string;
  ctaText: string;
  error?: string;
  loading: boolean;
  isGift: boolean;
};

class _PaymentForm extends React.PureComponent<
  PaymentFormProps,
  PaymentFormState
> {
  state = {
    name: '',
    country: 'GB',
    ctaText: 'Enrol Now',
    error: null,
    loading: false,
    isGift: false,
  };

  // eslint-disable-next-line
  cardEl: null | CardElement;

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    this.setState({
      [name]: value,
    });
  };

  handleCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    let newValue = value;
    if (value === '-') {
      newValue = 'GB';
    }
    if (value.charAt(value.length - 1) === '1') {
      newValue = value.slice(0, 2);
    }
    this.setState({
      [name]: newValue,
    });
  };

  handleServerResponse = async (
    response:
      | { requiresAction: true; paymentIntentClientSecret: string }
      | {
          success: true;
          paymentIntentId: string;
          customerId: string;
          chargeId: string;
        }
      | { error: string },
    // Used for data analysis and tracking
    paymentId?: string,
    // Used for affiliate tracking
    customerId?: string,
    chargeId?: string
  ) => {
    const { onSuccess } = this.props;
    const { isGift } = this.state;
    if (response.error) {
      // Show error from server on payment form
      let apiError = null;
      if (typeof response.error === 'string') {
        apiError = response.error;
      } else {
        // Use Stripe.js to handle required card action
        apiError = response.error.message;
      }
      this.setState({
        ctaText: 'Enrol Now',
        error: apiError,
        loading: false,
      });
    } else if (response.requiresAction) {
      // $FlowFixMe
      await this.handleAction(response);
    } else {
      // Show success message
      this.setState({
        ctaText: 'Enrol Now',
        error: null,
        loading: false,
      });
      onSuccess(paymentId, customerId, chargeId, isGift);
    }
  };

  handleAction = async (response: {
    requiresAction: boolean;
    paymentIntentClientSecret: string;
  }) => {
    const { stripe, csrftoken, authToken, items, dispatch } = this.props;
    this.setState({
      ctaText: 'Authorizing Payment…',
      error: null,
      loading: true,
    });
    // $FlowFixMe
    const result = await stripe.handleCardAction(
      response.paymentIntentClientSecret
    );

    if (result.error) {
      this.setState({
        ctaText: 'Enrol Now',
        error: result.error.message,
        loading: false,
      });
    } else {
      // $FlowFixMe
      const confirmResult = await api.confirmPayment(
        {
          paymentIntentId: result.paymentIntent.id,
          items,
          csrftoken,
          authToken,
        },
        dispatch
      );

      await this.handleServerResponse(
        confirmResult,
        // The next 3 parameters will only exist if the purchase has
        // now gone through successfully, following user intervention
        // e.g. 3DS
        confirmResult.paymentIntentId,
        confirmResult.customerId,
        confirmResult.chargeId
      );
    }
  };

  handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    const {
      email,
      stripe,
      config,
      total,
      items,
      couponCode,
      csrftoken,
      authToken,
      dispatch,
    } = this.props;
    const { name, country, isGift } = this.state;
    e.preventDefault();

    try {
      this.setState({
        ctaText: 'Creating Order',
        error: null,
        loading: true,
      });

      if (stripe && this.cardEl) {
        const { error, paymentMethod } = await stripe.createPaymentMethod(
          'card',
          this.cardEl,
          {
            billing_details: { name, email, address: { country } },
          }
        );

        if (error) {
          this.setState({
            ctaText: 'Enrol Now',
            error: error.message,
            loading: false,
          });
        } else {
          // $FlowFixMe
          const response = await api.confirmPayment(
            {
              paymentMethod: paymentMethod.id,
              currency: config.currency,
              items,
              amount: total * 100,
              couponCode,
              isGift,
              csrftoken,
              authToken,
              email,
              name,
            },
            dispatch
          );

          await this.handleServerResponse(
            response,
            // The next 3 parameters will only exist if a purchase has
            // succeeded immediately without user intervention (3DS)
            // required.
            response.paymentIntentId,
            response.customerId,
            response.chargeId
          );
        }
      } else {
        // TODO:
        // toaster.danger(
        //   `An error occurred. Please try refreshing the page, or if the problem persists, contact us on support${PLATFORM_EMAIL}`
        // );
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { countries, total, providerName, theme } = this.props;
    const { name, country, ctaText, error, loading, isGift } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Section>
          <fieldset style={{ marginTop: '0.625rem' }}>
            <label htmlFor="name">
              <chakra.span>Name</chakra.span>
              <input
                onChange={this.handleInputChange}
                type="text"
                className="field"
                id="name"
                name="name"
                placeholder="Cardholder's name"
                required
                value={name}
              />
            </label>
            <label htmlFor="cardElement">
              <chakra.span>Card</chakra.span>
              <CardElement
                id="cardElement"
                className="field"
                onReady={(el) => {
                  this.cardEl = el;
                }}
                onChange={(event) => {
                  if (event.complete) {
                    try {
                      analytics.track('Payment Info Entered', {
                        step: 4,
                        step_variant: 'complete_payment',
                      });
                    } catch (err) {
                      try {
                        Sentry.captureException(err);
                      } catch (e) {
                        console.warn('Sentry not installed');
                      }
                      console.warn('analytics not installed');
                    }
                  }
                }}
                disabled={loading}
                options={{
                  style: {
                    base: {
                      color: theme?.colors.text.default,
                      iconColor: theme?.colors.text.default,
                    },
                    empty: {
                      color: theme?.colors.text.muted,
                      iconColor: theme?.colors.text.muted,
                    },
                    invalid: {
                      color: theme?.colors.text.error,
                      iconColor: theme?.colors.text.error,
                    },
                  },
                }}
              />
            </label>
            <label htmlFor="billingCountry" className="select">
              <chakra.span>Country</chakra.span>
              <div
                id="payment-country"
                className={`country field ${country.toLowerCase()}`}
              >
                <select
                  id="billingCountry"
                  name="country"
                  onChange={this.handleCountryChange}
                  value={country}
                  style={{ width: '100%' }}
                >
                  {Object.keys(countries).map((key) => (
                    <option key={key} value={key}>
                      {countries[key]}
                    </option>
                  ))}
                </select>
              </div>
            </label>
          </fieldset>
        </Section>
        <Flex>{<Error visible={error != null}>{error}</Error>}</Flex>
        {/* TEMP: Remove gifting functionality */}
        {/* <Flex
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          style={{ marginBottom: Spacing.small }}
        >
          <label htmlFor="gift-checkbox" style={{ cursor: 'pointer', margin: 0 }}>
            <input
              id="gift-checkbox"
              type="checkbox"
              name="gift-item"
              onChange={() => this.setState({ isGift: !isGift })}
              style={{ marginRight: Spacing.xxsmall }}
            />
            This item is a gift
          </label>
          {isGift && (
            <small>
              {
                'After you have completed your purchase, we will email you instructions explaining how you can gift this course.'
              }
            </small>
          )}
        </Flex> */}
        <ButtonReset
          type="submit"
          className={`stripe-element`}
          isLoading={loading}
        >
          {`${ctaText}`}
        </ButtonReset>
        <SecurePayment visibleMobileOnly />
        <Paragraph color="text.muted" className="tip no-margin" mt={6}>
          <Small>
            By placing your order you agree to our{' '}
            <chakra.a
              href={navRoutes.global.terms.path()}
              target="_blank"
              rel="noopener noreferrer"
              _hover={{
                textDecoration: 'underline',
              }}
            >
              Terms of Service
            </chakra.a>{' '}
            and{' '}
            <chakra.a
              href={navRoutes.global.privacy.path()}
              target="_blank"
              rel="noopener noreferrer"
              _hover={{
                textDecoration: 'underline',
              }}
            >
              Privacy Policy
            </chakra.a>
            {`. By entering your card details, you authorise ${
              providerName || PLATFORM_DISPLAY_NAME
            } to send
            instructions to the financial institution that issued your card to
            take payments from your card account in accordance with the terms of
            your agreement with them.`}
          </Small>
        </Paragraph>
      </Form>
    );
  }
}

const PaymentForm = _PaymentForm;

/* ------------------------------------------------------------------------- */

const OrderConfirmation = ({
  selectedPaymentPlan,
  amountPaid,
  paymentPlanTerm,
  paymentPlanInstalmentAmount,
  email,
  inviteImage,
  inviteSubject,
  shareUrl,
  isAuthenticated,
  slug,
  title,
  shouldRedirect,
  redirectDomain,
  mentorName,
  selectedStartDate,
  isCourseVoucher,
  width,
}: {
  selectedPaymentPlan: 'upfront' | 'instalment';
  amountPaid: number;
  paymentPlanTerm?: number;
  paymentPlanInstalmentAmount?: number;
  email: string;
  inviteImage: string;
  inviteSubject: string;
  shareUrl: string;
  isAuthenticated: boolean;
  slug: string;
  title: string;
  shouldRedirect: boolean;
  redirectDomain: string;
  mentorName: string;
  selectedStartDate?: string;
  isCourseVoucher: boolean;
  width: number;
}) => {
  useEffect(() => {
    // `OrderConfirmation` is only added to the DOM following a successful purchase.
    // Therefore we redirect upon mounting, if specified in the props
    if (shouldRedirect) {
      const encodedSlug = encodeURI(slug);
      const encodedTitle = encodeURI(title);
      const date = moment(selectedStartDate).format('YYYY-MM-DD');
      const redirectURL = `${redirectDomain}?slug=${encodedSlug}&title=${encodedTitle}&total=${amountPaid}&date=${date}`;
      setTimeout(function () {
        window.location.replace(redirectURL);
      }, 2000);
    }
  }, []);
  const history = useHistory();

  return (
    <CheckoutCard
      id="order-success-dialog"
      flexDirection="column"
      className="successCard"
    >
      <div className="success-header">
        <i className="fa fa-check-circle" />
        {isCourseVoucher ? (
          <Heading as="h1">Course Redeemed</Heading>
        ) : (
          <Heading as="h1">Payment Successful</Heading>
        )}
      </div>

      {shouldRedirect ? (
        <Heading as="h2" size="h3">
          {mentorName
            ? `Thank you for your purchase, redirecting you back to ${mentorName}...`
            : 'Thank you for your purchase, redirecting...'}
        </Heading>
      ) : (
        <Flex
          flexDirection="column"
          css={`
            text-align: ${isCourseVoucher ? 'center' : 'left'};
          `}
        >
          <PaymentSuccessSteps isAuthenticated={isAuthenticated} />

          <div className="success-info">
            <Flex flexDirection={width > Device.raw.tablet ? 'row' : 'column'}>
              {!isCourseVoucher && (
                <div>
                  <Heading as="h2" size="h4">
                    Your Purchase Summary
                  </Heading>
                  <Paragraph>
                    Thank you for your purchase. Your payment was successful.
                  </Paragraph>
                  <table className="purchaseSummary">
                    <tbody>
                      <tr className="item-row">
                        <td>Balance Paid</td>
                        <td className="currency">{`£${amountPaid.toFixed(
                          2
                        )}`}</td>
                      </tr>
                      {selectedPaymentPlan === 'instalment' &&
                      paymentPlanTerm &&
                      paymentPlanInstalmentAmount ? (
                        <tr className="item-row">
                          <td>{`${paymentPlanTerm} monthly payments`}</td>
                          <td className="currency">{`£${paymentPlanInstalmentAmount.toFixed(
                            2
                          )}`}</td>
                        </tr>
                      ) : null}
                      <tr className="total-row">
                        <td>Order Total</td>
                        <td className="currency">
                          {selectedPaymentPlan === 'instalment' &&
                          paymentPlanTerm &&
                          paymentPlanInstalmentAmount
                            ? `£${(
                                paymentPlanTerm * paymentPlanInstalmentAmount +
                                amountPaid
                              ).toFixed(2)}`
                            : `£${amountPaid.toFixed(2)}`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Paragraph className="tip">
                    {`You will receive an email shortly at ${email} containing the payment receipt. `}
                    {
                      'If you do not receive this email within 2 hours, please email '
                    }
                    <a
                      href={`mailto:support${PLATFORM_EMAIL}`}
                    >{`support${PLATFORM_EMAIL}`}</a>
                  </Paragraph>
                </div>
              )}
              {!isCourseVoucher && <div style={{ height: 10, width: 70 }} />}
              <div>
                <Paragraph color="text.muted">
                  {`Now you've ${
                    isCourseVoucher
                      ? 'redeemed your course'
                      : 'completed your purchase'
                  }, please check for emails from ${PLATFORM_DISPLAY_NAME} containing essential course information.`}
                </Paragraph>
                {PLATFORM === 'steppit' && (
                  <>
                    {isAuthenticated ? (
                      <>
                        <Paragraph
                          color="text.muted"
                          fontWeight="semibold"
                          mt={2}
                          mb={4}
                        >
                          When your class starts, you can access the course
                          here:
                        </Paragraph>
                        <LinkButton
                          icon="Workspaces"
                          to={navRoutes.learner.library.path()}
                        >
                          My Classes
                        </LinkButton>
                      </>
                    ) : (
                      <>
                        <Paragraph
                          color="text.muted"
                          fontWeight="semibold"
                          mt={2}
                          mb={4}
                        >
                          {`To access your course, you'll first need to create a ${PLATFORM_DISPLAY_NAME} account:`}
                        </Paragraph>
                        <ButtonReset
                          icon="AccountCircle"
                          onClick={() => {
                            history.push(
                              `${navRoutes.public.signup.path()}?email=${encodeURIComponent(
                                email
                              )}`
                            );
                            window.location.reload();
                          }}
                        >
                          Create Account
                        </ButtonReset>
                      </>
                    )}
                  </>
                )}
              </div>
            </Flex>
          </div>
        </Flex>
      )}
    </CheckoutCard>
  );
};
/* ------------------------------------------------------------------------- */

const Footer = ({
  contactNumber,
  contactEmail,
}: {
  contactNumber: string;
  contactEmail: string;
}) => (
  <>
    <Flex
      flexDirection="column"
      alignItems="center"
      style={{ padding: '2rem 1rem 0 1rem' }}
    >
      {contactEmail || contactNumber ? (
        <Paragraph color="text.muted" textAlign="center">
          {'If you have any questions, or would like more information, please '}
          {contactNumber ? (
            <>
              {'call '}
              <a href={`tel:${contactNumber}`}>{contactNumber}</a>
            </>
          ) : (
            ''
          )}
          {contactNumber && contactEmail ? 'or ' : ''}
          {contactEmail ? (
            <>
              {'email '}
              <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
            </>
          ) : (
            ''
          )}
          {'.'}
        </Paragraph>
      ) : null}

      <Paragraph color="text.muted" textAlign="center" pb={4}>
        <chakra.span color="text.muted">
          By placing your order you agree to our&nbsp;
        </chakra.span>
        <chakra.a
          href={navRoutes.global.terms.path()}
          target="_blank"
          rel="noopener noreferrer"
          _hover={{
            textDecoration: 'underline',
          }}
        >
          Terms of Service
        </chakra.a>
        <chakra.span color="text.muted">&nbsp;and&nbsp;</chakra.span>
        <chakra.a
          href={navRoutes.global.privacy.path()}
          target="_blank"
          rel="noopener noreferrer"
          _hover={{
            textDecoration: 'underline',
          }}
        >
          Privacy Policy
        </chakra.a>
        <chakra.span color="text.muted">.</chakra.span>
      </Paragraph>

      <Paragraph
        color="text.muted"
        textAlign="center"
        style={{ paddingBottom: '4rem' }}
      >
        <chakra.span color="text.muted">
          Having trouble with your order? Visit our&nbsp;
        </chakra.span>
        <chakra.a
          href={helpUrl}
          target="_blank"
          rel="noopener noreferrer"
          _hover={{
            textDecoration: 'underline',
          }}
        >
          Help Center
        </chakra.a>
        <chakra.span color="text.muted">.</chakra.span>
      </Paragraph>
      {/* {discountText && (
      <CouponMessageWrapperMobile error={false}>
        <i className="fa fa-check-circle" />
        <CouponMessage error={false}>
          {`${discount ? `£${discount.toFixed(2)} discount` : ''} ${discountText}`}
        </CouponMessage>
      </CouponMessageWrapperMobile>
    )} */}
    </Flex>
  </>
);

type SharedProps = {
  productType: string;
  hasPurchased: boolean;
  csrftoken: string;
  inviteImage: string;
  secureImage: string;
  authenticatedOnLoad: boolean;
  endpoints: {
    accountResetPassword: string;
  };
  // The URL base on the checkout
  base: string;
  user: {
    id?: number;
    name: string;
    firstName?: string;
    lastName?: string;
    token: string;
    email: string;
    country: string;
    username?: string;
    marketingConsent?: 'True' | 'False';
  };
  paymentTypeImages: {
    amex: string;
    discover: string;
    maestro: string;
    mastercard: string;
    visaElectron: string;
    visa: string;
  };
  dispatch: Dispatch;
};

type Props = SharedProps & {
  countries: Countries;
  courseProvider: {
    id: number;
    name: string;
    logoUrl: string;
    contactNumber: string;
    contactEmail: string;
    websiteUrl: string;
    conversionTrackingUrl: string;
    stripeAccountId: string;
    stripeAccountStandard: boolean;
  };
  course: {
    slug: string;
    title: string;
    image: string;
    category: string;
    price: number;
    paymentPlanPrice?: number;
    paymentPlanInstalmentAmount?: number;
    paymentPlanTerm?: number;
    paymentPlanAvailable: boolean;
    shareUrl: string;
    classType: 'cohort' | 'intake' | 'open';
    courseType: 'professional' | 'short' | 'mini' | 'session';
  };
  bundle?: Bundle;
};

type State = {
  experiment: boolean;
  user: {
    id?: number;
    name: string;
    username: string;
    token: string;
    email: string;
    country: string;
  };
  subtotal: number;
  total: number;
  discount?: number;
  visibleForm: 'guest' | 'register' | 'login';
  stripe: Stripe | null | Promise<Stripe | null>;
  store: {
    activeStep: number;
    config: StripeConfig;
  };
  cohort: {
    upcomingCohorts: Array<Cohort>;
    initialCohort?: number;
    selectedCohort?: number;
    visibleWaitingList?: number;
  };
  payment: {
    selectedPaymentPlan: 'upfront' | 'instalment';
    successfulPayment: boolean;
  };
  orderItems: OrderItems;
  auth: {
    name: string;
    email: string;
    password: string;
    country: string;
    marketingConsent: boolean;
  };
  bundleSelected: boolean;
  validation: {
    loginError?: string;
    registerError?: string;
    logoutError?: string;
    couponError?: string;
    guestEmailError?: string;
    redeemCourseError?: string;
  };
  coupon: Coupon;
  loading: {
    initialData: boolean;
    loginLoading: boolean;
    registerLoading: boolean;
    logoutLoading: boolean;
    waitingListLoading: boolean;
    couponLoading: boolean;
    guestEmailLoading: boolean;
    redeemCourseLoading: boolean;
  };
  width: number;
  redirectOnSuccess: boolean;
};

class Checkout extends React.Component<
  Props & { dispatch: Dispatch; theme: any },
  State
> {
  defaultAuth: {
    name: string;
    email: string;
    password: string;
    country: string;
    marketingConsent: boolean;
  } = {
    name: '',
    email: '',
    password: '',
    country: 'GB',
    marketingConsent: false,
  };

  defaultValidation: {
    loginError?: string;
    registerError?: string;
    logoutError?: string;
    couponError?: string;
    guestEmailError?: string;
    redeemCourseError?: string;
  } = {
    loginError: null,
    registerError: null,
    logoutError: null,
    couponError: null,
    guestEmailError: null,
    redeemCourseError: null,
  };

  dateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  couponSubmit: { current: null | CouponSubmit } = React.createRef();

  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      experiment: false,
      user: props.user,
      subtotal: 0,
      total: 0,
      discount: null,
      visibleForm: 'guest',
      stripe: null,
      store: {
        activeStep: props.authenticatedOnLoad ? 2 : 1,
        config: {
          country: '',
          currency: '',
          stripeCountry: '',
          stripePublishableKey: '',
        },
      },
      cohort: {
        upcomingCohorts: [],
        initialCohort: null,
        selectedCohort: null,
        visibleWaitingList: null,
      },
      payment: {
        selectedPaymentPlan: 'upfront',
        successfulPayment: false,
      },
      orderItems: [],
      auth: this.defaultAuth,
      coupon: {
        code: null,
        type: '',
        platform: '',
        amount: 0,
        valid: false,
      },
      validation: this.defaultValidation,
      bundleSelected: false,
      loading: {
        initialData: true,
        loginLoading: false,
        // HACK: Init to true if user is logged in, then flip to false
        // on mount. Without this, the `Change` button renders incorrectly
        logoutLoading: props.authenticatedOnLoad,
        registerLoading: false,
        waitingListLoading: false,
        couponLoading: false,
        guestEmailLoading: false,
        redeemCourseLoading: false,
      },
      width: 9999,
      redirectOnSuccess: false,
    };
  }

  componentDidMount = async () => {
    const { courseProvider } = this.props;
    const { conversionTrackingUrl, stripeAccountId, stripeAccountStandard } =
      courseProvider;

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    // const { store } = this.state;
    // Wait for initial config data to load
    const config = await this.initData();

    const stripeJs = document.createElement('script');
    stripeJs.src = 'https://js.stripe.com/v3/';
    stripeJs.async = true;
    stripeJs.onload = () => {
      const stripePromise = loadStripe(
        config?.stripePublishableKey,
        stripeAccountId && stripeAccountStandard
          ? { stripeAccount: stripeAccountId }
          : undefined
      );
      this.setState({
        stripe: stripePromise,
      });
    };
    if (document.body) document.body.appendChild(stripeJs);

    // If the User has navigated from a Mentor's webpage, on payment success
    // we redirect back to the Mentor's webpage.
    const referrerDomain = document.referrer.split('/')[2];

    if (
      conversionTrackingUrl &&
      referrerDomain !== 'www.workshop.ws' &&
      referrerDomain !== 'workshop.ws' &&
      referrerDomain !== 'www.steppit.com' &&
      referrerDomain !== 'steppit.com'
    ) {
      this.setState({
        redirectOnSuccess: true,
      });
    }

    // HACK: See note in constructor
    this.setState((prevState) => ({
      loading: { ...prevState.loading, logoutLoading: false },
      auth: {
        ...prevState.auth,
        email: this.props.user ? this.props.user.email : '',
      },
    }));
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.authenticatedOnLoad !== this.props.authenticatedOnLoad) {
      this.setState({
        user: this.props.user,
        store: {
          ...this.state.store,
          activeStep: this.props.authenticatedOnLoad ? 2 : 1,
        },
      });
    }
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  initData = async () => {
    const { course, bundle, base } = this.props;

    const earlyAccessToken = getParamFromUrl('ea_t');
    const urlStartDate = getParamFromUrl('sd')
      ? moment(getParamFromUrl('sd'))
      : null;
    if (getParamFromUrl('bundle') && bundle) {
      this.setState({ bundleSelected: true });
    }

    if (getParamFromUrl('ee')) {
      this.setState({ experiment: true });
    }

    // See if the payment plan has been specified in the URL
    // $FlowFixMe
    let urlPaymentPlan: 'upfront' | 'instalment' =
      getParamFromUrl('payment') || 'upfront';
    // If it has, ensure that it's a legitimate choice
    if (urlPaymentPlan !== 'upfront' && urlPaymentPlan !== 'instalment') {
      urlPaymentPlan = 'upfront';
    }

    // If a coupon code has been provided in the URL, apply it when we load the
    // page.
    const couponCode = getParamFromUrl('coupon');
    if (couponCode) {
      this.setState(
        (prevState) => ({
          coupon: { ...prevState.coupon, code: couponCode },
        }),
        () => this.couponSubmit.current && this.couponSubmit.current.click()
      );
    }

    const config: $Exact<{ error: any }> | StripeConfig = await api.getConfig(
      base
    );

    const upcomingCohorts: $Exact<{ error: any }> | { results: Array<Cohort> } =
      await api.getUpcomingCohorts(course.slug, earlyAccessToken);

    if (config.error || upcomingCohorts.error) {
      // TODO:
      // toaster.danger(
      //   `An error occurred. Please try refreshing the page, or if the problem persists, contact us on support${PLATFORM_EMAIL}`
      // );
      return;
    }

    let initialCohort = null;
    let cohorts = [];
    if (
      upcomingCohorts &&
      upcomingCohorts.results &&
      upcomingCohorts.results.length > 0
    ) {
      // For class types which aren't 'cohort' filter out any dates in the past
      cohorts = upcomingCohorts.results.filter((cohort) => {
        const startDate = new Date(cohort.startDate);
        return (
          (cohort.socialType !== 'private' && startDate >= new Date()) ||
          cohort.socialType === 'private'
        );
      });

      // Determine which cohort to 'select' initially, assuming there are some
      cohorts.forEach((cohort) => {
        const cohortStartDate = moment(cohort.startDate);
        // If a start date has been provided in the URL, that should be the
        // checked radio button, assuming one exists which is on sale.
        if (cohort.onSale) {
          if (
            urlStartDate &&
            cohortStartDate.valueOf() === urlStartDate.valueOf()
          ) {
            initialCohort = cohort.id;
          } else if (
            initialCohort === null &&
            (cohort.socialType !== 'private' || course.courseType === 'mini')
          ) {
            // For non-cohort based courses, select the first available cohort.
            initialCohort = cohort.id;
          } else if (initialCohort === null && cohort.spacesAvailable > 0) {
            // If there's no start date in the URL, select the first cohort with spaces available.
            initialCohort = cohort.id;
          }
        }
      });
    }

    this.setState(
      (prevState) => ({
        payment: { ...prevState.payment, selectedPaymentPlan: urlPaymentPlan },
        store: { ...prevState.store, config },
        cohort: {
          initialCohort,
          selectedCohort: initialCohort,
          upcomingCohorts: cohorts,
          visibleWaitingList: null,
        },
        loading: { ...prevState.loading, initialData: false },
      }),
      () => this.calculateTotal()
    );
    return config;
  };

  analyticsCheckoutStepCompleted = ({
    step,
    stepVariant,
  }: {
    step: number;
    stepVariant: string;
  }) => {
    try {
      analytics.track('Checkout Step Completed', {
        step: `${step}`,
        step_variant: stepVariant,
      });
    } catch (err) {
      try {
        Sentry.captureException(err);
      } catch (e) {
        console.warn('Sentry not installed');
      }
      console.warn('analytics not installed');
    }
  };

  calculateTotalAmount = (
    format: 'string' | 'number' = 'string'
  ): string | number => {
    const { total, discount } = this.state;
    if (!total) return 0.0;

    const amount = discount ? total - discount : total;

    return format === 'number' ? amount : amount.toFixed(2);
  };

  calculateDiscount = (total: number, coupon: Coupon): number | null => {
    let discount = null;
    if (coupon && coupon.valid) {
      if (
        coupon.type === 'percentage' ||
        coupon.type === 'platform_percentage'
      ) {
        discount = (coupon.amount / 100) * total;
      } else if (coupon.type === 'monetary') {
        discount = coupon.amount;
      }
    }

    return discount;
  };

  calculateTotalPriceAndDiscount = (
    price: number,
    paymentPlanPrice: number,
    bundle: Bundle,
    coupon: Coupon
  ) => {
    const {
      payment: { selectedPaymentPlan },
      bundleSelected,
    } = this.state;

    let total = price;
    if (bundleSelected) {
      total = bundle.price;
    } else if (selectedPaymentPlan === 'instalment') {
      total = paymentPlanPrice;
    }

    const discount = this.calculateDiscount(total, coupon);

    return { total, discount };
  };

  calculateTotal = () => {
    const {
      courseProvider: { name },
      course: {
        title,
        image,
        price,
        paymentPlanPrice,
        slug: courseSlug,
        category,
        classType,
        courseType,
      },
      bundle,
    } = this.props;
    const {
      payment: { selectedPaymentPlan },
      cohort: { selectedCohort, upcomingCohorts },
      bundleSelected,
      coupon,
    } = this.state;

    const { total, discount } = this.calculateTotalPriceAndDiscount(
      price,
      paymentPlanPrice,
      bundle,
      coupon
    );

    const cohort = upcomingCohorts.find((c) => c.id === selectedCohort);

    if ((!cohort && !bundleSelected && courseType !== 'mini') || !total) {
      return;
    }

    let imageUrl = image;
    let product = title;
    let skuDisplay =
      courseType === 'mini' || !cohort?.startDate
        ? null
        : new Date(cohort.startDate).toLocaleDateString(
            'en-GB',
            this.dateOptions
          );
    let sku = cohort?.id;
    let skuPrice = `£${total.toFixed(2)}`;
    let lineItemPrice = `£${total.toFixed(2)}`;
    let type = 'Course';
    let slug = courseSlug;
    let paymentPlan = selectedPaymentPlan;

    // Depending on whether the user has selected the bundle or not, we construct the line
    // items differently
    if (bundleSelected && bundle) {
      imageUrl = bundle.image;
      product = bundle.title;
      skuDisplay = `${bundle.items.length} Courses`;
      sku = bundle.id;
      skuPrice = `£${bundle.price.toFixed(2)}`;
      lineItemPrice = `£${bundle.price.toFixed(2)}`;
      type = 'Bundle';
      slug = null;
      paymentPlan = null;
    }

    const orderItems = [
      {
        imageUrl,
        product,
        skuDisplay,
        sku,
        quantity: 1,
        skuPrice,
        lineItemPrice,
        title,
        // Not displayed but sent over API
        brand: name,
        category,
        type,
        slug,
        paymentPlan,
        classType,
        courseType,
        ...(coupon ? { couponCode: coupon.code } : {}),
        ...(discount ? { discount } : {}),
      },
    ];

    this.setState({
      subtotal: total,
      total,
      discount,
      orderItems,
    });
  };

  handleBundleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;

    this.setState({ bundleSelected: checked }, () => this.calculateTotal());
  };

  handleWaitingListSignup = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.setState((prevState) => ({
      loading: { ...prevState.loading, waitingListLoading: true },
    }));

    const formData = new FormData(e.currentTarget);

    const response = await fetch('', {
      method: 'POST',
      headers: {
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: formData,
    });

    if (response.ok) {
      // TODO: Migrate modal away from bootstrap
      const modal = $('#waitingListModal');
      modal.modal('show');

      this.setState((prevState) => ({
        cohort: { ...prevState.cohort, visibleWaitingList: null },
        loading: { ...prevState.loading, waitingListLoading: false },
      }));
    }
  };

  handleCohortChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    this.setState(
      (prevState) => ({
        cohort: {
          ...prevState.cohort,
          selectedCohort: parseInt(value),
        },
      }),
      () => this.calculateTotal()
    );
  };

  handlePaymentPlanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    this.setState(
      // $FlowFixMe
      (prevState) => ({
        payment: {
          ...prevState.payment,
          selectedPaymentPlan: value,
        },
      }),
      () => this.calculateTotal()
    );
  };

  onAuthInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.currentTarget;

    this.setState((prevState) => ({
      auth: {
        ...prevState.auth,
        [name]:
          type === 'checkbox'
            ? checked
            : type === 'radio'
            ? value === 'true'
            : value,
      },
    }));
  };

  onAuthCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    let newValue = value;
    if (value === '-') {
      newValue = 'GB';
    }
    if (value.charAt(value.length - 1) === '1') {
      newValue = value.slice(0, 2);
    }
    this.setState((prevState) => ({
      auth: {
        ...prevState.auth,
        [name]: newValue,
      },
    }));
  };

  toggleAuthForm = (
    e: React.ChangeEvent<HTMLButtonElement>,
    toShow: 'guest' | 'register' | 'login'
  ) => {
    e.preventDefault();
    this.setState({
      auth: this.defaultAuth,
      validation: this.defaultValidation,
      visibleForm: toShow,
    });
  };

  handleAuthError = (
    error,
    loadingState:
      | 'loginLoading'
      | 'registerLoading'
      | 'logoutLoading'
      | 'guestEmailLoading',
    validationState:
      | 'loginError'
      | 'registerError'
      | 'logoutError'
      | 'guestEmailError'
  ) => {
    // Extract the most detailed error message from the response
    let errorMessage = error.message;
    if (error.details && error.details.length > 0) {
      errorMessage = error.details[0].message;
    }

    // Update the auth loading and validation state to clear any
    // loading states and to display any error messages
    this.setState((prevState) => ({
      loading: { ...prevState.loading, [loadingState]: false },
      validation: { ...prevState.validation, [validationState]: errorMessage },
    }));
  };

  handleGuestEmailSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      auth: { email },
    } = this.state;
    const { csrftoken } = this.props;
    e.preventDefault();

    // Set the loading state and clear any validation errors
    this.setState((prevState) => ({
      loading: { ...prevState.loading, guestEmailLoading: true },
      validation: {
        ...prevState.validation,
        guestEmailError: null,
      },
    }));

    const response = await api.checkTempEmail(
      {
        email,
      },
      csrftoken
    );

    if (response.error) {
      this.handleAuthError(
        response.error,
        'guestEmailLoading',
        'guestEmailError'
      );
      return;
    }

    // If the email already exists, then tell the user
    if (response.exists) {
      this.handleAuthError(
        response.error,
        'guestEmailLoading',
        'guestEmailError'
      );
    } else {
      // Successfully checked email, so progress to the next checkout step
      try {
        analytics.identify({ set: { email } });
        analytics.track('Guest Entered Email', { email });
        this.analyticsCheckoutStepCompleted({
          step: 1,
          stepVariant: 'auth_guest',
        });
      } catch (err) {
        try {
          Sentry.captureException(err);
        } catch (e) {
          console.warn('Sentry not installed');
        }
        console.warn('analytics not installed');
      }

      this.setState((prevState) => ({
        loading: { ...prevState.loading, guestEmailLoading: false },
      }));

      this.handleNextStepOnPress(2);
    }
  };

  handleRegister = async (e: React.ChangeEvent<HTMLFormElement>) => {
    const { course, csrftoken, dispatch } = this.props;
    const {
      auth: { name, email, password, country, marketingConsent },
      experiment,
    } = this.state;
    e.preventDefault();

    // Set the loading state and clear any validation errors
    this.setState((prevState) => ({
      loading: { ...prevState.loading, registerLoading: true },
      validation: {
        ...prevState.validation,
        loginError: null,
        registerError: null,
      },
    }));

    const marketingConsentUpdate = marketingConsent === 'true';

    const response = await api.register(
      {
        name,
        email,
        password1: password,
        marketing_consent: experiment
          ? marketingConsentUpdate
          : marketingConsent,
        source: `checkout-${course.slug}`,
      },
      dispatch
    );

    if (response.error) {
      this.handleAuthError(response.error, 'registerLoading', 'registerError');
      return;
    }

    // Retrieve the users details
    const user = await api.getUserProfile(response.key, csrftoken);

    if (user.error) {
      this.handleAuthError(user.error, 'registerLoading', 'registerError');
      return;
    }

    await api.updateProfile({ country }, csrftoken);

    try {
      analytics.setUserId(user.id, user.email);
      analytics.identify({
        set: {
          first_name: user.firstName,
          last_name: user.lastName,
          email: user.email,
          username: user.username,
          marketing_consent: user.profile.marketingConsent,
        },
      });
      analytics.track('Signed Up', {
        first_name: user.firstName,
        last_name: user.lastName,
        username: user.username,
        marketing_consent: user.profile.marketingConsent,
        email: user.email,
        type: 'checkout',
      });
      analytics.logConversion('signedUp');
      this.analyticsCheckoutStepCompleted({
        step: 1,
        stepVariant: 'auth_signup',
      });
    } catch (err) {
      try {
        Sentry.captureException(err);
      } catch (e) {
        console.warn('Sentry not installed');
      }
      console.warn('analytics not installed');
    }

    this.handleNextStepOnPress(2);

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        id: user.id,
        name: user.name,
        username: user.username,
        email: user.email,
        token: response.key,
      },
      loading: { ...prevState.loading, registerLoading: false },
    }));
  };

  handleLogin = async (e: React.ChangeEvent<HTMLFormElement>) => {
    const { csrftoken, dispatch } = this.props;
    const {
      auth: { email, password },
    } = this.state;

    e.preventDefault();
    // Set the loading state and clear any validation errors
    this.setState((prevState) => ({
      loading: {
        ...prevState.loading,
        loginLoading: true,
        guestEmailLoading: true,
      },
      validation: {
        ...prevState.validation,
        loginError: null,
        registerError: null,
        guestEmailError: null,
      },
    }));

    // Login to get an auth token
    const response = await api.login({ email, password }, dispatch);

    if (response.error) {
      this.handleAuthError(response.error, 'loginLoading', 'loginError');
      return;
    }

    // Retrieve the users details
    const user = await api.getUserProfile(response.key, csrftoken);

    if (user.error) {
      this.handleAuthError(user.error, 'loginLoading', 'loginError');
      return;
    }

    try {
      analytics.setUserId(user.id);
      analytics.identify({
        set: {
          first_name: user.firstName,
          last_name: user.lastName,
          email: user.email,
          username: user.username,
          marketing_consent: user.profile.marketingConsent,
        },
      });
      analytics.track('Signed In', {
        username: user.username,
      });
      this.analyticsCheckoutStepCompleted({
        step: 1,
        stepVariant: 'auth_signin',
      });
    } catch (err) {
      try {
        Sentry.captureException(err);
      } catch (e) {
        console.warn('Sentry not installed');
      }
      console.warn('analytics not installed');
    }

    this.handleNextStepOnPress(2);

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        id: user.id,
        name: user.name,
        username: user.username,
        email: user.email,
        token: response.key,
      },
      loading: {
        ...prevState.loading,
        loginLoading: false,
        guestEmailLoading: false,
      },
    }));
  };

  handleSignOut = async (e: React.ChangeEvent<HTMLButtonElement>) => {
    const { dispatch } = this.props;
    const { user } = this.state;
    e.preventDefault();

    // Set the loading state and clear any validation errors
    this.setState((prevState) => ({
      loading: { ...prevState.loading, logoutLoading: true },
      validation: {
        ...prevState.validation,
        logoutError: null,
      },
    }));

    const response = await api.logout(dispatch);

    if (response.error) {
      this.handleAuthError(response.error, 'logoutLoading', 'logoutError');
      return;
    }

    try {
      analytics.track('Signed Out', {
        username: user.username,
      });
      analytics.reset();
    } catch (err) {
      try {
        Sentry.captureException(err);
      } catch (e) {
        console.warn('Sentry not installed');
      }
      console.warn('analytics not installed');
    }

    this.setState(
      (prevState) => ({
        user: {
          ...prevState.user,
          id: null,
          name: '',
          username: '',
          email: '',
          token: '',
          country: '',
        },
        loading: { ...prevState.loading, logoutLoading: false },
      }),
      () => this.handleNextStepOnPress(1)
    );
    window.location.reload();
  };

  handleApplyCoupon = async (e) => {
    const { user, course, csrftoken } = this.props;
    const { coupon } = this.state;
    e.preventDefault();

    const { code } = coupon;

    if (!code) {
      this.setState((prevState) => ({
        discount: null,
        loading: { ...prevState.loading, couponLoading: false },
        validation: {
          ...prevState.validation,
          couponError: 'Please provide a valid coupon.',
        },
      }));
      return;
    }

    // Set the loading state and clear any validation errors
    this.setState((prevState) => ({
      loading: { ...prevState.loading, couponLoading: true },
      validation: { ...prevState.validation, couponError: null },
    }));

    const response = await api.applyCoupon(
      code,
      course.slug,
      user.token,
      csrftoken
    );

    try {
      analytics.track('Coupon Entered', {
        coupon_id: code,
      });
    } catch (err) {
      try {
        Sentry.captureException(err);
      } catch (e) {
        console.warn('Sentry not installed');
      }
      console.warn('analytics not installed');
    }

    if (response.error) {
      this.setState((prevState) => ({
        coupon: {
          code: '',
          type: '',
          platform: '',
          amount: 0,
          valid: false,
        },
        discount: null,
        loading: { ...prevState.loading, couponLoading: false },
        validation: {
          ...prevState.validation,
          couponError: response.error.message,
        },
      }));
      try {
        analytics.track('Coupon Denied', {
          coupon_id: code,
          reason: response.error.message,
        });
      } catch (err) {
        try {
          Sentry.captureException(err);
        } catch (e) {
          console.warn('Sentry not installed');
        }
        console.warn('analytics not installed');
      }
      return;
    }

    this.setState(
      (prevState) => ({
        coupon: response.coupon,
        loading: { ...prevState.loading, couponLoading: false },
      }),
      this.calculateTotal
    );
    try {
      const {
        course: { price, paymentPlanPrice },
        bundle,
      } = this.props;

      const { discount } = this.calculateTotalPriceAndDiscount(
        price,
        paymentPlanPrice,
        bundle,
        response.coupon
      );

      analytics.track('Coupon Applied', {
        coupon_id: code,
        coupon_type: response.coupon.type,
        discount,
      });
    } catch (err) {
      try {
        Sentry.captureException(err);
      } catch (e) {
        console.warn('Sentry not installed');
      }
      console.warn('analytics not installed');
    }
  };

  handleRedeemCourse = async (e) => {
    const { user, csrftoken } = this.props;
    const {
      coupon,
      cohort,
      auth: { email },
    } = this.state;
    e.preventDefault();

    const { code } = coupon;
    const { selectedCohort } = cohort;

    if (!code || !selectedCohort) {
      this.setState((prevState) => ({
        loading: { ...prevState.loading, redeemCourseLoading: false },
        validation: {
          ...prevState.validation,
          redeemCourseError: !code
            ? 'Please provide a valid coupon.'
            : 'Please select a cohort.',
        },
      }));
      return;
    }

    // Set the loading state and clear any validation errors
    this.setState((prevState) => ({
      loading: { ...prevState.loading, redeemCourseLoading: true },
      validation: { ...prevState.validation, redeemCourseError: null },
    }));

    const response = await api.redeemCourse(
      code,
      selectedCohort,
      email,
      user.token,
      csrftoken
    );

    if (response.error) {
      this.setState((prevState) => ({
        loading: { ...prevState.loading, redeemCourseLoading: false },
        validation: {
          ...prevState.validation,
          redeemCourseError: response.error.message,
        },
      }));
      return;
    }

    this.analyticsCheckoutStepCompleted({
      step: 4,
      stepVariant: 'complete_redeem',
    });

    this.setState((prevState) => ({
      payment: { ...prevState.payment, successfulPayment: true },
      loading: { ...prevState.loading, redeemCourseLoading: false },
    }));
  };

  onCouponInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    this.setState((prevState) => ({
      coupon: {
        ...prevState.coupon,
        code: value,
      },
    }));
  };

  _logPaymentWithSegment = (
    transactionId: string,
    paymentId?: string,
    customerId?: string
  ) => {
    const {
      user,
      store: { config },
      orderItems,
      total,
      coupon,
      cohort,
    } = this.state;
    const { selectedCohort, upcomingCohorts } = cohort;

    try {
      // TODO: Group users client-side
      // LOG GROUP
      const selectedCohortObj = upcomingCohorts.find(
        (c) => c.id === selectedCohort
      );
      const simpleCourseName = orderItems[0].slug
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      let numMembers = 0;
      let percentageFull = 0;
      let name = '';
      let cohortStartDate = '';
      if (selectedCohortObj) {
        numMembers =
          selectedCohortObj.spacesTotal - selectedCohortObj.spacesAvailable;
        percentageFull =
          (1 -
            selectedCohortObj.spacesAvailable / selectedCohortObj.spacesTotal) *
          100;
        name = `${simpleCourseName} | ${moment(
          selectedCohortObj.startDate
        ).format('dddd Do MMMM YYYY')}${
          selectedCohortObj.label ? ` - ${selectedCohortObj.label}` : ''
        }`;
        cohortStartDate = selectedCohortObj.startDate;
      }

      analytics.group(selectedCohort, {
        group_type: 'cohort',
        name,
        course: orderItems[0].slug,
        start_date: cohortStartDate,
        num_members: numMembers,
        percentage_full: percentageFull,
      });
      analytics.track('User Added to Group', {
        group_id: selectedCohort,
        group_type: 'cohort',
        name,
        course: orderItems[0].slug,
        start_date: cohortStartDate,
        num_members: numMembers,
        percentage_full: percentageFull,
      });
      let discount = 0;
      if (coupon && coupon.valid) {
        if (
          coupon.type === 'percentage' ||
          coupon.type === 'platform_percentage'
        ) {
          discount = (coupon.amount / 100) * total;
        } else if (coupon.type === 'monetary') {
          discount = coupon.amount;
        }
      }
      const products = orderItems.map((orderItem) => ({
        product_id: orderItem.slug || orderItem.sku,
        sku: orderItem.sku,
        category: orderItem.category,
        name: orderItem.title,
        brand: orderItem.brand,
        variant: orderItem.type,
        price: Number(orderItem.lineItemPrice.replace(/[^0-9.-]+/g, '')) || '',
        quantity: orderItem.quantity || '',
        coupon: coupon.code || '',
        payment_plan: orderItem.paymentPlan || '',
        class_type: orderItem.classType || '',
        course_type: orderItem.courseType || '',
      }));
      const tax = (this.calculateTotalAmount('number') / 120) * 20;
      analytics.track('Purchase Completed', {
        order_id: transactionId,
        customer_id: customerId,
        affiliation: 'Web',
        total: +this.calculateTotalAmount('number').toFixed(2),
        revenue: +(this.calculateTotalAmount('number') - tax).toFixed(2),
        value: +(this.calculateTotalAmount('number') - tax).toFixed(2),
        tax: +tax.toFixed(2),
        discount,
        coupon: coupon.code || '',
        currency: config.currency.toUpperCase(),
        products,
      });
    } catch (e) {
      try {
        Sentry.captureMessage(
          `gtm event failed for purchase by user ${user.id || ''}`
        );
      } catch (e) {
        console.warn('Sentry not installed');
      }
      console.warn('analytics not installed');
    }
    // Log Bing purchases manually
    try {
      window.uetq = window.uetq || [];
      window.uetq.push('event', 'Purchase', {
        event_category: 'Ecommerce',
        event_label: `Order Id: ${transactionId}`,
        revenue_value: total,
        currency: config.currency.toUpperCase(),
      });
    } catch (error) {
      console.warn('UET not installed');
    }
  };

  handlePaymentSuccess = (
    paymentId?: string,
    customerId?: string,
    chargeId?: string,
    isGift: boolean
  ) => {
    // We must always have a transaction ID in order for the data to log
    const transactionId = paymentId || `${S4()}-${S4()}-${S4()}-${S4()}`;

    // Handle analytics
    this.analyticsCheckoutStepCompleted({
      step: 4,
      stepVariant: 'complete_payment',
    });

    // eslint-disable-next-line no-underscore-dangle
    this._logPaymentWithSegment(transactionId, paymentId, customerId);

    // Transition UI to success
    this.setState((prevState) => ({
      payment: { ...prevState.payment, successfulPayment: true },
    }));
  };

  loadingPlaceholder = () => (
    <PageWrapper alignItems="center" justifyContent="center">
      <Spinner />
    </PageWrapper>
  );

  calcBundleTotalPrice = (bundle: Bundle) =>
    bundle.items.reduce((acc, item) => acc + item.price, 0);

  handleNextStepOnPress = (goto: number) => {
    this.setState((prevState) => ({
      store: { ...prevState.store, activeStep: goto },
    }));
  };

  renderMain = () => {
    const {
      visibleForm,
      user,
      store,
      loading,
      cohort,
      payment,
      auth,
      validation,
      orderItems,
      bundleSelected,
      coupon,
      experiment,
      redirectOnSuccess,
      width,
      stripe,
    } = this.state;
    const {
      csrftoken,
      courseProvider,
      countries,
      course,
      inviteImage,
      bundle,
      endpoints,
      hasPurchased,
      theme,
      dispatch,
    } = this.props;
    const { name: providerName, conversionTrackingUrl } = courseProvider;
    const { activeStep, config } = store;
    const {
      paymentPlanAvailable,
      price,
      paymentPlanPrice,
      paymentPlanInstalmentAmount,
      paymentPlanTerm,
      slug,
      title,
      courseType,
    } = course;

    const { selectedCohort, upcomingCohorts, visibleWaitingList } = cohort;
    const { selectedPaymentPlan, successfulPayment } = payment;
    const { name, email, password, country, marketingConsent } = auth;

    const isMiniCourse = courseType === 'mini';

    const isCourseVoucher = coupon.type === 'course';

    let paymentStepNumber = 3;
    if (paymentPlanAvailable && !bundleSelected) paymentStepNumber += 1;
    if (isCourseVoucher) paymentStepNumber = 3;
    if (isMiniCourse) paymentStepNumber = 2;

    const isAuthenticated = !!user.id;

    // Create elements used within the select cohort area
    const soldOut = (
      <chakra.span
        bg="background.tint2"
        color="text.muted"
        borderRadius="full"
        fontSize="xs"
        px={2}
        py={1}
        textAlign="center"
        fontWeight="semibold"
      >
        SOLD OUT
      </chakra.span>
    );

    const selectedCohortObj = upcomingCohorts.find(
      (c) => c.id === selectedCohort
    );

    const remainingPercentage = selectedCohortObj
      ? (selectedCohortObj.spacesAvailable / selectedCohortObj.spacesTotal) *
        100
      : 100;

    let visibleAuthForm = (
      <GuestEmailForm
        email={email}
        onChange={this.onAuthInputChange}
        handleSubmit={this.handleGuestEmailSubmit}
        toggleAuthForm={this.toggleAuthForm}
        error={validation.guestEmailError}
        loading={loading.guestEmailLoading}
        isAuthenticated={isAuthenticated}
      />
    );

    if (visibleForm === 'register') {
      visibleAuthForm = (
        <RegisterForm
          handleRegister={this.handleRegister}
          providerName={providerName}
          countries={countries}
          toggleAuthForm={this.toggleAuthForm}
          name={name}
          email={email}
          password={password}
          country={country}
          marketingConsent={marketingConsent}
          experiment={experiment}
          onChange={this.onAuthInputChange}
          onCountryChange={this.onAuthCountryChange}
          error={validation.registerError}
          loading={loading.registerLoading}
        />
      );
    } else if (visibleForm === 'login') {
      visibleAuthForm = (
        <LoginForm
          resetPasswordUrl={endpoints.accountResetPassword}
          handleLogin={this.handleLogin}
          toggleAuthForm={this.toggleAuthForm}
          email={email}
          password={password}
          onChange={this.onAuthInputChange}
          error={validation.loginError}
          loading={loading.loginLoading}
        />
      );
    }

    return (
      <MainPane style={{ ...(successfulPayment ? { margin: '0 auto' } : {}) }}>
        {successfulPayment ? (
          <OrderConfirmation
            inviteImage={inviteImage}
            inviteSubject={encodeURI(`Join me on ${course.title}`)}
            shareUrl={encodeURI(course.shareUrl)}
            email={email}
            selectedPaymentPlan={selectedPaymentPlan}
            amountPaid={parseFloat(this.calculateTotalAmount('number'))}
            paymentPlanTerm={paymentPlanTerm}
            paymentPlanInstalmentAmount={paymentPlanInstalmentAmount}
            isAuthenticated={isAuthenticated}
            slug={slug}
            title={title}
            shouldRedirect={redirectOnSuccess}
            redirectDomain={conversionTrackingUrl}
            mentorName={providerName}
            selectedStartDate={
              selectedCohortObj ? selectedCohortObj.startDate : undefined
            }
            isCourseVoucher={isCourseVoucher}
            width={width}
          />
        ) : (
          <MainWrapper flexDirection="column">
            {loading.initialData ? (
              <Flex>
                <Loading />
              </Flex>
            ) : (
              <>
                <Step style={{ marginTop: 0 }}>
                  <CheckoutCard flexDirection="column">
                    <Collapsible
                      trigger={
                        <Flex flexDirection="column">
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <ResponsiveHeading as="h4" size="h4">
                              {`Step 1 – ${
                                visibleForm === 'register'
                                  ? 'Sign Up'
                                  : visibleForm === 'login' || isAuthenticated
                                  ? 'Log In'
                                  : 'Confirm Your Email'
                              }`}
                            </ResponsiveHeading>
                            <ChangeButton
                              visible={activeStep > 1}
                              onClick={
                                isAuthenticated
                                  ? this.handleSignOut
                                  : () => this.handleNextStepOnPress(1)
                              }
                              isLoading={loading.logoutLoading}
                            >
                              {isAuthenticated ? 'Log Out' : 'Change'}
                            </ChangeButton>
                          </Flex>
                          {activeStep > 1 && (
                            <>
                              {isAuthenticated ? (
                                <Text color="text.muted">{`Logged in as ${user.email}`}</Text>
                              ) : (
                                <Text>{email}</Text>
                              )}
                            </>
                          )}
                        </Flex>
                      }
                      triggerDisabled
                      open={activeStep === 1}
                      overflowWhenOpen="visible"
                    >
                      <>{visibleAuthForm}</>
                    </Collapsible>
                  </CheckoutCard>
                </Step>
                {hasPurchased && (
                  <Text color="text.muted" fontWeight="semibold" mt={6}>
                    You are already enrolled on this course.
                  </Text>
                )}
                {!hasPurchased && !isMiniCourse && (
                  <Step>
                    <CheckoutCard
                      flexDirection="column"
                      disabled={activeStep < 2}
                    >
                      <Collapsible
                        trigger={
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <ResponsiveHeading as="h4" size="h4">
                              {`${`Step 2 – Pick a Start Date${
                                bundle ? ' or Bundle' : ''
                              }`}`}
                            </ResponsiveHeading>
                            <ChangeButton
                              visible={activeStep > 2}
                              onClick={() => this.handleNextStepOnPress(2)}
                            >
                              Change
                            </ChangeButton>
                          </Flex>
                        }
                        triggerDisabled
                        open={activeStep === 2}
                        overflowWhenOpen="visible"
                      >
                        {!bundleSelected ? (
                          <React.Fragment>
                            {activeStep === 2 && (
                              <AnalyticsCheckoutStepViewed
                                step={2}
                                stepVariant="select_date"
                              />
                            )}
                            <Paragraph color="text.muted">
                              {`You will start your course alongside a group of fellow students, all starting on this date.${
                                bundle
                                  ? ' You can also save money by getting this course as part of the full bundle'
                                  : ''
                              }.`}
                            </Paragraph>
                            {selectedCohortObj && (
                              <div className="cohort-item">
                                <CohortText>
                                  {`${moment(
                                    selectedCohortObj.startDate
                                  ).format('dddd Do MMMM YYYY')}${
                                    selectedCohortObj.label
                                      ? ` - ${selectedCohortObj.label}`
                                      : ''
                                  }`}
                                  {selectedCohortObj.socialType ===
                                    'private' && (
                                    <chakra.span
                                      bg={
                                        remainingPercentage <= 50
                                          ? 'background.error'
                                          : remainingPercentage <= 70
                                          ? 'background.warning'
                                          : 'background.primary'
                                      }
                                      color={
                                        remainingPercentage <= 50
                                          ? 'text.error'
                                          : remainingPercentage <= 70
                                          ? 'text.warning'
                                          : 'text.primary'
                                      }
                                      borderRadius="full"
                                      fontSize="xs"
                                      px={2}
                                      py={1}
                                      textAlign="center"
                                    >
                                      {`${selectedCohortObj.spacesAvailable} spaces remaining`}
                                    </chakra.span>
                                  )}
                                </CohortText>
                              </div>
                            )}
                            <Collapsible
                              trigger={
                                <CollapseLink>
                                  {'Select another start date? '}
                                  <i className="fa fa-caret-down" />
                                </CollapseLink>
                              }
                            >
                              {upcomingCohorts.map((c) => (
                                <UpcomingCohort
                                  key={`cohort-${c.id}`}
                                  cohort={c}
                                  isChecked={c.id === selectedCohort}
                                  spacesLimited={c.socialType === 'private'}
                                  isCohort={c.socialType === 'private'}
                                  waitingListIsVisible={
                                    visibleWaitingList === c.id
                                  }
                                  waitingListLoading={
                                    loading.waitingListLoading
                                  }
                                  soldOutComponent={soldOut}
                                  email={email}
                                  onClickWaitingList={() =>
                                    this.setState((prevState) => ({
                                      cohort: {
                                        ...prevState.cohort,
                                        visibleWaitingList: c.id,
                                      },
                                    }))
                                  }
                                  handleWaitingListSignup={
                                    this.handleWaitingListSignup
                                  }
                                  handleCohortChange={this.handleCohortChange}
                                />
                              ))}
                            </Collapsible>
                          </React.Fragment>
                        ) : null}
                        {bundle ? (
                          <React.Fragment>
                            {activeStep === 2 && (
                              <AnalyticsCheckoutStepViewed
                                step={2}
                                stepVariant="select_bundle"
                              />
                            )}
                            <Checkbox
                              id="bundle-check"
                              name="bundle-check"
                              value="bundle-check"
                              checked={bundleSelected}
                              onChange={this.handleBundleChange}
                              labelCss="margin-top: 0;"
                            >
                              <BundleText>
                                {'Buy the full '}
                                <strong>{`${bundle.title}`}</strong>
                                {' bundle:'}
                              </BundleText>
                            </Checkbox>
                            <Flex
                              flexDirection="column"
                              style={{
                                backgroundColor: Color.background.tint1,
                                padding: '1rem',
                                borderRadius: '5px',
                                marginBottom: '1rem',
                              }}
                            >
                              <BundleLabel>Bundle Details</BundleLabel>
                              {bundle.items
                                .sort((a, b) =>
                                  moment(a.start_date).diff(
                                    moment(b.start_date)
                                  )
                                )
                                .map((item) => (
                                  <BundleItemWrapper>
                                    <BundleImageWrapper>
                                      <BundleImage src={item.image} />
                                    </BundleImageWrapper>
                                    <div>
                                      <BundleText>
                                        <strong>{item.title}</strong>
                                      </BundleText>
                                      <BundleText
                                        style={{ paddingBottom: '10px' }}
                                      >
                                        {`Normally £${item.price}`}
                                      </BundleText>
                                      <BundleText>
                                        {`Starting ${moment(
                                          item.start_date,
                                          'DD/MM/YYYY'
                                        ).format('Do MMMM YYYY')}`}
                                      </BundleText>
                                    </div>
                                  </BundleItemWrapper>
                                ))}
                              <BundleLabel style={{ textAlign: 'right' }}>
                                {'Total price: '}
                                <chakra.span
                                  style={{ textDecoration: 'line-through' }}
                                >
                                  {`£${this.calcBundleTotalPrice(bundle)}`}
                                </chakra.span>
                                <strong>{` £${bundle.price}`}</strong>
                                {` (save £${
                                  this.calcBundleTotalPrice(bundle) -
                                  bundle.price
                                })`}
                              </BundleLabel>
                            </Flex>{' '}
                          </React.Fragment>
                        ) : null}
                        <StepButton
                          isDisabled={!bundleSelected && !cohort.selectedCohort}
                          onClick={() => {
                            this.handleNextStepOnPress(3);
                            this.analyticsCheckoutStepCompleted({
                              step: 2,
                              stepVariant: bundle
                                ? 'select_bundle'
                                : 'select_date',
                            });
                          }}
                        >
                          {bundleSelected
                            ? 'Confirm Selection'
                            : 'Confirm Start Date'}
                        </StepButton>
                      </Collapsible>
                      <MaskOverlay visible={activeStep < 2} />
                    </CheckoutCard>
                  </Step>
                )}
                {!hasPurchased &&
                  paymentPlanAvailable &&
                  !bundleSelected &&
                  !isCourseVoucher && (
                    <Step>
                      <CheckoutCard
                        flexDirection="column"
                        disabled={activeStep < 3}
                      >
                        <Collapsible
                          trigger={
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <ResponsiveHeading as="h4" size="h4">
                                {`Step ${
                                  isMiniCourse ? '2' : '3'
                                } – Select Plan`}
                              </ResponsiveHeading>
                              <ChangeButton
                                visible={activeStep > 3}
                                onClick={() => this.handleNextStepOnPress(3)}
                              >
                                Change
                              </ChangeButton>
                            </Flex>
                          }
                          triggerDisabled
                          open={activeStep === 3}
                          overflowWhenOpen="visible"
                        >
                          <div
                            className="plan-item"
                            style={{ marginTop: '0.625rem' }}
                          >
                            <Radiobutton
                              id="planUpfront"
                              name="payment-plan"
                              value="upfront"
                              checked={selectedPaymentPlan === 'upfront'}
                              labelCss="flex: 1;"
                              onChange={this.handlePaymentPlanChange}
                            >
                              <Text
                                css="
                            display: flex;
                            justify-content: space-between;
                            flex: 1;
                          "
                              >
                                {'Pay In Full '}
                                <Text>{`£${price.toFixed(2)}`}</Text>
                              </Text>
                            </Radiobutton>
                            {activeStep === 3 &&
                              selectedPaymentPlan === 'upfront' && (
                                <AnalyticsCheckoutStepViewed
                                  step={3}
                                  stepVariant="plan_upfront"
                                />
                              )}
                          </div>
                          <div className="plan-item">
                            <Radiobutton
                              id="planInstalment"
                              name="payment-plan"
                              value="instalment"
                              checked={selectedPaymentPlan === 'instalment'}
                              labelCss="flex: 1;"
                              inputCss="margin-top: -50px;"
                              onChange={this.handlePaymentPlanChange}
                            >
                              <Text css="flex: 1;">
                                Monthly Instalments
                                <div css="display: flex; justify-content: space-between;">
                                  <Text color="text.muted">
                                    Initial Payment
                                  </Text>
                                  <Text color="text.muted">
                                    {`£${paymentPlanPrice.toFixed(2)}`}
                                  </Text>
                                </div>
                                <div css="display: flex; justify-content: space-between;">
                                  <Text color="text.muted">{`${paymentPlanTerm} Monthly Payments`}</Text>
                                  <Text color="text.muted">
                                    {`£${paymentPlanInstalmentAmount.toFixed(
                                      2
                                    )}`}
                                  </Text>
                                </div>
                              </Text>
                            </Radiobutton>
                            {activeStep === 3 &&
                              selectedPaymentPlan === 'instalment' && (
                                <AnalyticsCheckoutStepViewed
                                  step={3}
                                  stepVariant="plan_instalment"
                                />
                              )}
                          </div>
                          {selectedPaymentPlan === 'instalment' && (
                            <Paragraph className="tip">
                              {`You'll be charged £${paymentPlanPrice.toFixed(
                                2
                              )} now and you'll then be charged £${paymentPlanInstalmentAmount.toFixed(
                                2
                              )} for ${paymentPlanTerm} consecutive months, starting next month.`}
                            </Paragraph>
                          )}
                          <StepButton
                            onClick={() => {
                              this.analyticsCheckoutStepCompleted({
                                step: 3,
                                stepVariant:
                                  selectedPaymentPlan === 'upfront'
                                    ? 'plan_upfront'
                                    : 'plan_instalment',
                              });
                              this.handleNextStepOnPress(4);
                            }}
                            style={{ marginBottom: 10, marginTop: 20 }}
                          >
                            Confirm Payment Plan
                          </StepButton>
                        </Collapsible>
                        <MaskOverlay visible={activeStep < 3} />
                      </CheckoutCard>
                    </Step>
                  )}
                {!hasPurchased && isCourseVoucher ? (
                  <Step>
                    <CheckoutCard
                      flexDirection="column"
                      disabled={activeStep < paymentStepNumber}
                    >
                      <Collapsible
                        trigger={
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <ResponsiveHeading as="h4" size="h4">
                              {`Step ${paymentStepNumber} – Redeem`}
                            </ResponsiveHeading>
                          </Flex>
                        }
                        triggerDisabled
                        open={activeStep === paymentStepNumber}
                        overflowWhenOpen="visible"
                      >
                        {activeStep === paymentStepNumber && (
                          <AnalyticsCheckoutStepViewed
                            step={4}
                            stepVariant="complete_redeem"
                          />
                        )}
                        <StepButton
                          onClick={this.handleRedeemCourse}
                          style={{ marginBottom: 10, marginTop: 20 }}
                        >
                          {loading.redeemCourseLoading ? (
                            <i className="fa fa-circle-o-notch fa-pulse fa-fw" />
                          ) : (
                            'Redeem My Course'
                          )}
                        </StepButton>
                        {validation.redeemCourseError && (
                          <Error
                            visible
                            style={{ marginTop: 15, marginBottom: 0 }}
                          >
                            {validation.redeemCourseError}
                          </Error>
                        )}
                      </Collapsible>
                      <MaskOverlay visible={activeStep < paymentStepNumber} />
                    </CheckoutCard>
                  </Step>
                ) : (
                  !hasPurchased && (
                    <Step>
                      <CheckoutCard
                        flexDirection="column"
                        disabled={activeStep < paymentStepNumber}
                      >
                        <Collapsible
                          trigger={
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <ResponsiveHeading as="h4" size="h4">
                                {`Step ${paymentStepNumber} – Payment`}
                              </ResponsiveHeading>
                              {activeStep === paymentStepNumber && (
                                <SecurePayment hiddenMobile />
                              )}
                            </Flex>
                          }
                          triggerDisabled
                          open={activeStep === paymentStepNumber}
                          overflowWhenOpen="visible"
                        >
                          {activeStep === paymentStepNumber && (
                            <AnalyticsCheckoutStepViewed
                              step={4}
                              stepVariant="complete_payment"
                            />
                          )}
                          <Elements stripe={stripe}>
                            <ElementsConsumer>
                              {(stripeProps) => (
                                <PaymentForm
                                  userIsAuthenticated={isAuthenticated}
                                  authToken={user.token}
                                  csrftoken={csrftoken}
                                  email={email}
                                  countries={countries}
                                  config={config}
                                  total={this.calculateTotalAmount()}
                                  items={orderItems}
                                  couponCode={
                                    coupon.valid ? coupon.code : undefined
                                  }
                                  onSuccess={this.handlePaymentSuccess}
                                  stripe={stripeProps.stripe}
                                  elements={stripeProps.elements}
                                  providerName={courseProvider.name}
                                  theme={theme}
                                  dispatch={dispatch}
                                />
                              )}
                            </ElementsConsumer>
                          </Elements>
                        </Collapsible>
                        <MaskOverlay visible={activeStep < paymentStepNumber} />
                      </CheckoutCard>
                    </Step>
                  )
                )}
              </>
            )}
          </MainWrapper>
        )}
      </MainPane>
    );
  };

  couponText = (type: Coupon.type, amount?: number): string => {
    switch (type) {
      case 'platform_percentage':
      case 'percentage':
        return `Discount Applied (${amount}% off)`;
      case 'monetary':
        return `Discount Applied (£${amount} off)`;
      case 'course':
        return 'Voucher code applied. You can now redeem your course.';
      default:
        return '';
    }
  };

  renderOrderItems = () => {
    const {
      subtotal,
      total,
      discount,
      orderItems,
      coupon,
      loading,
      validation,
    } = this.state;
    const { courseProvider } = this.props;

    const isCourseVoucher = coupon.type === 'course';

    const discountText = this.couponText(coupon.type, coupon.amount);

    const originalPrice = total.toFixed(2);
    const finalPrice = this.calculateTotalAmount();

    const showDiscountText =
      isCourseVoucher || (discount != null && discount > 0);

    return (
      <>
        <Flex className="order-items">
          {orderItems.length > 0 ? (
            orderItems.map((item) => (
              <Flex
                key={`order-item-${item.sku}`}
                className="line-item"
                flex={1}
              >
                <Box className="image-wrapper">
                  <img className="image" src={item.imageUrl} alt="Order Item" />
                </Box>
                <Box className="label" flex={1}>
                  <Link to={navRoutes.global.courseLanding.path(item.slug)}>
                    <Paragraph
                      className="product"
                      display="inline-block"
                      _hover={{ textDecoration: 'underline' }}
                    >
                      {item.product}
                    </Paragraph>
                  </Link>
                  {item.skuDisplay && (
                    <Paragraph className="sku">{item.skuDisplay}</Paragraph>
                  )}
                </Box>
                {/* <Paragraph className="count">{`${item.quantity} x ${item.skuPrice}`}</Paragraph> */}
                {!isCourseVoucher && (
                  <VisibleDesktopOnly>
                    <Paragraph className="price">
                      {item.lineItemPrice}
                    </Paragraph>
                  </VisibleDesktopOnly>
                )}
              </Flex>
            ))
          ) : (
            <div className="line-item" style={{ flex: 1 }}>
              <div className="label">
                <Paragraph className="product">Basket empty</Paragraph>
              </div>
              <Paragraph className="price">£ -</Paragraph>
            </div>
          )}
        </Flex>
        <Flex className="order-total" flexDirection="column">
          {!isCourseVoucher && (
            <div className="line-item subtotal">
              <Paragraph className="label">Subtotal</Paragraph>
              <Paragraph className="price">{`£${
                orderItems.length > 0 ? subtotal.toFixed(2) : ' -'
              }`}</Paragraph>
            </div>
          )}
          {/* <div className="line-item shipping">
            <Paragraph className="label">Shipping</Paragraph>
            <Paragraph className="price">Free</Paragraph>
          </div> */}
          <Collapsible
            trigger={
              <CollapseLink className="line-item coupon">
                Have a coupon code?
              </CollapseLink>
            }
          >
            <div className="line-item coupon-input">
              <Box borderColor="border.muted" borderWidth={1} borderRadius="md">
                <CouponForm method="post" onSubmit={this.handleApplyCoupon}>
                  <CouponInput
                    type="text"
                    className="form-control"
                    placeholder="Apply Coupon Code"
                    name="coupon"
                    value={coupon.code || ''}
                    onChange={this.onCouponInputChange}
                  />
                  <CouponSubmit
                    ref={this.couponSubmit}
                    type="submit"
                    className="btn btn-outline-primary"
                    isLoading={loading.couponLoading}
                  >
                    Apply
                  </CouponSubmit>
                </CouponForm>
              </Box>
              {validation.couponError && (
                <CouponMessageWrapper error>
                  <i className="fa fa-times-circle" />
                  <CouponMessage error>{validation.couponError}</CouponMessage>
                </CouponMessageWrapper>
              )}
              {coupon.valid && (
                <CouponMessageWrapper error={false}>
                  <i className="fa fa-check-circle" />
                  <CouponMessage error={false}>
                    Coupon successfully applied
                  </CouponMessage>
                </CouponMessageWrapper>
              )}
            </div>
          </Collapsible>
          {showDiscountText && (
            <div className="line-item discount">
              <Paragraph className="label">{discountText}</Paragraph>
              {discount != null && (
                <Paragraph className="price">{`£${discount.toFixed(
                  2
                )}`}</Paragraph>
              )}
            </div>
          )}
          {!isCourseVoucher && (
            <div className="line-item total">
              <Paragraph className="label">Total</Paragraph>
              <Paragraph className="price">
                {discount != null && (
                  <chakra.span className="discounted">
                    {`£${orderItems.length > 0 ? originalPrice : ' -'}`}
                  </chakra.span>
                )}
                {`£${orderItems.length > 0 ? finalPrice : ' -'}`}
              </Paragraph>
            </div>
          )}
        </Flex>
      </>
    );
  };

  renderPaymentIcons = () => {
    const { paymentTypeImages } = this.props;
    return (
      <>
        <Flex className="line-item payment-methods">
          <Paragraph color="text.muted" fontSize="sm">
            We Accept:
          </Paragraph>
          <Flex style={{ overflow: 'hidden' }}>
            <img src={paymentTypeImages.visa} alt="VISA" />
            <img src={paymentTypeImages.mastercard} alt="Mastercard" />
            <img src={paymentTypeImages.amex} alt="American Express" />
            <img src={paymentTypeImages.visaElectron} alt="VISA Electron" />
            <img src={paymentTypeImages.maestro} alt="Maestro" />
            <img src={paymentTypeImages.discover} alt="Discover" />
          </Flex>
        </Flex>
      </>
    );
  };

  renderSummary = () => {
    const {
      courseProvider: { contactNumber, contactEmail },
    } = this.props;
    const {
      payment: { successfulPayment },
      loading,
      width,
    } = this.state;

    return (
      <Box
        position={width > Device.raw.tablet ? 'sticky' : 'relative'}
        // enabled={width > Device.raw.tablet}
        // bottomBoundary=".footer"
      >
        <SummaryPane
          // flexDirection={width <= Device.raw.tablet ? 'row' : 'column'}
          flexDirection="column"
          className="summary"
          display={!successfulPayment ? 'flex' : 'none'}
        >
          <SummaryHeader>
            <Heading as="h1" size="h3">
              Order Summary
            </Heading>
          </SummaryHeader>
          {this.renderOrderItems()}
          {this.renderPaymentIcons()}
        </SummaryPane>
      </Box>
    );
  };

  render() {
    const { courseProvider } = this.props;
    const { width } = this.state;

    return (
      <div className="checkoutWrapper">
        <PageWrapper flexDirection="column">
          <MainSection
            flexDirection={width > Device.raw.tablet ? 'row' : 'column-reverse'}
          >
            {this.renderMain()}
            {this.renderSummary()}
          </MainSection>
        </PageWrapper>
        <Flex style={{ flex: 1 }} />
        <Footer
          contactNumber={courseProvider.contactNumber}
          contactEmail={courseProvider.contactEmail}
        />
      </div>
    );
  }
}

type MembershipProps = SharedProps & {
  membership: {
    name: string;
    slug: string;
    sku: string;
  };
  groups: Array<{
    name: string;
    slug: string;
    logo?: string;
    description: string;
    imageMobile?: string;
    memberCount: number;
  }>;
  membershipPlans: Array<{
    name: string;
    slug: string;
    sku: string;
    billingInterval: string;
    pricingPlan: {
      id: string;
      object: string;
      active: boolean;
      aggregateUsage?: string;
      amount: number;
      amountDecimal: string;
      billingScheme: string;
      created: number;
      currency: string;
      interval: string;
      intervalCount: number;
      livemode: boolean;
      metadata: {};
      nickname: string;
      product: string;
      tiers: null;
      tiersMode: null;
      transformUsage: null;
      trialPeriodDays: null;
      usageType: string;
    };
  }>;
};

const LandingPage = (props: MembershipProps) => {
  const {
    hasPurchased,
    groups,
    membershipPlans,
    user,
    csrftoken,
    membership,
    courseProvider,
    dispatch,
  } = props;

  const [loading, setLoading] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [error, setError] = useState({ hasError: false, message: '' });
  const [successfulPurchase, setSuccess] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({
    id: null,
    interval: 'month',
    price: 0,
  });
  const [email, setEmail] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);

  const isAuthenticated = !!user.id;

  // TODO: For now we only expect a single group
  const group = groups[0];

  const initData = async () => {
    setLoading(true);
    const configResponse: $Exact<{ error: any }> | StripeConfig =
      await api.getConfig();
    setLoading(false);

    if (configResponse.error) {
      setError({
        error: true,
        message: `An error occurred. Please try refreshing the page, or if the problem persists, contact us on support${PLATFORM_EMAIL}`,
      });
      return;
    }

    const stripeJs = document.createElement('script');
    stripeJs.src = 'https://js.stripe.com/v3/';
    stripeJs.async = true;
    stripeJs.onload = () => {
      const sp = loadStripe(
        configResponse?.stripePublishableKey,
        courseProvider?.stripeAccountId && courseProvider?.stripeAccountStandard
          ? { stripeAccount: courseProvider.stripeAccountId }
          : undefined
      );
      setStripePromise(sp);
    };
    if (document.body) document.body.appendChild(stripeJs);
  };

  const handleCheckoutOnSubmit = async (e) => {
    e.preventDefault();

    if (!isAuthenticated && !validateEmail(email)) {
      setError({
        hasError: true,
        message: 'Please enter a valid email address.',
      });
      return;
    }

    const { id } = selectedPlan;

    if (stripePromise) {
      setLoading(true);
      setError({ hasError: false, message: '' });

      if (!isAuthenticated) {
        // Before creating the stripe session and redirecting the user to checkout,
        // we need to check whether this email address has been used to make a guest
        // related purchase already. If so, tell the user they need to finish creating
        // their account before making another purchase
        const response = await api.checkTempEmail(
          {
            email,
          },
          csrftoken
        );

        if (response.error) {
          setLoading(false);
          setError({ hasError: true, message: response.error.message });
          return;
        }

        // If the email already exists, then tell the user
        if (response.exists) {
          setLoading(false);
          setError({ hasError: true, message: response.error.message });
          return;
        }
      }

      const sessionResponse = await api.getCheckoutSession(
        {
          type: 'membership',
          id,
          email: encodeURIComponent(email),
          successUrl: encodeURIComponent(`${window.location}?success=true`),
          cancelUrl: encodeURIComponent(`${window.location}?success=false`),
        },
        dispatch
      );
      setLoading(false);

      if (sessionResponse.error) {
        setError({
          hasError: true,
          message: `An error occurred. Please try refreshing the page, or if the problem persists, contact us on support${PLATFORM_EMAIL}`,
        });
        return;
      }

      const stripe = await stripePromise;
      const result = await stripe.redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: sessionResponse.checkoutSessionId,
      });

      if (result.error.message) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        setError({
          hasError: true,
          message: result.error.message,
        });
      }
    }
  };

  const handleCheckoutOptionChange = (e) => {
    const membershipPlan = membershipPlans.find(
      (plan) => plan.slug === e.target.value
    );
    const { pricingPlan } = membershipPlan;
    setSelectedPlan({
      id: membershipPlan.slug,
      interval: pricingPlan.interval,
      price: pricingPlan.amount / 100,
    });
  };

  useEffect(() => {
    initData();
    handleCheckoutOptionChange({ target: { value: membershipPlans[0].slug } });

    const successParam = getParamFromUrl('success');
    if (successParam === 'false') {
      setError({
        hasError: true,
        message: 'We were unable to complete your purchase. Please try again.',
      });
    }
    setSuccess(successParam === 'true');
  }, []);

  return (
    <div className="root">
      <div className="main">
        <header className="header">
          <div
            className="headerLogo"
            style={group.logo ? { backgroundImage: `url(${group.logo})` } : {}}
          />
        </header>
        {successfulPurchase ? (
          <CheckoutCard flexDirection="column" className="successCard">
            <div className="success-header">
              <i className="fa fa-check-circle" />
              <Heading as="h1">Payment Successful</Heading>
            </div>

            <Flex>
              <div className="success-info">
                <Paragraph>
                  Thank you for your purchase. Your payment was successful.
                </Paragraph>
                {!isAuthenticated ? (
                  <>
                    <Paragraph style={{ fontWeight: 700 }}>
                      {`Now you've completed your purchase, make sure you follow the instructions in your confirmation email.`}
                    </Paragraph>
                    <Paragraph style={{ fontWeight: 700 }}>
                      {'Get started by '}
                      <a href="/signup/">creating an account.</a>
                      {
                        ' Please sign up with the same email address used when making your purchase.'
                      }
                    </Paragraph>
                  </>
                ) : (
                  <Paragraph style={{ fontWeight: 700 }}>
                    {`Now you've completed your purchase, make sure you follow the instructions in your confirmation email.`}
                  </Paragraph>
                )}
                <Paragraph>
                  {isAuthenticated
                    ? `Your payment receipt has been sent to ${user.email}. `
                    : ''}
                  {
                    'If you do not receive an email within 2 hours, please email '
                  }
                  <a
                    href={`mailto:support${PLATFORM_EMAIL}`}
                  >{`support${PLATFORM_EMAIL}`}</a>
                </Paragraph>
              </div>
            </Flex>
          </CheckoutCard>
        ) : (
          <>
            <Paragraph>{group.description}</Paragraph>
            <div className="paymentSummary">
              <h1>{`£${selectedPlan.price.toFixed(2)}`}</h1>
              <h4>{`per ${selectedPlan.interval} subscription`}</h4>
            </div>
            <form className="paymentForm" onSubmit={handleCheckoutOnSubmit}>
              {membershipPlans.map((plan, i) => (
                <label
                  key={plan.slug}
                  htmlFor={plan.slug}
                  className="checkboxLabel"
                >
                  <input
                    id={plan.slug}
                    type="radio"
                    name="plan"
                    value={plan.slug}
                    defaultChecked={i === 0}
                    onChange={handleCheckoutOptionChange}
                    disabled={loading}
                  />
                  <chakra.span className="checkboxCheck" />
                  <chakra.span className="optionHeading">
                    {`${plan.pricingPlan.nickname} Membership`}
                  </chakra.span>
                </label>
              ))}
              {!isAuthenticated ? (
                <label htmlFor="email" style={{ fontSize: 13 }}>
                  <chakra.span>Email Address</chakra.span>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    placeholder="mail@example.com"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </label>
              ) : null}
              {error.hasError && (
                <Alert
                  appearance="card"
                  intent="danger"
                  title={error.message}
                />
              )}
              <Paragraph style={{ marginTop: '0.5rem', marginBottom: 0 }}>
                <input
                  name="termsAccepted"
                  type="checkbox"
                  checked={termsAccepted}
                  required
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                />
                {`  I agree to the `}
                <a
                  href="https://www.workshop.ws/terms/indaba-yoga-online-terms/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`${group.name} Terms`}
                </a>
              </Paragraph>
              {!isAuthenticated ? (
                <Paragraph style={{ marginTop: '1rem', marginBottom: 0 }}>
                  {'Have an account? '}
                  <a
                    href={`/login?next=/checkout/membership/${membership.slug}`}
                  >
                    Log in here
                  </a>
                </Paragraph>
              ) : null}
              <ButtonReset
                type="submit"
                id="submit"
                disabled={loading}
                isLoading={loading}
              >
                <chakra.span className="buttonText">Checkout</chakra.span>
              </ButtonReset>
              <div className="legalText">
                {`Your card will be immediately charged £${selectedPlan.price.toFixed(
                  2
                )} and then billed £${selectedPlan.price.toFixed(2)} each ${
                  selectedPlan.interval
                }.`}
              </div>
            </form>
          </>
        )}
        <Paragraph style={{ marginTop: '2rem', marginBottom: '0.5rem' }}>
          Powered by
        </Paragraph>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="261 261 424 184"
            width="33"
            height="33"
          >
            <path
              style={{ stroke: '#000', fillOpacity: 0, strokeWidth: 20 }}
              d="M272.5 152.5h400v400h-400V152.5z"
            />
            <path
              style={{ stroke: '#000', fillOpacity: 0, strokeWidth: 20 }}
              d="M362.5 266.5h22l22 150 66-139.7 66 139.7 22-150h22"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 311 72"
            height="20"
            style={{ marginLeft: 10 }}
          >
            <path d="M.37,5.24H10L20,41,31.26,5.24h6.41L49.33,40.09,59,5.24h9.58L53.8,55.64H45.66L34.43,22,23.27,55.64H14.56Z" />
            <path d="M74,53.84a17,17,0,0,1-6.41-6.77,19.78,19.78,0,0,1-2.23-9.36,20.2,20.2,0,0,1,2.23-9.43A16.89,16.89,0,0,1,74,21.44a18.71,18.71,0,0,1,9.86-2.52,18.71,18.71,0,0,1,9.86,2.52,16.89,16.89,0,0,1,6.41,6.84,20.2,20.2,0,0,1,2.23,9.43,19.78,19.78,0,0,1-2.23,9.36,17,17,0,0,1-6.41,6.77,18.71,18.71,0,0,1-9.86,2.52A18.71,18.71,0,0,1,74,53.84ZM90.7,45.92a12.76,12.76,0,0,0,2.48-8.21,13,13,0,0,0-2.48-8.32,9.13,9.13,0,0,0-13.76,0,13,13,0,0,0-2.48,8.32,12.76,12.76,0,0,0,2.48,8.21,9.2,9.2,0,0,0,13.76,0Z" />
            <path d="M105.64,19.64H114l.21,3.81a15.26,15.26,0,0,1,4.07-3.16,10.71,10.71,0,0,1,5.22-1.37,8.63,8.63,0,0,1,1.59.14l-.65,8.79a12.22,12.22,0,0,0-3.1-.36,8.33,8.33,0,0,0-4.1,1,6.43,6.43,0,0,0-2.67,2.56V55.64h-8.92Z" />
            <path d="M126.44,1.64h8.93V34.4l11.16-14.76h10.08l-13.1,17.28L156.9,55.64H146.82l-11.45-16v16h-8.93Z" />
            <path d="M162.48,55.71a30.32,30.32,0,0,1-5.87-2l.79-7.63a30.73,30.73,0,0,0,5.65,2.7,16.73,16.73,0,0,0,5.44.9q5.47,0,5.47-3.6a3.48,3.48,0,0,0-1.44-3,25.43,25.43,0,0,0-5.18-2.48,21.89,21.89,0,0,1-7.85-4.43A9,9,0,0,1,157,29.43a9.33,9.33,0,0,1,3.6-7.6q3.6-2.91,9.72-2.91a22.46,22.46,0,0,1,11.16,2.81l-.79,7.63a29.07,29.07,0,0,0-5.15-2.88,13.08,13.08,0,0,0-5.08-1,5.84,5.84,0,0,0-3.56.94,3.11,3.11,0,0,0-1.26,2.66,3.23,3.23,0,0,0,.76,2.23,6.66,6.66,0,0,0,2.12,1.55c.91.46,2.42,1.09,4.54,1.91a20.78,20.78,0,0,1,7.56,4.43,9,9,0,0,1,2.3,6.44,9.38,9.38,0,0,1-3.67,7.92q-3.67,2.81-10.44,2.81A28.43,28.43,0,0,1,162.48,55.71Z" />
            <path d="M214.46,23.49q3.34,4.43,3.35,12.13v20h-8.93V36.27c0-3.41-.55-5.9-1.65-7.49A6,6,0,0,0,202,26.41a8.9,8.9,0,0,0-7,3.09V55.64h-8.93v-54H195v20.3a20.33,20.33,0,0,1,4.39-2.12,15,15,0,0,1,4.89-.76Q211.11,19.06,214.46,23.49Z" />
            <path d="M229.4,53.84A17.1,17.1,0,0,1,223,47.07a19.78,19.78,0,0,1-2.23-9.36A20.2,20.2,0,0,1,223,28.28a17,17,0,0,1,6.41-6.84,20.57,20.57,0,0,1,19.73,0,17,17,0,0,1,6.41,6.84,20.32,20.32,0,0,1,2.23,9.43,19.89,19.89,0,0,1-2.23,9.36,17.1,17.1,0,0,1-6.41,6.77,20.57,20.57,0,0,1-19.73,0Zm16.74-7.92a12.7,12.7,0,0,0,2.48-8.21,13,13,0,0,0-2.48-8.32,9.12,9.12,0,0,0-13.75,0,13,13,0,0,0-2.48,8.32,12.7,12.7,0,0,0,2.48,8.21,9.19,9.19,0,0,0,13.75,0Z" />
            <path d="M288.66,21.58a15.77,15.77,0,0,1,5.54,6.77,22.91,22.91,0,0,1,1.87,9.36,22.91,22.91,0,0,1-1.87,9.36,15.77,15.77,0,0,1-5.54,6.77,15.16,15.16,0,0,1-8.79,2.52,19.16,19.16,0,0,1-5.51-.69A14.36,14.36,0,0,1,270,53.48V73.06h-8.93V19.64h7.2l.94,3.31A16.81,16.81,0,0,1,274,20a16.3,16.3,0,0,1,5.83-1A15.16,15.16,0,0,1,288.66,21.58Zm-4,24.34a13.79,13.79,0,0,0,2.23-8.21,13.81,13.81,0,0,0-2.23-8.21,7.23,7.23,0,0,0-6.2-3.09,7.77,7.77,0,0,0-6.69,3q-2.24,3-2.23,8.28T271.81,46a7.77,7.77,0,0,0,6.69,3A7.23,7.23,0,0,0,284.7,45.92Z" />
            <path d="M298.63,25.46a7.14,7.14,0,0,1-2.78-2.88,9.09,9.09,0,0,1-1-4.32,9,9,0,0,1,1-4.31,7.05,7.05,0,0,1,2.78-2.87,8.41,8.41,0,0,1,8,0A7.09,7.09,0,0,1,309.43,14a9,9,0,0,1,1,4.31,9.09,9.09,0,0,1-1,4.32,7.18,7.18,0,0,1-2.77,2.88,8.34,8.34,0,0,1-8,0Zm7.58-1a5.62,5.62,0,0,0,2.19-2.53,8.78,8.78,0,0,0,.73-3.67,8.68,8.68,0,0,0-.73-3.65,5.7,5.7,0,0,0-2.19-2.52,7.28,7.28,0,0,0-7.12,0,5.79,5.79,0,0,0-2.2,2.52,8.68,8.68,0,0,0-.74,3.65,8.78,8.78,0,0,0,.74,3.67,5.7,5.7,0,0,0,2.2,2.53,7.28,7.28,0,0,0,7.12,0Zm-6.46-10.35h2.95a3.1,3.1,0,0,1,2,.6,2.27,2.27,0,0,1,.71,1.83,2.42,2.42,0,0,1-1.35,2.25l1.78,3.35h-1.65l-1.48-2.93H301.3v2.93h-1.55Zm2.8,3.7a1.15,1.15,0,0,0,1.28-1.25,1.22,1.22,0,0,0-.33-.9,1.3,1.3,0,0,0-.95-.32H301.3v2.47Z" />
          </svg>
        </div>
      </div>
      <div className="content">
        <div className="imageStack">
          <img
            src="https://wpimg.workshop.ws/wp-content/uploads/2020/03/31124106/indaba_covermainpagejpg-scaled.jpg"
            width="200"
            height="250"
            alt=""
          />
          <img
            src="https://wpimg.workshop.ws/wp-content/uploads/2020/03/31124140/indaba_photo0012-scaled.jpg"
            width="200"
            height="250"
            alt=""
          />
          <img
            src="https://wpimg.workshop.ws/wp-content/uploads/2020/03/31124307/indaba_photo0572-scaled.jpg"
            width="200"
            height="250"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const CheckoutSteps: React.FC<Props> = (props) => {
  const renderCheckout = props.productType === 'course';
  const dispatch = useDispatch();
  const theme = useTheme();
  return renderCheckout ? (
    <Checkout dispatch={dispatch} theme={theme} {...props} />
  ) : (
    <LandingPage dispatch={dispatch} theme={theme} {...props} />
  );
};

export default CheckoutSteps;
