import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useSpring } from 'react-spring';
import equal from 'fast-deep-equal/react';

import { discourseUrl } from 'constants/env';
import { PLATFORM_DISPLAY_NAME } from 'constants/common';

import {
  Box,
  Card,
  Divider,
  Flex,
  Heading,
  MdIcon,
  Text,
  Button,
} from '@workshop/ui';

import { organisationActions } from 'redux/actions/common';

import { UserAvatar } from 'components/UserAvatar';

import {
  usePermissions,
  useUser,
  useDiscourseUser,
  useTeams,
  useRoles,
  useTeamProfiles,
  useCurrentTeam,
} from 'redux/selectors';

import {
  HOME_SCREEN_ROUTES,
  HomeScreenRoute,
  HomeScreenRoutes,
} from 'navigation/Routes';
import { AnimatedFlex } from 'components/Common';
import UnderConstruction from 'components/UnderConstruction';

import homeCardItemVariants from './variants';

interface HomeCardProps {}

const getWelcomeMessage = (hours: number, name: string) => {
  if (hours >= 6 && hours < 9) return `Morning, ${name}! ⏰`;
  if (hours >= 9 && hours < 12) return `Good morning, ${name} ☀️`;
  if (hours >= 12 && hours < 15) return `Hey ${name} 👋`;
  if (hours >= 15 && hours < 18) return `Afternoon, ${name} ☕`;
  if (hours >= 18 && hours < 21) return `Evening, ${name} 🌙`;
  if (hours >= 21 && hours < 24) return `Good evening, ${name} 🦉`;

  return `Hi ${name} 😊`;
};

const HomeCard: React.FC<HomeCardProps> = () => {
  const hour = moment().hours();

  const dispatch = useDispatch();
  const userPermissions = usePermissions();
  const user = useUser();
  const discourseUser = useDiscourseUser();
  const userTeams = useTeams();
  const roles = useRoles();
  const teamProfiles = useTeamProfiles();
  const currentTeam = useCurrentTeam();

  /**
   * Only keep routes from the HOME_SCREEN_ROUTES
   * for which the user has permission to view/edit
   * */
  const userRoutes = Object.keys(HOME_SCREEN_ROUTES)
    .map((key) => key as HomeScreenRoute)
    .reduce<HomeScreenRoutes>(
      (acc, key) => {
        const routes = HOME_SCREEN_ROUTES[key];

        const filteredRoutes = routes.filter(({ permissions }) => {
          if (!permissions) {
            // No permissions specificed on this route - everyone can view
            return true;
          }

          if (permissions.canView?.find((p) => userPermissions.includes(p))) {
            // User has viewing permissions
            return true;
          }

          if (permissions.canEdit?.find((p) => userPermissions.includes(p))) {
            // User has edit permissions
            return true;
          }

          return false;
        });

        return { ...acc, [key]: filteredRoutes };
      },
      { learner: [], teacher: [] }
    );

  const hasCurrentTeam = typeof currentTeam === 'number' && currentTeam > 0;
  const routes = hasCurrentTeam ? userRoutes.teacher : userRoutes.learner;

  routes?.sort((a, b) =>
    a.sidebarLink?.index && b.sidebarLink?.index
      ? a.sidebarLink?.index - b.sidebarLink?.index
      : 0
  );

  // Animation for the Content of the Dropdown
  const animatedContentStyle = useSpring({
    // delay: 150,
    config: { duration: 200 },
    opacity: Boolean(routes) ? 1 : 0,
  });

  const routeOptions = (
    // @ts-ignore
    <AnimatedFlex style={animatedContentStyle} w="100%">
      {routes && (
        <Flex flexDir="column" w="100%">
          {routes.map((r, idx) => (
            <Link
              key={`route-${r.name}`}
              to={equal(r.component, UnderConstruction) ? '#' : r.path()}
            >
              {idx !== 0 && <Divider m={0} />}
              <Flex
                flexDir="column"
                px={4}
                py={4}
                opacity={r.component === UnderConstruction ? 0.5 : 1}
                _hover={{ backgroundColor: 'background.tint2' }}
              >
                <Flex alignItems="center">
                  {r.icon && (
                    <MdIcon name={r.icon} color="common.primary" mr={2} />
                  )}
                  <Text color="common.primary" fontWeight="semibold" flex={1}>
                    {r.name}
                  </Text>
                  {r.component === UnderConstruction ? (
                    <Text fontSize="sm" color="text.muted">
                      Coming Soon
                    </Text>
                  ) : null}
                </Flex>

                <Text mt={1}>{r.description}</Text>
              </Flex>
            </Link>
          ))}
        </Flex>
      )}
    </AnimatedFlex>
  );

  return (
    <>
      <Card
        alignItems="center"
        flexDir="column"
        mx="auto"
        my={6}
        width="100%"
        maxWidth="500px"
        minHeight="230px"
      >
        <Flex
          width="100%"
          flexDir="column"
          alignItems="center"
          textAlign="center"
          backgroundColor="background.default"
          borderRadius="md"
          py={10}
          position="relative"
        >
          <Heading as="h1" fontSize="2xl" fontWeight="bold" mb={1}>
            {user ? getWelcomeMessage(hour, user.name.split(' ')[0]) : ''}
          </Heading>
          <Text color="text.muted">What are we doing today?</Text>
        </Flex>
        <Flex flexDir="column" w="100%">
          <Flex
            flex={1}
            flexDir="column"
            backgroundColor="background.tint3"
            borderRadius="md"
            // overflow="hidden"
            {...(hasCurrentTeam
              ? {
                  _hover: { backgroundColor: 'background.tint2' },
                  cursor: 'pointer',
                  onClick: () =>
                    dispatch(organisationActions.setCurrentTeam(null)),
                }
              : {})}
          >
            <Flex p={4} alignItems="center">
              <UserAvatar
                name={user?.name}
                userId={user?.id || 1}
                avatarPicture={
                  discourseUser
                    ? `${discourseUrl}${discourseUser?.avatarTemplate.replace(
                        '{size}',
                        '240'
                      )}`
                    : ''
                }
                size="sm"
              />
              <Box pl={4} flex={1}>
                <Text fontWeight="semibold">{user?.name}</Text>
              </Box>
              {hasCurrentTeam && (
                <MdIcon
                  name="KeyboardArrowRight"
                  ml="defaultMargin"
                  color="common.muted"
                  width={6}
                  height={6}
                />
              )}
            </Flex>
            {!hasCurrentTeam && routeOptions}
          </Flex>

          {userTeams.length > 0 && (
            <>
              <Box
                mt={2}
                pt={4}
                mb={2}
                borderTopWidth={1}
                borderTopColor="border.muted"
              >
                <Text fontWeight="semibold" textAlign="center">
                  {`My Channel${userTeams.length === 1 ? '' : 's'}`}
                </Text>
              </Box>

              {userTeams.map((userTeam) => {
                const teamProfile = teamProfiles[userTeam.team];
                // Extract the user's roles for the current team we're iterating over
                const roleIds =
                  userTeams.find(({ team }) => team === userTeam.team)?.roles ||
                  [];
                const userTeamRoles = roleIds.map(
                  (roleId) => roles[roleId]?.name
                );
                // Determine whether the team we're iterating over is the user's currently
                // active team
                const isCurrentTeam = userTeam.team === currentTeam;

                const HomescreenVariant =
                  teamProfile && homeCardItemVariants[teamProfile.id];

                const variantAvailable = Boolean(HomescreenVariant);

                return (
                  <Flex
                    key={`team-${userTeam.id}`}
                    flex={1}
                    flexDir="column"
                    backgroundColor="background.tint3"
                    borderRadius="md"
                    mt={2}
                    // overflow="hidden"
                    position="relative"
                    {...(!isCurrentTeam
                      ? {
                          _hover: { backgroundColor: 'background.tint2' },
                          cursor: 'pointer',
                          onClick: () => {
                            dispatch(
                              organisationActions.setCurrentTeam(userTeam.team)
                            );
                            localStorage.setItem(
                              'defaultTeam',
                              userTeam.team.toString()
                            );
                          },
                        }
                      : {})}
                  >
                    <Flex p={4} alignItems="center">
                      <UserAvatar
                        name={teamProfile?.name}
                        userId={teamProfile?.id || 1}
                        avatarPicture={teamProfile?.logoDark || ''}
                        size="sm"
                      />
                      <Box pl={4} flex={1}>
                        <Text fontWeight="semibold">{teamProfile?.name}</Text>
                        <Flex alignItems="center">
                          <Text color="text.muted" fontSize="sm">
                            {userTeamRoles.map((r, idx) =>
                              idx + 1 < userTeamRoles.length ? `${r}, ` : r
                            )}
                          </Text>
                        </Flex>
                      </Box>
                      {!isCurrentTeam && (
                        <MdIcon
                          name="KeyboardArrowRight"
                          ml="defaultMargin"
                          color="common.muted"
                          width={6}
                          height={6}
                        />
                      )}
                    </Flex>
                    {variantAvailable && (
                      <HomescreenVariant
                        variantId={teamProfile.id}
                        isCurrentTeam={isCurrentTeam}
                      />
                    )}
                    {isCurrentTeam && routeOptions}
                  </Flex>
                );
              })}
            </>
          )}
        </Flex>
      </Card>
    </>
  );
};

export default HomeCard;
