import React, { ReactElement } from 'react';
import {
  useSlate,
  ReactEditor,
  RenderLeafProps,
  RenderElementProps,
} from 'slate-react';
import { Editor, Transforms, Element as SlateElement } from 'slate';
import { HistoryEditor } from 'slate-history';

import {
  IconButton,
  HStack,
  chakra,
  ListItem,
  OrderedList,
  UnorderedList,
  Heading,
  MdIcon,
} from '@workshop/ui';

type EditorProps = Editor | ReactEditor | HistoryEditor;
const LIST_TYPES = ['ol', 'ul'];

const isBlockActive = (editor: EditorProps, format: string) => {
  const nodeGen = Editor.nodes(editor, {
    match: (n) =>
      // @ts-ignore
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  let node = nodeGen.next();
  while (!node.done) {
    return true;
  }
  return false;
};

const isMarkActive = (editor: EditorProps, format: string) => {
  const marks = Editor.marks(editor);
  // @ts-ignore
  return marks ? marks[format] === true : false;
};

export const toggleBlock = (editor: EditorProps, format: string) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      LIST_TYPES.includes(
        // @ts-ignore
        (!Editor.isEditor(n) && SlateElement.isElement(n) && n.type) as string
      ),
    split: true,
  });
  const newProperties: Partial<SlateElement> = {
    // @ts-ignore
    type: isActive ? 'p' : isList ? 'li' : format,
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const toggleMark = (editor: EditorProps, format: string) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const MarkButton = ({
  format,
  icon,
}: {
  format: string;
  icon: ReactElement;
}) => {
  const editor = useSlate();
  return (
    <IconButton
      variant="outline"
      colorScheme="blue"
      isActive={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      aria-label={format}
      icon={icon}
      borderWidth={0}
      fontSize="20px"
    />
  );
};

export const BlockButton = ({
  format,
  icon,
}: {
  format: string;
  icon: ReactElement;
}) => {
  const editor = useSlate();
  return (
    <IconButton
      variant="outline"
      colorScheme="blue"
      isActive={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
      aria-label={format}
      icon={icon}
      borderWidth={0}
      fontSize="20px"
    />
  );
};

export const Toolbar = () => {
  return (
    <HStack
      borderWidth="0 0 1px 0"
      padding="10px 5px"
      spacing="5px"
      wrap="wrap"
    >
      <MarkButton format="bold" icon={<MdIcon name="FormatBold" />} />
      <MarkButton format="italic" icon={<MdIcon name="FormatItalic" />} />
      <MarkButton
        format="underline"
        icon={<MdIcon name="FormatUnderlined" />}
      />
      {/* <MarkButton format="code" icon={<MdIcon name="Code" />} /> */}
      <BlockButton format="h2" icon={<MdIcon name="LooksOne" />} />
      <BlockButton format="h3" icon={<MdIcon name="LooksTwo" />} />
      {/* <BlockButton format="blockquote" icon={<MdIcon name="FormatQuote" />} /> */}
      <BlockButton format="ol" icon={<MdIcon name="FormatListNumbered" />} />
      <BlockButton format="ul" icon={<MdIcon name="FormatListBulleted" />} />
    </HStack>
  );
};

const BlockquoteStyle: React.CSSProperties | undefined = {
  margin: '1.5em 10px',
  padding: '0.5em 10px',
};

export const Element = ({
  attributes,
  children,
  element,
}: RenderElementProps) => {
  // @ts-ignore
  switch (element.type) {
    case 'blockquote':
      return (
        <chakra.blockquote
          style={BlockquoteStyle}
          borderLeftWidth="10px"
          borderLeftColor="border.default"
          {...attributes}
        >
          {children}
        </chakra.blockquote>
      );
    case 'li':
      return <ListItem {...attributes}>{children}</ListItem>;
    case 'ol':
      return <OrderedList {...attributes}>{children}</OrderedList>;
    case 'ul':
      return <UnorderedList {...attributes}>{children}</UnorderedList>;
    case 'h1':
      return (
        <Heading as="h1" fontSize="2xl" mb={4} {...attributes}>
          {children}
        </Heading>
      );
    case 'h2':
      return (
        <Heading as="h2" fontSize="xl" mb={4} {...attributes}>
          {children}
        </Heading>
      );
    case 'h3':
      return (
        <Heading as="h3" fontSize="lg" mb={4} {...attributes}>
          {children}
        </Heading>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  // @ts-ignore
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  // @ts-ignore
  if (leaf.code) {
    children = (
      <chakra.code
        padding="3px"
        backgroundColor="background.tint2"
        fontSize="90%"
      >
        {children}
      </chakra.code>
    );
  }

  // @ts-ignore
  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  // @ts-ignore
  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};
