import { decamelizeKeys } from 'humps';
import {
  CMSCourseListSchema,
  CMSCourseSchema,
  CMSCohortSchema,
} from 'redux/schemas/cms';

import { callAPI, callAPIWithTeam } from 'utils';
import API from 'constants/api';

import { CourseAT } from 'redux/actionTypes/cms';

import { Dispatch } from 'types';
import {
  FetchCohortListAction,
  FetchCourseAction,
  PatchCourseAction,
  DeleteCourseAction,
  CourseListAction,
  CreateCourseAction,
  CourseEmailPreviewAction,
  ICourseListItem,
  ICourse,
} from 'types/cms';

export const list =
  ({
    fetchNextPage,
    url,
  }: {
    fetchNextPage?: boolean;
    url?: string | undefined;
  }) =>
  // @ts-ignore
  async (dispatch: Dispatch) =>
    dispatch(
      callAPIWithTeam<CourseListAction>({
        types: [
          CourseAT.CMS_FETCH_COURSE_LIST_REQUEST,
          CourseAT.CMS_FETCH_COURSE_LIST_SUCCESS,
          CourseAT.CMS_FETCH_COURSE_LIST_FAILURE,
        ],
        endpoint: url || API.cms.mentorCourses,
        method: 'GET',
        schema: [CMSCourseListSchema],
        path: 'results',
        meta: {
          toast: {
            error: { message: 'Could not retrieve courses', source: 'api' },
          },
        },
        fetchNextPage: fetchNextPage
          ? // @ts-ignore
            async (url) => {
              return await dispatch(list({ fetchNextPage: true, url }));
            }
          : null,
      })
    );

export const create = (data: Partial<ICourseListItem>) => {
  return callAPIWithTeam<CreateCourseAction>({
    types: [
      CourseAT.CMS_CREATE_COURSE_REQUEST,
      CourseAT.CMS_CREATE_COURSE_SUCCESS,
      CourseAT.CMS_CREATE_COURSE_FAILURE,
    ],
    endpoint: API.cms.mentorCourses,
    method: 'POST',
    body: decamelizeKeys(data),
    schema: CMSCourseListSchema,
  });
};

export const retrieve = (id: number) => {
  return callAPIWithTeam<FetchCourseAction>({
    types: [
      CourseAT.CMS_FETCH_COURSE_REQUEST,
      CourseAT.CMS_FETCH_COURSE_SUCCESS,
      CourseAT.CMS_FETCH_COURSE_FAILURE,
    ],
    endpoint: API.cms.course(id),
    method: 'GET',
    schema: CMSCourseSchema,
  });
};

export const update = (id: number, data: Partial<ICourse> | FormData) => {
  return callAPIWithTeam<PatchCourseAction>({
    types: [
      CourseAT.CMS_PATCH_COURSE_REQUEST,
      CourseAT.CMS_PATCH_COURSE_SUCCESS,
      CourseAT.CMS_PATCH_COURSE_FAILURE,
    ],
    endpoint: API.cms.course(id),
    method: 'PATCH',
    body: data instanceof FormData ? data : decamelizeKeys(data),
    schema: CMSCourseSchema,
    meta: {
      toast: {
        error: 'Could not retrieve this course',
      },
    },
  });
};

export const remove = (id: number) => {
  return callAPIWithTeam<DeleteCourseAction>({
    types: [
      CourseAT.CMS_DELETE_COURSE_REQUEST,
      CourseAT.CMS_DELETE_COURSE_SUCCESS,
      CourseAT.CMS_DELETE_COURSE_FAILURE,
    ],
    endpoint: API.cms.course(id),
    method: 'DELETE',
    schema: CMSCourseSchema,
    meta: {
      courseId: id.toString(),
      toast: { success: 'Successfully deleted course' },
    },
  });
};

export const sendPreviewEmail = (data: {
  [key: string]: string | number | object | undefined;
  dynamicData: object | undefined;
}) =>
  callAPIWithTeam<CourseEmailPreviewAction>({
    types: [
      CourseAT.CMS_SEND_PREVIEW_EMAIL_REQUEST,
      CourseAT.CMS_SEND_PREVIEW_EMAIL_SUCCESS,
      CourseAT.CMS_SEND_PREVIEW_EMAIL_FAILURE,
    ],
    endpoint: API.cms.previewEmail(),
    method: 'POST',
    body: decamelizeKeys(data),
    meta: {
      // Toast display is controlled from within EmailConfigModal
      toast: {
        success: false,
        error: false,
      },
    },
  });

export const fetchMyCohorts =
  ({
    fetchNextPage,
    url,
  }: {
    fetchNextPage?: boolean;
    url?: string | undefined;
    // @ts-ignore: TODO Fix this - caused by making the `fetchNextPage` async, which
    // is required so that calling code waits the appropriate amount of time!
  }) =>
  // @ts-ignore
  async (dispatch: Dispatch) =>
    dispatch(
      callAPI<FetchCohortListAction>({
        types: [
          CourseAT.CMS_COHORT_LIST_REQUEST,
          CourseAT.CMS_COHORT_LIST_SUCCESS,
          CourseAT.CMS_COHORT_LIST_FAILURE,
        ],
        endpoint: url || API.cms.myCohorts,
        method: 'GET',
        schema: [CMSCohortSchema],
        path: 'results',
        fetchNextPage: fetchNextPage
          ? // @ts-ignore
            async (url) => {
              return await dispatch(
                fetchMyCohorts({ fetchNextPage: true, url })
              );
            }
          : null,
      })
    );
