import React from 'react';
import {
  Box,
  Heading,
  Paragraph,
  chakra,
  TextProps,
  Divider,
  PropsOf,
  Stack,
  LinkButton,
} from '@workshop/ui';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import navRoutes from 'navigation/Routes';

import { PLATFORM } from 'constants/env';
import { PLATFORM_DISPLAY_NAME, PLATFORM_EMAIL } from 'constants/common';

const H = (props: TextProps) => (
  <Heading fontSize="xl" pb={3} pt={6} scrollMarginTop={20} {...props} />
);
const P = (props: TextProps) => <Paragraph pb={3} {...props} />;
const A = (props: PropsOf<typeof chakra.a>) => (
  <chakra.a color="text.primary" fontWeight="semibold" {...props} />
);

const TermsScreen: React.FC = () => {
  if (PLATFORM === 'steppit') {
    return (
      <ScreenWrapper>
        <Box listStylePosition="inside" mx={{ base: 'defaultMargin', md: 0 }}>
          <P>
            <chakra.em>Last Updated 29 March, 2023</chakra.em>
          </P>

          <P>
            Hello, and welcome to our Terms of Service. This is important and
            affects your legal rights, so please read them, along with our{' '}
            <A href="/privacy">Privacy Policy</A>, and any other terms
            referenced in this document carefully.
          </P>

          <H as="h3">Changes to the Agreements</H>

          <P>
            Occasionally we may, at our own discretion, make changes to the
            Agreements. When we make material changes to the Agreements,
            we&rsquo;ll provide you with prominent notice as appropriate under
            the circumstances, e.g., by displaying a prominent notice within the
            Service or by sending you an email. In some cases, we will notify
            you in advance, and your continued use of the Service after the
            changes have been made will constitute your acceptance of the
            changes. Please therefore make sure you read any such notice
            carefully. If you do not wish to continue using the Service under
            the new version of the Agreements, you may terminate the Agreements
            by contacting us by email.
          </P>
          <Divider mt={6} mb={6} />
          <Stack
            direction={{ base: 'column', md: 'row' }}
            spacing={4}
            justifyContent="center"
            alignItems="flex-start"
          >
            <LinkButton href="#general-terms" variant="ghost">
              General Terms
            </LinkButton>
            <LinkButton href="#acceptable-use-policy" variant="ghost">
              Acceptable Use Policy
            </LinkButton>
            <LinkButton href="#refund-policy" variant="ghost">
              Refund Policy
            </LinkButton>
            <LinkButton href="#channel-terms" variant="ghost">
              Channel Terms
            </LinkButton>
            <LinkButton href="#ai-terms" variant="ghost">
              AI Terms
            </LinkButton>
          </Stack>
          <Divider mt={6} mb={6} />
          <H as="h2" fontSize="3xl" id="general-terms">
            General Terms
          </H>

          <H as="h3">Introduction</H>

          <P>
            Thanks for choosing Steppit, a product and trading name of Workshop
            Tech Solutions Ltd (&ldquo;Steppit&rdquo;, &ldquo;Workshop&rdquo;,
            &ldquo;we&rdquo;, &ldquo;us&rdquo;, &ldquo;our&rdquo;). By signing
            up or otherwise using the Steppit services, websites, and software
            applications (collectively, &ldquo;Steppit Service&rdquo; or
            &ldquo;Service&rdquo;), or accessing any content or material that is
            made available by Steppit through the Service (the
            &ldquo;Content&rdquo;) you are entering into a binding contract with
            the Steppit entity indicated at the start of this section. The
            Service also includes the Steppit Support Community as further
            described in the <A href="#community">Steppit Support Community</A>{' '}
            section.
          </P>

          <P>
            Use of the Steppit Service relies on several technical{' '}
            <A href="https://help.workshop.ws/hc/en-us/articles/115001463191-What-devices-does-Workshop-support-">
              requirements
            </A>
            .
          </P>

          <P>
            Your agreement with us includes these Terms of Service
            (&ldquo;Terms&rdquo;) and our <A href="/privacy">Privacy Policy</A>.
            (The Terms, Privacy Policy, and any additional terms that you agree
            to, as discussed in the{' '}
            <A href="#entire-agreement">Entire Agreement</A> section, are
            referred to together as the &ldquo;Agreements&rdquo;.) If you wish
            to review the terms of the Agreements, the effective version of the
            Agreements can be found on the Steppit website. You acknowledge that
            you have read and understood the Agreements, accept the Agreements,
            and agree to be bound by them. If you don&rsquo;t agree with (or
            cannot comply with) the Agreements, then you may not use the Steppit
            Service or consume any Content.
          </P>

          <P>
            In order to use the Steppit Service and access the Content, you need
            to (1) be 18 or older, or be 13 or older and have your parent or
            guardian&rsquo;s consent to the Agreements, (2) have the power to
            enter a binding contract with us and not be barred from doing so
            under any applicable laws, and (3) be resident in a country where
            the Service is available. You also promise that any registration
            information that you submit to Steppit is true, accurate, and
            complete, and you agree to keep it that way at all times.
          </P>

          <H as="h3">General</H>

          <P>
            Steppit enables independent content creators and training or
            educational institutions and their official representatives
            (collectively, &ldquo;Channel Owners&rdquo; or
            &ldquo;Creators&rdquo;) to provide recorded instruction, tutoring,
            and learning services (the &ldquo;Content&rdquo;) in connection with
            students (collectively, &ldquo;Students&rdquo;, the
            &ldquo;Users&rdquo;) via Steppit, a product of the Steppit Service.
            The Service includes, without limitation, facilitating and hosting
            Content and supporting materials, and taking feedback from Users.
          </P>

          <P>
            The Steppit Service is only a marketplace for Creators and Students.
            We do not hire or employ Creators nor are we responsible or liable
            for any interactions involved between the Creators and the Students
            who purchase a Creator&rsquo;s Content via the Service. We are not
            responsible for disputes, claims, losses, injuries, or damage of any
            kind that might arise out of or related to conduct of Creators or
            Students, including, but not limited to, any Student&rsquo;s
            reliance upon any information provided by a Creator.
          </P>

          <H as="h3">Rights We Grant You</H>

          <P>
            The Steppit Service and the Content are the property of Steppit or
            Steppit&rsquo;s licensors. Subject to these Terms and our policies
            (including the{' '}
            <A href="#acceptable-use-policy">Acceptable Use Policy</A>,{' '}
            <A href="#code-of-conduct">Code of Conduct</A>, and course-specific
            eligibility requirements and other terms), we grant you a limited,
            non-exclusive, revocable license to make use of the Steppit Service,
            and a limited, non-exclusive, non-transferable, revocable license to
            make personal, non-commercial use of the Content (the
            &ldquo;License&rdquo;) for which you have paid all required fees.
            This License shall remain in effect until and unless terminated by
            you or Steppit. You promise and agree that you are using the Content
            for your own personal, non-commercial use and that you will not
            redistribute or transfer the Steppit Service or the Content.
          </P>

          <P>
            The Steppit software applications and the Content are licensed, not
            sold, to you, and Steppit and its licensors retain ownership of all
            copies of the Steppit software applications and Content even after
            installation on your personal computers, mobile handsets, tablets,
            and/or other relevant devices (&ldquo;Devices&rdquo;).
          </P>

          <P>
            All Workshop and Steppit trademarks, service marks, trade names,
            logos, domain names, and any other feature of the Workshop and
            Steppit brands (&ldquo;Steppit Brand Features&rdquo;) are the sole
            property of Steppit or its licensors. The Agreements do not grant
            you any rights to use any Steppit Brand Features whether for
            commercial or non-commercial use.
          </P>

          <P>
            You agree and abide by our{' '}
            <A href="#acceptable-use-policy">Acceptable Use Policy</A> and not
            to use the Steppit Service, the Content, or any part thereof in any
            manner not expressly permitted by the Agreements. Except for the
            rights expressly granted to you in these Agreements, Steppit grants
            no right, title, or interest to you in the Steppit Service or
            Content.
          </P>

          <P>
            Third party software (for example, open source software libraries)
            included in the Steppit Service are licensed to you either under the
            Agreements or under the relevant third party software
            library&rsquo;s license terms.
          </P>

          <H as="h3">User-Generated Content</H>

          <P>
            Steppit Users may post, upload, and/or contribute
            (&ldquo;post&rdquo;) content to the Service (which may include, for
            example, pictures, text, messages, information, and/or other types
            of content) (&ldquo;User Content&rdquo;). For the avoidance of
            doubt, &ldquo;User Content&rdquo; includes any such content posted
            to the Steppit Support Community as well as any other part of the
            Steppit Service.
          </P>

          <P>
            You promise that, with respect to any User Content you post on the
            Steppit Service, (1) you have the right to post such User Content,
            and (2) such User Content, or its use by Steppit as contemplated by
            the Agreements, does not violate the Agreements, applicable law, or
            the intellectual property (including without limitation copyright),
            publicity, personality, or other rights of others or imply any
            affiliation with or endorsement of you or your User Content by
            Steppit or any Creator, entity or individual without express written
            consent from such individual or entity.
          </P>

          <P>
            Steppit may, but has no obligation to, monitor, review, or edit User
            Content. In all cases, Steppit reserves the right to remove or
            disable access to any User Content for any or no reason, including
            but not limited to, User Content that, in Steppit&rsquo;s sole
            discretion, violates the Agreements. Steppit may take these actions
            without prior notification to you or any third party. Removal or
            disabling of access to User Content shall be at our sole discretion,
            and we do not promise to remove or disable access to any specific
            User Content.
          </P>

          <P>
            You are solely responsible for all User Content that you post.
            Steppit is not responsible for User Content nor does it endorse any
            opinion contained in any User Content. YOU AGREE THAT IF ANYONE
            BRINGS A CLAIM AGAINST WORKSHOP RELATED TO USER CONTENT THAT YOU
            POST, THEN, TO THE EXTENT PERMISSIBLE UNDER LOCAL LAW, YOU WILL
            INDEMNIFY AND HOLD WORKSHOP HARMLESS FROM AND AGAINST ALL DAMAGES,
            LOSSES, AND EXPENSES OF ANY KIND (INCLUDING REASONABLE LAWYER FEES
            AND COSTS) ARISING OUT OF SUCH CLAIM.
          </P>

          <H as="h3">Rights You Grant Us</H>

          <P>
            In consideration for the rights granted to you under the Agreements,
            you grant us the right (1) to allow the Steppit Service to use the
            processor, bandwidth, and storage hardware on your Device in order
            to facilitate the operation of the Service, (2) to provide
            advertising and other information to you. In any part of the Steppit
            Service, the Content you view, including its selection and
            placement, may be influenced by commercial considerations, including
            agreements with third parties. Some Content licensed or provided to
            Steppit may contain advertising as part of the Content.
          </P>

          <P>
            If you provide feedback, ideas or suggestions to Steppit in
            connection with the Steppit Service of Content
            (&ldquo;Feedback&rdquo;), you acknowledge that the Feedback is not
            confidential and you authorize Steppit to use that Feedback without
            restriction and without payment to you. Feedback is considered a
            type of User Content.
          </P>

          <P>
            You grant Steppit a non-exclusive, transferable, sub-licensable,
            royalty-free, perpetual (or, in jurisdictions where this is not
            permitted, for a term equal to the duration of the Agreement plus
            twenty (20) years), irrevocable, fully paid, worldwide license to
            use, reproduce, make available to the public (e.g. perform or
            display), publish, translate, modify, create derivative works from,
            and distribute any of your User Content in connection with the
            Service through any medium, whether alone or in combination with
            other content or materials, in any manner and by any means, method
            or technology, whether now known or hereafter created. Aside from
            the rights specifically granted herein, you retain ownership of all
            rights, including intellectual property rights, in the User Content.
            Where applicable and permitted under applicable law, you also agree
            to waive any &ldquo;moral rights&rdquo; (or the equivalent under
            applicable law) such as your right to be identified as the author of
            any User Content, including Feedback and your right to object to
            derogatory treatment of such User Content.
          </P>

          <H as="h3">Infringement and Reporting User Content</H>

          <P>
            Steppit respects the rights of intellectual property owners. If you
            believe that any Content infringes your intellectual property rights
            or other rights. If Steppit is notified by a copyright holder that
            any Content infringes a copyright, Steppit may in its absolute
            discretion take actions without prior notification to the provider
            of that Content. If the provider believes that the content is not
            infringing, the provider may submit a counter-notification to
            Steppit with a request to restore the removed content.
          </P>

          <P>
            If you believe that any Content does not comply with the{' '}
            <A href="#acceptable-use-policy">Acceptable Use Policy</A>, please
            contact us.
          </P>

          <H as="h3">Service Limitations and Modifications</H>

          <P>
            Steppit will make reasonable efforts to keep the Steppit Service
            operational. However, certain technical difficulties or maintenance
            may, from time to time, result in temporary interruptions. To the
            extent permissible under applicable law, Steppit reserves the right,
            periodically and at any time, to modify or discontinue, temporarily
            or permanently, functions and features of the Steppit Service, with
            or without notice, all without liability to you, except where
            prohibited by law, for any interruption, modification, or
            discontinuation of the Steppit Service or any function or feature
            thereof. You understand, agree, and accept that Steppit has no
            obligation to maintain, support, upgrade, or update the Service, or
            to provide all or any specific content through the Service. This
            section will be enforced to the extent permissible by applicable
            law. Steppit and/or the owners of any Content may, from time to
            time, remove any such Content without notice to the extent permitted
            by applicable law.
          </P>

          <P>
            Steppit may modify the Service or discontinue their availability at
            any time.
          </P>

          <H as="h3" id="community">
            Steppit Community
          </H>

          <P>
            The Steppit Community is a place for discussions and exchange of
            information, tips, and other materials related to the Steppit
            Service. If you do not agree to the Agreements you may not use the
            Steppit Community.
          </P>

          <P>
            By using the Steppit Community, you confirm that any registration
            information that you submit to Steppit is true, accurate, and
            complete and that you will update such information in order to keep
            it current. It is strictly prohibited to include information in your
            profile that suggests that you are a Steppit employee or moderator
            or to otherwise pose as such an employee or moderator when using the
            Steppit Community. You also acknowledge and agree that Steppit may
            remove or reclaim your username at any time if Steppit, in its
            absolute discretion, considers such action appropriate.
          </P>

          <H as="h3">No Official Support</H>

          <P>
            No User Content or other content posted by Steppit employees,
            moderators and/or representatives on the Steppit Community should be
            construed as official support provided by Steppit. For details
            regarding official support, see the section{' '}
            <A href="#customer-support">Customer Support</A>. Any content
            provided or made available to you on the Steppit Support Community
            by Steppit employees, moderators, and/or representatives is provided
            on an &ldquo;as is&rdquo; basis without warranties of any kind.
          </P>

          <P>
            You acknowledge that opinions express in User Content on the Steppit
            Support Community are those of contributors of such User Content
            only and do not reflect the opinions or policies of Steppit or any
            of its officers, shareholders, employees, agents, directors,
            subsidiaries, affiliates, suppliers, or licensors.
          </P>

          <H as="h3" id="customer-support">
            Customer Support
          </H>

          <P>
            For customer support with account-related and payment-related
            questions (&ldquo;Customer Support Queries&rdquo;), please submit a
            support ticket to our customer service team using the{' '}
            <A href="https://help.workshop.ws/hc/en-us/requests/new">
              Customer Service contact form
            </A>
            . We will use reasonable endeavours to respond to all Customer
            Support Queries within a reasonable time frame and/or that we will
            be able to satisfactorily answer any such queries.
          </P>

          <H as="h3">Education Research</H>

          <P>
            Steppit is committed to advancing the science of learning and
            teaching, and records of your participation in courses may be used
            for education research. In the interest of this research, you may be
            exposed to variations in the course content. Research findings will
            typically be reported at the aggregate level. Your personal identity
            will not be publicly disclosed in any research findings without your
            express consent.
          </P>

          <H as="h3">Pricing</H>

          <P>
            Pricing of paid Services (e.g., courses) is determined by Steppit
            and/or the Creator of the Content in question (Steppit and Creators
            collectively known as &ldquo;Sellers&rdquo;). You agree to pay the
            fees for the Content that you purchase, and hereby authorize the
            Seller to charge your credit or debit card for such fees. If your
            credit or debit card is declined, you agree to pay the fees within
            thirty (30) days of notification, and pay (at the Seller&rsquo;s
            discretion) a late payment charge of 1.5% per month or the maximum
            permitted by law, whichever is greater. If your card is declined,
            Steppit or the Seller may choose to limit your access to the
            platform and/or the course being purchased.
          </P>

          <P>
            Fees may vary based on your location and other factors, and Steppit
            reserves the right to change any fees at any time at its sole
            discretion. Any change, update, or modification will be effective
            immediately upon posting through the relevant Services.
          </P>

          <H as="h3">Payment</H>

          <P>
            European Union Users: By purchasing Content, you are confirming that
            you want the Content immediately credited to your User Account and
            that by doing so, you are hereby waiving any and all cancellation
            rights under applicable laws, including but not limited to the EU
            Consumer Rights Directive (2011/83/EU) and/or any national laws
            implementing it. Notwithstanding the foregoing, you will still be
            eligible for the 14 day refund right as set out in the{' '}
            <A href="#refund-policy">Refund Policy</A>.
          </P>

          <P>
            All Other Users: Refunds may be available as described in our{' '}
            <A href="#refund-policy">Refund Policy</A>.
          </P>

          <H as="h3">Instalment Payments</H>

          <P>
            When purchasing certain course Content, you may have the choice to
            pay for the course using an instalment plan. When entering into an
            instalment plan, you are entering into a 0% APR instalment loan
            which is to be paid according to the payment plan terms specified at
            the time of purchasing.
          </P>

          <P>
            The first instalment will be charged to your credit or debit card
            immediately upon your election to purchase the Content. The second
            instalment will automatically be charged to your credit or debit
            card 1 month (30-days) after the initial payment. Any subsequent
            payments will be taken 1 month (30-days) after the preceeding
            payment.
          </P>

          <P>
            If you do not pay all of the instalments in full accordance with the
            above, you will lose access to the Content and only be entitled to a
            refund in accordance with our{' '}
            <A href="#refund-policy">Refund Policy</A>. The Seller has no
            obligation to offer refunds to students who fail to fully pay their
            instalment plan.
          </P>

          <H as="h3">Payment Processing</H>

          <P>
            Outside of the mobile applications, Sellers use the 3rd party
            payment platform, Stripe, the Stripe API, and in conjunction, your
            Stripe account to process credit and debit card transactions for
            your Steppit account. Steppit is a &ldquo;Partner Application&rdquo;
            as defined in the Stripe Terms of Service. By using Steppit and
            agreeing to the Steppit Terms or Service, you also agree to be bound
            by Stripe&rsquo;s Terms of Service.
          </P>

          <P>
            Purchases through the iOS application are processed by Apple and
            will show in your Apple account.
          </P>

          <H as="h3">Taxes</H>

          <P>
            European Union: In the event that the sale or delivery of Content to
            any Student in the European Union is subject to any value added tax
            (&ldquo;VAT&rdquo;), under applicable law, Sellers will collect and
            remit the VAT to the competent tax authorities for sales of such
            Content to Students in the European Union. Sellers may at their own
            discretion increase the Sale Price where the Seller is of the view
            that VAT may be due and Sellers will have a liability to account for
            such. You will indemnify and hold Sellers harmless against any and
            all claims by any tax authority for any underpayment of VAT, and any
            penalties and/or interest thereon.
          </P>

          <P>
            All Other Countries: For sales of any courses in countries other
            than the European Union, the Seller is responsible for remitting the
            taxes to the appropriate taxing authority (which may be different to
            the tax authority in your own location). Steppit is unable to
            provide you with tax advice and you should consult your own tax
            advisor.
          </P>

          <H as="h3">Term and Termination</H>

          <P>
            The Agreements will continue to apply to you until terminated by
            either you or Steppit. However, you acknowledge and agree that the
            perpetual license granted by you in relation to User Content,
            including Feedback, is irrevocable and will therefore continue after
            expiry or termination of any of the Agreements for any reason.
            Steppit may terminate the Agreements or suspend your access to the
            Steppit Service at any time, including in the event of actual or
            suspected unauthorised use of the Steppit Service and/or Content, or
            non-compliance with the Agreements. If you or Steppit terminate the
            Agreements, or if Steppit suspends your access to the Steppit
            Service, you agree that Steppit shall have no liability or
            responsibility to you and Steppit will not refund any amounts that
            you have already paid, to the fullest extent permitted under
            applicable law. To learn how to terminate your Steppit account,
            please contact us through the{' '}
            <A href="https://help.workshop.ws/hc/en-us/requests/new">
              Customer Service contact form
            </A>
            . This section will be enforced to the extent permissible by
            applicable law. You may terminate the Agreements at any time.
          </P>

          <H as="h3">Warranty &amp; Disclaimer</H>

          <P>
            WE ENDEAVOUR TO PROVIDE THE BEST SERVICE WE CAN, BUT YOU UNDERSTAND
            AND AGREE THAT THE WORKSHOP SERVICE IS PROVIDED &ldquo;AS IS&rdquo;
            AND &ldquo;AS AVAILABLE&rdquo;, WITHOUT EXPRESS OR IMPLIED WARRANTY
            OR CONDITION OF ANY KIND. YOU USE THE WORKSHOP SERVICE AT YOUR OWN
            RISK. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WORKSHOP
            AND ALL OWNERS OF THE CONTENT MAKE NO REPRESENTATIONS AND DISCLAIM
            ANY WARRANTIES OR CONDITIONS OF SATISFACTORY QUALITY,
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
            NON-INFRINGEMENT. NEITHER WORKSHOP NOR ANY OWNER OF CONTENT WARRANTS
            THAT THE WORKSHOP SERVICE IS FREE OF MALWARE OR OTHER HARMFUL
            COMPONENTS. IN ADDITION, WORKSHOP MAKES NO REPRESENTATION NOR DOES
            IT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
            THIRD PARTY APPLICATIONS (OR THE CONTENT THEREOF), USER CONTENT, OR
            ANY OTHER PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY
            ON OR THROUGH THE WORKSHOP SERVICE OR ANY HYPERLINKED WEBSITE, OR
            FEATURED IN ANY BANNER OR OTHER ADVERTISING. YOU UNDERSTAND AND
            AGREE THAT WORKSHOP IS NOT RESPONSIBLE OR LIABLE FOR ANY TRANSACTION
            BETWEEN YOU AND THIRD PARTY PROVIDERS OF THIRD PARTY APPLICATIONS OR
            PRODUCTS OR SERVICES ADVERTISED ON OR THROUGH THE WORKSHOP SERVICE.
            AS WITH ANY PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR
            IN ANY ENVIRONMENT, YOU SHOULD USE YOUR JUDGMENT AND EXERCISE
            CAUTION WHERE APPROPRIATE. NO ADVICE OR INFORMATION WHETHER ORAL OR
            IN WRITING OBTAINED BY YOU FROM WORKSHOP SHALL CREATE ANY WARRANTY
            ON BEHALF OF WORKSHOP IN THIS REGARD. SOME ASPECTS OF THIS SECTION
            MAY NOT APPLY IN SOME JURISDICTIONS IF PROHIBITED BY APPLICABLE LAW.
          </P>

          <P>THIS DOES NOT AFFECT YOUR STATUTORY RIGHTS AS A CONSUMER.</P>

          <H as="h3">Limitation</H>

          <P>
            YOU AGREE THAT, TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOUR SOLE
            AND EXCLUSIVE REMEDY FOR ANY PROBLEMS OR DISSATISFACTION WITH THE
            WORKSHOP SERVICE IS TO UNINSTALL ANY WORKSHOP SOFTWARE AND TO STOP
            USING THE WORKSHOP SERVICE. WHILE WORKSHOP ACCEPTS NO RESPONSIBILITY
            FOR THIRD PARTY APPLICATIONS OR THE CONTENT THEREOF, AND WHILE YOUR
            RELATIONSHIP WITH SUCH THIRD PARTY APPLICATIONS MAY BE GOVERNED BY
            SEPARATE AGREEMENTS WITH SUCH THIRD PARTIES, TO THE EXTENT PERMITTED
            BY APPLICABLE LAW, YOUR SOLE AND EXCLUSIVE REMEDY, AS WITH RESPECT
            TO WORKSHOP, FOR ANY PROBLEMS OR DISSATISFACTION WITH THIRD PARTY
            APPLICATIONS OR THE CONTENT THEREOF, IS TO UNINSTALL AND/OR STOP
            USING ANY SUCH THIRD PARTY APPLICATIONS.
          </P>

          <P>
            TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL WORKSHOP,
            ITS OFFICERS, SHAREHOLDERS, EMPLOYEES, AGENTS, DIRECTORS,
            SUBSIDIARIES, AFFILIATES, SUCCESSORS, ASSIGNS, SUPPLIERS, OR
            LICENSORS BE LIABLE FOR (1) ANY INDIRECT, SPECIAL, INCIDENTAL,
            PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES; (2) ANY LOSS OF USE,
            DATA, BUSINESS, OR PROFITS (WHETHER DIRECT OR INDIRECT), IN ALL
            CASES ARISING OUT OF THE USE OR INABILITY TO USE THE WORKSHOP
            SERVICE, THIRD PARTY APPLICATIONS, OR THIRD PARTY APPLICATION
            CONTENT, REGARDLESS OF LEGAL THEORY, WITHOUT REGARD TO WHETHER
            WORKSHOP HAS BEEN WARNED OF THE POSSIBILITY OF THOSE DAMAGES, AND
            EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE; OR (3) AGGREGATE
            LIABILITY FOR ALL CLAIMS RELATING TO THE WORKSHOP SERVICE, THIRD
            PARTY APPLICATIONS, OR THIRD PARTY APPLICATION CONTENT MORE THAN THE
            AMOUNTS PAID BY YOU TO WORKSHOP DURING THE PRIOR TWELVE MONTHS IN
            QUESTION, TO THE EXTENT PERMISSIBLE BY APPLICABLE LAW.
          </P>

          <P>
            Nothing in the Agreements removes or limits Steppit&rsquo;s
            liability for fraud, fraudulent misrepresentation, death or personal
            injury caused by its negligence, and, if required by applicable law,
            gross negligence. Some aspects of this section may not apply in some
            jurisdictions if prohibited by applicable law.
          </P>

          <H as="h3">Third Party Rights</H>

          <P>
            You acknowledge and agree that the owners of the Content and certain
            distributors (such as app store providers) are intended
            beneficiaries of the Agreements and have the right to enforce the
            Agreements directly against you. Other than as set out in this
            section, the Agreements are not intended to grant rights to anyone
            except you and Steppit, and in no event shall the Agreements create
            any third party beneficiary rights. Furthermore, the rights to
            terminate, rescind, or agree to any variation, waiver, or settlement
            of the Agreements are not subject to the consent of any other
            person.
          </P>

          <P>
            If you have downloaded the App from the Apple, Inc.
            (&ldquo;Apple&rdquo;) App Store or if you are using the App on an
            iOS device, you acknowledge that you have read, understood, and
            agree to the following notice regarding Apple. This Agreement is
            between you and Steppit only, not with Apple, and Apple is not
            responsible for the Service and the content thereof. Apple has no
            obligation whatsoever to furnish any maintenance and support
            services with respect to the Service. In the event of any failure of
            the Service to conform to any applicable warranty, then you may
            notify Apple and Apple will refund any applicable purchase price for
            the App to you; and, to the maximum extent permitted by applicable
            law, Apple has no other warranty obligation whatsoever with respect
            to the Service. Apple is not responsible for addressing any claims
            by you or any third party relating to the Service or your possession
            and/or use of the Service, including, but not limited to: (1)
            product liability claims; (2) any claim that the Service fails to
            conform to any applicable legal or regulatory requirement; and (3)
            claims arising under consumer protection or similar legislation.
            Apple is not responsible for the investigation, defense, settlement
            and discharge of any third party claim that the Service and/or your
            possession and use of the App infringe that third party&rsquo;s
            intellectual property rights. You agree to comply with any
            applicable third party terms, when using the Service. Apple, and
            Apple&rsquo;s subsidiaries, are third party beneficiaries of this
            Agreement, and upon your acceptance of this Agreement, Apple will
            have the right (and will be deemed to have accepted the right) to
            enforce this Agreement against you as a third party beneficiary of
            this Agreement. You hereby represent and warrant that (1) you are
            not located in a country that is subject to a U.S. Government
            embargo, or that has been designated by the U.S. Government as a
            &ldquo;terrorist supporting&rdquo; country; and (2) you are not
            listed on any U.S. Government list of prohibited or restricted
            parties.
          </P>

          <H as="h3" id="entire-agreement">
            Entire Agreement
          </H>

          <P>
            Other than as stated in this section or as explicitly agreed upon in
            writing between you and Steppit, the Agreements constitute all the
            terms and conditions agreed upon between you and Steppit and
            supersede any prior agreements in relation to the subject matter of
            these Agreements, whether written or oral.
          </P>

          <H as="h3">Severability and Waiver</H>

          <P>
            Unless as otherwise stated in the Agreements, should any provision
            of the Agreements be held invalid or unenforceable for any reason or
            to any extent, such invalidity or enforceability shall not in any
            manner affect or render invalid or unenforceable the remaining
            provisions of the Agreements, and the application of that provision
            shall be enforced to the extent permitted by law.
          </P>

          <P>
            Any failure by Steppit or any third party beneficiary to enforce the
            Agreements or any provision thereof shall not waive Steppit&rsquo;s
            or the applicable third party beneficiary&rsquo;s right to do so.
          </P>

          <H as="h3">Assignment</H>

          <P>
            Steppit may assign the Agreements or any part of them, and Steppit
            may delegate any of its obligations under the Agreements. You may
            not assign the Agreements or any part of them, nor transfer or
            sublicense your rights under the Agreements, to any third party.
          </P>

          <H as="h3">Indemnification</H>

          <P>
            To the fullest extent permitted by applicable law, you agree to
            indemnify and hold Steppit harmless from and against all damages,
            losses, and expenses of any kind (including reasonable lawyer fees
            and costs) arising out of: (1) your breach of this Agreement; (2)
            any User Content; (3) any activity in which you engage on or through
            the Steppit Service; and (4) your violation of any law or the rights
            of a third party.
          </P>

          <H as="h3">Contact Us</H>

          <P>
            If you have any questions concerning the Steppit Service or the
            Agreements, please contact{' '}
            <A href="https://help.workshop.ws/hc/en-us/requests/new">
              Steppit customer service
            </A>
            .
          </P>

          <P>
            Thank you for reading our Terms. We hope you enjoy using Steppit!
          </P>

          <Divider mt={6} mb={6} />

          <H as="h2" fontSize="3xl" id="acceptable-use-policy">
            Acceptable Use Policy
          </H>

          <P>
            <chakra.em>Effective as of 9 March, 2023</chakra.em>
          </P>

          <P>
            Steppit respects intellectual property rights and expects you to do
            the same. We&rsquo;ve established a few ground rules for you to
            follow when using the Service, to make sure Steppit stays enjoyable
            for everyone. Please follow these rules and encourage other Users to
            do the same.
          </P>

          <P>The following is not permitted for any reason whatsoever:</P>

          <chakra.ul listStylePosition="outside" mb={4}>
            <chakra.li>
              Copying, redistributing, reproducing, &ldquo;ripping&rdquo;,
              recording, transferring, performing or displaying to the public,
              broadcasting, or making available to the public any part of the
              Steppit Service or the Content, or otherwise making any use of the
              Steppit Service or the Content which is not expressly permitted
              under the Agreements or applicable law or which otherwise
              infringes the intellectual property rights (such as copyright) in
              the Steppit Service or the Content or any part of it;
            </chakra.li>
            <chakra.li>
              Transferring copies of cached Content from an authorized Device to
              any other Device via any means;
            </chakra.li>
            <chakra.li>
              Reverse-engineering, decompiling, disassembling, modifying, or
              creating derivative works based on the Steppit Service, Content or
              any part thereof unless permitted by applicable law;
            </chakra.li>
            <chakra.li>
              Circumventing any technology used by Steppit, its licensors, or
              any third party to protect the Content or the Service.
            </chakra.li>
            <chakra.li>
              Selling, renting, sublicensing or leasing of any part of the
              Steppit Service or the Content;
            </chakra.li>
            <chakra.li>
              Circumventing any territorial restrictions applied by Steppit or
              its licensors;
            </chakra.li>
            <chakra.li>
              Removing or altering any copyright, trademark, or other
              intellectual property notices contained on or provided through the
              Steppit Service (including for the purpose of disguising or
              changing any indications of the ownership or source of any
              Content);
            </chakra.li>
            <chakra.li>
              Providing your password to any other person or using any other
              person&rsquo;s username and password; or
            </chakra.li>
            <chakra.li>
              &ldquo;Crawling&rdquo; the Steppit Service or otherwise using any
              automated means (including bots, scrapes, and spiders) to collect
              information from Steppit;
            </chakra.li>
          </chakra.ul>

          <P>
            Please respect Steppit, Creators and other users of the Steppit
            Service. Don&rsquo;t engage in any activity, post any User Content,
            or register and/or use a username or channel handle which is or
            includes material that:
          </P>

          <chakra.ul listStylePosition="outside">
            <chakra.li>
              Is offensive, abusive, defamatory, pornographic, threatening, or
              obscene;
            </chakra.li>
            <chakra.li>
              Is illegal, or intended to promote or commit an illegal act of any
              kind, including but not limited to violations of intellectual
              property rights, privacy rights, or proprietary rights of Steppit
              or a third party;
            </chakra.li>
            <chakra.li>
              Includes your password or purposely includes any other
              user&rsquo;s password or purposely includes personal data of third
              parties or is intended to solicit such personal data;
            </chakra.li>
            <chakra.li>
              Includes malicious content such as malware, Trojan horses, or
              viruses, or otherwise interferes with any user&rsquo;s access to
              the Service;
            </chakra.li>
            <chakra.li>
              Is intended to or does harass or bully other users;
            </chakra.li>
            <chakra.li>
              Impersonates or misrepresents your affiliation with another user,
              person, or entity, or is otherwise fraudulent, false, deceptive,
              or misleading;
            </chakra.li>
            <chakra.li>
              Uses automated means to artificially promote content;
            </chakra.li>
            <chakra.li>
              Involves commercial or sales activities, such as advertising,
              promotions, contests, sweepstakes, or pyramid schemes, that are
              not expressly authorized by Steppit;
            </chakra.li>
            <chakra.li>
              Links to, references, or otherwise promotes commercial products or
              services, except as expressly authorized by Steppit;
            </chakra.li>
            <chakra.li>
              Interferes with or in any way disrupts the Steppit Service,
              tampers with, breaches, or attempts to probe, scan, or test for
              vulnerabilities in the Service or Steppit&rsquo;s computer
              systems, network, usage rules, or any of Steppit&rsquo;s security
              components, authentication measures or any other protection
              measures applicable to the Service, the Content or any part
              thereof; or
            </chakra.li>
            <chakra.li>
              Conflicts with the Agreements, as determined by Steppit.
            </chakra.li>
          </chakra.ul>

          <P>
            You acknowledge and agree that posting any such User Content may
            result in immediate termination or suspension of your Steppit
            account. You also agree that Steppit may also reclaim your username
            or channel handle for any reason.
          </P>

          <P>
            Your password protects your user account, and you are solely
            responsible for keeping your password confidential and secure. You
            understand that you are responsible for all use of your username and
            password on the Service. If your username or password is lost or
            stolen, or if you believe there has been unauthorized access to your
            account by third parties, please notify us immediately and change
            your password as soon as possible.
          </P>

          <P>
            Steppit may remove anyone from the Steppit Platform at any time for
            any reason, solely in Steppit&rsquo;s discretion. This right is not
            modified by any other section of these Terms.
          </P>

          <Divider mt={6} mb={6} />

          <H as="h2" fontSize="3xl" id="refund-policy">
            Refund Policy
          </H>

          <P>
            <chakra.em>Effective as of 9 March, 2023</chakra.em>
          </P>

          <P>
            For details on our refund deadlines and policies, please refer to
            the information below. Please note that we treat violations of our
            Terms of Service and <A href="#code-of-conduct">Code of Conduct</A>{' '}
            very seriously, and we have no obligation to offer refunds to
            students who are found to be in violation of these terms, even if
            their requests are made within the designated refund period.
            Similarly we have no obligation to offer late refunds to students
            who do not pass a course, or who are otherwise unsatisfied with
            their final grade.
          </P>

          <H as="h3">For Course Purchases</H>

          <P>
            If you cancel your paid enrollment for a course, Steppit or the
            Seller of the course will offer you a complete refund until 14 days
            after your payment.
          </P>

          <P>
            Notwithstanding the foregoing, shorter refund periods may apply to
            specific courses. In these cases, the shorter period will be stated
            as part of the sign-up process for the course.
          </P>

          <P>
            By paying for a course before your course start date, your refund
            eligibility period will start on the payment date, not the
            course&rsquo;s start date. Any feature access periods will start on
            the course&rsquo;s start date, not on your payment date.
          </P>

          <P>
            By opting to pay for a course using instalments, your refund
            eligibility period will start on the payment date of the initial
            deposit.
          </P>

          <H as="h3">How to Request a Refund</H>

          <P>
            To request a refund, contact the Seller of your course or else
            Steppit at support@steppit.com with your email address and query.
            Alternatively you can request a refund through the support
            functionality within the mobile application.
          </P>

          <H as="h3">When and How We Issue Refunds</H>

          <P>
            Upon determining that a refund is due to you under these Terms, we
            will issue such a refund within 14 days of our confirmation that
            such a refund is due to you.
          </P>

          <P>
            For Content purchased as a result of our refer-a-friend schemes, you
            are not eligible for a refund on the order for which the reward was
            offered.
          </P>

          <P>
            If you are entitled to a refund we will refund you on the credit or
            debit card used by you to pay for the Content.
          </P>

          <H as="h3" id="code-of-conduct">
            Code of Conduct
          </H>

          <P>
            <chakra.em>Effective as of 9 March, 2023</chakra.em>
          </P>

          <P>
            All students participating in a course must agree to abide by the
            following code of conduct:
          </P>
          <chakra.ol listStylePosition="outside">
            <chakra.li>I will register for only one account.</chakra.li>
            <chakra.li>
              My answers to homework, quizzes, exams, projects, and other
              assessments will be my own work (except for assessments that
              explicitly permit collaboration).
            </chakra.li>
            <chakra.li>
              I will not make solutions to homework, quizzes, exams, projects
              and other assessments available to anyone else (except to the
              extent an assessment explicitly permits sharing solutions). This
              includes both solutions from me, as well as any solutions provided
              by the course staff or others.
            </chakra.li>
            <chakra.li>
              I will not engage in any other activities that will dishonestly
              improve my results or dishonestly improve or hurt the results of
              others.
            </chakra.li>
          </chakra.ol>
          <Divider mt={12} mb={6} />
          <H as="h2" fontSize="3xl" id="channel-terms">
            Channel Terms
          </H>

          <H as="h3">Introduction</H>

          <P>
            Welcome to the Steppit Channel Terms. In order to create, manage and
            sell Content on the Steppit Service, Creators are required to create
            a Channel. This section outlines the terms and conditions that
            govern the creation, use, and sale of courses by Channels on the
            Steppit Service. As a Channel, it&#39;s important to understand
            these terms so you can create and sell your courses while adhering
            to all policies and guidelines. Please read these terms carefully
            before creating your Channel and selling your courses.
          </P>

          <P>
            By using the Steppit Service, you acknowledge that you have read,
            understood, and agree to be bound by this Agreement. If you do not
            agree to this Agreement, you may not use the Platform to create,
            offer or sell Content.
          </P>

          <H as="h3">Interpretation</H>
          <H as="h4" fontWeight="normal">
            Definitions:
          </H>

          <P>
            Agreement: the agreement between Steppit and the Creator for the
            supply of the Content in accordance with these Terms.
          </P>

          <P>
            Business Day: a day (other than a Saturday, Sunday or public
            holiday) when banks in London are open for business.
          </P>

          <P>
            Channel: the channel that the Content is distributed through, owned
            by the Creator.
          </P>

          <P>
            Content: the sessions, courses and any supporting materials (or any
            part of them) distributed through the Channel.
          </P>

          <P>
            Creator: the creator of Content on the Steppit Service and owner of
            the Channel (&ldquo;you&rdquo;).
          </P>

          <P>
            Gross Amount: the amount received by Steppit for purchases of the
            Content by Platform Users.
          </P>
          <P>
            Intellectual Property Rights: patents, utility models, rights to
            inventions, copyright and neighbouring and related rights,
            trademarks and service marks, business names and domain names,
            rights in get-up and trade dress, goodwill and the right to sue for
            passing off or unfair competition, rights in designs, database
            rights, rights to use, and protect the confidentiality of,
            confidential information (including know-how and trade secrets), and
            all other intellectual property rights, in each case whether
            registered or unregistered and including all applications and rights
            to apply for and be granted, renewals or extensions of, and rights
            to claim priority from, such rights and all similar or equivalent
            rights or forms of protection which subsist or will subsist now or
            in the future in any part of the world.
          </P>

          <P>
            Net Amount: the Gross Amount less applicable intermediary fees (i.e.
            Apple&rsquo;s App Store or Google Play), payment processor fees and
            any tax deductions.
          </P>

          <P>
            Platform Users: individuals or corporate entities who have
            subscribed to use the Platform.
          </P>

          <P>
            Sale Price: the sale price of the Content, in the Creator&rsquo;s
            preferred currency (Great British Pounds by default).
          </P>

          <P>
            Sales Currency: the currency of the sale. This is determined by the
            country of origin of the Platform User purchasing the Content. For
            mobile application sales, such as through Apple&rsquo;s App Store or
            Google Play, the currency will be automatically determined.
          </P>

          <P>
            Services: the Steppit services, websites, and software applications,
            including the website hosted on{' '}
            <A href="/">https://www.steppit.com</A> (also referred to as
            &ldquo;Steppit&rdquo;, &ldquo;Steppit Service&rdquo; or
            &ldquo;Platform&rdquo;).
          </P>

          <P>
            Steppit: a trading name of Workshop Tech Solutions Ltd. (also
            referred to as &ldquo;Workshop&rdquo;, &ldquo;we&rdquo;,
            &ldquo;us&rdquo;, &ldquo;our&rdquo;).
          </P>

          <P>
            Terms: all Steppit terms and conditions, including these Channel
            Terms and General Terms.
          </P>

          <H as="h4" fontWeight="normal">
            Interpretation:
          </H>

          <P>
            a reference to a statute or statutory provision is a reference to
            such statute or provision as amended or re-enacted. A reference to a
            statute or statutory provision includes any subordinate legislation
            made under that statute or statutory provision, as amended or
            re-enacted;
          </P>

          <P>
            any phrase introduced by the terms including, include, in particular
            or any similar expression shall be construed as illustrative and
            shall not limit the sense of the words preceding those terms; and
          </P>

          <P>a reference to writing or written includes emails.</P>

          <H as="h3" id="channel-ownership">
            Channel Ownership
          </H>

          <P>
            The User who has the &ldquo;Owner&rdquo; role in a Channel is the
            legal owner of the Channel (known in these Terms as the
            &ldquo;Creator&rdquo;) and is fully responsible for it. By default,
            this is the User who creates the Channel, however the
            &ldquo;Owner&rdquo; role can be transferred to another User after
            the Channel&rsquo;s creation.
          </P>

          <P>
            The Creator may invite other Users to become team members on the
            Channel, but ownership of the Channel remains with the Creator.
          </P>

          <H as="h3" id="content">
            Content
          </H>

          <P>
            All Intellectual Property Rights in the Content referred to in this
            agreement remain vested in the Creator with full title guarantee and
            free from encumbrances.
          </P>

          <P>The Creator warrants that:</P>

          <P>
            It owns the Intellectual Property Rights in the Content and has the
            authority to authorise Steppit to reproduce, distribute, publicly
            perform (including by means of a digital audio transmission),
            publicly display, communicate to the public, promote, market and
            otherwise use the Content on and through the Services in the manner
            contemplated by these Terms;
          </P>

          <P>
            No Content infringes any Intellectual Property Right of any third
            party;
          </P>

          <P>
            It has the necessary qualifications, credentials and expertise,
            including without limitation, education, training, knowledge, and
            skill set to teach and offer the services provided as part of the
            Content and through the Services; and
          </P>

          <P>The Creator will not:</P>
          <P>
            (a) upload or provide any inappropriate, offensive, racist, hateful,
            sexist, pornographic, false, misleading, incorrect, infringing,
            defamatory or libellous content or information through the Services;
          </P>
          <P>
            (b) upload, post or otherwise transmit any unsolicited or
            unauthorised advertising, promotional materials, junk mail, spam,
            chain letters, pyramid schemes or any other form of solicitation
            (commercial or otherwise) through the Services or to any Platform
            User without the prior written consent of Steppit;
          </P>
          <P>
            (c) use the Services for any business other than for providing
            tutoring, teaching and instructional services to Platform Users;
          </P>
          <P>
            (d) engage in any activity that will require Steppit to obtain any
            licences from or pay any royalties to any third party, including, by
            way of example and not limitation, the payment of royalties for the
            public performance of any musical works or sound recordings;
          </P>
          <P>
            (e) copy, modify, distribute, reverse engineer, deface, tarnish,
            mutilate, hack, or interfere with the Services or operations
            thereof, except as permitted in these Terms;
          </P>
          <P>
            (f) frame or embed the Services in a manner to embed a free coupon
            version of the Content or other similar functionality intended to
            circumvent the Services;
          </P>
          <P>
            (g) in relation to the Services impersonate another person or gain
            unauthorised access to another person&#39;s account;
          </P>
          <P>
            (h) introduce any virus, worm, spyware or any other computer code,
            file or program that may or is intended to damage or hijack the
            operation of any hardware, software or telecommunications equipment,
            or any other aspect of the Services or operation thereof; scrape,
            spider, use a robot or other automated means of any kind to access
            the Services; or
          </P>
          <P>
            (i) interfere with or otherwise prevent other suppliers or Creators
            from providing their services or lessons.
          </P>

          <P>
            The Creator shall ensure that at all times it has and maintains all
            the licences, permissions, authorisations, consents and permits that
            it needs to carry out its obligations under these Terms.
          </P>

          <H as="h3" id="revenue-share">
            Revenue Share and Payments
          </H>

          <P>
            Steppit uses Stripe as its payment processor, and Creators are
            required to create a connected Stripe account in order to sell
            Content. Creators therefore agree to the Stripe Connected Account
            Agreement (available at{' '}
            <A
              href="https://stripe.com/legal/connect-account"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://stripe.com/legal/connect-account
            </A>
            ) when selling Content on the Platform.
          </P>

          <P>
            Unless otherwise agreed between Steppit and the Creator in writing,
            revenue generated from sales of the Content will be subject to the
            following &ldquo;Revenue Share&rdquo;. Steppit will receive 20% of
            revenue generated from sales of the Content and the Creator will
            receive 80% of revenue generated, less any applicable payment
            processing fees (including Stripe fees available at{' '}
            <A
              href="https://stripe.com/pricing"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://stripe.com/pricing
            </A>
            ). The Creator agrees to this Revenue Share and acknowledges that
            Steppit has the right to deduct its portion of the revenue generated
            from the sale of Content.
          </P>

          <P>
            Payouts to the Creator&rsquo;s bank account will be made according
            to the payout schedule of their Stripe account.
          </P>

          <P>
            Steppit will not be liable to make payments to the Creator resulting
            from invalid purchases by any person, bot or animated programme.
          </P>

          <P>
            Please note that in accordance with Steppit's regulatory and
            compliance policies, Channels based in Brazil are currently not
            authorized to sell Content on the platform due to local legal and
            operational restrictions.
          </P>

          <H as="h3" id="course-pricing">
            Course Pricing
          </H>

          <P>
            The Sale Price of the Content will be determined by the Creator
            before the Content is made available to Platform Users, unless
            otherwise agreed to in writing between Steppit and the Creator. The
            Platform will handle billing and fee interactions with Platform
            Users through either Steppit&#39;s or the Creator&#39;s Stripe
            account.
          </P>

          <P>
            Normally, Steppit will list the Content at the Sale Price or a
            local/mobile app equivalent (as detailed below). If a promotion is
            available for your Content, Steppit may set a different discounted
            or list price for the Content (see{' '}
            <A href="#promotions">Promotions</A>).
          </P>

          <P>
            For purchases made in foreign currencies, the Sale Price or
            promotional price will be converted to the student&#39;s applicable
            currency using a system-wide foreign currency conversion rate set by
            Steppit. This conversion rate is periodically fixed into a table of
            corresponding price tiers by currency, known as the &quot;Price Tier
            Matrix&quot;. Note that this matrix is fixed to a set of rounded
            prices, and may not be identical to applicable market rates at the
            time of transaction processing. Steppit reserves the right to update
            the Price Tier Matrix at any time. The Price Tier Matrix is
            available{' '}
            <A
              target="_blank"
              rel="noopener noreferrer"
              href="https://cdn.workshop.ws/media/public/steppit/steppit-price-tier-matrix.pdf"
            >
              here
            </A>
            .
          </P>

          <P>
            For purchases made through a mobile application, the pricing matrix
            of the mobile platform provider will apply, and Steppit will select
            the closest price tier to the applicable Sale Price or promotional
            price. Since mobile platforms impose their own currency conversion
            rates, the conversion rates for mobile app prices may differ from
            those in the Price Tier Matrix.
          </P>

          <P>
            The Creator grants Steppit permission to share their Content with
            Steppit employees, selected partners, and in cases where Steppit
            needs to restore access to accounts who have previously purchased
            the Creator&rsquo;s Content. In such cases, the Creator understands
            that they will not receive compensation.
          </P>

          <H as="h3" id="promotions">
            Promotions
          </H>

          <P>
            Steppit may offer Platform Users promotional discounts to purchase
            the Content under a &quot;Platform Promotion Scheme&rdquo;. When
            promotions are run under a Platform Promotion Scheme, Steppit may
            waive part or all of its Revenue Share to minimise the effect of the
            discount on the Creator&rsquo;s Revenue Share, however Steppit is
            not liable for any reduction in Creator revenue resulting from
            promotional discounts offered to Platform Users.
          </P>

          <P>
            Any promotions run outside of a Platform Promotion Scheme are
            subject to the normal Revenue Share percentages, where discounts are
            applied to the Sale Price before the normal Revenue Share is
            applied.
          </P>

          <H as="h3" id="pro-subscription">
            Pro Subscription
          </H>
          <P>
            Steppit Pro Subscriptions include the features and functionality as
            outlined on our website and the Documentation for the applicable
            Subscription Plan. Unless otherwise indicated at the time of
            purchase or in an Order Form, the Subscription Term for any add-ons
            shall be coterminous with your primary Subscription to the Steppit
            Service, including any renewal Subscription Term.
          </P>
          <P>
            You acknowledge and agree that if you exceed the usage permitted by
            your Subscription and Subscription Plan: (i) you may be required to
            upgrade your Subscription Plan or purchase additional usage to
            continue accessing and using Steppit Pro; and (ii) Steppit may
            disable or degrade performance of such Steppit Pro features.
          </P>

          <H as="h3" id="team-members">
            Team Members
          </H>

          <P>
            Steppit allows Creators to add other users as team members of their
            Channel to co-manage Students and Content that you manage. By adding
            a team member, you understand that you are authorizing them to take
            certain actions that affect your Steppit account and Content.
            Violations of Steppit&rsquo;s terms and policies by your team member
            may also impact your Steppit account and Content. Steppit is not
            able to advise on any questions or mediate any disputes between you
            and such users.
          </P>

          <H as="h3" id="student-data">
            Student Data
          </H>

          <P>
            Creators will have access to student data such as names and email
            addresses through the Steppit Service. The Creator agrees that they
            will not use the data they receive for any purpose other than
            providing their services to those students on the Steppit Service,
            and that they won&#39;t solicit additional personal data or store
            students&#39; personal data outside the Steppit Service. They agree
            to indemnify Steppit against any claims arising from their use of
            students&#39; personal data.
          </P>

          <H as="h3" id="refunds">
            Refunds
          </H>

          <P>
            The Creator acknowledges and accepts that Platform Users have the
            right to receive a refund. Neither the Creator nor Steppit shall
            receive any payments, fees or commissions in respect of any Content
            for which a refund has been granted. When granted, refunds use the
            Creator&rsquo;s available Stripe balance, which doesn&#39;t include
            any pending balance. If the Creator&rsquo;s available balance
            doesn&#39;t cover the amount of the refund, Stripe may debit the
            remaining amount from the Creator&rsquo;s bank account.
          </P>

          <P>
            In the event that a Platform User requests a refund in respect of
            the Content within the conditions of the{' '}
            <A href="#refund-policy">Refund Policy</A>, Steppit has the right to
            grant refunds without permission from the Creator.
          </P>

          <H as="h3" id="tax">
            Tax
          </H>

          <P>
            The Creator understands and acknowledges that it is solely
            responsible for the payment of any taxes on its income and complying
            with all tax and regulatory policies in its country after receiving
            its Revenue Share.
          </P>

          <H as="h3" id="licence">
            Licence
          </H>

          <P>
            The Creator hereby grants to Steppit a perpetual, non-exclusive,
            royalty-free non-transferable licence to use the Intellectual
            Property Rights in the Content in relation to the supply and
            development of the Services. The rights granted in this clause
            include, but are not limited to, hosting, storing, caching,
            reproducing, publishing, displaying (publicly or otherwise),
            performing (publicly or otherwise), distributing, transmitting,
            modifying, adapting (including, without limitation, in order to
            conform it to the requirements of any networks, devices, services,
            or media through which the Services are available), creating
            derivative works of, and otherwise using all Content through the
            Services.
          </P>

          <P>
            The Creator hereby grants to Steppit the right to sublicense the
            Content to Platform Users directly or indirectly through selected
            third parties, so that those third parties can research and/or
            analyse such Content on other media and services. For more
            information on how we may use the Content, please visit our Privacy
            Policy{' ('}
            <A href="/privacy">https://www.steppit.com/privacy</A>
            {').'}
          </P>

          <P>
            The Creator hereby grants Steppit permission to make use of all or
            any part of the Content for quality control or delivering,
            marketing, promoting, demonstrating or operating the Services.
          </P>

          <P>
            The Creator hereby grants Steppit permission to use its name,
            likeness, image or voice in connection with offering, delivering,
            marketing, promoting, demonstrating, and selling the Content and
            waives any and all rights of privacy, publicity, or any other rights
            of a similar nature in connection therewith, to the extent
            permissible under applicable law.
          </P>

          <P>
            Steppit reserves the right to provide our Platform to the
            Creator&rsquo;s competitors and make no promise of exclusivity in
            any particular market segment. The Creator further acknowledges and
            agrees that Steppit employees and contractors may also be Steppit
            customers and that they may compete with you, although they may not
            use your Content or confidential information in doing so.
          </P>

          <H as="h3" id="trademarks">
            Trademarks
          </H>

          <P>
            Subject to the requirements below, Creators may use Steppit
            trademarks where Steppit authorizes them to do so.
          </P>

          <P>The Creator must:</P>
          <P>
            (a) Only use the images of Steppit trademarks that Steppit makes
            openly available to the Creator, as detailed in any guidelines
            Steppit may publish;
          </P>
          <P>
            (b) Only use Steppit trademarks in connection with the promotion and
            sale of Content available on the Steppit Service or the Creator’s
            participation on Steppit; and
          </P>
          <P>
            (c) Immediately comply if Steppit requests that the Creator
            discontinues use.
          </P>

          <P>The Creator must not:</P>

          <P>(a) Use Steppit trademarks in a misleading or disparaging way;</P>
          <P>
            (b) Use Steppit trademarks in a way that implies that Steppit
            endorses, sponsors, or approves of the Content; or
          </P>
          <P>
            (c) Use Steppit trademarks in a way that violates applicable law or
            in connection with an obscene, indecent, or unlawful topic or
            material.
          </P>

          <H as="h3" id="channel-termination">
            Deletion of the Channel and Termination
          </H>

          <P>
            Steppit may terminate this Agreement and modify, terminate, or
            refuse the Creator&rsquo;s access to the Steppit Service at any
            time, with or without cause or notice. Steppit reserves the right to
            remove the Creator&rsquo;s Channel and Content without warning.
          </P>

          <P>
            Termination of the Agreement shall not affect any of the
            parties&#39; rights and remedies that have accrued as at
            termination.
          </P>

          <P>
            Any provision of the Agreement that expressly or by implication is
            intended to come into or continue in force on or after termination
            shall remain in full force and effect.
          </P>

          <P>
            If the Creator wishes to delete their Channel, it may do so by
            contacting Steppit via email at support@steppit.com. Steppit will
            use reasonable endeavours to respond to the Creator&#39;s request
            within 48 hours.
          </P>

          <P>
            The Creator hereby understands and agrees that, after the Channel
            has been deleted, the Creator&#39;s name and all Content will remain
            accessible to Platform Users in accordance with the terms granted to
            such Platform Users.
          </P>

          <P>
            If the Creator closes their account or terminates their Channel,
            Steppit may keep a copy of their Content after termination. The
            Creator grants Steppit a non-exclusive, perpetual, irrevocable
            license to maintain such archival copy for compliance with any
            relevant laws and regulations and for Steppit&rsquo;s internal
            business purposes, subject to applicable law.
          </P>

          <H as="h3" id="indemnity">
            Indemnity
          </H>

          <P>
            If the Creator behaves in a way that gets Steppit in legal trouble,
            Steppit may exercise legal recourse against the Creator. The Creator
            agrees to indemnify, defend (if we so request), and hold harmless
            Steppit, our group companies, and their officers, directors,
            suppliers, partners, and agents from an against any third-party
            claims, demands, losses, damages, or expenses (including reasonable
            attorney fees) arising from (a) the Content you post or submit, (b)
            your use of the Services (c) your violation of these Terms, or (d)
            your violation of any rights of a third party. Your indemnification
            obligation will survive the termination of these Terms and your use
            of the Services.
          </P>

          <H as="h3" id="channel-general">
            General
          </H>

          <P>
            Entire agreement. The Agreement constitutes the entire agreement
            between the parties and supersedes and extinguishes all previous
            agreements, promises, assurances, warranties, representations and
            understandings between them, whether written or oral, relating to
            its subject matter.
          </P>

          <P>
            Severance. If any provision or part-provision of the Agreement is or
            becomes invalid, illegal or unenforceable, it shall be deemed
            modified to the minimum extent necessary to make it valid, legal and
            enforceable. If such modification is not possible, the relevant
            provision or part-provision shall be deemed deleted. Any
            modification to or deletion of a provision or part-provision under
            this clause shall not affect the validity and enforceability of the
            rest of the Agreement.
          </P>

          <P>
            Notices. Any notice or other communication given to a party under or
            in connection with the Agreement shall be in writing, addressed to
            that party at its registered office or such other address as that
            party may have specified to the other party in writing in accordance
            with this clause, and shall be delivered personally, or sent by
            pre-paid first class post or other next working day delivery
            service, commercial courier or email. A notice or other
            communication shall be deemed to have been received: if delivered
            personally; if sent by pre-paid first class post or other next
            working day delivery service, at 9.00 am on the second Business Day
            after posting; if delivered by commercial courier, on the date and
            at the time that the courier&#39;s delivery receipt is signed; or,
            if sent by email, one Business Day after transmission.
          </P>

          <P>
            Account access. Steppit reserves the right to access your account,
            Channel, and Content in order to respond to requests for technical
            support, to maintain the safety and security of the Platform, and
            for other legitimate business purposes, as necessary, in
            Steppit&#39;s discretion.
          </P>

          <P>
            Content monitoring. Steppit may, but has no obligation to, monitor
            any Content that appears on the Platform or review any conduct
            occurring through the Platform, including any interactions between
            Creators and Students and Steppit employees.
          </P>

          <P>
            Third party rights. No one other than a party to these Terms shall
            have any right to enforce any of its terms.
          </P>

          <P>
            Relationship between us. The Creator and Steppit agrees that no
            joint venture, partnership, employment, contractor, or agency
            relationship exists between them.
          </P>

          <P>
            Governing law. The Agreement, and any dispute or claim arising out
            of or in connection with it or its subject matter or formation
            (including non-contractual disputes or claims), shall be governed
            by, and construed in accordance with the law of England and Wales.
          </P>

          <P>
            Jurisdiction. Each party irrevocably agrees that the courts of
            England and Wales shall have exclusive jurisdiction to settle any
            dispute or claim arising out of or in connection with these Terms or
            its subject matter or formation (including non-contractual disputes
            or claims).
          </P>

          <Divider mt={12} mb={6} />
          <H as="h2" fontSize="3xl" id="ai-terms">
            AI Terms
          </H>
          <H as="h3">Introduction</H>
          <P>
            These Steppit AI Terms (&ldquo;Steppit AI Terms&rdquo;) are a
            component of the overarching agreements between you and Steppit,
            applicable to your usage and access of any Steppit AI feature(s).
            The terminology used in these Terms has the same meaning as in the
            Agreements. Whenever the Terms of Service apply, any reference to
            &quot;Customer Data&quot; in these Steppit AI Terms will also
            signify &quot;Content&quot;.
          </P>
          <H as="h3">Interpretations</H>

          <P>
            &quot;Steppit AI&quot; refers to any feature(s) or functionality
            offered by Steppit and designated as Steppit AI that utilise data
            models trained by machine learning.
          </P>

          <P>
            &quot;Steppit AI Tools&quot; indicates the suite of Steppit AI
            authorship and editing tools, which encompass tools for generating
            course plans and long-form textual content through prompting,
            editing existing textual content via pre-engineered prompts,
            extracting information from existing textual content via
            pre-engineered prompts, and translating existing textual content.
          </P>
          <H as="h3">General</H>
          <P>
            Machine learning and artificial intelligence models can enhance
            their performance over time to cater to specific use cases. We may
            employ data we obtain from your utilisation of Steppit AI to improve
            our models.
          </P>

          <P>
            Input and Output. You can provide input to be processed by Steppit
            AI (&quot;Input&quot;) and obtain output generated and returned by
            Steppit AI based on the Input (&quot;Output&quot;). Your Customer
            Data comprises Input and Output when you utilise Steppit AI. You are
            exclusively accountable for the development, content, operation,
            maintenance, and utilization of your Customer Data. You will ensure
            that your Input and usage of Steppit AI and Output do not (i) breach
            any applicable laws; (ii) violate these Steppit AI Terms, the
            Agreements, or our{' '}
            <A href="/terms#acceptable-use-policy">Acceptable Use Policy</A>; or
            (iii) infringe, violate, or misappropriate any of our rights or any
            third party&#39;s rights. You recognize that due to the nature of
            machine learning and the technology powering Steppit AI features,
            Output may not be unique, and Steppit AI may produce the same or
            similar output to Steppit or a third party.
          </P>

          <P>
            Restrictions on Steppit AI Feature Usage. You may not use Steppit AI
            or Output (i) to develop foundation models or other large-scale
            models that compete with Steppit or Steppit AI; (ii) to deceive any
            person that Output from the Services was solely generated by a
            human; (iii) to create spam or content for dissemination in
            electoral campaigns, or (iv) in a way that violates any technical
            documentation, usage guidelines, or parameters.
          </P>

          <P>
            WARRANTY DISCLAIMER. STEPPIT DOES NOT MAKE ANY GUARANTEE REGARDING
            THE RESULTS THAT MAY BE OBTAINED FROM USING STEPPIT AI OR THE
            ACCURACY OF ANY OTHER INFORMATION OBTAINED THROUGH STEPPIT AI. YOU
            UNDERSTAND AND AGREE THAT ANY DATA AND/OR MATERIAL OBTAINED THROUGH
            THE USE OF ANY STEPPIT AI FEATURE IS AT YOUR SOLE RISK. YOU SHOULD
            NOT RELY ON FACTUAL ASSERTIONS IN OUTPUT WITHOUT INDEPENDENTLY
            VERIFYING THEIR ACCURACY. OUTPUT THAT APPEARS ACCURATE DUE TO ITS
            SPECIFICITY MAY STILL CONTAIN FACTUAL INACCURACIES. STEPPIT AI
            CANNOT DYNAMICALLY RETRIEVE INFORMATION, AND OUTPUT MAY NOT ACCOUNT
            FOR EVENTS OR CHANGES TO UNDERLYING FACTS OCCURRING AFTER THE AI
            MODEL WAS TRAINED. NO INFORMATION OR ADVICE, WHETHER ORAL OR
            WRITTEN, OBTAINED BY YOU FROM STEPPIT OR THROUGH STEPPIT AI, SHALL
            CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
          </P>

          <P>
            Service Level Terms Are Not Applicable. Any downtime of Steppit AI
            resulting from a third-party service&#39;s failure will not be
            considered in the Availability and Downtime calculations,
            notwithstanding anything to the contrary in your Agreements or the
            Service Level Terms.
          </P>

          <P>
            Third-Party Provider Policies. If you choose to use Steppit AI
            feature(s), you may not use the Steppit AI features in a way that
            violates any OpenAI Policy, including their{' '}
            <A href="https://openai.com/policies/usage-policies">
              Usage Policies
            </A>
            {'; and '}
            <A href="https://openai.com/api/policies/sharing-publication/">
              Sharing and Publication Policy
            </A>
            . You also agree to OpenAI's{' '}
            <A href="https://openai.com/policies/api-data-usage-policies">
              Data Usage Policies
            </A>
            .
          </P>
        </Box>
      </ScreenWrapper>
    );
  }

  return (
    <ScreenWrapper>
      <Box listStylePosition="inside" mx={{ base: 'defaultMargin', md: 0 }}>
        <P>
          <chakra.em>Last Updated 12 January, 2023</chakra.em>
        </P>
        <P>
          Hello, and welcome to our Terms of Service. This is important and
          affects your legal rights, so please read them, along with our{' '}
          <chakra.span>
            <A href={navRoutes.global.privacy.path()}>Privacy Policy</A>
          </chakra.span>
          <chakra.span>
            , and any other terms referenced in this document carefully.
          </chakra.span>
        </P>
        <H>
          <chakra.span>Introduction</chakra.span>
        </H>
        <P>
          {`Thanks for choosing ${PLATFORM_DISPLAY_NAME}, a product of Workshop Tech Solutions Ltd
          (“Workshop”, “we”, “us”, “our”). By signing up or otherwise using the
          Workshop services, websites, and software applications (collectively,
          “Workshop”, “Workshop Service” or “Service”), or accessing any content
          or material that is made available by Workshop through the Service
          (the “Content”) you are entering into a binding contract with the
          Workshop entity indicated at the start of this section. The Service
          also include the Workshop Support Community as further described in
          the `}
          <chakra.span>
            <A href="#community">Workshop Support Community</A>
          </chakra.span>
          <chakra.span> section.</chakra.span>
        </P>
        <P>
          Use of the Workshop Service relies on several technical{' '}
          <chakra.span>
            <A href="https://help.workshop.ws/hc/en-us/articles/115001463191-What-devices-does-Workshop-support-">
              requirements
            </A>
          </chakra.span>
          <chakra.span>.</chakra.span>
        </P>
        <P>
          Your agreement with us includes these Terms of Service (“Terms”) and
          our{' '}
          <chakra.span>
            <A href={navRoutes.global.privacy.path()}>Privacy Policy</A>
          </chakra.span>
          . (The Terms, Privacy Policy, and any additional terms that you agree
          to, as discussed in the{' '}
          <chakra.span>
            <A href="#entire-agreement">Entire Agreement</A>
          </chakra.span>
          <chakra.span>
            {' '}
            section, are referred to together as the “Agreements”.) If you wish
            to review the terms of the Agreements, the effective version of the
            Agreements can be found on Workshop’s website. You acknowledge that
            you have read and understood the Agreements, accept the Agreements,
            and agree to be bound by them. If you don’t agree with (or cannot
            comply with) the Agreements, then you may not use the Workshop
            Service or consume any Content.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            In order to use the Workshop Service and access the Content, you
            need to (1) be 18 or older, or be 13 or older and have your parent
            or guardian’s consent to the Agreements, (2) have the power to enter
            a binding contract with us and not be barred from doing so under any
            applicable laws, and (3) be resident in a country where the Service
            is available. You also promise that any registration information
            that you submit to Workshop is true, accurate, and complete, and you
            agree to keep it that way at all times.
          </chakra.span>
        </P>
        <H>
          <chakra.span>Changes to the Agreements</chakra.span>
        </H>
        <P>
          Occasionally we may, at our own discretion, make changes to the
          Agreements. When we make material changes to the Agreements, we’ll
          provide you with prominent notice as appropriate under the
          circumstances, e.g., by displaying a prominent notice within the
          Service or by sending you an email. In some cases, we will notify you
          in advance, and your continued use of the Service after the changes
          have been made will constitute your acceptance of the changes. Please
          therefore make sure you read any such notice carefully. If you do not
          wish to continue using the Service under the new version of the
          Agreements, you may terminate the Agreements by contacting us by{' '}
          <chakra.span>
            <A href={`mailto:support${PLATFORM_EMAIL}`}>email</A>
          </chakra.span>
          .
        </P>
        <H>
          <chakra.span>General</chakra.span>
        </H>
        <P>
          Workshop enables students (“Students”) to connect with independent
          contractor mentors and training or educational institutions and their
          official representatives (the “Mentors”, collectively with Students,
          the “Users”) who provide{' '}
          <chakra.span>
            recorded instruction, tutoring, and learning services (the
            “Content”) via the Workshop Service. The Service include, without
            limitation, facilitating and hosting Content and supporting
            materials, and taking feedback from Users.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            The Workshop Service is only a marketplace for Mentors and Students.
            We do not hire or employ Mentors nor are we responsible or liable
            for any interactions involved between the Mentors and the Students
            who purchase a Mentor’s Content via the Service. We are not
            responsible for disputes, claims, losses, injuries, or damage of any
            kind that might arise out of or related to conduct of Mentors or
            Students, including, but not limited to, any Student’s reliance upon
            any information provided by a Mentor.
          </chakra.span>
        </P>
        <H>
          <chakra.span>Rights We Grant You</chakra.span>
        </H>
        <P>
          <chakra.span>
            The Workshop Service and the Content are the property of Workshop or
            Workshop’s licensors. Subject to these Terms and our policies
            (including the{' '}
            <A href="#acceptable-use-policy">Acceptable Use Policy</A>,{' '}
            <A href="#code-of-conduct">Code of Conduct</A>, and course-specific
            eligibility requirements and other terms), we grant you a limited,
            non-exclusive, revocable license to make use of the Workshop
            Service, and a limited, non-exclusive, non-transferable, revocable
            license to make personal, non-commercial use of the Content (the
            “License”) for which you have paid all required fees. This License
            shall remain in effect until and unless terminated by you or
            Workshop. You promise and agree that you are using the Content for
            your own personal, non-commercial use and that you will not
            redistribute or transfer the Workshop Service or the Content.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            The Workshop software applications and the Content are licensed, not
            sold, to you, and Workshop and its licensors retain ownership of all
            copies of the Workshop software applications and Content even after
            installation on your personal computers, mobile handsets, tablets,
            and/or other relevant devices (“Devices”).
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            All Workshop trademarks, service marks, trade names, logos, domain
            names, and any other feature of the Workshop brand (“Workshop Brand
            Features”) are the sole property of Workshop or its licensors. The
            Agreements do not grant you any rights to use any Workshop Brand
            Features whether for commercial or non-commercial use.
          </chakra.span>
        </P>
        <P>
          You agree and abide by our{' '}
          <A href="#acceptable-use-policy">Acceptable Use Policy</A>
          <chakra.span>
            {' '}
            and not to use the Workshop Service, the Content, or any part
            thereof in any manner not expressly permitted by the Agreements.
            Except for the rights expressly granted to you in these Agreements,
            Workshop grants no right, title, or interest to you in the Workshop
            Service or Content.
          </chakra.span>
        </P>
        <P>
          Third party software (for example, open source software libraries)
          included in the Workshop Service are licensed to you either under the
          Agreements or under the relevant third party software library’s
          license terms.
        </P>
        <H>
          <chakra.span>User-Generated Content</chakra.span>
        </H>
        <P>
          <chakra.span>
            Workshop users may post, upload, and/or contribute (“post”) content
            to the Service (which may include, for example, pictures, text,
            messages, information, and/or other types of content)(“User
            Content”). For the avoidance of doubt, “User Content” includes any
            such content posted to the Workshop Support Community as well as any
            other part of the Workshop Service.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            You promise that, with respect to any User Content you post on
            Workshop, (1) you have the right to post such User Content, and (2)
            such User Content, or its use by Workshop as contemplated by the
            Agreements, does not violate the Agreements, applicable law, or the
            intellectual property (including without limitation copyright),
            publicity, personality, or other rights of others or imply any
            affiliation with or endorsement of you or your User Content by
            Workshop or any mentor, entity or individual without express written
            consent from such individual or entity.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            Workshop may, but has no obligation to, monitor, review, or edit
            User Content. In all cases, Workshop reserves the right to remove or
            disable access to any User Content for any or no reason, including
            but not limited to, User Content that, in Workshop’s sole
            discretion, violates the Agreements. Workshop may take these actions
            without prior notification to you or any third party. Removal or
            disabling of access to User Content shall be at our sole discretion,
            and we do not promise to remove or disable access to any specific
            User Content.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            You are solely responsible for all User Content that you post.
            Workshop is not responsible for User Content nor does it endorse any
            opinion contained in any User Content. YOU AGREE THAT IF ANYONE
            BRINGS A CLAIM AGAINST WORKSHOP RELATED TO USER CONTENT THAT YOU
            POST, THEN, TO THE EXTENT PERMISSIBLE UNDER LOCAL LAW, YOU WILL
            INDEMNIFY AND HOLD WORKSHOP HARMLESS FROM AND AGAINST ALL DAMAGES,
            LOSSES, AND EXPENSES OF ANY KIND (INCLUDING REASONABLE LAWYER FEES
            AND COSTS) ARISING OUT OF SUCH CLAIM.
          </chakra.span>
        </P>
        <H>
          <chakra.span>Rights You Grant Us</chakra.span>
        </H>
        <P>
          <chakra.span>
            In consideration for the rights granted to you under the Agreements,
            you grant us the right (1) to allow the Workshop Service to use the
            processor, bandwidth, and storage hardware on your Device in order
            to facilitate the operation of the Service, (2) to provide
            advertising and other information to you. In any part of the
            Workshop Service, the Content you view, including its selection and
            placement, may be influenced by commercial considerations, including
            agreements with third parties. Some Content licensed or provided to
            Workshop may contain advertising as part of the Content.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            If you provide feedback, ideas or suggestions to Workshop in
            connection with the Workshop Service of Content (“Feedback”), you
            acknowledge that the Feedback is not confidential and you authorize
            Workshop to use that Feedback without restriction and without
            payment to you. Feedback is considered a type of User Content.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            You grant Workshop a non-exclusive, transferable, sub-licensable,
            royalty-free, perpetual (or, in jurisdictions where this is not
            permitted, for a term equal to the duration of the Agreement plus
            twenty (20) years), irrevocable, fully paid, worldwide license to
            use, reproduce, make available to the public (e.g. perform or
            display), publish, translate, modify, create derivative works from,
            and distribute any of your User Content in connection with the
            Service through any medium, whether alone or in combination with
            other content or materials, in any manner and by any means, method
            or technology, whether now known or hereafter created. Aside from
            the rights specifically granted herein, you retain ownership of all
            rights, including intellectual property rights, in the User Content.
            Where applicable and permitted under applicable law, you also agree
            to waive any “moral rights” (or the equivalent under applicable law)
            such as your right to be identified as the author of any User
            Content, including Feedback and your right to object to derogatory
            treatment of such User Content.
          </chakra.span>
        </P>
        <H>
          <chakra.span>Infringement and Reporting User Content</chakra.span>
        </H>
        <P>
          <chakra.span>
            Workshop respects the rights of intellectual property owners. If you
            believe that any Content infringes your intellectual property rights
            or other rights. If Workshop is notified by a copyright holder that
            any Content infringes a copyright, Workshop may in its absolute
            discretion take actions without prior notification to the provider
            of that Content. If the provider believes that the content is not
            infringing, the provider may submit a counter-notification to
            Workshop with a request to restore the removed content.
          </chakra.span>
        </P>
        <P>
          If you believe that any Content does not comply with the{' '}
          <A href="#acceptable-use-policy">Acceptable Use Policy</A>, please{' '}
          <chakra.span>
            <A href={`mailto:support${PLATFORM_EMAIL}`}>contact us</A>
          </chakra.span>
          <chakra.span>.</chakra.span>
        </P>
        <H>
          <chakra.span>Service Limitations and Modifications</chakra.span>
        </H>
        <P>
          <chakra.span>
            Workshop will make reasonable efforts to keep the Workshop Service
            operational. However, certain technical difficulties or maintenance
            may, from time to time, result in temporary interruptions. To the
            extent permissible under applicable law, Workshop reserves the
            right, periodically and at any time, to modify or discontinue,
            temporarily or permanently, functions and features of the Workshop
            Service, with or without notice, all without liability to you,
            except where prohibited by law, for any interruption, modification,
            or discontinuation of the Workshop Service or any function or
            feature thereof. You understand, agree, and accept that Workshop has
            no obligation to maintain, support, upgrade, or update the Service,
            or to provide all or any specific content through the Service. This
            section will be enforced to the extent permissible by applicable
            law. Workshop and/or the owners of any Content may, from time to
            time, remove any such Content without notice to the extent permitted
            by applicable law.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            Workshop may modify the Service or discontinue their availability at
            any time.
          </chakra.span>
        </P>
        <H id="community">
          <chakra.span>Workshop Community</chakra.span>
        </H>
        <P>
          The Workshop Community is a place for discussions and exchange of
          information, tips, and other materials related to the Workshop
          Service. If you do not agree to the Agreements you may not use the
          Workshop Community.
        </P>
        <P>
          <chakra.span>
            By using the Workshop Community, you confirm that any registration
            information that you submit to Workshop is true, accurate, and
            complete and that you will update such information in order to keep
            it current. It is strictly prohibited to include information in your
            profile that suggests that you are a Workshop employee or moderator
            or to otherwise pose as such an employee or moderator when using the
            Workshop Community. You also acknowledge and agree that Workshop may
            remove or reclaim your username at any time if Workshop, in its
            absolute discretion, considers such action appropriate.
          </chakra.span>
        </P>
        <H>
          <chakra.span>No Official Support</chakra.span>
        </H>
        <P>
          No User Content or other content posted by Workshop employees,
          moderators and/or representatives on the Workshop Community should be
          construed as official support provided by Workshop. For details
          regarding official support, see the section{' '}
          <chakra.span>
            <A href="#customer-support">Customer Support</A>
          </chakra.span>
          <chakra.span>
            . Any content provided or made available to you on the Workshop
            Support Community by Workshop employees, moderators, and/or
            representatives is provided on an “as is” basis without warranties
            of any kind.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            You acknowledge that opinions express in User Content on the
            Workshop Support Community are those of contributors of such User
            Content only and do not reflect the opinions or policies of Workshop
            or any of its officers, shareholders, employees, agents, directors,
            subsidiaries, affiliates, suppliers, or licensors.
          </chakra.span>
        </P>
        <H id="customer-support">
          <chakra.span>Customer Support</chakra.span>
        </H>
        <P>
          For customer support with account-related and payment-related
          questions (“Customer Support Queries”), please submit a support ticket
          to our customer service team using the{' '}
          <chakra.span>
            <A href="https://help.workshop.ws/hc/en-us/requests/new">
              Customer Service contact form
            </A>
          </chakra.span>
          <chakra.span>
            . We will use reasonable endeavours to respond to all Customer
            Support Queries within a reasonable time frame and/or that we will
            be able to satisfactorily answer any such queries.
          </chakra.span>
        </P>
        <H>
          <chakra.span>Education Research</chakra.span>
        </H>
        <P>
          <chakra.span>
            Workshop is committed to advancing the science of learning and
            teaching, and records of your participation in courses may be used
            for education research. In the interest of this research, you may be
            exposed to variations in the course content. Research findings will
            typically be reported at the aggregate level. Your personal identity
            will not be publicly disclosed in any research findings without your
            express consent.
          </chakra.span>
        </P>
        <H>
          <chakra.span>Pricing</chakra.span>
        </H>
        <P>
          <chakra.span>
            Pricing of paid Services (e.g., courses) is determined by Workshop
            and the Mentor. You agree to pay the fees for the Content that you
            purchase, and hereby authorize us to charge your credit card for
            such fees. If your credit card is declined, you agree to pay us the
            fees within thirty (30) days of notification from us, and pay (at
            our discretion) a late payment charge of 1.5% per month or the
            maximum permitted by law, whichever is greater. If your card is
            declined we may choose to limit your access to the platform and/or
            the course being purchased.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            Fees may vary based on your location and other factors, and Workshop
            reserves the right to change any fees at any time at its sole
            discretion. Any change, update, or modification will be effective
            immediately upon posting through the relevant Services.
          </chakra.span>
        </P>
        <H>
          <chakra.span>Payment</chakra.span>
        </H>
        <P>
          <chakra.strong>
            <chakra.span>European Union Users:</chakra.span>
          </chakra.strong>{' '}
          By purchasing Content, you are confirming that you want the Content
          immediately credited to your User Account and that by doing so, you
          are hereby waiving any and all cancellation rights under applicable
          laws, including but not limited to the EU Consumer Rights Directive
          (2011/83/EU) and/or any national laws implementing it. Notwithstanding
          the foregoing, you will still be eligible for the 14 day refund right
          as set out in the <A href="#refund-policy">Refund Policy</A>
          <chakra.span>.</chakra.span>
        </P>
        <P>
          <chakra.span>
            <chakra.strong>All Other Users:</chakra.strong> Refunds may be
            available as described in our{' '}
            <A href="#refund-policy">Refund Policy</A>.
          </chakra.span>
        </P>
        <H>
          <chakra.span>Instalment Payments</chakra.span>
        </H>
        <P>
          <chakra.span>
            When purchasing certain course Content, you may have the choice to
            pay for the course using an instalment plan. When entering into an
            instalment plan, you are entering into a 0% APR instalment loan
            which is to be paid according to the payment plan terms specified at
            the time of purchasing.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            The first instalment will be charged to your credit or debit card
            immediately upon your election to purchase the Content. The second
            instalment will automatically be charged to your credit or debit
            card 1 month (30-days) after the initial payment. Any subsequent
            payments will be taken 1 month (30-days) after the preceeding
            payment.{' '}
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            If you do not pay all of the instalments in full accordance with the
            above, you will lose access to the Content and only be entitled to a
            refund in accordance with our{' '}
            <A href="#refund-policy">Refund Policy</A>. Workshop has no
            obligation to offer refunds to students who fail to fully pay their
            instalment plan.
          </chakra.span>
        </P>
        <H>
          <chakra.span>Payment Processing</chakra.span>
        </H>
        <P>
          <chakra.span>
            Outside of the mobile applications, Workshop uses the 3rd party
            payment platform, Stripe, the Stripe API, and in conjunction, your
            Stripe account to process credit and debit card transactions for
            your Workshop account. Workshop is a “Partner Application” as
            defined in the Stripe Terms of Service.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            By using Workshop and agreeing to the Workshop Terms or Service, you
            also agree to be bound by Stripe’s Terms of Service.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            Purchases through the iOS application are processed by Apple and
            will show in your Apple account.
          </chakra.span>
        </P>
        <H>
          <chakra.span>Taxes</chakra.span>
        </H>
        <P>
          <chakra.span>European Union:</chakra.span>
          <chakra.span>
            {' '}
            In the event that the sale or delivery of a course to any Student in
            the European Union is subject to any value added tax (“VAT”), under
            applicable law, Workshop will collect and remit the VAT to the
            competent tax authorities for sales of such Content to Students in
            the European Union. Workshop may at its own discretion increase the
            Sale Price where Workshop is of the view that VAT may be due and
            Workshop will have a liability to account for such. You will
            indemnify and hold Workshop harmless against any and all claims by
            any tax authority for any underpayment of VAT, and any penalties
            and/or interest thereon.
          </chakra.span>
        </P>
        <P>
          <chakra.span>All Other Countries:</chakra.span> For sales of any
          courses in countries other than the European Union, as a Mentor, you
          are responsible for remitting the taxes to the appropriate taxing
          authority (which may be different to the tax authority in your own
          location). Workshop is unable to provide you with tax advice and you
          should consult your own tax advisor.
        </P>
        <H>
          <chakra.span>Term and Termination</chakra.span>
        </H>
        <P>
          The Agreements will continue to apply to you until terminated by
          either you or Workshop. However, you acknowledge and agree that the
          perpetual license granted by you in relation to User Content,
          including Feedback, is irrevocable and will therefore continue after
          expiry or termination of any of the Agreements for any reason.
          Workshop may terminate the Agreements or suspend your access to the
          Workshop Service at any time, including in the event of actual or
          suspected unauthorised use of the Workshop Service and/or Content, or
          non-compliance with the Agreements. If you or Workshop terminate the
          Agreements, or if Workshop suspends your access to the Workshop
          Service, you agree that Workshop shall have no liability or
          responsibility to you and Workshop will not refund any amounts that
          you have already paid, to the fullest extent permitted under
          applicable law. To learn how to terminate your Workshop account,
          please contact us through the{' '}
          <chakra.span>
            <A href="https://help.workshop.ws/hc/en-us/requests/new">
              Customer Service contact form
            </A>
          </chakra.span>
          <chakra.span>
            . This section will be enforced to the extent permissible by
            applicable law. You may terminate the Agreements at any time.
          </chakra.span>
        </P>
        <H>
          <chakra.span>Warranty &amp; Disclaimer</chakra.span>
        </H>
        <P>
          <chakra.span>
            WE ENDEAVOUR TO PROVIDE THE BEST SERVICE WE CAN, BUT YOU UNDERSTAND
            AND AGREE THAT THE WORKSHOP SERVICE IS PROVIDED “AS IS” AND “AS
            AVAILABLE”, WITHOUT EXPRESS OR IMPLIED WARRANTY OR CONDITION OF ANY
            KIND. YOU USE THE WORKSHOP SERVICE AT YOUR OWN RISK. TO THE FULLEST
            EXTENT PERMITTED BY APPLICABLE LAW, WORKSHOP AND ALL OWNERS OF THE
            CONTENT MAKE NO REPRESENTATIONS AND DISCLAIM ANY WARRANTIES OR
            CONDITIONS OF SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, OR NON-INFRINGEMENT. NEITHER WORKSHOP NOR ANY
            OWNER OF CONTENT WARRANTS THAT THE WORKSHOP SERVICE IS FREE OF
            MALWARE OR OTHER HARMFUL COMPONENTS. IN ADDITION, WORKSHOP MAKES NO
            REPRESENTATION NOR DOES IT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
            RESPONSIBILITY FOR ANY THIRD PARTY APPLICATIONS (OR THE CONTENT
            THEREOF), USER CONTENT, OR ANY OTHER PRODUCT OR SERVICE ADVERTISED
            OR OFFERED BY A THIRD PARTY ON OR THROUGH THE WORKSHOP SERVICE OR
            ANY HYPERLINKED WEBSITE, OR FEATURED IN ANY BANNER OR OTHER
            ADVERTISING. YOU UNDERSTAND AND AGREE THAT WORKSHOP IS NOT
            RESPONSIBLE OR LIABLE FOR ANY TRANSACTION BETWEEN YOU AND THIRD
            PARTY PROVIDERS OF THIRD PARTY APPLICATIONS OR PRODUCTS OR SERVICES
            ADVERTISED ON OR THROUGH THE WORKSHOP SERVICE. AS WITH ANY PURCHASE
            OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT,
            YOU SHOULD USE YOUR JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
            NO ADVICE OR INFORMATION WHETHER ORAL OR IN WRITING OBTAINED BY YOU
            FROM WORKSHOP SHALL CREATE ANY WARRANTY ON BEHALF OF WORKSHOP IN
            THIS REGARD. SOME ASPECTS OF THIS SECTION MAY NOT APPLY IN SOME
            JURISDICTIONS IF PROHIBITED BY APPLICABLE LAW.
          </chakra.span>
        </P>
        <P>THIS DOES NOT AFFECT YOUR STATUTORY RIGHTS AS A CONSUMER.</P>
        <H>
          <chakra.span>Limitation</chakra.span>
        </H>
        <P>
          <chakra.span>
            YOU AGREE THAT, TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOUR SOLE
            AND EXCLUSIVE REMEDY FOR ANY PROBLEMS OR DISSATISFACTION WITH THE
            WORKSHOP SERVICE IS TO UNINSTALL ANY WORKSHOP SOFTWARE AND TO STOP
            USING THE WORKSHOP SERVICE. WHILE WORKSHOP ACCEPTS NO RESPONSIBILITY
            FOR THIRD PARTY APPLICATIONS OR THE CONTENT THEREOF, AND WHILE YOUR
            RELATIONSHIP WITH SUCH THIRD PARTY APPLICATIONS MAY BE GOVERNED BY
            SEPARATE AGREEMENTS WITH SUCH THIRD PARTIES, TO THE EXTENT PERMITTED
            BY APPLICABLE LAW, YOUR SOLE AND EXCLUSIVE REMEDY, AS WITH RESPECT
            TO WORKSHOP, FOR ANY PROBLEMS OR DISSATISFACTION WITH THIRD PARTY
            APPLICATIONS OR THE CONTENT THEREOF, IS TO UNINSTALL AND/OR STOP
            USING ANY SUCH THIRD PARTY APPLICATIONS.
          </chakra.span>
        </P>
        <P>
          TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL WORKSHOP, ITS
          OFFICERS, SHAREHOLDERS, EMPLOYEES, AGENTS, DIRECTORS, SUBSIDIARIES,
          AFFILIATES, SUCCESSORS, ASSIGNS, SUPPLIERS, OR LICENSORS BE LIABLE FOR
          (1) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, OR
          CONSEQUENTIAL DAMAGES; (2) ANY LOSS OF USE, DATA, BUSINESS, OR PROFITS
          (WHETHER DIRECT OR INDIRECT), IN ALL CASES ARISING OUT OF THE USE OR
          INABILITY TO USE THE WORKSHOP SERVICE, THIRD PARTY APPLICATIONS, OR
          THIRD PARTY APPLICATION CONTENT, REGARDLESS OF LEGAL THEORY, WITHOUT
          REGARD TO WHETHER WORKSHOP HAS BEEN WARNED OF THE POSSIBILITY OF THOSE
          DAMAGES, AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE; OR (3)
          AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE WORKSHOP SERVICE,
          THIRD PARTY APPLICATIONS, OR THIRD PARTY APPLICATION CONTENT MORE THAN
          THE AMOUNTS PAID BY YOU TO WORKSHOP DURING THE PRIOR TWELVE MONTHS IN
          QUESTION, TO THE EXTENT PERMISSIBLE BY APPLICABLE LAW.
        </P>
        <P>
          <chakra.span>
            Nothing in the Agreements removes or limits Workshop’s liability for
            fraud, fraudulent misrepresentation, death or personal injury caused
            by its negligence, and, if required by applicable law, gross
            negligence. Some aspects of this section may not apply in some
            jurisdictions if prohibited by applicable law.
          </chakra.span>
        </P>
        <H>
          <chakra.span>Third Party Rights</chakra.span>
        </H>
        <P>
          <chakra.span>
            You acknowledge and agree that the owners of the Content and certain
            distributors (such as app store providers) are intended
            beneficiaries of the Agreements and have the right to enforce the
            Agreements directly against you. Other than as set out in this
            section, the Agreements are not intended to grant rights to anyone
            except you and Workshop, and in no event shall the Agreements create
            any third party beneficiary rights. Furthermore, the rights to
            terminate, rescind, or agree to any variation, waiver, or settlement
            of the Agreements are not subject to the consent of any other
            person.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            If you have downloaded the App from the Apple, Inc. (“Apple”) App
            Store or if you are using the App on an iOS device, you acknowledge
            that you have read, understood, and agree to the following notice
            regarding Apple. This Agreement is between you and Workshop only,
            not with Apple, and Apple is not responsible for the Service and the
            content thereof. Apple has no obligation whatsoever to furnish any
            maintenance and support services with respect to the Service. In the
            event of any failure of the Service to conform to any applicable
            warranty, then you may notify Apple and Apple will refund any
            applicable purchase price for the App to you; and, to the maximum
            extent permitted by applicable law, Apple has no other warranty
            obligation whatsoever with respect to the Service. Apple is not
            responsible for addressing any claims by you or any third party
            relating to the Service or your possession and/or use of the
            Service, including, but not limited to: (1) product liability
            claims; (2) any claim that the Service fails to conform to any
            applicable legal or regulatory requirement; and (3) claims arising
            under consumer protection or similar legislation. Apple is not
            responsible for the investigation, defense, settlement and discharge
            of any third party claim that the Service and/or your possession and
            use of the App infringe that third party’s intellectual property
            rights. You agree to comply with any applicable third party terms,
            when using the Service. Apple, and Apple’s subsidiaries, are third
            party beneficiaries of this Agreement, and upon your acceptance of
            this Agreement, Apple will have the right (and will be deemed to
            have accepted the right) to enforce this Agreement against you as a
            third party beneficiary of this Agreement. You hereby represent and
            warrant that (1) you are not located in a country that is subject to
            a U.S. Government embargo, or that has been designated by the U.S.
            Government as a “terrorist supporting” country; and (2) you are not
            listed on any U.S. Government list of prohibited or restricted
            parties.
          </chakra.span>
        </P>
        <H id="entire-agreement">
          <chakra.span>Entire Agreement</chakra.span>
        </H>
        <P>
          <chakra.span>
            Other than as stated in this section or as explicitly agreed upon in
            writing between you and Workshop, the Agreements constitute all the
            terms and conditions agreed upon between you and Workshop and
            supersede any prior agreements in relation to the subject matter of
            these Agreements, whether written or oral.
          </chakra.span>
        </P>
        <H>
          <chakra.span>Severability and Waiver</chakra.span>
        </H>
        <P>
          <chakra.span>
            Unless as otherwise stated in the Agreements, should any provision
            of the Agreements be held invalid or unenforceable for any reason or
            to any extent, such invalidity or enforceability shall not in any
            manner affect or render invalid or unenforceable the remaining
            provisions of the Agreements, and the application of that provision
            shall be enforced to the extent permitted by law.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            Any failure by Workshop or any third party beneficiary to enforce
            the Agreements or any provision thereof shall not waive Workshop’s
            or the applicable third party beneficiary’s right to do so.
          </chakra.span>
        </P>
        <H>
          <chakra.span>Assignment</chakra.span>
        </H>
        <P>
          <chakra.span>
            Workshop may assign the Agreements or any part of them, and Workshop
            may delegate any of its obligations under the Agreements. You may
            not assign the Agreements or any part of them, nor transfer or
            sublicense your rights under the Agreements, to any third party.
          </chakra.span>
        </P>
        <H>
          <chakra.span>Indemnification</chakra.span>
        </H>
        <P>
          <chakra.span>
            To the fullest extent permitted by applicable law, you agree to
            indemnify and hold Workshop harmless from and against all damages,
            losses, and expenses of any kind (including reasonable lawyer fees
            and costs) arising out of: (1) your breach of this Agreement; (2)
            any User Content; (3) any activity in which you engage on or through
            the Workshop Service; and (4) your violation of any law or the
            rights of a third party.
          </chakra.span>
        </P>
        <H>
          <chakra.span>Contact Us</chakra.span>
        </H>
        <P>
          If you have any questions concerning the Workshop Service or the
          Agreements, please contact{' '}
          <chakra.span>
            <A href="https://help.workshop.ws/hc/en-us/requests/new">
              Workshop customer service
            </A>
          </chakra.span>
          <chakra.span>.</chakra.span>
        </P>
        <P>
          Thank you for reading our Terms. We hope you enjoy learning with
          Workshop!
        </P>
      </Box>
      <Divider />
      <Box listStylePosition="inside" mx={{ base: 'defaultMargin', md: 0 }}>
        <H as="h2" fontSize="3xl" id="acceptable-use-policy">
          Acceptable Use Policy
        </H>
        <P>
          <chakra.em>Effective as of 29 November, 2018</chakra.em>
        </P>
        <P>
          <chakra.span>
            Workshop respects intellectual property rights and expects you to do
            the same. We’ve established a few ground rules for you to follow
            when using the Service, to make sure Workshop stays enjoyable for
            everyone. Please follow these rules and encourage other Users to do
            the same.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            The following is not permitted for any reason whatsoever:
          </chakra.span>
        </P>
        <chakra.ul listStylePosition="outside">
          <chakra.li>
            <chakra.span>
              Copying, redistributing, reproducing, “ripping”, recording,
              transferring, performing or displaying to the public,
              broadcasting, or making available to the public any part of the
              Workshop Service or the Content, or otherwise making any use of
              the Workshop Service or the Content which is not expressly
              permitted under the Agreements or applicable law or which
              otherwise infringes the intellectual property rights (such as
              copyright) in the Workshop Service or the Content or any part of
              it;
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Transferring copies of cached Content from an authorized Device to
              any other Device via any means;
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Reverse-engineering, decompiling, disassembling, modifying, or
              creating derivative works based on the Workshop Service, Content
              or any part thereof unless permitted by applicable law;
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Circumventing any technology used by Workshop, its licensors, or
              any third party to protect the Content or the Service.
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Selling, renting, sublicensing or leasing of any part of the
              Workshop Service or the Content;
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Circumventing any territorial restrictions applied by Workshop or
              its licensors;
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Removing or altering any copyright, trademark, or other
              intellectual property notices contained on or provided through the
              Workshop Service (including for the purpose of disguising or
              changing any indications of the ownership or source of any
              Content);
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Providing your password to any other person or using any other
              person’s username and password; or
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              “Crawling” the Workshop Service or otherwise using any automated
              means (including bots, scrapes, and spiders) to collect
              information from Workshop;
            </chakra.span>
            <chakra.span></chakra.span>
          </chakra.li>
        </chakra.ul>
        <P>
          <chakra.span>
            Please respect Workshop the owners of the Content and other users of
            the Workshop Service. Don’t engage in any activity, post any User
            Content, or register and/or use a username which is or includes
            material that:
          </chakra.span>
        </P>
        <chakra.ul listStylePosition="outside">
          <chakra.li>
            <chakra.span>
              Is offensive, abusive, defamatory, pornographic, threatening, or
              obscene;
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Is illegal, or intended to promote or commit an illegal act of any
              kind, including but not limited to violations of intellectual
              property rights, privacy rights, or proprietary rights of Workshop
              or a third party;
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Includes your password or purposely includes any other user’s
              password or purposely includes personal data of third parties or
              is intended to solicit such personal data;
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Includes malicious content such as malware, Trojan horses, or
              viruses, or otherwise interferes with any user’s access to the
              Service;
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Is intended to or does harass or bully other users;
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Impersonates or misrepresents your affiliation with another user,
              person, or entity, or is otherwise fraudulent, false, deceptive,
              or misleading;
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Uses automated means to artificially promote content;
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Involves commercial or sales activities, such as advertising,
              promotions, contests, sweepstakes, or pyramid schemes, that are
              not expressly authorized by Workshop;
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Links to, references, or otherwise promotes commercial products or
              services, except as expressly authorized by Workshop;
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Interferes with or in any way disrupts the Workshop Service,
              tampers with, breaches, or attempts to probe, scan, or test for
              vulnerabilities in the Service or Workshop’s computer systems,
              network, usage rules, or any of Workshop’s security components,
              authentication measures or any other protection measures
              applicable to the Service, the Content or any part thereof; or
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              Conflicts with the Agreements, as determined by Workshop.
            </chakra.span>
          </chakra.li>
        </chakra.ul>
        <P>
          <chakra.span>
            You acknowledge and agree that posting any such User Content may
            result in immediate termination or suspension of your Workshop
            account. You also agree that Workshop may also reclaim your username
            for any reason.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            Your password protects your user account, and you are solely
            responsible for keeping your password confidential and secure. You
            understand that you are responsible for all use of your username and
            password on the Service. If your username or password is lost or
            stolen, or if you believe there has been unauthorized access to your
            account by third parties, please notify us immediately and change
            your password as soon as possible.
          </chakra.span>
        </P>
      </Box>
      <Divider />
      <Box listStylePosition="inside" mx={{ base: 'defaultMargin', md: 0 }}>
        <H as="h2" fontSize="3xl" id="refund-policy">
          Refund Policy
        </H>
        <P>
          <chakra.em>Effective as of 29 November, 2018</chakra.em>
        </P>
        <P>
          <chakra.span>
            {`For details on our refund deadlines and policies, please refer to
            the information below${
              PLATFORM === 'workshop'
                ? `; note that our policies differ for
            "mini-course" and course purchases, and that payment options may
            vary from one to another. Please also`
                : `. Please`
            } note that we treat violations
            of our Terms of Service and `}{' '}
            <A href="#code-of-conduct">Code of Conduct</A> very seriously, and
            we have no obligation to offer refunds to students who are found to
            be in violation of these terms, even if their requests are made
            within the designated refund period. Similarly we have no obligation
            to offer late refunds to students who do not pass a course, or who
            are otherwise unsatisfied with their final grade.
          </chakra.span>
        </P>
        {PLATFORM === 'workshop' && (
          <>
            <H>
              <chakra.span>For "Mini-course" Purchases</chakra.span>
            </H>
            <P>
              <chakra.span>
                Workshop offers Students a fourteen 14-day money back guarantee
                on mini-courses that are purchased on the Workshop website
                (https://app.workshop.ws/). If you, as a Student, are unhappy
                with such a course and request a refund within 14 (14) days of
                the date that you paid for access to that course, we will
                provide you with a full refund of the amount you paid.
              </chakra.span>
            </P>
            <P>
              <chakra.span>
                Notwithstanding the foregoing, if you purchase a course through
                a third party marketplace (e.g., in-app purchases through the
                Apple App Store or Google Play Store), the refund policy
                applicable to that third party marketplace will apply, unless
                otherwise explicitly state by Workshop. Except by otherwise
                explicitly stated by Workshop, the third party marketplace will
                be solely responsible for making refunds under its refund
                policy, and Workshop will have no refund obligations. Workshop
                disclaims any responsibility or liability related to any third
                party marketplace’s refund policy or the third party’s
                compliance or noncompliance with such policy.
              </chakra.span>
            </P>
            <P>
              <chakra.span>
                Please note that if we believe that you are abusing our refund
                policy in our sole discretion, we reserve the right to suspend
                or terminate your User Account and refuse or restrict any and
                all current or future use of Workshop, without any liability to
                You.
              </chakra.span>
            </P>
          </>
        )}

        <H>
          <chakra.span>For Course Purchases</chakra.span>
        </H>
        <P>
          <chakra.span>
            If you cancel your paid enrollment for a course, Workshop will offer
            you a complete refund until 30 days after your payment, unless the
            payment has been made within 30 days of the start date of the
            course, in which case Workshop will offer you a complete refund
            until 14 days after your payment.{' '}
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            Notwithstanding the foregoing, shorter refund periods may apply to
            specific courses. In these cases, the shorter period will be stated
            as part of the sign-up process for the course.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            By paying for a course before your course start date, your refund
            eligibility period will start on the payment date, not the course’s
            start date. Any feature access periods will start on the course’s
            start date, not on your payment date.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            By opting to pay for a course using instalments, your refund
            eligibility period will start on the payment date of the initial
            deposit.
          </chakra.span>
        </P>
        <H>
          <chakra.span>How to Request a Refund</chakra.span>
        </H>
        <P>
          <chakra.span>
            <chakra.span>
              To request a refund, contact us at{' '}
              <A
                href={`mailto:support${PLATFORM_EMAIL}`}
              >{`support${PLATFORM_EMAIL}`}</A>{' '}
              with your email address and query. Alternatively you can request a
              refund through the support functionality within the mobile
              application.
            </chakra.span>
          </chakra.span>
        </P>
        <H>
          <chakra.span>When and How We Issue Refunds</chakra.span>
        </H>
        <P>
          <chakra.span>
            Upon determining that a refund is due to you under these Terms, we
            will issue such a refund within 14 days of our confirmation that
            such a refund is due to you.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            For Content purchased as a result of our refer-a-friend scheme, you
            are not eligible for a refund on the order for which the reward was
            offered.
          </chakra.span>
        </P>
        <P>
          <chakra.span>
            If you are entitled to a refund we will refund you on the credit
            card or debit card used by you to pay for the Content.
          </chakra.span>
        </P>
      </Box>
      <Divider />
      <Box mx={{ base: 'defaultMargin', md: 0 }}>
        <H as="h2" fontSize="3xl" id="code-of-conduct">
          Code of Conduct
        </H>
        <P>
          <chakra.em>Effective as of 29 November, 2018</chakra.em>
        </P>
        <P>
          <chakra.span>
            All students participating in a course must agree to abide by the
            following code of conduct:
          </chakra.span>
        </P>
        <chakra.ol>
          <chakra.li>
            <chakra.span>I will register for only one account.</chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              My answers to homework, quizzes, exams, projects, and other
              assessments will be my own work (except for assessments that
              explicitly permit collaboration).
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              I will not make solutions to homework, quizzes, exams, projects
              and other assessments available to anyone else (except to the
              extent an assessment explicitly permits sharing solutions). This
              includes both solutions from me, as well as any solutions provided
              by the course staff or others.
            </chakra.span>
          </chakra.li>
          <chakra.li>
            <chakra.span>
              I will not engage in any other activities that will dishonestly
              improve my results or dishonestly improve or hurt the results of
              others.
            </chakra.span>
          </chakra.li>
        </chakra.ol>
        <P>
          <chakra.span></chakra.span>
        </P>
      </Box>
    </ScreenWrapper>
  );
};

export default TermsScreen;
