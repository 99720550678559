// --- Uncomment the desired environment --- //
// export const ENV = 'local';
// export const ENV = 'dev';
export const ENV = 'prod';

type Env = 'local' | 'dev' | 'prod';

type Platform = 'steppit' | 'workshop';

// export const LOCAL_PLATFORM: Platform = 'steppit';
export const LOCAL_PLATFORM: Platform = 'workshop';

export const PLATFORM = process.env.REACT_APP_PLATFORM
  ? (process.env.REACT_APP_PLATFORM as Platform)
  : LOCAL_PLATFORM;

export const BACKEND_ENV = process.env.REACT_APP_BACKEND_ENV
  ? (process.env.REACT_APP_BACKEND_ENV as Env)
  : ENV;

// --- This is the root file for all API calls --- //

export const urlMap = {
  // URL for local development
  local: {
    // baseUrl: 'http://100.96.1.18:8000',
    baseUrl: 'http://localhost:8000',
    // baseUrl: 'http://192.168.1.240:8000',
    discourseUrl: 'http://localhost:9292',
  },
  // URL for development
  dev: {
    baseUrl:
      PLATFORM === 'steppit'
        ? 'https://api-dev.steppit.com'
        : 'https://app-dev.workshop.ws',
    discourseUrl: 'https://discourse-dev.workshop.ws',
  },
  // URL for production
  prod: {
    baseUrl:
      PLATFORM === 'steppit'
        ? 'https://api.steppit.com'
        : 'https://app.workshop.ws',
    discourseUrl: 'https://community.workshop.ws',
  },
};

/** Max number of requests allowed in parallel via discourse API */
export const MAX_DISCOURSE_REQUESTS = 5;

// Default API prefix
// @ts-ignore
export const baseUrl = urlMap[BACKEND_ENV].baseUrl;
export const api = baseUrl + '/api/v1.1';
export const discourseUrl = urlMap[BACKEND_ENV].discourseUrl;
export const helpUrl =
  PLATFORM === 'workshop'
    ? 'https://help.workshop.ws/'
    : 'https://www.steppit.com/resources/help';
export const blogUrl = 'https://www.steppit.com/resources/blog';
export const discordUrl = 'https://discord.gg/hyBhApmxeB';
export const aiFeatureUrl =
  'https://www.steppit.com/resources/features/ai-course-creator';

// Public API Keys
export const stripePublicKey: string =
  process.env.REACT_APP_STRIPE_PUBLIC_KEY || 'pk_test_XWqTYpXSKSFUq9USPsYNubsu';

// Full production mode
export const FRONT_ENV =
  process.env.NODE_ENV === 'production' && BACKEND_ENV === 'prod'
    ? 'production'
    : 'development';
