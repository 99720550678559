import React from 'react';
import { saveAs } from 'file-saver';

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Button,
  LinkButton,
  ListItem,
  Text,
  UnorderedList,
} from '@workshop/ui';

import { DetailedUnitNotes, DetailedUnitCheckLists } from 'models/learner';

export const ChecklistModalBody: React.FC<{
  checklists: DetailedUnitCheckLists;
}> = ({ checklists }) => (
  <Flex flexDir="column">
    {checklists
      .flatMap((c) => c.checkList)
      .map(({ items, title, id }, idx) => (
        <Accordion key={id} allowToggle allowMultiple={false} width="100%">
          <AccordionItem borderTopWidth={0}>
            {({ isExpanded }) => (
              <>
                <AccordionButton paddingY={3} _focus={{ boxShadow: 'none' }}>
                  <Flex flex="1" textAlign="left">
                    <Text fontWeight="semibold" fontSize="lg">
                      {title}
                    </Text>
                  </Flex>
                  <AccordionIcon ml={1} color="text.muted" />
                </AccordionButton>
                <AccordionPanel padding={0}>
                  <Divider mb={0} />
                  <UnorderedList padding="defaultPadding">
                    {items.map(({ description, title }) => (
                      <ListItem>{title}</ListItem>
                    ))}
                  </UnorderedList>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      ))}
  </Flex>
);

export const DownloadsModalBody: React.FC<{
  downloads: { name: string; link: string; teacherOnly: boolean }[];
  onClickVideo: (video: string) => void;
}> = ({ downloads, onClickVideo }) => {
  return (
    // Play videos in a modal, download PDFs and open links in a new tab
    <Flex flexDir="column" padding="defaultPadding">
      {downloads.map(({ name, link }, idx) => {
        const extension = link.split('.').pop();
        const googleDocRegex =
          /^https:\/\/docs\.google\.com\/document\/d\/.*\/export\?format=pdf$/;
        if (extension === 'mp4') {
          return (
            <Button
              key={idx}
              onClick={() => onClickVideo(link)}
              icon="PlayArrow"
              secondary
              mt={idx === 0 ? 0 : 2}
              height="auto"
              minHeight={10}
              whiteSpace="inherit"
              py={2}
            >
              {name}
            </Button>
          );
        } else if (extension === 'pdf' && !googleDocRegex.test(link)) {
          return (
            <Button
              key={idx}
              onClick={() => {
                // Download PDF
                saveAs(link, name);
              }}
              icon="GetApp"
              secondary
              mt={idx === 0 ? 0 : 2}
              height="auto"
              minHeight={10}
              whiteSpace="inherit"
              py={2}
            >
              {name}
            </Button>
          );
        }
        return (
          <LinkButton
            key={idx}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            icon="OpenInNew"
            secondary
            mt={idx === 0 ? 0 : 2}
            height="auto"
            minHeight={10}
            whiteSpace="inherit"
            py={2}
          >
            {name}
          </LinkButton>
        );
      })}
    </Flex>
  );
};

export const NotesModalBody: React.FC<{ notes: DetailedUnitNotes }> = ({
  notes,
}) => (
  <Flex flexDir="column">
    {notes.map(({ title, steps, moduleType }, idx) => (
      <Accordion key={idx} allowToggle allowMultiple={false} width="100%">
        <AccordionItem borderTopWidth={0}>
          <>
            <AccordionButton paddingY={3} _focus={{ boxShadow: 'none' }}>
              <Flex flex="1" textAlign="left">
                <Text fontWeight="semibold" fontSize="lg">
                  {title}
                </Text>
              </Flex>
              <AccordionIcon ml={1} color="text.muted" />
            </AccordionButton>
            <AccordionPanel padding={0}>
              <Divider mb={0} />
              <Flex flexDir="column" padding="defaultPadding">
                {steps.map(({ title, notes, stepType }, stepIdx) => {
                  const index =
                    stepType === 'normal' && steps.length > 1
                      ? steps[0].stepType === 'intro'
                        ? stepIdx
                        : stepIdx + 1
                      : null;
                  return (
                    <Flex
                      key={`step-notes-${stepIdx}`}
                      flexDir="column"
                      mb="defaultMargin"
                    >
                      <Text fontWeight="bold" mb={2}>
                        {index ? `Step ${index} : ${title}` : title}
                      </Text>
                      <Flex flexDir="column">
                        {notes.map((note, noteIdx) => (
                          <Box key={`note-${stepIdx}-${noteIdx}`}>
                            {notes.length > 1
                              ? `${noteIdx + 1}. ${note}`
                              : note}
                          </Box>
                        ))}
                      </Flex>
                    </Flex>
                  );
                })}
              </Flex>
            </AccordionPanel>
          </>
        </AccordionItem>
      </Accordion>
    ))}
  </Flex>
);
