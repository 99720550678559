import React from 'react';
import { Flex } from '@workshop/ui';

import { NAV_HEIGHT } from 'containers/AppHeader';

export const ScreenWrapper: React.FC<any> = ({
  children,
  mb = 'defaultScreenY',
}) => {
  return (
    <Flex
      direction="column"
      flex={1}
      alignItems="center"
      minH={`calc(100vh - ${NAV_HEIGHT}px - ${NAV_HEIGHT}px)`}
    >
      <Flex
        flex={1}
        width={{ base: '100%', md: '85%', xl: '70%', '2xl': '70%' }}
        maxWidth="1200px"
        flexDirection="column"
        mb={mb}
      >
        {children}
      </Flex>
    </Flex>
  );
};

ScreenWrapper.displayName = 'ScreenWrapper';
