import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { getParamFromUrl, analytics } from 'utils';

import { PLATFORM } from 'constants/env';

import { Flex, Heading, Text, LinkButton } from '@workshop/ui';
import navRoutes from 'navigation/Routes';

import { SignupData } from 'types/common';
import { authActions } from 'redux/actions/common';
import { SignupCard } from 'screens/common/Signup/SignupForm';
import { BrandLg } from 'components/Brand';

const SignupScreen: React.FC = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const emailParam = getParamFromUrl(location, 'email');

  const onSubmit = async ({
    email,
    marketingConsent,
    name,
    password1,
  }: SignupData) => {
    await dispatch(
      authActions.signupRequest({
        email,
        marketingConsent,
        name,
        password1,
      })
    ).then((res) => {
      if (!res.error) {
        dispatch(
          authActions.tokenRequest({ username: email, password: password1 })
        );
        const firstName = name.split(' ')[0];
        const lastName =
          name.split(' ').length > 1 ? name.replace(`${firstName} `, '') : '';
        analytics.identify({
          set: {
            first_name: firstName,
            last_name: lastName,
            email: email,
            marketing_consent: Boolean(marketingConsent),
          },
        });
        analytics.track('Signed Up', {
          first_name: firstName,
          last_name: lastName,
          marketing_consent: Boolean(marketingConsent),
          email: email,
          type: 'signup',
        });
        analytics.logConversion('signedUp');
      }
    });
  };

  return (
    <Flex
      position="absolute"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100vw"
      minHeight="100vh"
      py={12}
      backgroundColor="background.tint3"
    >
      <Link to="/">
        <BrandLg mb={10} />
      </Link>
      <SignupCard
        onSubmit={onSubmit}
        hideSocialButtons={PLATFORM === 'steppit'}
        {...(emailParam ? { email: emailParam } : {})}
      >
        <Heading
          as="h1"
          fontSize="2xl"
          fontWeight="bold"
          mb={6}
          textAlign="center"
        >
          {emailParam ? 'Join Your Course' : 'Create Your Account'}
        </Heading>
      </SignupCard>
      <Flex
        mt={4}
        py={4}
        alignItems="center"
        maxWidth={450}
        width="100%"
        paddingX={{ base: 4, md: 0 }}
        flexDirection={{ base: 'column', sm: 'row' }}
        borderTopWidth="1px"
        borderBottomWidth="1px"
        borderColor="border.default"
      >
        <Text flex={1} mb={{ base: 2, sm: 0 }}>
          Already have an account?
        </Text>
        <LinkButton variant="outline" to={navRoutes.public.login.path()}>
          Log In
        </LinkButton>
      </Flex>
    </Flex>
  );
};

export default SignupScreen;
