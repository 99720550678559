import React from 'react';
import { Flex, Spinner } from '@workshop/ui';

export const Loading = () => (
  <Flex h="100%" w="100%" justifyContent="center" alignItems="center">
    <Spinner size="xl" color="common.primary" thickness="4px" />
  </Flex>
);

export const LoadingScreen = () => (
  <Flex h="100vh" w="100%" backgroundColor="background.tint2">
    <Loading />
  </Flex>
);
