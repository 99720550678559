import React, { useState } from 'react';

import { Flex, MdIcon, Text, Stack, Box } from '@workshop/ui';

import { PLATFORM } from 'constants/env';

import { StepsModal } from 'components/Common';

interface PublishCourseModalProps {
  isOpen: boolean;
  onClose: () => void;
  onPublish: (accessChoice: 'public' | 'private') => void;
  showConfetti?: boolean;
}

const PublishCourseModal: React.FC<PublishCourseModalProps> = ({
  isOpen,
  onClose,
  onPublish,
  showConfetti = false,
}) => {
  const [accessChoice, setAccessChoice] = useState<'public' | 'private' | null>(
    null
  );
  const accessChoices: {
    name: string;
    icon: string;
    slug: 'public' | 'private';
  }[] = [
    {
      name: 'Start a Private Class',
      icon: 'GroupWork',
      slug: 'private',
    },
  ];
  if (PLATFORM === 'steppit') {
    accessChoices.push({
      name: 'Create a Public Link',
      icon: 'Public',
      slug: 'public',
    });
  }

  return (
    <StepsModal
      heading="Let's Do This"
      isOpen={isOpen}
      onClose={onClose}
      modalSize="xl"
      showConfetti={showConfetti}
      // hideStepLabels={!isMobile}
      onCompleteStep={async (stepIndex: number) => {
        if (stepIndex === 1 && accessChoice) {
          onPublish(accessChoice);
        }
      }}
      steps={[
        {
          label: 'Ready to Go?',
          icon: <MdIcon name="Celebration" />,
          nextButtonText: 'Next',
          content: (
            <Box pt={6} pb={{ base: 6, md: 0 }}>
              <Text mb={2}>
                If you're happy with how your course is looking and are ready to
                launch, then that's the biggest step <b>DONE</b>. Congrats! 🥳
              </Text>
              <Text color="text.muted" fontSize="sm" mb={4}>
                (Don't worry if it's not 100% perfect, you can come back and
                tweak it any time)
              </Text>
              <Text>
                Now comes the fun bit – let's get your course published and in
                front of some students...
              </Text>
            </Box>
          ),
        },
        {
          label: 'Start Sharing',
          icon: <MdIcon name="Groups" />,
          nextButtonDisabled: !accessChoice,
          nextButtonText:
            accessChoice === 'public'
              ? 'Publish & Create Link'
              : accessChoice === 'private'
              ? 'Publish & Start Class'
              : 'Publish',
          content: (
            <Box pt={6} pb={{ base: 6, md: 0 }}>
              {PLATFORM === 'steppit' ? (
                <>
                  <Text mb={2}>
                    If you want to actively guide and support your students as
                    they run through your course, you can now create private
                    classes so that small groups can take it together.
                  </Text>
                  <Text mb={4}>
                    You can also just make your course available on a public
                    link so that you can freely share the content with anyone
                    who wants it.
                  </Text>
                  <Text fontWeight="semibold" mb={4}>
                    Which option do you want to start with?
                  </Text>
                </>
              ) : null}
              <Stack direction="row">
                {accessChoices.map((ac) => (
                  <Flex
                    key={`accessChoice-${ac.slug}`}
                    flex={1}
                    borderWidth={2}
                    borderColor={
                      ac.slug === accessChoice
                        ? 'common.primary'
                        : 'border.muted'
                    }
                    backgroundColor={
                      ac.slug === accessChoice
                        ? 'background.default'
                        : 'background.tint1'
                    }
                    borderRadius="md"
                    p={4}
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    cursor="pointer"
                    _hover={{ backgroundColor: 'background.default' }}
                    onClick={() => setAccessChoice(ac.slug)}
                    // {...(mt.disabled
                    //   ? {
                    //       pointerEvents: 'none',
                    //       opacity: 0.5,
                    //     }
                    //   : {})}
                  >
                    <Box>
                      <MdIcon
                        name={ac.icon}
                        boxSize={6}
                        color="common.primary"
                      />
                    </Box>
                    <Box mt={1}>
                      <Text
                        fontSize="md"
                        fontWeight="bold"
                        color="common.primary"
                      >
                        {ac.name}
                      </Text>
                    </Box>
                  </Flex>
                ))}
              </Stack>
            </Box>
          ),
        },
      ]}
    />
  );
};

export default PublishCourseModal;
