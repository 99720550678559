import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  RouteComponentProps,
  withRouter,
  matchPath,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  useTeams,
  useCurrentTeam,
  usePermissions,
  useIsStaff,
  useIsImpersonated,
  useHasRole,
  useCurrentTeamProfile,
} from 'redux/selectors';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import {
  Box,
  LinkBox,
  LinkOverlay,
  Flex,
  Text,
  Spinner,
  useTheme,
} from '@workshop/ui';
import Brand from 'components/Brand';
import ActiveRoomsBanner from 'components/ActiveRoomsBanner';

import { PLATFORM } from 'constants/env';
import { PRO_ORGS } from 'constants/organisation';
import {
  IMPERSONATED_BANNER_HEIGHT,
  ACTIVE_ROOMS_BANNER_HEIGHT,
} from 'constants/ui';
import { hooks } from 'utils';
import { GlobalState } from 'types';
import { organisationActions } from 'redux/actions/common';
import { stopImpersonating } from 'redux/actions/common/auth';
import { clear404Pathname } from 'redux/actions/common/ui';

import { AppHeader, AppHeading } from 'containers/AppHeader';
import AppSidebarContainer from 'containers/AppSidebar';
import { PageForbiddenScreen } from 'screens/common/PageForbidden';
import { PageNotFoundScreen } from 'screens/common/PageNotFound';

import navRoutes, { Route as RouteType } from './Routes';

import PrivateMessaging from './PrivateMessaging';

interface RouteRendererProps {
  routes: RouteType[];
  userPermissions: string[];
  pathname404: string | null;
}

const RouteRenderer = ({
  routes,
  userPermissions,
  pathname404,
}: RouteRendererProps) => {
  const isOwner = useHasRole('owner');
  const isAdmin = useHasRole('admin');
  const currentTeamProfile = useCurrentTeamProfile();
  const currentTeam = useCurrentTeam();
  const isPro = Boolean(
    currentTeamProfile?.isPro || (currentTeam && PRO_ORGS.includes(currentTeam))
  );
  return (
    <Switch>
      {routes.map((route, idx) => {
        let { path, component: Component, permissions } = route;

        const show404 =
          pathname404 &&
          matchPath(pathname404, {
            path: path(),
            exact: true,
            strict: false,
          });

        if (show404)
          return (
            <Route
              key={`route-${route.path()}_${idx}`}
              exact
              path={path()}
              component={PageNotFoundScreen}
            />
          );

        /**
         * User can only edit a screen if they have the relevant permission(s)
         */
        const canEdit = permissions?.canEdit?.some((p) =>
          userPermissions.includes(p)
        );

        /**
         * User can view a screen if:
         * - they can edit (see above)
         * - there are no specific canView permissions defined for this route
         * - the user has the relevant view permissions
         * - (for CMS paths on Steppit) if the channel isn't Pro,
         *   the user must be the owner of the channel or an admin
         */

        let canView =
          canEdit ||
          !permissions?.canView ||
          permissions.canView.some((p: string) => userPermissions.includes(p));

        const isCmsPath = Object.values(navRoutes.cms).find((r) =>
          matchPath(path(), {
            path: r.path(),
            exact: true,
            strict: false,
          })
        );
        if (
          PLATFORM === 'steppit' &&
          isCmsPath &&
          !isAdmin &&
          !isOwner &&
          !isPro
        ) {
          canView = false;
        }

        return canView ? (
          <Route
            key={`route-${route.path()}_${idx}`}
            exact
            path={path()}
            render={(props) => <Component {...props} />}
          />
        ) : (
          <Route
            key={`route-${route.path()}_${idx}`}
            exact
            path={path()}
            render={(props) => (
              <PageForbiddenScreen name={route.name} {...props} />
            )}
          />
        );
      })}
      <Route component={PageNotFoundScreen} />
    </Switch>
  );
};

interface ImpersonatedUserBannerProps extends RouteComponentProps {
  userName: string;
}

const _ImpersonatedUserBanner: React.FC<ImpersonatedUserBannerProps> = ({
  history,
  userName,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      h={`${IMPERSONATED_BANNER_HEIGHT}px`}
      position="fixed"
      top="0"
      w="100%"
      zIndex={1200}
    >
      <Flex
        background="linear-gradient(90deg,rgba(255,255,255,0.7) 0%,rgba(252,151,52,1) 30%,rgb(239 186 37) 50%,rgba(252,151,52,1) 70%,rgba(255,255,255,0) 100%);"
        h="100%"
        justifyContent="center"
        alignItems="center"
        w="100%"
      >
        <Text fontSize="xs" color="white" fontWeight="bold">
          {`Currently logged in as: ${userName}`}
        </Text>
        <Text fontSize="xs" color="white" fontWeight="bold" mx={2}>
          |
        </Text>
        {loading ? (
          <Spinner size="xs" color="white" />
        ) : (
          <Text
            color="white"
            fontSize="xs"
            fontWeight="bold"
            cursor="pointer"
            onClick={() => {
              setLoading(true);
              dispatch(stopImpersonating()).then((response) => {
                localStorage.removeItem('defaultTeam');
                window.location.reload();
              });
            }}
          >
            Stop Impersonating
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

const ImpersonatedUserBanner = withRouter(_ImpersonatedUserBanner);

interface PrivateRoutesProps extends RouteComponentProps {}

const PrivateRoutes = ({ location, history }: PrivateRoutesProps) => {
  const dispatch = useDispatch();

  const myTeams = useTeams();
  const currentTeam = useCurrentTeam();
  const isStaff = useIsStaff();
  const userPermissions = usePermissions();
  const isImpersonated = useIsImpersonated();

  const userName = useSelector(
    (state: GlobalState) => state.user.userDetails.name
  );
  const pathname404 = useSelector(
    (state: GlobalState) => state.ui.show404ForPath
  );
  const activeAssessment = useSelector(
    (state: GlobalState) => state.user.userProfile.activeAssessment
  );
  const activeRooms = useSelector(
    (state: GlobalState) => state.user.userProfile.activeRooms
  );

  const windowDimensions = useWindowDimensions();

  const [sidebarMinimised, setSidebarMinimised] = useState(false);

  const currentRoute = hooks.useCurrentRoute();

  const theme = useTheme();

  useEffect(() => {
    // Collapse/Expand the sidebar based on the current route settings and window width.
    //
    // As the user changes pages and the value of `currentRoute` changes,
    // we look for the default sidebar state within the extracted Route
    // definition and show/hide the sidebar depending on the value.
    if (
      (windowDimensions.width < parseInt(theme.breakpoints.lg, 10) &&
        !sidebarMinimised) ||
      (currentRoute?.sibarDefaultMinimised && !sidebarMinimised)
    ) {
      setSidebarMinimised(true);
      return;
    }

    if (
      windowDimensions.width >= parseInt(theme.breakpoints.lg, 10) &&
      !currentRoute?.sibarDefaultMinimised &&
      sidebarMinimised
    ) {
      setSidebarMinimised(false);
    }
  }, [
    currentRoute?.sibarDefaultMinimised,
    currentRoute?.path,
    windowDimensions.width,
  ]);

  useEffect(() => {
    // We only want to keep any reference to a 404 error
    // for a given pathname while the user is still on that path
    // --> if they navigate to a different page, clear the 404 error
    if (pathname404) {
      dispatch(clear404Pathname());
    }
    const isLearnerPath = Object.values(navRoutes.learner).find((r) =>
      matchPath(location.pathname, {
        path: r.path(),
        exact: true,
        strict: false,
      })
    );
    const isCmsPath = Object.values(navRoutes.cms).find((r) =>
      matchPath(location.pathname, {
        path: r.path(),
        exact: true,
        strict: false,
      })
    );

    const hasTeams = myTeams.length > 0;
    const hasCurrentTeam = typeof currentTeam === 'number' && currentTeam > 0;

    if (hasTeams) {
      if (isLearnerPath && hasCurrentTeam) {
        // If the user lands on a "learner" page and has a current team set,
        // the team should be cleared to set everything to "learner" mode
        dispatch(organisationActions.setCurrentTeam(null));
      }
      if (isCmsPath && !hasCurrentTeam) {
        // If their current team isn't set and they land on a CMS page,
        // send them home to select their team
        history.push(navRoutes.common.home.path());
      }
    } else {
      if (isCmsPath) {
        // If the user doesn't have any teams and lands on a CMS page,
        // send them home
        history.push(navRoutes.common.home.path());
      }
    }

    const isAssessmentPath =
      matchPath(location.pathname, {
        path: navRoutes.learner.assessment.path(),
        exact: true,
        strict: false,
      }) ||
      matchPath(location.pathname, {
        path: navRoutes.learner.assessmentModule.path(),
        exact: true,
        strict: false,
      });

    const isCmsCoursePath = matchPath(location.pathname, {
      path: navRoutes.cms.editCourse.path(),
      exact: false,
      strict: false,
    });
    const isCmsSessionPath = matchPath(location.pathname, {
      path: navRoutes.cms.session.path(),
      exact: false,
      strict: false,
    });
    if (isCmsCoursePath && !isCmsCoursePath.isExact && !isCmsSessionPath) {
      // If landing on unknown CMS course URL, redirect to course page
      history.push(isCmsCoursePath.url);
    }

    if (activeAssessment?.course && isLearnerPath && !isAssessmentPath) {
      // If the user has an active assessment and lands on any non-assessment
      // learner page, redirect them to the assessment
      history.push(navRoutes.learner.assessment.path(activeAssessment.course));
    }
  }, [location.pathname, activeAssessment]);

  const routes = [
    ...(isStaff ? Object.values(navRoutes.admin) : []),
    ...(isStaff || myTeams.length > 0 ? Object.values(navRoutes.cms) : []),
    ...Object.values(navRoutes.learner),
    ...Object.values(navRoutes.common),
  ];

  const sidebarWidth = sidebarMinimised ? '52px' : '228px';

  let topPosition = 0;
  if (isImpersonated) {
    topPosition += IMPERSONATED_BANNER_HEIGHT;
  }
  if (activeRooms?.length > 0) {
    topPosition += ACTIVE_ROOMS_BANNER_HEIGHT;
  }

  return (
    <>
      {isImpersonated && (
        <>
          <ImpersonatedUserBanner userName={userName} />
          <Box mb={`${IMPERSONATED_BANNER_HEIGHT}px`} />
        </>
      )}
      {activeRooms?.length > 0 && (
        <>
          <ActiveRoomsBanner />
          <Box mb={`${ACTIVE_ROOMS_BANNER_HEIGHT}px`} />
        </>
      )}
      <AppHeader topPosition={topPosition} />
      <Flex backgroundColor="background.tint3">
        {currentRoute?.hideSidebar ? (
          <Box
            position={{ base: 'fixed', md: 'absolute' }}
            top={0.5}
            left={0.5}
            zIndex={1100}
          >
            <LinkBox flex={1} padding={3}>
              <LinkOverlay href="/">
                <Brand navbarTransparent={false} />
              </LinkOverlay>
            </LinkBox>
          </Box>
        ) : (
          <AppSidebarContainer
            sidebarWidth={sidebarWidth}
            sidebarMinimised={sidebarMinimised}
            onSidebarToggle={() => setSidebarMinimised(!sidebarMinimised)}
          />
        )}
        <PrivateMessaging />
        <Flex flex={1} direction="column">
          <AppHeading />
          <RouteRenderer
            routes={routes}
            userPermissions={userPermissions}
            pathname404={pathname404}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default withRouter(PrivateRoutes);
