import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { simplifyName, emojifyText } from 'utils';

import {
  Image,
  Card,
  Divider,
  Box,
  Flex,
  Text,
  MdIcon,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
} from '@workshop/ui';

import { UserAvatar } from 'components/UserAvatar';

type ActivityFeedItemCardProps =
  | { isLoading: true }
  | {
      isLoading?: false;
      createdAt: string;
      imageUrl?: string;
      likeCount?: number;
      linkTo?: string;
      postsCount?: number;
      summary?: string;
      title?: string;
      userAvatar?: string;
      userName?: string;
      openInNewTab?: boolean;
    };

const defaultProps = {
  createdAt: '',
  imageUrl: undefined,
  likeCount: undefined,
  linkTo: undefined,
  postsCount: undefined,
  summary: 'Loading...',
  title: 'Loading...',
  userAvatar: undefined,
  userName: undefined,
  openInNewTab: false,
};

const ActivityFeedItemCard: React.FC<ActivityFeedItemCardProps> = (props) => {
  const {
    createdAt,
    imageUrl,
    likeCount,
    linkTo,
    postsCount,
    summary,
    title,
    userName,
    userAvatar,
    openInNewTab,
  } = props.isLoading ? defaultProps : props;

  const content = (
    <Card
      padding={0}
      flexDir="column"
      _hover={linkTo ? { background: 'background.tint1' } : {}}
    >
      {props.isLoading ? (
        <Flex flexDirection="row" padding={2}>
          <SkeletonCircle size="2rem" />
          <Skeleton flex={1} ml={2} />
        </Flex>
      ) : (
        <Flex alignItems="center" m={2}>
          {userName && userAvatar ? (
            <>
              <UserAvatar
                size="2xs"
                userId={0}
                name={userName}
                avatarPicture={userAvatar}
              />
              <Text ml={2} fontWeight="semibold" fontSize="sm">
                {simplifyName(userName)}
              </Text>
            </>
          ) : null}
          {createdAt && (
            <>
              <Flex flex={1} />
              <Text fontSize="sm" color="text.muted" pr={2}>
                {moment(createdAt).year() === moment().year()
                  ? moment(createdAt).fromNow()
                  : moment(createdAt).format('D MMM YYYY')}
              </Text>
            </>
          )}
        </Flex>
      )}
      <Divider />

      <Flex flexDir="column">
        {title ? (
          <Skeleton
            isLoaded={!props.isLoading}
            m="defaultPadding"
            loadingStyle={{ height: 20 }}
          >
            <Text fontWeight="semibold">{emojifyText(title)}</Text>
          </Skeleton>
        ) : null}

        {summary ? (
          <SkeletonText
            isLoaded={!props.isLoading}
            mx="defaultPadding"
            mb="defaultPadding"
            noOfLines={3}
          >
            <Text>{emojifyText(summary)}</Text>
          </SkeletonText>
        ) : null}
        {imageUrl && (
          <Image
            src={imageUrl}
            w="100%"
            h="auto"
            maxHeight="image.max"
            objectFit="cover"
          />
        )}
      </Flex>

      {!props.isLoading &&
        linkTo &&
        Boolean(likeCount) &&
        Boolean(postsCount && postsCount > 1) && (
          <Flex
            justifyContent="flex-end"
            alignItems="center"
            p="defaultPadding"
          >
            {Boolean(postsCount && postsCount > 1) && postsCount && (
              <Flex alignItems="center">
                <MdIcon color="text.muted" name="ChatBubble" mr={1} />
                <Text color="text.muted">{postsCount - 1}</Text>
              </Flex>
            )}
            <Flex alignItems="center" ml={postsCount ? 6 : 0}>
              {Boolean(likeCount) && (
                <>
                  <MdIcon color="text.muted" name="Favorite" mr={1} />
                  <Text color="text.muted">{likeCount}</Text>
                </>
              )}
            </Flex>
          </Flex>
        )}
    </Card>
  );

  if (linkTo && !openInNewTab) {
    return <Link to={linkTo}>{content}</Link>;
  }
  if (linkTo && openInNewTab) {
    return (
      <Box cursor="pointer" onClick={() => window.open(linkTo, '_blank')}>
        {content}
      </Box>
    );
  }
  return <Box>{content}</Box>;
};

export default ActivityFeedItemCard;
