import React from 'react';

import { Box, Flex, Text, MdIcon, FlexProps, Spinner } from '@workshop/ui';
import { colorUtils } from 'utils';

type UserAvatarSize = '3xs' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

interface UserAvatarProps extends FlexProps {
  avatarPicture?: string;
  canEdit?: boolean;
  name: string;
  onClick?: () => void;
  size?: UserAvatarSize;
  fallbackIcon?: React.ReactElement | null;
  userId: number;
  isLoading?: boolean;
}

const getFontSize = (size: UserAvatarSize): string => {
  switch (size) {
    case '2xs':
      return 'md';
    case 'xs':
      return 'lg';
    case 'sm':
      return '3xl';
  }
  return '5xl';
};

const UserAvatar: React.FC<UserAvatarProps> = ({
  avatarPicture,
  canEdit = false,
  name,
  onClick,
  size = 'sm',
  userId,
  fallbackIcon,
  isLoading = false,
  ...props
}) => {
  return (
    <Flex
      position="relative"
      alignItems="center"
      justifyContent="center"
      color="white"
      backgroundColor={
        userId
          ? `rgba(${colorUtils.getRandomColor(userId)})`
          : 'background.tint3'
      }
      backgroundImage={avatarPicture && `url(${avatarPicture})`}
      backgroundSize="cover"
      boxSize={`image.${size}`}
      minW={`image.${size}`}
      borderRadius="100%"
      onClick={!canEdit && !isLoading && onClick ? onClick : () => null}
      {...props}
    >
      {isLoading && (
        <>
          <Box
            position="absolute"
            w="100%"
            h="100%"
            backgroundColor="neutralAlpha.400"
            borderRadius="100%"
          />
          <Spinner position="absolute" w="20%" h="20%" />
        </>
      )}
      {!avatarPicture && name && (
        <Text fontSize={getFontSize(size)}>{name[0]?.toUpperCase()}</Text>
      )}
      {!avatarPicture && !name && fallbackIcon}
      {onClick && canEdit && size !== 'xs' && (
        <Flex
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          alignItems="flex-end"
          justifyContent="flex-end"
          cursor="pointer"
          onClick={onClick}
          role="group"
          borderRadius="100%"
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            boxSize={size === 'sm' ? 5 : 8}
            backgroundColor="background.default"
            border="1px"
            borderRadius="100%"
            borderColor="common.primary"
            _groupHover={{ backgroundColor: 'background.primary' }}
          >
            <MdIcon
              name="AddAPhoto"
              boxSize={size === 'sm' ? 3 : 4}
              color="common.primary"
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default UserAvatar;
