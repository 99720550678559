import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import navRoutes from 'navigation/Routes';

import { GlobalState } from 'types';
import { Module } from 'types/learner';

import { courseActions, stepQuestionActions } from 'redux/actions/learner';
import { useIsAuthenticated, useIsMentor } from 'redux/selectors';

import { hooks, getParamFromUrl } from 'utils';

import { Flex, Text, Card, useToast } from '@workshop/ui';

import { SessionPlayer } from 'components/SessionPlayer';
import { getCrumbs } from 'components/Breadcrumbs';
import { UserAvatar } from 'components/UserAvatar';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';
import { generateSteps } from 'screens/learner/Session/src/utils';

// Routing Props
interface MatchParams {
  courseId?: string;
  moduleId?: string;
  courseSlug?: string;
  moduleSlug?: string;
}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {
  session?: Module;
  asComponent?: boolean;
  onComplete?: () => void;
}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const Session: React.FC<Props> = ({
  courseId,
  courseSlug,
  course,
  location,
  history,
  module,
  moduleQuestions,
  asComponent,
  isPublic,
  isLocked,
  onComplete,
}) => {
  const stepId = getParamFromUrl(location, 'step');
  const toast = useToast();
  const isAuthenticated = useIsAuthenticated();
  const isMentor = useIsMentor();

  /** ---------------- DATA LOADING ---------------- */
  const { courseLoading } = hooks.useLoadingDataState(
    {
      courseLoading: {
        actions: courseId
          ? [() => courseActions.retrieve(courseId)]
          : courseSlug
          ? [() => courseActions.retrieve(courseSlug)]
          : [],
      },
    },
    [courseId, courseSlug]
  );

  const {
    checkList: [moduleChecklist],
    steps: moduleSteps = [],
    moduleFormat = 'guided',
    exerciseText = '',
    imagePortraitMobile = '',
  } = module || { checkList: [] };

  /**
   * Build an array containing the question IDs for all the steps included in this module
   * Fetch all these questions via the API
   */
  const questionIds = moduleSteps.reduce(
    (acc: number[], { questions }) =>
      questions && questions.length ? [...acc, ...questions] : [...acc],
    []
  );

  const { questionsLoading } = hooks.useLoadingDataState(
    {
      questionsLoading: {
        actions: questionIds.length
          ? [() => stepQuestionActions.list(questionIds)]
          : [],
        startLoading: !Boolean(courseLoading),
      },
    },
    [questionIds?.length, courseLoading]
  );

  if (courseLoading || questionsLoading) {
    if (asComponent) {
      return (
        <Flex flexDirection="column" mb={4} position="relative">
          <SessionPlayer
            loading
            onCompleteSession={async () => null}
            onSetOrienation={async () => null}
            onUnlockStep={async () => null}
            steps={[]}
            navigationStep={null}
            navigateToStep={() => null}
          />
        </Flex>
      );
    }
    return (
      <ScreenWrapper>
        <Flex flexDirection="column" mb={4} position="relative">
          <SessionPlayer
            loading
            onCompleteSession={async () => null}
            onSetOrienation={async () => null}
            onUnlockStep={async () => null}
            steps={[]}
            navigationStep={null}
            navigateToStep={() => null}
          />
        </Flex>
      </ScreenWrapper>
    );
  }

  if (!module || isLocked) {
    history.push(navRoutes.common.home.path());

    /** Returning early if no module found (e.g typo in module slug) */
    return null;
  }

  /** ---------------- CHECKLIST DATA ---------------- */
  const checkListItems = moduleChecklist?.items.map(
    ({ id, title: content, slug }) => {
      return {
        id,
        isChecked: true,
        content,
        slug,
      };
    }
  );

  const checklist = checkListItems
    ? {
        items: checkListItems,
        title: moduleChecklist.title,
      }
    : undefined;

  /** ---------------- STEPS DATA ---------------- */
  const steps = generateSteps({
    checklist,
    imagePortraitMobile,
    exerciseText,
    moduleFormat,
    moduleQuestions,
    steps: moduleSteps,
    unlocked: true,
  });

  const sessionPlayerComponent = (
    <Flex flexDirection="column" mb={4} position="relative">
      <SessionPlayer
        previewModeEnabled
        onCompleteSession={async () => {
          toast({
            title: 'Session Complete!',
            status: 'success',
            duration: 1500,
          });
          if (onComplete) {
            return onComplete();
          }

          const crumbs = getCrumbs(
            location.pathname,
            isAuthenticated,
            isMentor
          );
          const prevCrumb = crumbs[Math.max(crumbs.length - 2, 0)];
          const courseUrl = prevCrumb?.matchedPath?.url;

          if (courseUrl) {
            if (isPublic) {
              // TODO: Popup asking to sign up / enrol to save progress
              history.push({
                pathname: courseUrl,
              });
            } else {
              history.push({
                pathname: courseUrl,
              });
            }
          }
        }}
        onSetOrienation={async () => null}
        onUnlockStep={async () => null}
        requirements={checklist}
        showRequirements={Boolean(checklist)}
        steps={steps}
        navigationStep={stepId}
        pathname={location.pathname}
        navigateToStep={(idx: number) => {
          let currentSearchParams = new URLSearchParams(location.search);
          currentSearchParams.set('step', idx.toString());
          history.push({
            pathname: location.pathname,
            search: currentSearchParams.toString(),
          });
        }}
      />
    </Flex>
  );

  if (asComponent) return sessionPlayerComponent;

  return (
    <ScreenWrapper>
      {course && (
        <Flex mb={8} mt={-6} justifyContent="center" zIndex={5}>
          <Link to={navRoutes.global.channel.path(course.organisation.handle)}>
            <Card
              alignItems="center"
              padding={1}
              borderRadius="full"
              // backgroundColor={bg}
              backgroundColor="background.primary"
              transition="background-color 2s"
              boxShadow="none"
              _hover={{ opacity: 0.8 }}
            >
              <UserAvatar
                name={course.organisation.name}
                userId={course.organisation.id || 1}
                avatarPicture={course.organisation.logoDark || ''}
                size="2xs"
              />
              <Text
                fontWeight="semibold"
                ml={3}
                mr={3}
                // color={textColor}
                color="text.primary"
                transition="color 2s"
              >
                {course.organisation.name}
              </Text>
            </Card>
          </Link>
        </Flex>
      )}
      {sessionPlayerComponent}
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: GlobalState, props: OwnProps) => {
  const {
    courses: {
      moduleQuestions,
      modules: moduleState,
      courses: { detail: courseState },
    },
  } = state.learner;

  const courseId = props.match?.params.courseId;
  let moduleId = props.match?.params.moduleId;

  const courseSlug = props.match?.params.courseSlug;
  const moduleSlug = props.match?.params.moduleSlug;

  const course = courseSlug ? courseState[courseSlug] : undefined;

  if (course?.courseType === 'session' && !moduleId && !moduleSlug) {
    moduleId = course.standaloneModule.toString();
  }

  const module =
    props.session ||
    (moduleSlug
      ? moduleState[moduleSlug]
      : Object.values(moduleState).find((m) => m.id.toString() === moduleId));

  // TODO: Lock session if accessed via ID and not licensed or owned by user
  // const myCourses = ...
  // const licensedCourses = getLicensedCourses(
  //   courseState,
  //   licenseState,
  //   currentTeam.id
  // );

  const isPublic = course && course.isPublic;
  const isLocked = props.session
    ? false
    : courseSlug
    ? course
      ? !isPublic
      : true
    : false;

  return {
    courseId,
    courseSlug,
    course,
    module,
    moduleQuestions,
    isPublic,
    isLocked,
  };
};

const connector = connect(mapStateToProps);

export default connector(Session);
