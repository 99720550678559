export interface LoginData {
  username: string;
  password: string;
}

export interface SignupData {
  name: string;
  email: string;
  marketingConsent?: boolean;
  password1: string;
  source?: string;
  referral?: string;
}

export const PROVIDERS = ['facebook', 'apple'] as const;
export type ProviderTypes = typeof PROVIDERS[number];

export interface SocialProvider {
  connectUrl: string;
  id: ProviderTypes;
  loginUrl: string;
  name: string;
}

export interface SocialConnection {
  id: number;
  provider: ProviderTypes;
  name: string;
  avatar?: string;
}
