import uniqBy from 'lodash/uniqBy';
import { Action } from 'types';
import { OrganisationAT } from 'redux/actionTypes/common';
import {
  CurrentView,
  InvitationsState,
  RoleState,
  MyTeamsState,
  PermissionState,
  TeamMemberState,
  CurrentTeamState,
  OrganisationUIState,
  OrganisationState,
  OrganisationAction,
  OrgSubscriptionsState,
} from 'types/common';

const roleInitialState: RoleState = {};

export const roleReducer = (
  state = roleInitialState,
  action: Action
): RoleState => {
  switch (action.type) {
    case OrganisationAT.FETCH_ROLE_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.roles,
      };
    case OrganisationAT.FETCH_ROLE_REQUEST:
    case OrganisationAT.FETCH_ROLE_FAILURE:
    default:
      return state;
  }
};

const permissionInitialState: PermissionState = {};

export const permissionReducer = (
  state = permissionInitialState,
  action: Action
): PermissionState => {
  switch (action.type) {
    case OrganisationAT.FETCH_PERMISSION_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.permissions,
      };
    case OrganisationAT.FETCH_PERMISSION_REQUEST:
    case OrganisationAT.FETCH_PERMISSION_FAILURE:
    default:
      return state;
  }
};

const myTeamsInitialState: MyTeamsState = [];

export const myTeamsReducer = (
  state = myTeamsInitialState,
  action: Action
): MyTeamsState => {
  switch (action.type) {
    case OrganisationAT.FETCH_MY_TEAMS_SUCCESS:
      return action.payload.results;
    case OrganisationAT.FETCH_MY_TEAMS_REQUEST:
    case OrganisationAT.FETCH_MY_TEAMS_FAILURE:
    default:
      return state;
  }
};

export const currentTeamReducer = (
  state: CurrentTeamState = null,
  action: Action
): CurrentTeamState => {
  switch (action.type) {
    case OrganisationAT.SET_CURRENT_TEAM:
      return action.payload;
    default:
      return state;
  }
};

export const teamMemberReducer = (
  state: TeamMemberState = {},
  action: Action
): TeamMemberState => {
  switch (action.type) {
    case OrganisationAT.CREATE_TEAM_MEMBER_SUCCESS:
    case OrganisationAT.FETCH_TEAM_MEMBER_LIST_SUCCESS:
    case OrganisationAT.PATCH_TEAM_MEMBER_SUCCESS:
    case OrganisationAT.FETCH_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.teamMember,
      };
    case OrganisationAT.DELETE_TEAM_MEMBER_SUCCESS:
      return Object.keys(state).reduce(
        (newState: TeamMemberState, k) =>
          k === action.meta.memberId.toString()
            ? newState
            : { ...newState, [k]: state[k] },
        {}
      );
    case OrganisationAT.SET_CURRENT_TEAM:
      return Object.values(state).reduce(
        (newState: TeamMemberState, k) =>
          k.team !== action.payload
            ? newState
            : { ...newState, [k.id]: state[k.id] },
        {}
      );
    case OrganisationAT.CREATE_TEAM_MEMBER_REQUEST:
    case OrganisationAT.FETCH_TEAM_MEMBER_LIST_REQUEST:
    case OrganisationAT.CREATE_TEAM_MEMBER_FAILURE:
    case OrganisationAT.FETCH_TEAM_MEMBER_LIST_FAILURE:
    case OrganisationAT.PATCH_TEAM_MEMBER_REQUEST:
    case OrganisationAT.FETCH_TEAM_MEMBER_REQUEST:
    case OrganisationAT.PATCH_TEAM_MEMBER_FAILURE:
    case OrganisationAT.FETCH_TEAM_MEMBER_FAILURE:
    default:
      return state;
  }
};

export const organisationProfileReducer = (
  state: OrganisationState = {},
  action: Action
): OrganisationState => {
  switch (action.type) {
    case OrganisationAT.UPDATE_ORGANISATION_SUCCESS:
    case OrganisationAT.FETCH_ORGANISATION_SUCCESS:
      return {
        ...state,
        [action.payload.result]: {
          ...state[action.payload.result],
          ...action.payload.entities.organisations[action.payload.result],
        },
      };
    case OrganisationAT.CREATE_ORGANISATION_SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload,
        },
      };
    case OrganisationAT.UPDATE_ORGANISATION_REQUEST:
    case OrganisationAT.FETCH_ORGANISATION_REQUEST:
    case OrganisationAT.UPDATE_ORGANISATION_FAILURE:
    case OrganisationAT.FETCH_ORGANISATION_FAILURE:
    default:
      return state;
  }
};

const defaultUIState = {
  loading: false,
  error: false,
  errorPayload: null,
  errorMessage: null,
};

const updateUIState = (
  state: OrganisationUIState,
  action: OrganisationAction,
  type: 'request' | 'success' | 'failure',
  key: keyof OrganisationUIState,
  errorMessage?: string
): OrganisationUIState => {
  switch (type) {
    case 'request':
      return {
        ...state,
        [key]: {
          ...state[key],
          loading: !action.error,
        },
      };
    case 'success':
      return {
        ...state,
        [key]: defaultUIState,
      };
    case 'failure':
      return {
        ...state,
        [key]: {
          ...state[key],
          loading: false,
        },
      };
    default:
      return state;
  }
};

export const organisationUIReducer = (
  state: OrganisationUIState = {
    roles: defaultUIState,
    permissions: defaultUIState,
    organisation: defaultUIState,
    organisationList: defaultUIState,
  },
  action: Action
): OrganisationUIState => {
  switch (action.type) {
    case OrganisationAT.FETCH_ROLE_REQUEST:
      return updateUIState(state, action, 'request', 'roles');
    case OrganisationAT.FETCH_ROLE_SUCCESS:
      return updateUIState(state, action, 'success', 'roles');
    case OrganisationAT.FETCH_ROLE_FAILURE:
      return updateUIState(state, action, 'failure', 'roles');
    case OrganisationAT.FETCH_PERMISSION_REQUEST:
      return updateUIState(state, action, 'request', 'permissions');
    case OrganisationAT.FETCH_PERMISSION_SUCCESS:
      return updateUIState(state, action, 'success', 'permissions');
    case OrganisationAT.FETCH_PERMISSION_FAILURE:
      return updateUIState(state, action, 'failure', 'permissions');
    case OrganisationAT.CREATE_TEAM_MEMBER_REQUEST:
    case OrganisationAT.FETCH_TEAM_MEMBER_LIST_REQUEST:
      return updateUIState(state, action, 'request', 'organisationList');
    case OrganisationAT.CREATE_TEAM_MEMBER_SUCCESS:
    case OrganisationAT.FETCH_TEAM_MEMBER_LIST_SUCCESS:
      return updateUIState(state, action, 'success', 'organisationList');
    case OrganisationAT.CREATE_TEAM_MEMBER_FAILURE:
    case OrganisationAT.FETCH_TEAM_MEMBER_LIST_FAILURE:
      return updateUIState(
        state,
        action,
        'failure',
        'organisationList',
        'Could not retrieve teamAccesss'
      );
    case OrganisationAT.FETCH_TEAM_MEMBER_REQUEST:
    case OrganisationAT.PATCH_TEAM_MEMBER_REQUEST:
      return updateUIState(state, action, 'request', 'organisation');
    case OrganisationAT.FETCH_TEAM_MEMBER_SUCCESS:
    case OrganisationAT.PATCH_TEAM_MEMBER_SUCCESS:
      return updateUIState(state, action, 'success', 'organisation');
    case OrganisationAT.FETCH_TEAM_MEMBER_FAILURE:
    case OrganisationAT.PATCH_TEAM_MEMBER_FAILURE:
      return updateUIState(
        state,
        action,
        'failure',
        'organisation',
        'Could not retrieve teamAccesss'
      );
    default:
      return state;
  }
};

export const invitationsReducer = (
  state: InvitationsState = {},
  action: Action
): InvitationsState => {
  switch (action.type) {
    case OrganisationAT.FETCH_INVITATION_SUCCESS:
      const {
        result,
        entities: { memberInvitation },
      } = action.payload;
      return {
        ...state,
        [result.toString()]: memberInvitation[result],
      };
    case OrganisationAT.FETCH_INVITATION_LIST_SUCCESS:
      const invitations = action.payload.results.reduce(
        (acc: InvitationsState, r) => {
          return { ...acc, [r.id.toString()]: r };
        },
        {}
      );
      return { ...state, ...invitations };
    case OrganisationAT.DELETE_INVITATION_SUCCESS:
      return Object.keys(state).reduce(
        (newState: InvitationsState, k) =>
          k === action.meta.inviteId.toString()
            ? newState
            : { ...newState, [k]: state[k] },
        {}
      );
    default:
      return state;
  }
};

export const currentViewReducer = (
  state: CurrentView = 'learner',
  action: Action
): CurrentView => {
  switch (action.type) {
    case OrganisationAT.SET_CURRENT_VIEW:
      return action.payload;

    default:
      return state;
  }
};

export const orgSubscriptionsReducer = (
  state: OrgSubscriptionsState = [],
  action: Action
): OrgSubscriptionsState => {
  switch (action.type) {
    case OrganisationAT.FETCH_ORG_SUBSCRIPTIONS_SUCCESS: {
      const { payload } = action;
      return uniqBy([...state, ...payload.subscriptions], 'id');
    }
    default:
      return state;
  }
};
