import { BackgroundAT } from 'redux/actionTypes/common';

import { Action } from 'types';
import { BackgroundState } from 'types/common';

import { STATUS } from 'constants/background';

const initialState: BackgroundState = {
  uploads: {},
  location: {
    countryCode: '',
    continentCode: '',
    currency: '',
  },
};

export const backgroundReducer = (
  state = initialState,
  action: Action
): BackgroundState => {
  switch (action.type) {
    case BackgroundAT.START_CHUNK_UPLOAD: {
      // Add temporary upload item to indicate that the upload has started
      const { ...rest } = action.payload;
      return {
        ...state,
        uploads: {
          ...state.uploads,
          [`temp-${rest.filename}`]: {
            filename: '',
            filesize: 0,
            status: 'started',
            progress: 0,
            ...rest,
          },
        },
      };
    }
    case BackgroundAT.CREATE_CHUNK_UPLOAD: {
      // Remove temporary upload item when chunk upload is created
      const { id, ...rest } = action.payload;
      const { [`temp-${rest.filename}`]: temp, ...uploads } = state.uploads;
      return {
        ...state,
        uploads: {
          ...uploads,
          [id]: rest,
        },
      };
    }
    case BackgroundAT.UPDATE_CHUNK_UPLOAD: {
      const { id, ...rest } = action.payload;
      return {
        ...state,
        uploads: {
          ...state.uploads,
          [id]: {
            ...state.uploads[id],
            ...rest,
          },
        },
      };
    }
    case BackgroundAT.COMPLETE_UPLOAD_CHUNK_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        uploads: {
          ...state.uploads,
          [payload.id]: {
            ...state.uploads[payload.id],
            // Confirmation from the backend that the uplad is complete
            progress: 100,
          },
        },
      };
    }
    case BackgroundAT.CANCEL_CHUNK_UPLOAD: {
      return {
        ...state,
        uploads: {
          ...state.uploads,
          [action.payload]: {
            ...state.uploads[action.payload],
            status: STATUS.cancelled,
          },
        },
      };
    }
    case BackgroundAT.CLEAR_CHUNK_UPLOADS: {
      return {
        ...state,
        uploads: {},
      };
    }
    case BackgroundAT.SET_LOCATION: {
      return {
        ...state,
        location: action.payload,
      };
    }
    default:
      return state;
  }
};
