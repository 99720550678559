import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import isEmail from 'validator/lib/isEmail';

import styled, {
  Text,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Skeleton,
  VStack,
  Divider,
  useColorModeValue,
} from '@workshop/ui';

import navRoutes from 'navigation/Routes';
import { GlobalState } from 'types';

import { authActions, uiAction } from 'redux/actions/common';
import { useAuthError } from 'redux/selectors/auth';

import FacebookButton, {
  FacebookResponse,
} from 'components/SocialAuth/FacebookButton';
import AppleButton, { AppleResponse } from 'components/SocialAuth/AppleButton';

interface FormData {
  email: string;
  password: string;
}

const Form = styled.form`
  width: 100%;
`;

interface LoginFormProps {
  email?: string;
  isLoading?: boolean;
  onLoginSuccess?: () => void;
  hideSocialButtons?: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({
  email,
  isLoading: isLoadingProp = false,
  onLoginSuccess = () => null,
  hideSocialButtons = false,
}) => {
  const { register, handleSubmit, errors } = useForm<FormData>({
    defaultValues: { email },
  });
  const dispatch = useDispatch();

  const isLoading = useSelector(({ auth }: GlobalState) => auth.loading);
  const [facebookLoading, setFacebookLoading] = React.useState(false);
  const [appleLoading, setAppleLoading] = React.useState(false);

  const inputBg = useColorModeValue('background.default', 'background.tint3');

  const onSubmit = handleSubmit(({ email: formEmail, password }) => {
    dispatch(
      authActions.tokenRequest({ username: email || formEmail, password })
    ).then((res) => {
      if (!res.error) {
        onLoginSuccess();
      }
    });
  });

  const handleFacebookLogin = async (result: FacebookResponse) => {
    if ('status' in result) {
      // TODO: Handle error - should be handled by onError
      return;
    }

    setFacebookLoading(true);

    const { accessToken } = result;
    const response = await dispatch(
      authActions.socialLogin('facebook', { accessToken })
    );

    setFacebookLoading(false);

    if (!response.error) {
      onLoginSuccess();
    }
  };

  const handleAppleLogin = async (result: AppleResponse) => {
    if ('error' in result) {
      // TODO: Handle error - should be handled by onError
      return;
    }

    // TODO: TODO: Don't know how to handle
    if ('code' in result) return;

    setAppleLoading(true);
    const { code, id_token } = result.authorization;

    // When the user signs in via Apple for the first time their
    // user details are included in the response from Apple.
    //
    // In all subsequery requests, their details are omitted. In
    // this scenario the user will receive an error when trying
    // to login with Apple if we weren't successful in creating
    // their account the first time around
    //
    // TODO: Come up with a better way of handling the above?
    let userDetails: {
      name: { firstName: string | null; lastName: string | null };
      email: string | null;
    } = {
      name: { firstName: null, lastName: null },
      email: null,
    };

    if (result.user) {
      const { user } = result;
      userDetails = { name: user.name, email: user.email };
    }

    const response = await dispatch(
      authActions.socialLogin('apple', {
        accessToken: code,
        idToken: id_token,
        ...userDetails,
      })
    );

    setAppleLoading(false);

    if (!response.error) {
      onLoginSuccess();
    }
  };

  const { errorMessage } = useAuthError();

  const handleChange = () => {
    if (errorMessage) {
      dispatch(authActions.clearAuthError);
    }
  };

  return (
    <Flex flex={1} width="100%">
      <Form onSubmit={onSubmit}>
        <Flex flexDirection="column" flex={1}>
          <Skeleton isLoaded={!isLoadingProp}>
            <Flex flexDirection="column" mb={2}>
              {email ? (
                <Text textAlign="center" color="text.muted">
                  {email}
                </Text>
              ) : (
                <FormControl isInvalid={Boolean(errors.email)}>
                  <Input
                    ref={register({
                      required: true,
                      validate: (value) => isEmail(value),
                    })}
                    onChange={handleChange}
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    bg={inputBg}
                    _placeholder={{
                      color: 'text.muted',
                    }}
                  />
                  <FormErrorMessage>
                    Please enter a valid email address.
                  </FormErrorMessage>
                </FormControl>
              )}
            </Flex>
          </Skeleton>
          <Flex flexDirection="column" mb={2}>
            <FormControl isInvalid={Boolean(errors.password)}>
              <Input
                ref={register({
                  required: {
                    value: true,
                    message: 'Please enter your password.',
                  },
                  minLength: {
                    value: 6,
                    message: 'Your password must be at least 6 characters',
                  },
                })}
                onChange={handleChange}
                id="password"
                name="password"
                type="password"
                placeholder="Password"
                mt={2}
                bg={inputBg}
                _placeholder={{
                  color: 'text.muted',
                }}
              />
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>
          </Flex>
          <FormControl isInvalid={Boolean(errorMessage)}>
            <FormErrorMessage>{errorMessage}</FormErrorMessage>
          </FormControl>
          <Button
            variant="solid"
            colorScheme="blue"
            type="submit"
            textTransform="initial"
            mt={2}
            fontSize="md"
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            Log In
          </Button>
          <Divider marginY="defaultMargin" />
          {!hideSocialButtons && (
            <VStack spacing="defaultMargin" justifyContent="center">
              <FacebookButton
                isLoading={facebookLoading}
                isDisabled={facebookLoading}
                callback={handleFacebookLogin}
                onError={() =>
                  dispatch(
                    uiAction.errorToastMessage(
                      'Unable to log in. Please try again.'
                    )
                  )
                }
              />
              <AppleButton
                isLoading={appleLoading}
                isDisabled={appleLoading}
                callback={handleAppleLogin}
                onError={() =>
                  dispatch(
                    uiAction.errorToastMessage(
                      'Unable to log in. Please try again.'
                    )
                  )
                }
              />
            </VStack>
          )}
          <Text
            as={Link}
            textAlign="center"
            mt="defaultMargin"
            fontSize="sm"
            color="common.primary"
            fontWeight="semibold"
            to={navRoutes.public.forgotPassword.path()}
          >
            Forgot your password?
          </Text>
        </Flex>
      </Form>
    </Flex>
  );
};

export default LoginForm;
