import React, { useEffect, useCallback, useMemo, useState } from 'react';
import isHotkey from 'is-hotkey';
import { Editable, withReact, Slate, ReactEditor } from 'slate-react';
import { Editor, Transforms, createEditor, Node, Descendant } from 'slate';
import { withHistory } from 'slate-history';
import sanitizeHtml from 'sanitize-html';
import { slateToHtml, htmlToSlate } from 'slate-serializers';

import { Box, TextareaProps } from '@workshop/ui';

import { IconTooltip, Tooltip } from 'components/IconTooltip';

import { Element, Leaf, toggleMark, Toolbar } from './components';

const allowedTags = [
  'a',
  'br',
  'em',
  'h1',
  'h2',
  'h3',
  'h4',
  'i',
  'li',
  'ol',
  'p',
  'span',
  'strong',
  'ul',
  'u',
];

const sanitizeMarkup = (markup: string) =>
  sanitizeHtml(markup, { allowedTags }).replace(/\n/g, '');

// @refresh reset
const HOTKEYS: { [hotkey: string]: string } = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

export interface RichTextEditorProps extends Omit<TextareaProps, 'onChange'> {
  name: string;
  isDisabled?: boolean;
  defaultValue?: string;
  onChange: (name: string, markup: string, defaultValue?: string) => void;
  reset?: boolean;
  tooltip?: Tooltip;
}

const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

const RTEditor: React.FC<RichTextEditorProps> = ({
  name,
  isDisabled = false,
  defaultValue = '',
  onChange,
  reset,
  placeholder,
  tooltip,
  ...rest
}) => {
  const [value, setValue] = useState<Node[]>(
    defaultValue ? htmlToSlate(defaultValue) : initialValue
  );
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const [focused, setFocused] = React.useState(false);
  const savedSelection = React.useRef(editor.selection);

  useEffect(() => {
    if (reset) {
      setValue(defaultValue ? htmlToSlate(defaultValue) : initialValue);
    }
  }, [reset, defaultValue]);

  const onContentStateChange = (rawContentState: Descendant[]) => {
    const markup = slateToHtml(rawContentState);
    const sanitizedMarkup = sanitizeMarkup(markup);
    onChange(name, sanitizedMarkup, defaultValue);
  };

  const onFocus = React.useCallback(() => {
    setFocused(true);
    if (!editor.selection && value?.length) {
      Transforms.select(
        editor,
        savedSelection.current ?? Editor.end(editor, [])
      );
    }
  }, [editor]);

  const onBlur = React.useCallback(() => {
    setFocused(false);
    savedSelection.current = editor.selection;
  }, [editor]);

  const divRef = React.useRef<HTMLDivElement>(null);

  const focusEditor = React.useCallback(
    (e: React.MouseEvent) => {
      if (e.target === divRef.current) {
        ReactEditor.focus(editor);
        e.preventDefault();
      }
    },
    [editor]
  );

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event as any)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey];
        toggleMark(editor, mark);
      }
    }
  };

  return (
    <Box
      ref={divRef}
      onMouseDown={focusEditor}
      borderWidth={0.5}
      borderRadius="md"
      position="relative"
    >
      <Slate
        editor={editor}
        value={value}
        onChange={(newValue) => {
          setValue(newValue.length > 0 ? newValue : initialValue);
          onContentStateChange(newValue);
        }}
      >
        {!isDisabled && <Toolbar />}

        <Box p={4}>
          <Editable
            // @ts-ignore
            onFocus={onFocus}
            // @ts-ignore
            onBlur={onBlur}
            // @ts-ignore
            onKeyDown={onKeyDown}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={placeholder}
            spellCheck
            style={{ minHeight: '150px', resize: 'vertical', overflow: 'auto' }}
            readOnly={isDisabled}
            {...rest}
          />
        </Box>
      </Slate>
      {tooltip && (
        <Box position="absolute" bottom={1.5} right={8}>
          <IconTooltip tooltip={tooltip} />
        </Box>
      )}
    </Box>
  );
};

export const RichTextEditor: React.FC<RichTextEditorProps> = (props) => {
  const [resettingContent, setResettingContent] = useState(false);

  const resetContentState = async () => {
    /** Since we use the Editor in an un-controlled way,
     *  we reset the value by briefly rendering null,
     *  then re-render the Editor with its default value */
    setResettingContent(true);
    setTimeout(() => setResettingContent(false), 0);
  };

  useEffect(() => {
    if (props.reset) resetContentState();
  }, [props.reset]);

  if (resettingContent) return null;

  return <RTEditor {...props} />;
};
