import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import navRoutes from 'navigation/Routes';

import { Box, chakra, Link, Text } from '@workshop/ui';

import {
  LabelInput,
  LabelSelect,
  LabelWrapper,
  MultiSelect,
  ConfirmModal,
} from 'components/Common';
import { Loading } from 'components/Loading';

import { useCurrentTeamProfile } from 'redux/selectors';
import { courseActions, enrolmentActions } from 'redux/actions/cms';

import { capitalize } from 'utils/string';
import { hooks } from 'utils';

import { GlobalState } from 'types';

import {
  EditModal,
  Props as EditModalProps,
} from 'components/Common/EditModal';

export interface CreateLicenseFormData {
  organisation: number;
  enrolmentsTotal: number;
  expiryDate: Date | null;
}

const labelStyleProps = { whiteSpace: 'nowrap' } as const;

interface CreateLicenseModalProps extends EditModalProps {
  defaultSelectFromOrgs?: boolean;
  defaultLicensee?: number;
  orgNaming?: string;
  onClickCreateNewOrg?: () => void;
}

const CreateLicenseModal: React.FC<CreateLicenseModalProps> = (props) => {
  const {
    isOpen,
    modalSize,
    onClose,
    title,
    defaultLicensee,
    orgNaming = 'channel',
    defaultSelectFromOrgs = false,
    onClickCreateNewOrg,
  } = props;

  const { courses: coursesLoading } = hooks.useLoadingDataState(
    {
      courses: {
        actions: [() => courseActions.list({ fetchNextPage: true })],
      },
    },
    []
  );

  const [isUpdating, setIsUpdating] = useState(false);
  const [selectFromMyOrgs, setSelectFromMyOrgs] = useState(
    defaultSelectFromOrgs
  );
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [courseValues, setCourseValues] = useState(
    [] as { value: number; label: string }[]
  );

  const dispatch = useDispatch();
  const currentTeamProfile = useCurrentTeamProfile();

  const organisationList = useSelector(
    (state: GlobalState) => state.organisation.teamProfiles
  );

  const organisationOptions = Object.values(organisationList)
    .filter((o) => o.id !== currentTeamProfile?.id)
    .reduce(
      (acc, curr) => ({ ...acc, [curr.id.toString()]: curr.name }),
      {} as { [key: string]: string }
    );

  const courseList = useSelector(
    (state: GlobalState) => state.cms.course.courseList
  );

  // All selected courses must share the same pricing tier so, after selecting one course,
  // we filter remaining options to only those that are on the same pricing tier
  const firstSelectedCourseId =
    courseValues?.length > 0 ? courseValues[0].value : null;
  const selectedPricingTier = firstSelectedCourseId
    ? courseList[firstSelectedCourseId].pricingTier
    : null;

  const courseOptions = Object.values(courseList)
    .filter(
      (c) =>
        c.organisation === currentTeamProfile?.id &&
        (!selectedPricingTier || c.pricingTier?.id === selectedPricingTier.id)
    )
    .map((c) => ({
      value: c.id,
      label: c.title,
    }));

  const { handleSubmit, errors, register, reset, watch, setValue } =
    useForm<CreateLicenseFormData>({
      mode: 'onChange',
    });

  useEffect(() => {
    register('expiryDate');
  }, [register]);

  const handleOnClose = () => {
    reset();
    onClose();
  };

  const handleOnSave = async (e?: React.FormEvent<HTMLFormElement>) => {
    setIsUpdating(true);
    if (e && e.stopPropagation) {
      // prevent any outer forms from receiving the event too
      e.stopPropagation();
    }

    const submit = await handleSubmit(
      async ({ organisation, enrolmentsTotal, expiryDate }) => {
        await dispatch(
          enrolmentActions.createLicense({
            licensee: organisation,
            totalEnrolments: enrolmentsTotal,
            courses: courseValues.map((c) => c.value),
            ...(expiryDate
              ? {
                  expiryDate: moment(expiryDate).format('YYYY-MM-DD'),
                }
              : {}),
          })
        );
      }
    );

    await submit(e);

    setIsUpdating(false);

    handleOnClose();
  };

  const orgValue = watch('organisation');
  const enrolmentsValue = watch('enrolmentsTotal');
  const expiryDateValue = watch('expiryDate');

  const isValid =
    courseValues?.length > 0 &&
    orgValue &&
    enrolmentsValue > 0 &&
    (expiryDateValue ? moment(expiryDateValue).isAfter(moment()) : true);

  const modalProps = {
    isOpen,
    modalSize,
    onCancel: handleOnClose,
    onClose: handleOnClose,
    onSave: () => setShowConfirmModal(true),
    title,
    isUpdating,
    saveDisabled: !isValid || isUpdating,
  };

  const orgHelpText = (
    <>
      {`The ${orgNaming} you are providing courses to. If the ${orgNaming} doesn't exist yet, you can `}
      <Link
        color="text.primary"
        fontWeight="semibold"
        cursor="pointer"
        {...(onClickCreateNewOrg
          ? { onClick: onClickCreateNewOrg }
          : { href: `${navRoutes.common.home.path()}?p=new` })}
      >
        {`create a new ${orgNaming} here`}
      </Link>
      {`.`}
    </>
  );

  return (
    <EditModal saveLabel="Create Agreement" {...modalProps}>
      {coursesLoading ? (
        <Loading />
      ) : (
        <>
          <ConfirmModal
            body={
              <>
                {`This will bill your channel based on the total number of enrolments you have selected.\n\n`}
                <chakra.span fontWeight="bold">{`${
                  currentTeamProfile?.name
                } will now be billed${
                  selectedPricingTier
                    ? ` £${enrolmentsValue * selectedPricingTier?.amount}`
                    : ''
                } for ${enrolmentsValue} enrolments. Are you sure?`}</chakra.span>
              </>
            }
            btnColor="green"
            btnLabel="Yes, Create Agreement"
            title="You are about to create a Bulk Enrolment Agreement"
            isOpen={showConfirmModal}
            isLoading={isUpdating}
            onClose={() => setShowConfirmModal(false)}
            onClick={handleOnSave}
          />
          {/* Main section */}
          <Box>
            <Box mb={5}>
              {selectFromMyOrgs ? (
                <LabelSelect
                  isDisabled={false}
                  id="organisation"
                  name="organisation"
                  label={`Recipient ${capitalize(orgNaming)}`}
                  labelPosition="top"
                  labelButtonText="Enter Channel ID"
                  labelButtonOnClick={() => setSelectFromMyOrgs(false)}
                  helpText={orgHelpText}
                  error={!!errors.organisation}
                  registerInputRef={register({ required: true })}
                  defaultValue={defaultLicensee || undefined}
                  options={organisationOptions}
                  labelStyleProps={labelStyleProps}
                />
              ) : (
                <LabelInput
                  id="organisation"
                  name="organisation"
                  label="Recipient Channel ID"
                  labelPosition="top"
                  labelButtonText="Select from My Channels"
                  labelButtonOnClick={() => setSelectFromMyOrgs(true)}
                  helpText={orgHelpText}
                  error={!!errors.organisation}
                  registerInputRef={register({ required: true })}
                  labelStyleProps={labelStyleProps}
                />
              )}
            </Box>
            <Box mb={6}>
              <LabelWrapper
                labelPosition="top"
                label="Courses"
                helpText="The courses you will be making available through this agreement. (Selected courses must all be on the same pricing tier)."
              >
                <MultiSelect
                  placeholder="Select course(s)..."
                  options={courseOptions}
                  value={courseValues}
                  // @ts-ignore
                  onChange={(values) => setCourseValues(values)}
                />
              </LabelWrapper>

              {courseValues?.length > 0 && selectedPricingTier && (
                <Box
                  position="relative"
                  backgroundColor="background.warning"
                  borderRadius="sm"
                  minWidth="65px"
                  justifyContent="center"
                  px={2}
                  py={1}
                  mr="defaultMargin"
                >
                  <Text fontSize="xs" color="text.warning">
                    {`The selected course${
                      courseValues?.length === 1 ? '' : 's'
                    } will cost you `}
                    <chakra.span fontWeight="bold">
                      {`£${selectedPricingTier.amount} per enrolment`}
                    </chakra.span>
                    .
                  </Text>
                </Box>
              )}
            </Box>
            <Box mb={6}>
              <LabelInput
                id="enrolmentsTotal"
                name="enrolmentsTotal"
                label="Total Number of Enrolments"
                labelPosition="top"
                inputType="number"
                registerInputRef={register({ required: true })}
                labelStyleProps={labelStyleProps}
                helpText={`The total number of student-course enrolments available through this agreement. For example, if 10 students will be enrolled on 5 courses, your agreement should be for 50 enrolments (10 students x 5 courses).`}
              />
            </Box>
            <LabelInput
              id="expiryDate"
              name="expiryDate"
              label="Expiry Date"
              registerInputRef={register()}
              labelPosition="top"
              labelStyleProps={labelStyleProps}
              helpText="Enrolments provided by this agreement will not be redeemable after this date."
              inputType="date"
              error={Boolean(
                expiryDateValue && moment(expiryDateValue).isBefore(moment())
              )}
              errorMessage="Your agreement's expiry date cannot be in the past."
            />
          </Box>
        </>
      )}
    </EditModal>
  );
};

export default CreateLicenseModal;
