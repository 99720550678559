import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Card, Flex, Divider, Skeleton, Text, useTheme } from '@workshop/ui';

import { GlobalState } from 'types';
import { CohortMentor } from 'types/common';
import { Unit, UnitProgress, UnitSchedule } from 'types/learner';

import { ACCESS_TYPES, COURSE_STATUS, ICourseStatus } from 'constants/courses';
import navRoutes from 'navigation/Routes';
import messageBus from 'utils/messageBus';

import {
  cohortActions,
  courseActions,
  courseProgressActions,
  courseScheduleActions,
  journalActions,
} from 'redux/actions/learner';
import {
  getDiscourseCategory,
  getModules,
  getModuleSchedules,
  getModulesProgress,
  getSlugs,
  getUnitProgress,
  getUnits,
  getUnitSchedules,
} from 'redux/selectors/course';
import { useUser } from 'redux/selectors';

import { hooks } from 'utils';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';
import { ModulesList } from 'components/ModulesList';
import { ImageList } from 'components/ImageList';
import { CourseMenu } from 'components/SideMenu';
import { CourseModel, CohortModel } from 'models/learner';
import { UnitOverviewCard } from 'screens/learner/CourseOverview/UnitOverviewCard';
import { SectionTitle, InformationCard } from 'components/Common';
import { DYNAMIC_INFORMATION_IDS } from 'components/Common/InformationCard';
import { Loading } from 'components/Loading';

import { loadDiscourseData, getCohortSubcategoryId } from 'utils/learner';

const getCourseStatus = ({
  courseProgress,
  courseSchedule,
  cohort,
  course,
}: Partial<Props>) => {
  if (!course || !courseProgress || !courseSchedule || !cohort) return;

  const { expiryDate } = courseProgress;

  // Determine whether this user has an expiry date on the course
  const hasExpiry = courseProgress ? !!courseProgress.expiryDate : false;

  // Determine whether the cohort itself is one which can expire
  // TODO: Is this a thing?
  // const canExpire = cohort.socialType === ACCESS_TYPES.scheduled;
  const canExpire = hasExpiry;

  const upcoming = moment(courseSchedule.startDate).isAfter(moment(), 'days');
  if (upcoming) return COURSE_STATUS.upcoming;

  const expired =
    canExpire && hasExpiry && moment(expiryDate).diff(moment(), 'days') <= 0;
  if (expired) return COURSE_STATUS.expired;

  const expiringSoon =
    canExpire && hasExpiry && moment(expiryDate).diff(moment(), 'week') <= 2;
  if (expiringSoon) return COURSE_STATUS.expiringSoon;

  const courseEnded =
    Math.abs(moment(courseSchedule.startDate).diff(moment(), 'week') - 1) >
    course.duration;

  return courseEnded ? COURSE_STATUS.ended : COURSE_STATUS.inProgress;
};

const getUnitOverviewPrefix = (
  status: ICourseStatus | undefined,
  incompleteCount: number
) => {
  if (status === COURSE_STATUS.inProgress) return 'This Week';

  if (
    status === COURSE_STATUS.ended ||
    status === COURSE_STATUS.expiringSoon ||
    status === COURSE_STATUS.expired
  ) {
    return incompleteCount > 0 ? 'Continue Your Course' : 'Course Complete';
  }

  return 'Unit 1 Available Soon';
};

const getUnitOverviewTitle = (
  status: ICourseStatus | undefined,
  startDate: string,
  currentUnit: Unit | null,
  incompleteCount: number,
  userName: string
) => {
  if (status === COURSE_STATUS.inProgress) {
    return currentUnit ? `${currentUnit.prefix}: ${currentUnit.title}` : '';
  }

  if (status === COURSE_STATUS.expired) {
    return 'Access Expired';
  }

  if (status === COURSE_STATUS.ended || status === COURSE_STATUS.expiringSoon) {
    return incompleteCount > 0
      ? `You Have ${incompleteCount} Sessions Remaining`
      : userName
      ? `Congratulations, ${userName}! 🎉`
      : 'Congratulations! 🎉';
  }

  const year = !moment(startDate).isSame(moment(), 'year')
    ? ` ${moment(startDate).format('YYYY')}`
    : '';

  return `Class Starts On ${moment(startDate).format('MMM Do') + year}`;
};

const SubstituteMentor: React.FC<{ mentor: CohortMentor; course: string }> = ({
  mentor,
  course,
}) => {
  const id = `${DYNAMIC_INFORMATION_IDS.substitute_mentor}_${course}_${moment(
    mentor.expiryDate
  ).format('YYYYMMDD')}`;

  const description = `Your primary mentor is currently away, so you have a substitute, ${
    mentor.name
  }, who will be taking their place and providing you with feedback until ${moment(
    mentor.expiryDate
  ).format('dddd Do MMMM')}. 👍`;

  return (
    <>
      <InformationCard
        id={id}
        information={{
          title: 'Substitute mentor',
          description: description,
          status: 'info',
        }}
      />
      <Divider marginY={4} />
    </>
  );
};

// Routing Props
interface MatchParams {
  courseSlug: string;
}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const CourseOverview: React.FC<Props> = ({
  location,
  cohort,
  cohortCategory,
  course,
  courseProgress,
  courseSchedule,
  courseSlug,
  moduleProgress,
  modules,
  moduleSchedule,
  subCategoryId,
  unitProgress,
  units,
  unitSchedule,
  substituteMentor,
  journalEntries,
  history,
}) => {
  const theme = useTheme();
  // --- FETCH DATA
  const dispatch = useDispatch();

  const discourseUserId = useSelector(
    (state: GlobalState) => state.user.discourseUser.user?.id
  );

  const user = useUser();

  const [discourseDataLoading, setDiscourseDataLoading] = useState(true);

  const { courseLoading } = hooks.useLoadingDataState({
    courseLoading: {
      actions: [
        () => courseActions.retrieve(courseSlug, location.pathname, false),
      ],
    },
    journalLoading: { actions: [journalActions.retrieveJournalEntries] },
  });

  const { dataLoading } = hooks.useLoadingDataState(
    {
      dataLoading: {
        startLoading: !Boolean(courseLoading),
        actions: [
          () => cohortActions.retrieve(courseSlug),
          () => courseProgressActions.retrieve(courseSlug),
          () => courseScheduleActions.retrieve(courseSlug),
        ],
      },
    },
    [courseSlug, courseLoading]
  );

  useEffect(() => setDiscourseDataLoading(true), [courseSlug]);

  hooks.useDeepEqualEffect(() => {
    if (dataLoading) return;

    if (!cohort || !course || !subCategoryId) {
      setDiscourseDataLoading(false);
      return;
    }

    loadDiscourseData({
      dispatch,
      cohort,
      course,
      subCategoryId,
    }).then(() => setDiscourseDataLoading(false));

    /**
     * Subscribe to the "new" channel in discourse to check
     * if any topics have been created --> if yes, call handleDiscourseNewChannel
     */
    messageBus.subscribeToCourseChanges(
      course.discourseCategoryId,
      subCategoryId,
      dispatch
    );

    return () => {
      messageBus.unsubscribeFromCourseChanges();
    };
  }, [
    dispatch,
    cohort,
    course,
    dataLoading,
    subCategoryId,
    // If the message bus status changes, we may need to re-subscribe to required
    // channels
    messageBus.__bus.started,
  ]);

  if (courseLoading) return <Loading />;

  if (
    !courseProgress &&
    !dataLoading &&
    !courseLoading &&
    !discourseDataLoading
  ) {
    history.push(navRoutes.common.home.path());
  }

  // --- INSTANTIATE MODELS
  const courseModel = new CourseModel({
    course,
    courseSchedule,
    courseProgress,
    modules,
    moduleSchedule,
    moduleProgress,
    units,
    unitSchedule,
    unitProgress,
    journalEntries,
    cohort,
  });

  const cohortModel = new CohortModel({
    cohort,
    category: cohortCategory,
  });

  // determine course status
  const courseStatus = getCourseStatus({
    courseProgress,
    courseSchedule,
    cohort,
    course,
  });

  // --- RETREIVE DATA FROM MODELS
  const currentUnit = courseModel.getCurrentUnit();
  const currentSessions = currentUnit
    ? courseModel.getSessionsForUnit(currentUnit)
    : [];

  const incompleteSessions = courseModel.getIncompleteSessions();

  // TODO: add url and/or onClick handler
  const classActivityImages = cohortModel
    .getClassActivity()
    .map((e) => ({ src: e.image }));

  // TODO: Enter a random slug - should display a 404
  // TODO: Intro cards (2 weeks before start date)
  // TODO: Course countdown

  return (
    <ScreenWrapper>
      <CourseMenu
        courseSlug={courseSlug}
        isMini={course?.courseType === 'mini'}
      />
      {courseStatus === COURSE_STATUS.expired && (
        <>
          <InformationCard
            id="course-expired"
            information={{
              title: 'Access Expired',
              description:
                'Your access to sessions on this course has expired. You can still view your resources and posts using the links above.',
              status: 'error',
            }}
            isDismissable={false}
          />
          <Divider marginY={4} />
        </>
      )}
      {substituteMentor && (
        <SubstituteMentor mentor={substituteMentor} course={course.slug} />
      )}
      <Flex flex={1} mb={4} flexDirection="column" position="relative">
        {/* TODO: Ensure this matches the behaviour of the app */}
        <UnitOverviewCard
          imageUrl={
            courseStatus === COURSE_STATUS.inProgress
              ? currentUnit?.imageMobile || course?.imageLandscapeMobile
              : course?.imageLandscapeMobile
          }
          sessionImages={currentUnit?.sessionImages
            .map((i) => i.imageThumbnail)
            .reverse()}
          isLoading={dataLoading}
          prefix={getUnitOverviewPrefix(
            courseStatus,
            incompleteSessions.length
          )}
          title={getUnitOverviewTitle(
            courseStatus,
            courseSchedule?.startDate,
            currentUnit,
            incompleteSessions.length,
            user?.name.split(' ')[0]
          )}
          modules={
            courseStatus === COURSE_STATUS.expired
              ? []
              : currentSessions
                  .filter((s) => s.moduleType === 'normal')
                  .map((s) => {
                    const hasIntro = currentSessions[0].moduleType === 'intro';

                    const sessionIdx = hasIntro ? s.index - 1 : s.index;

                    const title = `Session ${sessionIdx}: ${s.title}`;

                    return {
                      ...s,
                      // TODO: Improve this - session index isn't correct by default
                      imageUrl: s.imageThumbnail,
                      isLoading: false,
                      linkTo: navRoutes.learner.module.path(courseSlug, s.id),
                      showScheduleBtn: !!s.schedule,
                      courseSlug,
                      title,
                    };
                  })
          }
        />
        {dataLoading || (!dataLoading && incompleteSessions.length > 0) ? (
          <>
            <Divider marginY={4} />
            <Skeleton
              isLoaded={!dataLoading}
              mb="defaultMargin"
              loadingStyle={{ width: 0.2 }}
            >
              <SectionTitle flex={1} title="Catch Up" mb={0} />
            </Skeleton>
            <Card
              display="flex"
              flexDirection="column"
              padding={0}
              overflow="initial"
            >
              <ModulesList
                isLoading={dataLoading}
                isExpired={courseStatus === COURSE_STATUS.expired}
                moduleItems={incompleteSessions.map((s) => {
                  return {
                    ...s,
                    imageUrl: s.imageThumbnail,
                    isLoading: dataLoading,
                    linkTo: navRoutes.learner.module.path(courseSlug, s.id),
                    showScheduleBtn: !!s.schedule,
                    courseSlug,
                  };
                })}
              />
            </Card>
          </>
        ) : null}
        {discourseDataLoading ||
        (!discourseDataLoading && classActivityImages.length > 0) ? (
          <>
            <Divider marginY={4} />
            <Skeleton
              isLoaded={!dataLoading}
              mb={2}
              loadingStyle={{ width: 0.2 }}
            >
              <Flex alignItems="center">
                <SectionTitle flex={1} title="Latest Activity" mb={0} />
                <Link to={`/course/${courseSlug}/activity`}>
                  <Text color="common.primary" fontWeight="semibold">
                    See All
                  </Text>
                </Link>
              </Flex>
            </Skeleton>
            <Link to={`/course/${courseSlug}/activity`}>
              <Flex position="relative">
                <ImageList
                  size="2xl"
                  containerType="plain"
                  isLoading={discourseDataLoading}
                  images={classActivityImages}
                  containerStyle={{ flexWrap: 'nowrap', overflow: 'hidden' }}
                />
                <Flex
                  position="absolute"
                  top={0}
                  right={0}
                  bottom={0}
                  width="100px"
                  background={`linear-gradient(90deg, transparent, ${theme.colors.background.tint3})`}
                />
              </Flex>
            </Link>
          </>
        ) : null}
      </Flex>
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: GlobalState, props: OwnProps) => {
  const { courseSlug } = props.match.params;

  const {
    courses: { courses: courseState, units: unitState, modules: moduleState },
    courseSchedule: {
      courses: courseScheduleState,
      units: unitScheduleState,
      modules: moduleScheduleState,
    },
    courseProgress: {
      courses: courseProgressState,
      units: unitProgressState,
      modules: moduleProgressState,
    },
    journal: { journalEntries },
  } = state.learner;

  const {
    discourse: { categories },
  } = state;

  // Course Data
  const course = courseState.detail[courseSlug];
  const units = course ? getUnits(unitState, course.units) : null;

  type U = Unit;
  type M = 'modules';
  const moduleSlugs = getSlugs<Pick<U, M>, M>(units, 'modules');

  const modules = moduleSlugs ? getModules(moduleState, moduleSlugs) : null;

  // Course Schedule Data
  const courseSchedule = courseScheduleState[courseSlug];
  const unitSchedule = courseSchedule
    ? getUnitSchedules(unitScheduleState, courseSchedule.unitSchedules)
    : null;

  type US = UnitSchedule;
  type MS = 'moduleSchedules';
  const moduleScheduleSlugs = getSlugs<Pick<US, MS>, MS>(
    unitSchedule,
    'moduleSchedules'
  );

  const moduleSchedule = moduleScheduleSlugs
    ? getModuleSchedules(moduleScheduleState, moduleScheduleSlugs)
    : null;

  // Course Progress Data
  const courseProgress = courseProgressState[courseSlug];
  const unitProgress = courseProgress
    ? getUnitProgress(unitProgressState, courseProgress.unitProgress)
    : null;

  type UP = UnitProgress;
  type MP = 'moduleProgress';
  const moduleProgressSlugs = getSlugs<Pick<UP, MP>, MP>(
    unitProgress,
    'moduleProgress'
  );

  const moduleProgress = moduleProgressSlugs
    ? getModulesProgress(moduleProgressState, moduleProgressSlugs)
    : null;

  // Cohort Data
  const cohort = state.learner.courses.cohorts[courseSlug];

  const subCategoryId = getCohortSubcategoryId(cohort, course);

  const cohortCategory = subCategoryId
    ? getDiscourseCategory(subCategoryId, categories)
    : null;

  // If there is a substitute mentor for this cohort, find and extract them
  // so that we can display this information to the student
  const substituteMentors = cohort?.mentors.filter(
    (mentor) => mentor.isSubstitute && moment(mentor.expiryDate).isAfter()
  );

  let substituteMentor: CohortMentor | null = null;

  if (substituteMentors?.length > 0) {
    substituteMentor = substituteMentors.sort((a, b) =>
      moment(a.expiryDate).isBefore(moment(b.expiryDate)) ? 1 : -1
    )[0];
  }

  return {
    cohort,
    cohortCategory,
    course,
    courseProgress,
    courseSchedule,
    courseSlug,
    moduleProgress,
    modules,
    moduleSchedule,
    subCategoryId,
    unitProgress,
    units,
    unitSchedule,
    substituteMentor,
    journalEntries,
  };
};

const connector = connect(mapStateToProps);

export default connector(CourseOverview);
