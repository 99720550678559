import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Joyride, {
  Props as JoyrideProps,
  TooltipRenderProps,
} from 'react-joyride';

import {
  Card,
  Text,
  Stack,
  Box,
  Button,
  useColorModeValue,
  useTheme,
  LightMode,
} from '@workshop/ui';

import { analytics } from 'utils';

import { profileActions } from 'redux/actions/common';
import {
  useDismissedInformationCards,
  useIsUserLoading,
} from 'redux/selectors/user';

interface TourStep {
  id: string;
  content: string;
  title?: string;
  target?: string;
  disableBeacon?: boolean;
  placement?: string;
  nextCondition?: boolean;
  nextInstruction?: string;
  spotlightClicks?: boolean;
}

// @ts-ignore
interface Props extends JoyrideProps {
  steps: TourStep[];
  analyticsName?: string;
  onDismiss?: () => void;
  hideIndicators?: boolean;
}

const TourTooltip: React.FC<
  TooltipRenderProps & {
    showSkipButton?: boolean;
    hideBackButton?: boolean;
    hideIndicators?: boolean;
    prevNextCondition: React.MutableRefObject<boolean | undefined>;
  }
> = ({
  continuous,
  index,
  step,
  size,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  isLastStep,
  skipProps,
  showSkipButton,
  hideBackButton,
  hideIndicators,
  prevNextCondition,
}) => {
  const tourStep = step as TourStep;
  const indicators = new Array(size).fill(1);
  const skipColor = useColorModeValue('blackAlpha', 'whiteAlpha');
  useEffect(() => {
    if (
      tourStep.nextCondition === true &&
      prevNextCondition.current === false
    ) {
      // Simulate clicking "next" button when next conditions become true
      // @ts-ignore
      primaryProps.onClick({ preventDefault: () => null });
    }
    prevNextCondition.current = tourStep.nextCondition;
  }, [tourStep.nextCondition]);
  return (
    <Card
      padding={4}
      paddingX={6}
      w={350}
      maxW="100%"
      flexDirection="column"
      textAlign="center"
      backgroundColor="background.primary"
      color="text.primary"
      borderRadius="lg"
      position="relative"
      {...tooltipProps}
    >
      <Box pt={4}>
        {tourStep.title && (
          <Text fontWeight="semibold" pb={2}>
            {tourStep.title}
          </Text>
        )}
        <Text whiteSpace="break-spaces">{tourStep.content}</Text>
        {tourStep.nextInstruction && tourStep.nextCondition === false ? (
          <Box bg="background.success" p={2} mt={4} borderRadius="sm">
            <Text color="text.success" fontWeight="semibold" fontSize="sm">
              {tourStep.nextInstruction}
            </Text>
          </Box>
        ) : null}
      </Box>
      <Stack
        flex={1}
        pt={7}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        {index > 0 && !hideBackButton && (
          <Button
            variant="outline"
            size="sm"
            icon="ArrowBack"
            w={8}
            {...backProps}
          />
        )}
        {continuous && tourStep.nextCondition !== false && (
          <Button
            {...primaryProps}
            size="sm"
            icon={isLastStep ? 'ThumbUp' : 'ArrowForward'}
            iconPosition={isLastStep ? 'left' : 'right'}
          >
            {isLastStep ? 'Got it' : 'Next'}
          </Button>
        )}
        {!continuous && (
          <Button icon="ThumbUp" size="sm" {...closeProps}>
            Got it
          </Button>
        )}
      </Stack>
      {size > 1 && (
        <Stack
          pt={4}
          direction="row"
          justifyContent="center"
          alignItems="center"
          flex={1}
        >
          {!hideIndicators &&
            indicators.map((i, idx) => (
              <Box
                w={idx === index ? 9 : 2}
                h={2}
                borderRadius="full"
                bg={idx <= index ? 'common.progress' : 'background.tint1'}
              />
            ))}
        </Stack>
      )}
      {showSkipButton && (
        <LightMode>
          <Button
            position="absolute"
            variant="ghost"
            colorScheme={skipColor}
            right={2}
            bottom={2}
            size="xs"
            {...skipProps}
          >
            Skip Tour
          </Button>
        </LightMode>
      )}
    </Card>
  );
};

const Tour: React.FC<Props> = ({
  steps,
  showSkipButton,
  hideBackButton,
  analyticsName,
  onDismiss,
  hideIndicators,
  ...rest
}) => {
  useEffect(() => {
    return () => handleDismiss();
  }, []);

  const isLoading = useIsUserLoading();
  const dispatch = useDispatch();
  const theme = useTheme();

  const dismissedInformationCards = useDismissedInformationCards();

  const unseenSteps = steps
    .filter((s) => !dismissedInformationCards.includes(s.id))
    .map((s) => ({
      ...s,
      target: s.target || `[data-tour="${s.id}"]`,
      disableBeacon: s.disableBeacon !== false,
    }));

  const handleDismiss = () => {
    const newDismissedCards = [
      ...dismissedInformationCards,
      ...unseenSteps.map((s) => s.id),
    ];
    if (
      !isLoading &&
      newDismissedCards.length > dismissedInformationCards.length
    ) {
      dispatch(
        profileActions.updateUserProfile(
          {
            dismissedInformationCards: newDismissedCards,
          },
          { toast: { success: false, error: false } }
        )
      );
    }
    onDismiss && onDismiss();
  };

  const prevNextCondition = useRef<boolean | undefined>();

  return (
    // @ts-ignore
    <Joyride
      // @ts-ignore
      steps={unseenSteps}
      continuous
      styles={{
        options: {
          arrowColor: theme.colors.background.primary,
          zIndex: 1101,
        },
      }}
      spotlightClicks
      disableOverlayClose
      floaterProps={{
        disableAnimation: true,
      }}
      tooltipComponent={(props) => (
        <TourTooltip
          prevNextCondition={prevNextCondition}
          showSkipButton={showSkipButton}
          hideBackButton={hideBackButton}
          hideIndicators={hideIndicators}
          {...props}
        />
      )}
      callback={({ type, status, step }) => {
        if (type === 'tour:start' && status === 'running' && analyticsName) {
          analytics.track(`${analyticsName} Tour Started`);
        }
        if (type === 'step:after' && status === 'running' && analyticsName) {
          analytics.track(`${analyticsName} Tour Step Completed`, {
            // @ts-ignore
            tour_step: step?.id,
          });
        }
        if (
          (type === 'tour:end' && status === 'finished') ||
          (type === 'tour:end' && status === 'skipped')
        ) {
          if (status === 'finished' && analyticsName) {
            analytics.track(`${analyticsName} Tour Completed`, {
              // @ts-ignore
              tour_step: step?.id,
            });
          }
          if (status === 'skipped' && analyticsName) {
            analytics.track(`${analyticsName} Tour Skipped`, {
              // @ts-ignore
              tour_step: step?.id,
            });
          }
          handleDismiss();
        }
      }}
      {...rest}
    />
  );
};

export default Tour;
