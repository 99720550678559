import { FRONT_ENV, BACKEND_ENV } from 'constants/env';

export const ROLES = {
  admin: 'admin',
  owner: 'owner',
  mentor: 'mentor',
  teamManager: 'team_manager',
  finance: 'finance',
  marketing: 'marketing',
  contentManager: 'content_manager',
} as const;

const isRemote = FRONT_ENV === 'production' || BACKEND_ENV === 'dev';

// (Replace IDs after ":" with local values if testing)
export const ORG_IDS = {
  workshop: isRemote ? 14 : 2,
  leiths: isRemote ? 8 : 1,
  lak: isRemote ? 46 : 42,
};

// URL path for each bespoke org landing page
export const ORG_LANDING_PAGES = {
  lak: '/leiths-academy-kitchen',
};

// List of org IDs that are not allowed to manually enrol students
export const ENROLMENT_BLACKLIST = [0];

// List of org IDs that are allowed to create courses
export const CREATE_COURSE_WHITELIST = [
  ORG_IDS.leiths,
  ORG_IDS.workshop,
  ORG_IDS.lak,
];

// List of org IDs with detailed cohort options
export const ADVANCED_COHORT_OPTIONS_WHITELIST = [
  ORG_IDS.leiths,
  ORG_IDS.workshop,
];

// List of org IDs with detailed course options
export const ADVANCED_COURSE_OPTIONS_WHITELIST = [
  ORG_IDS.leiths,
  ORG_IDS.workshop,
  ORG_IDS.lak,
];

// List of org IDs only available on Workshop
export const WORKSHOP_ORGS = [ORG_IDS.leiths, ORG_IDS.lak];

// List of org IDs that have "pro" status by default
export const PRO_ORGS = [ORG_IDS.leiths, ORG_IDS.workshop, ORG_IDS.lak];

export const BASIC_UNIT_LIMIT = 4;
export const PRO_TEAM_MEMBERS_LIMIT = 5;
export const PRO_MONTHLY_ENROLMENTS_LIMIT = 10;
export const PRO_ASSISTANT_UNITS_LIMIT = 8;

// Pro channel waiting list hash
export const PRO_LIST_HASH = '3547153';

// Pro channel monthly pricing in specified currency
export const PRO_PRICE = (currency: string | undefined) =>
  currency === 'GBP'
    ? '£30'
    : currency === 'EUR'
    ? '€35'
    : currency === 'USD'
    ? '$30'
    : '£30';

export const PRO_95_DISCOUNT_PRICE = (currency: string | undefined) =>
  currency === 'GBP'
    ? '£1.50'
    : currency === 'EUR'
    ? '€1.75'
    : currency === 'USD'
    ? '$1.50'
    : '£1.50';
