import React from 'react';

import {
  Box,
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  chakra,
} from '@workshop/ui';
import { Transition, animated } from 'react-spring';

import { Loading } from 'components/Loading';

import { TopicsList, PartialTopic } from 'screens/learner/ClassActivity';

interface ActivityPopupProps {
  isOpen: boolean;
  onClose: () => void;
  titleBold?: string;
  titleMuted?: string;
  topicsList: PartialTopic[];
  footer?: React.ReactElement;
  isLoading?: boolean;
  openTopicsInNewTab?: boolean;
  isCms?: boolean;
}

const ActivityPopup: React.FC<ActivityPopupProps> = ({
  isOpen,
  onClose,
  titleBold = '',
  titleMuted = '',
  topicsList,
  footer = null,
  isLoading = false,
  openTopicsInNewTab = false,
  isCms = false,
}) => {
  const AnimatedModalContent = animated(ModalContent);
  const AnimatedModalOverlay = animated(ModalOverlay);
  return (
    <Modal onClose={onClose} isOpen={isOpen} motionPreset="none" size="lg">
      <Transition
        items={isOpen}
        from={{ opacity: 0, backdropFilter: 'blur(0px)' }}
        enter={{ opacity: 1, backdropFilter: 'blur(10px)' }}
        leave={{ opacity: 0, backdropFilter: 'blur(0px)' }}
      >
        {(styles, item) => (
          <AnimatedModalOverlay backdropFilter="blur(10px)" style={styles} />
        )}
      </Transition>
      <Transition
        items={isOpen}
        from={{ scale: 0.98 }}
        enter={{ scale: 1 }}
        leave={{ scale: 0.98 }}
        config={{ tension: 200, friction: 8 }}
      >
        {(styles, item) => (
          <AnimatedModalContent
            marginX={2}
            marginY="20vh"
            backgroundColor="background.tint1"
            style={styles}
          >
            <ModalHeader px={{ base: 4, md: 6 }}>
              <Flex alignItems="center">
                <Text fontWeight="bold" pr={6}>
                  {titleBold}
                  <chakra.span fontWeight="semibold" color="text.muted">
                    {titleMuted}
                  </chakra.span>
                </Text>
              </Flex>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody paddingTop={0} paddingBottom={4} px={{ base: 4, md: 6 }}>
              {isLoading ? (
                <Box pb={4}>
                  <Loading />
                </Box>
              ) : (
                <Box>
                  <TopicsList
                    topics={topicsList}
                    openTopicsInNewTab={openTopicsInNewTab}
                    isCms={isCms}
                  />
                </Box>
              )}
              {footer}
            </ModalBody>
          </AnimatedModalContent>
        )}
      </Transition>
    </Modal>
  );
};

export default ActivityPopup;
