import * as React from 'react';
import sanitizeHtml from 'sanitize-html';

import { Box } from '@workshop/ui';

interface RenderHtmlProps {
  html: string;
}

const RenderHtml: React.FC<RenderHtmlProps> = ({ html }) => (
  <Box
    listStylePosition="inside"
    sx={{
      h1: {
        fontSize: '2xl',
      },
      h2: {
        fontSize: 'xl',
      },
      h3: {
        fontSize: 'lg',
      },
      'h1, h2, h3, h4': {
        fontWeight: 'bold',
        mb: 4,
      },
      'p, ul': {
        mb: 4,
      },
    }}
    dangerouslySetInnerHTML={{
      __html: sanitizeHtml(html, {
        allowedTags: [
          'p',
          'img',
          'br',
          'ul',
          'ol',
          'li',
          'h1',
          'h2',
          'h3',
          'h4',
          'i',
          'b',
          'strong',
          'em',
          'u',
        ],
      }),
    }}
  />
);

export default RenderHtml;
