import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { useHistory } from 'react-router';

import navRoutes from 'navigation/Routes';

import API from 'constants/api';
import { PLATFORM } from 'constants/env';
import { COUNTRY_OPTIONS } from 'constants/settings';
import { WORKSHOP_ORGS } from 'constants/organisation';

import { cohortActions, courseActions } from 'redux/actions/learner';
import { useUser } from 'redux/selectors';
import { hooks, colorUtils, getFlagEmoji } from 'utils';
import { getCookie } from 'utils/index';

import { GlobalState } from 'types';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import { Flex, Card, Text, useColorModeValue } from '@workshop/ui';
import { UserAvatar } from 'components/UserAvatar';
import { Loading } from 'components/Loading';

// @ts-ignore
import visaImage from 'assets/images/payment/visa.png'; // @ts-ignore
import visaElectronImage from 'assets/images/payment/visa-electron.png'; // @ts-ignore
import mastercardImage from 'assets/images/payment/mastercard.png'; // @ts-ignore
import maestroImage from 'assets/images/payment/maestro.png'; // @ts-ignore
import discoverImage from 'assets/images/payment/discover.png'; // @ts-ignore
import amexImage from 'assets/images/payment/amex.png';

import CheckoutSteps from './CheckoutSteps';

// Routing Props
interface MatchParams {
  courseSlug: string;
}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const CheckoutScreen: React.FC<Props> = ({
  course,
  courseSlug,
  cohorts,
  location,
}) => {
  const history = useHistory();

  const user = useUser();
  const isAuthenticated = !!user?.id;

  const { courseLoading } = hooks.useLoadingDataState(
    {
      courseLoading: {
        actions: isAuthenticated
          ? [
              () =>
                courseActions.retrieveSummary(
                  courseSlug,
                  location.pathname,
                  false
                ),
              () => cohortActions.list({ fetchNextPage: true }),
            ]
          : [
              () =>
                courseActions.retrieveSummary(
                  courseSlug,
                  location.pathname,
                  false
                ),
            ],
      },
    },
    [isAuthenticated]
  );

  useEffect(() => {}, [courseSlug]);

  // Get colors from team profile id or image
  const baseColor = course?.organisation?.id
    ? colorUtils.getRandomColor(
        course.organisation.id,
        undefined,
        undefined,
        'hex'
      )
    : '';
  const colors = hooks.useColors(course?.organisation?.brandColor || baseColor);

  const bg =
    useColorModeValue(
      colors?.light.primaryContainer,
      colors?.dark.primaryContainer
    ) || 'background.tint1';

  const textColor =
    useColorModeValue(
      colors?.light.onPrimaryContainer,
      colors?.dark.onPrimaryContainer
    ) || 'text.muted';

  const surface =
    useColorModeValue(colors?.light.surface, colors?.dark.surface) ||
    'background.tint3';

  const csrftoken = getCookie('csrftoken');

  if (
    PLATFORM !== 'steppit' ||
    (!course && !courseLoading) ||
    WORKSHOP_ORGS.includes(course?.organisation?.id)
  ) {
    history.push(navRoutes.common.home.path());
  }

  const hasPurchased = !!Object.values(cohorts).find(
    (c) => c.course === course?.slug
  );

  return (
    <ScreenWrapper>
      <Flex
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        left={0}
        // backgroundColor={surface}
        backgroundColor="background.tint3"
        zIndex={0}
        transition="background-color 1s"
      >
        {/* <Flex
          flex={1}
          backgroundColor={bg}
          transition="background-color 1s"
          opacity={0.14}
        /> */}
      </Flex>
      <Flex flexDirection="column" alignItems="center" zIndex={5} mt={-6}>
        {!course || courseLoading ? (
          <Flex mt={8}>
            <Loading />
          </Flex>
        ) : (
          <>
            <Flex mb={8}>
              <Link
                to={navRoutes.global.channel.path(course.organisation.handle)}
              >
                <Card
                  alignItems="center"
                  padding={1}
                  borderRadius="full"
                  backgroundColor={bg}
                  transition="background-color 2s"
                  boxShadow="none"
                  _hover={{ opacity: 0.8 }}
                >
                  <UserAvatar
                    name={course.organisation.name}
                    userId={course.organisation.id || 1}
                    avatarPicture={course.organisation.logoDark || ''}
                    size="2xs"
                  />
                  <Text
                    fontWeight="semibold"
                    ml={3}
                    mr={3}
                    color={textColor}
                    transition="color 2s"
                  >
                    {course.organisation.name}
                  </Text>
                </Card>
              </Link>
            </Flex>
            {/* @ts-ignore */}
            <CheckoutSteps
              productType="course"
              hasPurchased={hasPurchased}
              course={{
                slug: course.slug,
                title: course.title,
                image: course.imageLandscapeThumbnail,
                category: `${course.category}`,
                price: parseInt(course.price),
                paymentPlanPrice: undefined,
                paymentPlanTerm: undefined,
                paymentPlanAvailable: false,
                shareUrl: course.shareUrl,
                classType: course.classType,
                courseType: course.courseType,
              }}
              courseProvider={{
                id: course.organisation.id,
                name: course.organisation.name,
                logoUrl: course.organisation.logoDark || '',
                contactNumber: course.organisation.contactNumber || '',
                contactEmail: course.organisation.contactEmail || '',
                websiteUrl: course.organisation.websiteUrl || '',
                conversionTrackingUrl: '',
                stripeAccountId: course.organisation.stripeAccountId || '',
                stripeAccountStandard:
                  course.organisation.stripeAccountStandard || false,
              }}
              countries={Object.keys(COUNTRY_OPTIONS).reduce(
                (acc, key) => ({
                  ...acc,
                  [key]:
                    key === '-'
                      ? '-'
                      : `${getFlagEmoji(key.slice(0, 2))} ${
                          COUNTRY_OPTIONS[key]
                        }`,
                }),
                {}
              )}
              base="courses"
              user={{
                ...user,
                token: '',
                country: '',
              }}
              paymentTypeImages={{
                amex: amexImage,
                discover: discoverImage,
                maestro: maestroImage,
                mastercard: mastercardImage,
                visaElectron: visaElectronImage,
                visa: visaImage,
              }}
              endpoints={{
                accountResetPassword: API.auth.resetPassword(),
              }}
              csrftoken={csrftoken}
              authenticatedOnLoad={isAuthenticated}
              inviteImage=""
              secureImage=""
            />
          </>
        )}
      </Flex>
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: GlobalState, props: OwnProps) => {
  const { courseSlug } = props.match.params;

  const {
    courses: { courses: courseState, cohorts },
  } = state.learner;

  // Course Data
  const course = courseState.detail[courseSlug];
  return {
    course,
    courseSlug,
    cohorts,
  };
};

const connector = connect(mapStateToProps);

export default connector(withRouter(CheckoutScreen));
