import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import navRoutes from 'navigation/Routes';

import { InPageNav } from 'components/Common';

interface ClassesMenuProps extends RouteComponentProps {}

const ClassesMenu: React.FC<ClassesMenuProps> = ({ location }) => {
  let initialTab = 'classes';
  const currentPath = location.pathname.replace(/\/$/, '');
  if (currentPath === navRoutes.cms.students.path()) {
    initialTab = 'students';
  }
  return (
    <InPageNav
      initialTab={initialTab}
      tabs={[
        {
          slug: 'classes',
          icon: 'Workspaces',
          label: 'Class Management',
          linkTo: navRoutes.cms.classes.path(),
        },
        {
          slug: 'students',
          icon: 'People',
          label: 'My Students',
          linkTo: navRoutes.cms.students.path(),
        },
      ]}
    />
  );
};

export default withRouter(ClassesMenu);
