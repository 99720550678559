import React from 'react';
import { useForm } from 'react-hook-form';

import { Flex, AlertDialogIcon } from '@workshop/ui';
import {
  EditCard,
  LabelInput,
  DynamicList,
  useDynamicList,
} from 'components/Common';

import { CheckList, CheckListItem } from 'types/common';
import { RequirementSessionFormData } from 'types/cms';

import { hooks } from 'utils';

interface RequirementCardProps {
  description?: string;
  error?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isUpdating?: boolean;
  name?: string;
  onCancel?: () => void;
  onSave: (formData: RequirementSessionFormData) => void;
  requirements?: CheckList;
}

const noop = () => null;

const sortItems = (a: CheckListItem, b: CheckListItem) => {
  if (a.id && b.id) {
    return a.id - b.id;
  }
  return 0;
};

// TODO: Replace with FormCard
const RequirementCard: React.FC<RequirementCardProps> = ({
  isDisabled = false,
  isLoading = false,
  isUpdating = false,
  name = '',
  onCancel = noop,
  onSave,
  requirements,
}) => {
  const { register, handleSubmit, errors, clearError, formState, reset } =
    useForm<RequirementSessionFormData>();

  hooks.useDeepEqualEffect(() => {
    // Reset the state as default after state change (successful update)
    reset();
  }, [reset, requirements]);

  const rules = {
    title: (value: string) => !Boolean(value === ''),
  };

  const {
    // TODO: formData needs to have an accurate type
    formData,
    setReset,
    isValid,
    setDirty,
    ...dynamicProps
  } = useDynamicList(rules);

  const onSubmit = handleSubmit((uncontrolledFormData) =>
    onSave({ ...uncontrolledFormData, items: formData })
  );
  return (
    <EditCard
      onSave={onSubmit}
      onCancel={() => {
        onCancel();
        clearError();
        reset();
        setReset(true);
      }}
      saveDisabled={Boolean(
        (!formState.dirty && !isValid) || !!errors.requirementsTitle
      )}
      isDisabled={isDisabled}
      isUpdating={isUpdating}
    >
      <Flex direction="column">
        <Flex flexDirection="column" flex={1} mr={requirements ? '3rem' : 0}>
          {!isLoading && (
            <LabelInput
              isDisabled={isDisabled}
              id="requirementsTitle"
              name="requirementsTitle"
              label="Title"
              // ToDo check inputs with backend rules
              registerInputRef={register({
                required: true,
                maxLength: 60,
              })}
              error={Boolean(errors.requirementsTitle)}
              errorMessage="Please enter a title (max: 60 char)"
              defaultValue={requirements?.title}
              tooltip="session_requirements_title"
            />
          )}
        </Flex>
        <Flex flexDirection="column" flex={1}>
          <DynamicList
            defaultValues={requirements?.items.sort(sortItems)}
            {...dynamicProps}
          >
            {({
              inputId,
              inputIndex,
              value,
              itemIsFinal,
              hasInputError,
              handleChangeEvent,
              handleRemove,
            }) => (
              <Flex alignItems="center">
                <Flex direction="column" flex={1} mr="1rem">
                  <LabelInput
                    isDisabled={isDisabled}
                    id={`title-${inputId}`}
                    name="title"
                    value={value?.title || ''}
                    label={inputIndex === 0 ? 'Items' : '    '}
                    error={hasInputError && hasInputError('title')}
                    errorMessage="This field is required"
                    isLoading={isLoading}
                    onChange={handleChangeEvent}
                    onPaste={handleChangeEvent}
                    tooltip={
                      inputIndex === 0 ? 'session_requirements_item' : undefined
                    }
                  />
                </Flex>
                <Flex direction="column" justifyContent="center">
                  {!isLoading && !isDisabled && requirements && !itemIsFinal && (
                    <AlertDialogIcon
                      name="RemoveCircle"
                      alertHeader="Remove Requirement"
                      alertBody="Are you sure you would like to remove this requirement?"
                      submitBtnLabel="Remove"
                      submitBtnColor="red"
                      onSubmit={() => {
                        if (handleRemove) {
                          handleRemove();
                          setDirty(true);
                        }
                      }}
                      onCancel={() => {}}
                      color={isLoading ? 'neutral.300' : 'red.300'}
                      cursor={isLoading ? 'default' : 'pointer'}
                      mb="defaultMargin"
                      boxSize="18px"
                      minW="18px"
                      minH="18px"
                    />
                  )}
                </Flex>
              </Flex>
            )}
          </DynamicList>
        </Flex>
      </Flex>
    </EditCard>
  );
};

export default RequirementCard;
