import React, { useState } from 'react';

import navRoutes from 'navigation/Routes';

import { helpUrl, blogUrl, aiFeatureUrl } from 'constants/env';

import {
  Link as ExternalLink,
  Flex,
  Text,
  Box,
  Stack,
  Container,
  SimpleGrid,
  MdIcon,
  useColorModeValue,
} from '@workshop/ui';
import { Link } from 'react-router-dom';

import Brand from 'components/Brand';

import { PaymentsWelcomeModal } from 'screens/cms/MyOrganisation/src';

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  const [showPricing, setShowPricing] = useState(false);
  const currentYear = new Date().getFullYear();
  const linkStyle = {
    display: 'inline-block',
    _hover: { textDecoration: 'underline' },
  };
  const activeColorMode =
    localStorage.getItem('chakra-ui-color-mode') || 'system';
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
      zIndex={1}
      as="footer"
    >
      <Container as={Stack} maxW="6xl" py={10}>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} color="text.muted">
          <Stack align={{ base: 'center', md: 'flex-start' }}>
            {/* <Box {...linkStyle}>
              <Link to={navRoutes.public.howItWorks.path()}>How It Works</Link>
            </Box> */}
            <Box {...linkStyle}>
              <Link to={navRoutes.global.planner.path()}>
                Plan a Course with AI
              </Link>
            </Box>
            <Box {...linkStyle}>
              <ExternalLink href={aiFeatureUrl}>
                Learn About Our Pro AI
              </ExternalLink>
            </Box>
            <ExternalLink
              target="_blank"
              rel="noopener noreferrer"
              href={blogUrl}
            >
              Blog
            </ExternalLink>
            <ExternalLink
              target="_blank"
              rel="noopener noreferrer"
              href={helpUrl}
            >
              Help Center
            </ExternalLink>
          </Stack>
          <Stack align={{ base: 'center', md: 'flex-start' }}>
            <Box {...linkStyle}>
              <Text cursor="pointer" onClick={() => setShowPricing(true)}>
                Pricing
              </Text>
            </Box>
            <Box {...linkStyle}>
              <Link to={navRoutes.public.signup.path()}>Sign Up for Free</Link>
            </Box>
            <Box {...linkStyle}>
              <Link to={navRoutes.public.login.path()}>Log In</Link>
            </Box>
          </Stack>
          <Stack align={{ base: 'center', md: 'flex-start' }}>
            <Stack direction="row">
              <Box {...linkStyle}>
                <Link to={navRoutes.global.privacy.path()}>Privacy Policy</Link>
              </Box>
              <Text>|</Text>
              <Box {...linkStyle}>
                <Link to={navRoutes.global.cookies.path()}>Cookies</Link>
              </Box>
            </Stack>
            <Box {...linkStyle}>
              <Link to={navRoutes.global.terms.path()}>Terms of Service</Link>
            </Box>
            <Box>
              <Flex
                padding={1}
                background="background.default"
                borderRadius="full"
                mt={3}
              >
                <Flex
                  alignItems="center"
                  paddingX={2}
                  paddingY={1}
                  cursor="pointer"
                  bg={
                    activeColorMode === 'light'
                      ? 'background.tint3'
                      : 'transparent'
                  }
                  _hover={{ bg: 'background.tint3' }}
                  borderRadius="full"
                  onClick={() => {
                    localStorage.setItem('chakra-ui-color-mode', 'light');
                    window.location.reload();
                  }}
                >
                  <MdIcon name="LightMode" color="icon.default" mr={2} />
                  <Text fontSize="sm" fontWeight="semibold">
                    Light
                  </Text>
                </Flex>
                <Flex
                  alignItems="center"
                  paddingX={2}
                  paddingY={1}
                  cursor="pointer"
                  bg={
                    activeColorMode === 'dark'
                      ? 'background.tint3'
                      : 'transparent'
                  }
                  _hover={{ bg: 'background.tint3' }}
                  borderRadius="full"
                  mx={2}
                  onClick={() => {
                    localStorage.setItem('chakra-ui-color-mode', 'dark');
                    window.location.reload();
                  }}
                >
                  <MdIcon name="DarkMode" color="icon.default" mr={2} />
                  <Text fontSize="sm" fontWeight="semibold">
                    Dark
                  </Text>
                </Flex>
                <Flex
                  alignItems="center"
                  paddingX={2}
                  paddingY={1}
                  cursor="pointer"
                  bg={
                    activeColorMode === 'system'
                      ? 'background.tint3'
                      : 'transparent'
                  }
                  _hover={{ bg: 'background.tint3' }}
                  borderRadius="full"
                  onClick={() => {
                    localStorage.removeItem('chakra-ui-color-mode');
                    window.location.reload();
                  }}
                >
                  <MdIcon name="BrightnessMedium" color="icon.default" mr={2} />
                  <Text fontSize="sm" fontWeight="semibold">
                    Auto
                  </Text>
                </Flex>
              </Flex>
            </Box>
          </Stack>
        </SimpleGrid>
      </Container>
      <Box py={10}>
        <Flex
          align="center"
          _before={{
            content: '""',
            borderBottom: '1px solid',
            borderColor: useColorModeValue('gray.200', 'gray.700'),
            flexGrow: 1,
            mr: 8,
          }}
          _after={{
            content: '""',
            borderBottom: '1px solid',
            borderColor: useColorModeValue('gray.200', 'gray.700'),
            flexGrow: 1,
            ml: 8,
          }}
        >
          <Brand navbarTransparent={false} />
        </Flex>
        <Text pt={6} fontSize="sm" textAlign="center">
          {`©${currentYear} Workshop Tech Solutions Ltd.`}
        </Text>
      </Box>
      {showPricing && (
        <PaymentsWelcomeModal
          isOpen
          onClose={() => setShowPricing(false)}
          context="pricing"
          isConnected={false}
        />
      )}
    </Box>
  );
};

export default Footer;
