import groupBy from 'lodash/groupBy';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { useHistory } from 'react-router';
import moment from 'moment';

import { PLATFORM } from 'constants/env';
import { WORKSHOP_ORGS } from 'constants/organisation';
import { COURSE_PUBLISH_STATUS } from 'constants/courses';
import { PRO_ORGS } from 'constants/organisation';

import navRoutes from 'navigation/Routes';

import {
  Box,
  Button,
  Flex,
  Skeleton,
  Divider,
  Text,
  Link,
  MdIcon,
  Stack,
  Card,
  chakra,
} from '@workshop/ui';

import { hooks, getParamFromUrl } from 'utils';

import { courseActions, enrolmentActions } from 'redux/actions/cms';
import { courseActions as learnerCourseActions } from 'redux/actions/learner';
import { listCohorts, updateCohort } from 'redux/actions/cms/cohort';
import { listTeamMembers } from 'redux/actions/common/organisation';
import { useHasPermission, useCurrentTeamProfile } from 'redux/selectors';

import {
  CohortListCard,
  CreateCohortModal,
  EditCohortModal,
} from 'components/CohortAllocation';
import { SectionTitle, InformationCard } from 'components/Common';
import { IconTooltip } from 'components/IconTooltip';
import { ClassesMenu } from 'components/SideMenu';
import { HeaderTag } from 'components/AppHeader';
import { Loading } from 'components/Loading';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import { GlobalState } from 'types';
import { Cohort, PERMISSION_SLUGS } from 'types/common';

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux, RouteComponentProps {}

const CohortAllocationScreen = (props: Props) => {
  const [showCreateCohort, setShowCreateCohort] = React.useState(false);
  const [editCohortId, setEditCohortId] = React.useState<number | null>(null);
  const [inactiveListStatus, setInactiveListStatus] =
    React.useState('unloaded');

  const currentTeamProfile = useCurrentTeamProfile();
  const currentTeamOrg = currentTeamProfile?.id;

  const {
    cohort,
    currentTeam,
    currentTeamMemberList,
    ui,
    licenses,
    courses,
    permissions,
  } = props;

  const isPro = Boolean(
    currentTeamProfile?.isPro || (currentTeam && PRO_ORGS.includes(currentTeam))
  );

  const popupParam = getParamFromUrl(props.location, 'p');

  useEffect(() => {
    if (popupParam === 'new') {
      setShowCreateCohort(true);
    }
  }, [popupParam]);

  const dispatch = hooks.useDispatch();
  const history = useHistory();

  // Certain API calls and UI elements should only be available if the user
  // has edit member permissions
  const isEditingDisabled = !useHasPermission(
    PERMISSION_SLUGS.can_edit_classes
  );

  const { cohorts: cohortsLoading, listTeamMembers: listTeamMembersLoading } =
    hooks.useLoadingDataState(
      {
        cohorts: {
          actions: [
            () => courseActions.list({ fetchNextPage: true }),
            () => enrolmentActions.listLicenses(),
            () =>
              listCohorts({
                fetchNextPage: true,
                queryParams: 'status=active',
              }),
          ],
        },
        listTeamMembers: {
          actions:
            !isEditingDisabled && currentTeam ? [() => listTeamMembers()] : [],
        },
      },
      [currentTeam]
    );

  useEffect(() => {
    if (licenses.length > 0) {
      let coursesToFetch: string[] = [];
      licenses.forEach((l) => {
        const slugs = l.courses.map((c) => c.slug);
        coursesToFetch = [...new Set([...coursesToFetch, ...slugs])];
      });
      coursesToFetch.forEach((courseSlug) => {
        dispatch(learnerCourseActions.retrieve(courseSlug));
      });
    }
  }, [licenses.length]);

  const loadExpiredCohorts = async () => {
    setInactiveListStatus('loading');
    await dispatch(
      listCohorts({
        fetchNextPage: true,
        queryParams: 'status=inactive',
      })
    );
    setInactiveListStatus('loaded');
  };

  const orgCohorts = Object.values(cohort).filter(
    (c) => c.organisation === currentTeamOrg
  );

  useEffect(() => {
    if (
      !cohortsLoading &&
      !ui.cohort.loading &&
      !orgCohorts.length &&
      inactiveListStatus === 'unloaded'
    ) {
      // If no cohorts have been loaded, check for expired cohorts
      loadExpiredCohorts();
    }
  }, [
    cohortsLoading,
    orgCohorts.length,
    ui.cohort.loading,
    inactiveListStatus,
  ]);

  const loading =
    cohortsLoading ||
    listTeamMembersLoading ||
    (ui.cohort.loading && !orgCohorts.length);

  if (loading)
    return (
      <ScreenWrapper>
        <Flex flexDir="column">
          <Skeleton
            isLoaded={false}
            loadingStyle={{ height: 10, my: 2, mt: 10, width: 0.9 }}
          >
            <Box flex="1"></Box>
          </Skeleton>
          <Skeleton
            isLoaded={false}
            loadingStyle={{ height: 10, my: 2, width: 0.9 }}
          >
            <Box flex="1"></Box>
          </Skeleton>
        </Flex>
      </ScreenWrapper>
    );

  // Group our cohorts by class/course name
  const cohortsByCourse: { [key: string]: Cohort[] } = groupBy(
    orgCohorts.sort(
      (a, b) =>
        new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    ),
    'course'
  );

  const mentorPermissionId = Object.values(permissions).find(
    (p) => p.slug === PERMISSION_SLUGS.can_manage_students
  )?.id;
  // Format the Team Members data into Mentor data
  const availableMentors = Object.values(currentTeamMemberList).filter(
    (member) =>
      mentorPermissionId && member.permissions.includes(mentorPermissionId)
  );

  const editCohort = orgCohorts.find((c) => c.id === editCohortId);

  // License is valid if it contains the course for this cohort,
  // is not expired and has enrolments remaining
  const validLicenses = licenses.filter(
    (l) =>
      l.licensee.id === currentTeamOrg &&
      (!l.expiryDate ||
        (l.expiryDate && moment(l.expiryDate).isAfter(moment()))) &&
      l.totalEnrolments > l.enrolments.length
  );

  const externalLicenses = validLicenses.filter((l) => !l.allLicenseeCourses);

  const myCourses = Object.values(courses).filter(
    (c) => c.organisation === currentTeamOrg && c.courseType !== 'session'
  );

  const publishedCourses = myCourses.filter(
    (c) => c.status === COURSE_PUBLISH_STATUS.published
  );

  const canStartClass =
    !isEditingDisabled &&
    (externalLicenses.length > 0 ||
      (PLATFORM === 'workshop' &&
        currentTeam &&
        WORKSHOP_ORGS.includes(currentTeam)) ||
      (PLATFORM === 'steppit' && publishedCourses.length > 0));

  if (Object.keys(cohortsByCourse).length === 0) {
    return (
      <ScreenWrapper>
        {showCreateCohort && canStartClass && (
          <CreateCohortModal
            title="Start a Class"
            isOpen
            onClose={() => setShowCreateCohort(false)}
            onCancel={() => setShowCreateCohort(false)}
            onSave={() => null}
            onCreateCohort={(id) => setEditCohortId(id)}
            availableMentors={availableMentors}
          />
        )}
        <Flex
          color="text.muted"
          textAlign="center"
          maxWidth="680px"
          mx="auto"
          flex={1}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mb="20vh"
        >
          <Card
            flexDirection="column"
            alignItems="center"
            padding={{ base: 8, md: 16 }}
            paddingY={16}
            mb={4}
          >
            <Stack direction={{ base: 'column', sm: 'row' }} spacing={4} mb={8}>
              {[
                { icon: 'DateRange', label: 'Unlock Units Weekly' },
                { icon: 'QuestionAnswer', label: 'Class Group Chat' },
                { icon: 'DynamicFeed', label: 'Private Posts Feed' },
                { icon: 'SupervisedUserCircle', label: 'One-to-one\nSupport' },
              ].map(({ icon, label }) => (
                <Flex
                  key={`feature-${label}`}
                  flex={1}
                  flexDirection="column"
                  alignItems="center"
                  maxW="100px"
                >
                  <MdIcon
                    name={icon}
                    color="icon.primary"
                    boxSize={{ base: 12 }}
                    mb={4}
                  />
                  <Text
                    color="text.primary"
                    fontSize="sm"
                    fontWeight="semibold"
                    whiteSpace="break-spaces"
                  >
                    {label}
                  </Text>
                </Flex>
              ))}
            </Stack>
            <Text mb={2}>
              Classes let you enrol small groups of students onto your course so
              that they can follow units <b>week by week</b>, collaborate in a{' '}
              <b>class chat</b>, exchange feedback in a <b>private feed</b> of
              class posts and talk to you <b>one-to-one</b> whenever they need
              support.
            </Text>
            {myCourses.length === 0 && externalLicenses.length === 0 && (
              <Text mt={2}>
                <chakra.span
                  color="common.primary"
                  fontWeight="bold"
                  cursor="pointer"
                  _hover={{ textDecoration: 'underline' }}
                  onClick={() =>
                    history.push({
                      pathname: navRoutes.cms.catalogue.path(),
                      search: 'p=course',
                    })
                  }
                >
                  Build a course
                </chakra.span>{' '}
                to get started.
              </Text>
            )}

            {canStartClass ? (
              <Flex mt={6} mx={{ base: 2, md: 0 }} justifyContent="center">
                <Flex
                  justifyContent="flex-end"
                  marginX={{ base: 'defaultMargin', md: 0 }}
                  mb={{ base: 4, md: 2 }}
                >
                  <Button
                    onClick={() => setShowCreateCohort(true)}
                    icon="GroupAdd"
                  >
                    Start a Class
                  </Button>
                </Flex>
              </Flex>
            ) : null}
          </Card>
          {isPro && (
            <Box
              mx={{ base: 'defaultMargin', md: 0 }}
              color="text.default"
              textAlign="left"
            >
              <InformationCard id="pro_classes" mb={4} />
            </Box>
          )}
          {!isEditingDisabled && externalLicenses.length > 0 ? (
            <Box
              mx={{ base: 'defaultMargin', md: 0 }}
              color="text.default"
              textAlign="left"
            >
              <InformationCard id="classes_active_license" mb={4} />
            </Box>
          ) : null}
        </Flex>
      </ScreenWrapper>
    );
  }

  return (
    <ScreenWrapper>
      <ClassesMenu />
      {isPro && (
        <Box mx={{ base: 'defaultMargin', md: 0 }}>
          <InformationCard id="pro_classes" mb={4} />
        </Box>
      )}
      {showCreateCohort && canStartClass && (
        <CreateCohortModal
          title="Start a Class"
          isOpen
          onClose={() => setShowCreateCohort(false)}
          onCancel={() => setShowCreateCohort(false)}
          onSave={() => null}
          onCreateCohort={(id) => setEditCohortId(id)}
          availableMentors={availableMentors}
        />
      )}
      {editCohort && (
        <EditCohortModal
          cohort={editCohort}
          isOpen
          onClose={() => setEditCohortId(null)}
          onCancel={() => setEditCohortId(null)}
          onSave={() => null}
          modalSize="4xl"
          availableMentors={availableMentors}
          isLicensed={
            editCohort.courseDetails.organisation.id !== currentTeamOrg
          }
          validLicenses={validLicenses.filter(
            (l) =>
              !!l.courses.find((c) => c.slug === editCohort?.course) ||
              l.allLicenseeCourses
          )}
        />
      )}
      {!isEditingDisabled && externalLicenses.length > 0 ? (
        <Box mx={{ base: 'defaultMargin', md: 0 }}>
          <InformationCard id="classes_active_license" mb={4} />
        </Box>
      ) : null}

      {canStartClass ? (
        <Flex
          justifyContent="center"
          p={6}
          bg="background.primary"
          borderRadius="md"
          marginX={{ base: 'defaultMargin', md: 0 }}
          mb={6}
        >
          <Button onClick={() => setShowCreateCohort(true)} icon="GroupAdd">
            Start a Class
          </Button>
        </Flex>
      ) : null}
      {Object.keys(cohortsByCourse).length ? (
        Object.keys(cohortsByCourse)
          .sort((a, b) => a.localeCompare(b))
          .map((key, idx) => {
            const firstCohort = Object.values(cohortsByCourse[key])[0];
            const courseTitle = firstCohort.courseDetails.title;
            const courseOrg = firstCohort.courseDetails.organisation;
            const isLicensed = courseOrg.id !== currentTeamOrg;
            return (
              <Flex key={`cohort-${key}-${idx}`} mb={8} flexDir="column">
                <Flex mb="3">
                  <Flex flex={1} alignItems="center">
                    <SectionTitle
                      title={courseTitle}
                      mb="0"
                      pr={{ base: 0, md: 'defaultPadding' }}
                    />
                    {isLicensed && (
                      <Flex alignItems="center">
                        <HeaderTag
                          title={`From ${courseOrg.name}`}
                          bg="background.primary"
                          color="text.primary"
                        />
                        {courseOrg.contactEmail && (
                          <Link
                            href={`mailto:${courseOrg.contactEmail}`}
                            p={1.5}
                            borderRadius="full"
                          >
                            <IconTooltip
                              iconName="Email"
                              message={`If you have any questions for ${courseOrg.name}, please contact ${courseOrg.contactEmail}.`}
                              mb={0}
                            />
                          </Link>
                        )}
                      </Flex>
                    )}
                  </Flex>
                </Flex>
                <Box>
                  <CohortListCard
                    data={Object.values(cohortsByCourse[key])}
                    availableMentors={availableMentors}
                    onEdit={(id) => setEditCohortId(id)}
                    onSave={async (
                      values: { mentors: number[] },
                      cohortId: number
                    ) => dispatch(updateCohort(cohortId, values))}
                    isDisabled={isEditingDisabled}
                    isLoading={ui.cohort.loading}
                  />
                </Box>
              </Flex>
            );
          })
      ) : (
        <Text color="text.muted" mx={{ base: 'defaultPadding', md: 0 }}>
          You have no active classes
        </Text>
      )}
      <Divider my={4} />
      <Flex justifyContent="flex-end" mx={{ base: 'defaultPadding', md: 0 }}>
        {inactiveListStatus === 'unloaded' ? (
          <Button
            size="xs"
            variant="ghost"
            icon="History"
            onClick={() => loadExpiredCohorts()}
          >
            Show Expired Classes
          </Button>
        ) : inactiveListStatus === 'loading' ? (
          <Box>
            <Loading />
          </Box>
        ) : null}
      </Flex>
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: GlobalState) => {
  const { cms, organisation, ui } = state;
  const {
    cohort,
    enrolment: { license },
    course: { courseList: courses },
  } = cms;
  const { myTeams, currentTeam, currentTeamMemberList, permissions } =
    organisation;
  return {
    cohort,
    myTeams,
    currentTeam,
    currentTeamMemberList,
    ui,
    licenses: Object.values(license),
    courses,
    permissions,
  };
};

const connector = connect(mapStateToProps);

export default connector(CohortAllocationScreen);
