import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';

import navRoutes from 'navigation/Routes';

import { hooks } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';
import { useCurrentTeamProfile, useCurrentTeam } from 'redux/selectors';

import { assistantActions } from 'redux/actions/common';

import { PLATFORM } from 'constants/env';
import { PRO_ORGS } from 'constants/organisation';
import {
  ASSISTANT_TOOLS,
  ASSISTANT_TRAITS,
  ASSISTANT_TRAINING_PROGRESS,
  AssistantToolSlug,
  AssistantTraitSlug,
} from 'constants/assistant';

import { GlobalState } from 'types';

import {
  Flex,
  Box,
  Text,
  Card,
  Button,
  LinkButton,
  Stack,
  CircularProgress,
  MdIcon,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Tooltip,
  Skeleton,
  useTheme,
  chakra,
} from '@workshop/ui';

import {
  SectionTitle,
  InformationCard,
  ProCta,
  AssistantAnimation,
} from 'components/Common';
import { Loading } from 'components/Loading';
import { AssistantTrainingPopup } from 'components/AssistantTraining';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import DigestsPopup from './DigestsPopup';

// Routing Props
interface MatchParams {}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const TOOLS_SECTIONS = [
  { index: 0, slug: 'creating', label: 'For Creating' },
  { index: 1, slug: 'marketing', label: 'For Marketing' },
  { index: 2, slug: 'teaching', label: 'For Teaching' },
];

const DigestCta: React.FC<{}> = ({}) => {
  return (
    <Flex
      fontSize="sm"
      fontWeight="semibold"
      color="text.success"
      alignItems="center"
    >
      <MdIcon name="Done" mr={1.5} />
      <Text>Active on your weekly digests</Text>
    </Flex>
  );
};

const Assistant: React.FC<Props> = ({ digests }) => {
  const [toolsSection, setToolsSection] = useState<
    'creating' | 'marketing' | 'teaching'
  >('creating');
  const [traitPopup, setTraitPopup] = useState<AssistantTraitSlug | null>(null);
  const [digestsPopupIsOpen, setDigestsPopupIsOpen] = useState(false);

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const history = useHistory();

  const currentTeamProfile = useCurrentTeamProfile();
  const currentTeam = useCurrentTeam();
  const isPro = Boolean(
    currentTeamProfile?.isPro || (currentTeam && PRO_ORGS.includes(currentTeam))
  );

  const { digests: digestsLoading } = hooks.useLoadingDataState(
    {
      digests: {
        actions: [() => assistantActions.listDigests({ fetchNextPage: false })],
      },
    },
    [currentTeam]
  );

  const digestsList = Object.values(digests).sort(
    (a, b) => b.issueNumber - a.issueNumber
  );

  const latestDigest = !isPro
    ? {
        id: 1,
        slug: '',
        json: {
          summary: {
            headline: `Weekly Digest for ${currentTeamProfile?.name}`,
            body: 'Check out your weekly digest for personalised tips and insights, made just for you.',
          },
        },
        issueNumber: 1,
        created: new Date(),
      }
    : digestsList.length > 0
    ? digestsList[0]
    : null;
  const latestDigestDate = latestDigest ? moment(latestDigest.created) : null;

  const assistantTraits = ASSISTANT_TRAITS.map((trait) => {
    const trainingCount =
      (currentTeamProfile?.traits &&
        currentTeamProfile.traits[trait.slug]?.count) ||
      0;
    // @ts-ignore
    const stepsRepeat = !!trait.trainingSteps.find((s) => s.repeat);
    const maxSteps = stepsRepeat
      ? Object.keys(ASSISTANT_TRAINING_PROGRESS).length
      : trait.trainingSteps.length;
    const progress =
      maxSteps === Object.keys(ASSISTANT_TRAINING_PROGRESS).length
        ? // @ts-ignore
          ASSISTANT_TRAINING_PROGRESS[trainingCount] >= 0
          ? // @ts-ignore
            ASSISTANT_TRAINING_PROGRESS[trainingCount]
          : 100
        : Math.min((trainingCount / maxSteps) * 100, 100);
    return {
      ...trait,
      progress,
    };
  });

  const toolCtas: {
    slug: AssistantToolSlug;
    cta: React.ReactElement | null;
  }[] = [
    {
      slug: 'channelPage',
      cta: (
        <Text color="text.muted" fontSize="sm" fontWeight="semibold">
          Available from your{' '}
          <Link to={navRoutes.cms.editChannel.path()}>
            <chakra.span
              color="text.primary"
              _hover={{ textDecoration: 'underline' }}
            >
              Channel
            </chakra.span>{' '}
            tab
          </Link>
        </Text>
      ),
    },
    {
      slug: 'marketInsights',
      cta: <DigestCta />,
    },
    {
      slug: 'socialPosts',
      cta: (
        <Button variant="outline" size="sm">
          Create a Post
        </Button>
      ),
    },
    {
      slug: 'basicCourses',
      cta: isPro ? (
        <Text color="text.muted" fontSize="sm" fontWeight="semibold">
          Upgraded to the Pro Course Planner 👇
        </Text>
      ) : (
        <LinkButton
          to={navRoutes.global.planner.path()}
          variant="outline"
          size="sm"
        >
          Plan a Course
        </LinkButton>
      ),
    },
    {
      slug: 'proCourses',
      cta: (
        <Text color="text.muted" fontSize="sm" fontWeight="semibold">
          Available from your{' '}
          <Link to={navRoutes.cms.catalogue.path()}>
            <chakra.span
              color="text.primary"
              _hover={{ textDecoration: 'underline' }}
            >
              Content
            </chakra.span>
          </Link>{' '}
          tab
        </Text>
      ),
    },
    {
      slug: 'sessions',
      cta: (
        <Text color="text.muted" fontSize="sm" fontWeight="semibold">
          Available on your{' '}
          <Link to={navRoutes.cms.catalogue.path()}>
            <chakra.span
              color="text.primary"
              _hover={{ textDecoration: 'underline' }}
            >
              Content
            </chakra.span>
          </Link>{' '}
          while making a session
        </Text>
      ),
    },
    {
      slug: 'scripts',
      cta: (
        <Text color="text.muted" fontSize="sm" fontWeight="semibold">
          Available on your{' '}
          <Link to={navRoutes.cms.catalogue.path()}>
            <chakra.span
              color="text.primary"
              _hover={{ textDecoration: 'underline' }}
            >
              Content
            </chakra.span>
          </Link>{' '}
          while making a session
        </Text>
      ),
    },
    {
      slug: 'clipSummaries',
      cta: (
        <Text color="text.muted" fontSize="sm" fontWeight="semibold">
          Available on your{' '}
          <Link to={navRoutes.cms.catalogue.path()}>
            <chakra.span
              color="text.primary"
              _hover={{ textDecoration: 'underline' }}
            >
              Content
            </chakra.span>
          </Link>{' '}
          while making a session
        </Text>
      ),
    },
    {
      slug: 'courseTextBoxes',
      cta: (
        <Text color="text.muted" fontSize="sm" fontWeight="semibold">
          Available on your{' '}
          <Link to={navRoutes.cms.catalogue.path()}>
            <chakra.span
              color="text.primary"
              _hover={{ textDecoration: 'underline' }}
            >
              Content
            </chakra.span>
          </Link>{' '}
          while making a course or session
        </Text>
      ),
    },
    // {
    //   slug: 'sessionTextBoxes',
    //   cta: (
    //     <Text color="text.muted" fontSize="sm" fontWeight="semibold">
    //       Available on your{' '}
    //       <Link to={navRoutes.cms.catalogue.path()}>
    //         <chakra.span
    //           color="text.primary"
    //           _hover={{ textDecoration: 'underline' }}
    //         >
    //           Content
    //         </chakra.span>
    //       </Link>{' '}
    //       while making a session
    //     </Text>
    //   ),
    // },
    {
      slug: 'guide',
      cta: (
        <Button variant="outline" size="sm">
          Chat with My Assistant
        </Button>
      ),
    },
    {
      slug: 'audits',
      cta: <DigestCta />,
    },
    {
      slug: 'quizzes',
      cta: (
        <Text color="text.muted" fontSize="sm" fontWeight="semibold">
          Available on your{' '}
          <Link to={navRoutes.cms.catalogue.path()}>
            <chakra.span
              color="text.primary"
              _hover={{ textDecoration: 'underline' }}
            >
              Content
            </chakra.span>
          </Link>{' '}
          while making a session
        </Text>
      ),
    },
    {
      slug: 'rewards',
      cta: (
        <Flex
          fontSize="sm"
          fontWeight="semibold"
          color="text.success"
          alignItems="center"
        >
          <MdIcon name="Done" mr={1.5} />
          <Text>Active for all learners on your courses</Text>
        </Flex>
      ),
    },
    {
      slug: 'schedule',
      cta: (
        <Flex
          fontSize="sm"
          fontWeight="semibold"
          color="text.success"
          alignItems="center"
        >
          <MdIcon name="Done" mr={1.5} />
          <Text>Active for all learners on your courses</Text>
        </Flex>
      ),
    },
  ];

  const tools = Object.values(ASSISTANT_TOOLS).map((t) => ({
    ...t,
    cta: toolCtas.find((c) => c.slug === t.slug)?.cta || null,
  }));

  if (PLATFORM !== 'steppit') {
    history.push(navRoutes.public.login.path());
    return null;
  }

  if (!currentTeamProfile) {
    return (
      <ScreenWrapper>
        <Loading />
      </ScreenWrapper>
    );
  }

  return (
    <ScreenWrapper>
      {isPro && (
        <Box mx={{ base: 'defaultMargin', md: 0 }}>
          <InformationCard id="pro_assistant" mb={8} />
        </Box>
      )}
      <Stack spacing={8}>
        {!isPro && (
          <Card
            padding={6}
            pt={2}
            pb={10}
            flexDirection="column"
            alignItems="center"
          >
            <Flex
              boxSize="image.lg"
              alignItems="center"
              justifyContent="center"
              zIndex={1}
              borderRadius="full"
            >
              <Text fontSize="6xl">👋</Text>
            </Flex>
            <Text
              fontWeight="extrabold"
              fontSize={{ base: '3xl', md: '4xl' }}
              lineHeight={1.2}
              mb={2}
            >
              Need a hand?
            </Text>
            <Text
              mb={6}
              textAlign="center"
              color="text.muted"
              fontSize="lg"
              maxW="500px"
              mx="auto"
            >
              Upgrade your channel to Pro to unlock your own fully personalised
              AI-powered assistant.
            </Text>
            <ProCta />
          </Card>
        )}
        <Box>
          <SectionTitle title="Training" />
          {!isPro && (
            <Flex px={{ base: 'defaultMargin', md: 0 }}>
              <InformationCard
                id="assistant-training"
                information={{
                  title: 'Personalise and Train Your Assistant',
                  description:
                    'Your assistant can help you with everything from creating content to selling your courses, and by training it up in 5 key traits it can be completely personalised to your unique preferences, values and tone of voice.',
                }}
                isDismissable={false}
                mb={6}
                cta={
                  <ProCta
                    label=""
                    ctaText="Unlock"
                    ctaProps={{
                      size: 'xs',
                      ml: { base: 0, sm: 4 },
                      mt: { base: 2, sm: 0 },
                      colorScheme: 'blue',
                    }}
                  />
                }
              />
            </Flex>
          )}
          <Card
            padding={{ base: 2, sm: 3, md: 4 }}
            mb={{ base: 4, md: 0 }}
            overflow="none"
            alignItems="center"
            position="relative"
            opacity={isPro ? 1 : 0.5}
          >
            <Flex
              position={{ base: 'absolute', md: 'relative' }}
              bottom={{ base: -8, md: 'auto' }}
            >
              <Flex display={{ base: 'flex', md: 'none' }}>
                <AssistantAnimation boxSize={70} />
              </Flex>
              <Flex display={{ base: 'none', md: 'flex' }}>
                <AssistantAnimation boxSize={120} />
              </Flex>
            </Flex>
            <Flex
              flex={1}
              alignItems="center"
              pl={{ base: 1, md: 2, lg: 5 }}
              pb={{ base: 8, md: 0 }}
            >
              <Flex
                flex={1}
                direction="row"
                alignItems="center"
                flexWrap="wrap"
                pointerEvents={isPro ? 'auto' : 'none'}
              >
                {assistantTraits.map((t) => (
                  <Flex
                    key={`assistant-trait-${t.slug}`}
                    flexDirection={{ base: 'row', md: 'column' }}
                    alignItems="center"
                    justifyContent="center"
                    w={{ base: 'auto', md: 90 }}
                    h={{ base: 50, md: 110 }}
                    mr={{ base: 4, md: 0 }}
                    cursor="pointer"
                    _hover={{ transform: 'scale(1.05)' }}
                    transition="transform 0.3s"
                    // @ts-ignore
                    onClick={() => setTraitPopup(t.slug)}
                  >
                    <Flex
                      position="relative"
                      alignItems="center"
                      justifyContent="center"
                      mb={{ base: 0, md: 1 }}
                    >
                      <CircularProgress
                        value={t.progress}
                        // @ts-ignore
                        size={{ base: 10, md: 16 }}
                        thickness="8px"
                        color={`${t.colorScheme}.400`}
                        trackColor="background.tint2"
                        capIsRound
                        sx={{
                          '& > div:first-child': {
                            transitionProperty: 'width',
                          },
                        }}
                      />

                      <Flex
                        position="absolute"
                        bg={`${t.colorScheme}.100`}
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="full"
                        boxSize={{ base: 6, md: 10 }}
                      >
                        <MdIcon
                          name={t.icon}
                          color={`${t.colorScheme}.500`}
                          boxSize={{ base: 4, md: 6 }}
                        />
                      </Flex>
                    </Flex>
                    <Button
                      rightIcon={
                        <Box ml={-1}>
                          <MdIcon name="ModelTraining" fontSize="md" />
                        </Box>
                      }
                      size={'xs'}
                      variant="ghost"
                      colorScheme={t.colorScheme}
                    >
                      {t.labelShort}
                    </Button>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </Card>
        </Box>
        <Box>
          <Flex
            alignItems="center"
            mb={2}
            pr={{ base: 'defaultMargin', md: 0 }}
          >
            <SectionTitle title="Latest Digest" mb={0} />
            <Flex flex={1} />
            {isPro && (
              <Button
                size="sm"
                variant="ghost"
                icon="List"
                onClick={() => setDigestsPopupIsOpen(true)}
              >
                See All Digests
              </Button>
            )}
          </Flex>
          {!isPro && (
            <Flex px={{ base: 'defaultMargin', md: 0 }}>
              <InformationCard
                id="assistant-digests"
                information={{
                  title: 'Weekly Digests Made Just for You (Coming Soon)',
                  description:
                    'Every week, your assistant will send you a weekly digest full of helpful advice, market insights from the last week and all sorts of recommendations to help you stay on track with your goals and feel good about your progress each week.',
                }}
                isDismissable={false}
                mb={6}
                cta={
                  <ProCta
                    label=""
                    ctaText="Unlock"
                    ctaProps={{
                      size: 'xs',
                      ml: { base: 0, sm: 4 },
                      mt: { base: 2, sm: 0 },
                      colorScheme: 'blue',
                    }}
                  />
                }
              />
            </Flex>
          )}
          <Card padding={{ base: 6, md: 6 }} opacity={isPro ? 1 : 0.5}>
            <Skeleton isLoaded={!digestsLoading}>
              {latestDigest ? (
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing={6}
                  alignItems={{ base: 'flex-start', md: 'center' }}
                >
                  <Flex
                    w="image.lg"
                    flexDirection="column"
                    bg="background.tint2"
                    borderRadius="md"
                    overflow="hidden"
                  >
                    <Flex
                      bg="red.300"
                      textAlign="center"
                      flexDirection="column"
                    >
                      <Flex px={2}>
                        <Flex flexDirection="column" alignItems="center">
                          <Box bg="background.default" w={0.5} h={2.5} />
                          <Box
                            bg="background.default"
                            w={2}
                            h={2}
                            mt={-1}
                            borderRadius="full"
                          />
                        </Flex>
                        <Flex flex={1} />
                        <Flex flexDirection="column" alignItems="center">
                          <Box bg="background.default" w={0.5} h={2.5} />
                          <Box
                            bg="background.default"
                            w={2}
                            h={2}
                            mt={-1}
                            borderRadius="full"
                          />
                        </Flex>
                      </Flex>
                      <Text
                        fontSize="lg"
                        color="#fff"
                        fontWeight="semibold"
                        mt={-1}
                      >
                        {latestDigestDate?.format('MMM').toUpperCase()}
                      </Text>
                    </Flex>
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                    >
                      <Text fontSize="5xl" fontWeight="semibold">
                        {latestDigestDate?.format('D')}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex flex={1} flexDirection="column" alignItems="flex-start">
                    <Text fontWeight="bold" fontSize="lg">
                      {latestDigest.json.summary.headline}
                    </Text>
                    <Text
                      fontWeight="semibold"
                      fontSize="sm"
                      color="text.muted"
                      mb={2}
                    >
                      {`${latestDigestDate?.format('MMM Do [‘]YY')} • Issue #${
                        latestDigest.issueNumber
                      }`}
                    </Text>
                    <Text noOfLines={2}>{latestDigest.json.summary.body}</Text>
                    {isPro && latestDigest.slug && (
                      <LinkButton
                        mt={4}
                        icon="ArrowForward"
                        iconPosition="right"
                        size="sm"
                        variant="outline"
                        to={navRoutes.cms.digest.path(latestDigest.slug)}
                      >
                        Read Digest
                      </LinkButton>
                    )}
                  </Flex>
                </Stack>
              ) : !latestDigest && isPro ? (
                <Text color="text.muted">
                  Your first digest will be available soon!
                </Text>
              ) : null}
            </Skeleton>
          </Card>
        </Box>
        <Box>
          <SectionTitle title="Tools & Insights" />
          {!isPro && (
            <Flex px={{ base: 'defaultMargin', md: 0 }}>
              <InformationCard
                id="assistant-tools"
                information={{
                  description:
                    'Flick through the tabs below to see how your assistant can help you create content, market your courses and teach your classes...',
                  overlay: true,
                }}
                isDismissable={false}
                mb={3}
                cta={
                  <ProCta
                    label=""
                    ctaText="Unlock"
                    ctaProps={{
                      size: 'xs',
                      ml: { base: 0, sm: 4 },
                      mt: { base: 2, sm: 0 },
                      colorScheme: 'blue',
                    }}
                  />
                }
              />
            </Flex>
          )}
          <Tabs
            index={TOOLS_SECTIONS.find((s) => s.slug === toolsSection)?.index}
            onChange={(index) => {
              // @ts-ignore
              setToolsSection(TOOLS_SECTIONS[index]?.slug);
            }}
            isLazy
            variant="unstyled"
            mt={4}
          >
            <Flex
              alignItems="center"
              mb={6}
              mx={{ base: 'defaultMargin', md: 0 }}
            >
              <TabList flex={1} pr={4} flexWrap="wrap">
                {TOOLS_SECTIONS.map((t) => (
                  <Tab
                    key={`tab-${t.slug}`}
                    px={4}
                    py={2}
                    mr={2}
                    fontSize={{ base: 'sm', md: 'md' }}
                    borderRadius="full"
                    transition="background-color 0.3s, color 0.3s"
                    color="text.muted"
                    _hover={{
                      bg: 'background.primary',
                    }}
                    _active={{
                      bg: 'background.primaryDark',
                    }}
                    _selected={{
                      color: 'text.primaryDark',
                      bg: 'background.primaryDark',
                    }}
                  >
                    <Text fontWeight="semibold">{t.label}</Text>
                  </Tab>
                ))}
              </TabList>
            </Flex>
            <TabPanels>
              {TOOLS_SECTIONS.map((s) => (
                <TabPanel key={`tab-panel-${s.slug}`} padding={0}>
                  {tools
                    .filter((t) => t.section === s.slug)
                    .map((t) => (
                      <Card
                        key={`tool-${t.slug}`}
                        padding={6}
                        mb="defaultMargin"
                        opacity={t.disabled ? 0.5 : 1}
                      >
                        <Flex
                          flexDirection="column"
                          alignItems="flex-start"
                          w="100%"
                        >
                          <Flex
                            mb={2}
                            alignItems={{ base: 'flex-start', md: 'center' }}
                            w="100%"
                            flexDirection={{ base: 'column', md: 'row' }}
                          >
                            <Flex
                              flex={1}
                              alignItems={{ base: 'flex-start', sm: 'center' }}
                              flexDirection={{ base: 'column', sm: 'row' }}
                              mr={{ base: 0, md: 3 }}
                              mb={{ base: 2, md: 0 }}
                            >
                              <Text fontSize="2xl" mr={3}>
                                {t.emoji}
                              </Text>
                              <Text flex={1} fontSize="xl" fontWeight="bold">
                                {t.label}
                              </Text>
                            </Flex>
                            <Stack mb={{ base: 2, md: 0 }} direction="row">
                              {assistantTraits.map((trait) => (
                                <Flex
                                  key={`tool-${t.slug}-trait-${trait.slug}`}
                                  alignItems="center"
                                  justifyContent="center"
                                  display={
                                    (trait.slug === 'tone' && t.traitTone) ||
                                    (trait.slug === 'skillset' &&
                                      t.traitSkillset) ||
                                    (trait.slug === 'values' &&
                                      t.traitValues) ||
                                    (trait.slug === 'audience' &&
                                      t.traitAudience) ||
                                    (trait.slug === 'context' && t.traitContext)
                                      ? 'flex'
                                      : 'none'
                                  }
                                >
                                  <Tooltip
                                    label={`This tool considers your ${trait.label.toLowerCase()}`}
                                  >
                                    <Flex
                                      bg={`${trait.colorScheme}.100`}
                                      alignItems="center"
                                      justifyContent="center"
                                      borderRadius="full"
                                      boxSize={{ base: 6, md: 6 }}
                                    >
                                      <MdIcon
                                        name={trait.icon}
                                        color={`${trait.colorScheme}.500`}
                                        boxSize={{ base: 4, md: 4 }}
                                      />
                                    </Flex>
                                  </Tooltip>
                                </Flex>
                              ))}
                            </Stack>
                          </Flex>
                          <Text color="text.muted" mb={3}>
                            {t.description}
                          </Text>
                          {(isPro || t.free) && !t.disabled ? (
                            t.cta
                          ) : !isPro && !t.disabled ? (
                            <Text
                              color="text.muted"
                              fontSize="sm"
                              fontWeight="semibold"
                            >
                              Available with Pro
                            </Text>
                          ) : (
                            t.disabled && (
                              <Text
                                color="text.muted"
                                fontSize="sm"
                                fontWeight="semibold"
                              >
                                Coming Soon
                              </Text>
                            )
                          )}
                        </Flex>
                      </Card>
                    ))}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Box>
        {!isPro && <ProCta label="Unlock your AI assistant with" />}
      </Stack>
      {isPro && (
        <>
          <AssistantTrainingPopup
            isOpen={traitPopup !== null}
            onClose={() => setTraitPopup(null)}
            trait={traitPopup}
          />
          <DigestsPopup
            isOpen={digestsPopupIsOpen}
            onClose={() => setDigestsPopupIsOpen(false)}
          />
        </>
      )}
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    digests: state.assistant.digests,
  };
};

const connector = connect(mapStateToProps);

export default connector(Assistant);
