import React from 'react';
import { useSelector } from 'react-redux';
import { FaMoneyBillWave, FaGem } from 'react-icons/fa';
import { GiReceiveMoney } from 'react-icons/gi';
import { FiPercent } from 'react-icons/fi';

import { GlobalState } from 'types';

import { Box, Text, Flex, chakra } from '@workshop/ui';

import { PLATFORM_DISPLAY_NAME } from 'constants/common';
import { BASIC_UNIT_LIMIT, PRO_PRICE } from 'constants/organisation';

import { StepsModal } from 'components/Common';

interface PaymentsWelcomeModalProps {
  isOpen: boolean;
  onClose: () => void;
  isConnected: boolean;
  context?: 'setup' | 'pricing';
}

const PaymentsWelcomeModal: React.FC<PaymentsWelcomeModalProps> = ({
  isOpen,
  onClose,
  isConnected,
  context = 'setup',
}) => {
  const location = useSelector(
    (state: GlobalState) => state.background.location
  );
  const revSplit = (promo: boolean) => (
    <Box display={{ base: 'none', sm: 'block' }}>
      <Flex
        borderLeftWidth="1px"
        borderRightWidth="1px"
        borderColor="border.default"
        py={1}
        mt={2}
        mb={3}
        position="relative"
        alignItems="center"
      >
        <Flex position="absolute" w="100%" h="100%">
          <Flex flex={80} alignItems="center">
            <Flex flex={1} h="1px" bg="border.default" />
            <Text fontSize="xs" color="text.muted" px={2}>
              80%
            </Text>
            <Flex flex={1} h="1px" bg="border.default" />
          </Flex>
          <Box width="1px" bg="border.default" />
          <Flex flex={20} alignItems="center">
            <Flex flex={1} h="1px" bg="border.default" />
            <Text fontSize="xs" color="text.muted" px={2}>
              20%
            </Text>
            <Flex flex={1} h="1px" bg="border.default" />
          </Flex>
        </Flex>
      </Flex>
      <Flex textAlign="center" mb={10}>
        <Flex flex={8}>
          <Flex flex={1}>
            <Flex
              flex={96.25}
              flexDirection="column"
              alignItems="center"
              position="relative"
            >
              <Flex w="100%" h={4} bg="blue.300" borderLeftRadius="full"></Flex>
              {!promo && (
                <Text
                  top={5}
                  fontSize="xs"
                  color="text.primary"
                  position="absolute"
                >
                  Your share
                </Text>
              )}
            </Flex>
            <Flex
              flex={3.75}
              flexDirection="column"
              alignItems="center"
              position="relative"
            >
              <Flex
                w="100%"
                h={4}
                bg="orange.200"
                borderRightRadius="full"
              ></Flex>
              {!promo && (
                <Text
                  top={5}
                  fontSize="xs"
                  color="text.warning"
                  position="absolute"
                >
                  Stripe
                </Text>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex w={3} />
        <Flex flex={2}>
          <Flex
            flex={1}
            flexDirection="column"
            alignItems="center"
            position="relative"
          >
            <Flex
              w="100%"
              h={4}
              bg="green.300"
              borderRadius="full"
              justifyContent="flex-end"
            >
              {promo && <Flex w="85%" bg="red.200" borderRightRadius="full" />}
            </Flex>
            <Text
              top={5}
              fontSize="xs"
              color={promo ? 'text.error' : 'text.success'}
              position="absolute"
            >
              {promo ? 'Up to 20% off' : PLATFORM_DISPLAY_NAME}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );

  const steps = [
    {
      label: 'Revenue',
      icon: <FaMoneyBillWave />,
      nextButtonText: 'Next',
      content: (
        <Box py={4} textAlign="center">
          <Text pb={4}>
            {`Create and share unlimited sessions and courses on ${PLATFORM_DISPLAY_NAME} for free. When you're ready to monetise your content, you can sell cohort-based classes that offer premium learning experiences. Revenue from classes is shared between you and ${PLATFORM_DISPLAY_NAME}.`}
          </Text>
          {revSplit(false)}
          <Text pb={4}>
            {
              'Our revenue-sharing model is simple: 80% goes to you (from which Stripe takes a '
            }
            <chakra.a
              color="text.info"
              href="https://stripe.com/pricing"
              target="_blank"
              rel="noopener noreferrer"
              _hover={{ textDecoration: 'underline' }}
            >
              small fee
            </chakra.a>
            {`) and 20% goes to ${PLATFORM_DISPLAY_NAME}.`}
          </Text>
          <Text fontWeight="semibold">
            Our success is tied to yours, and so we’re here to support your
            channel’s growth every step of the way.
          </Text>
        </Box>
      ),
    },
    {
      label: 'Discounts',
      icon: <FiPercent />,
      nextButtonText: 'Next',
      content: (
        <Box py={4} textAlign="center">
          {revSplit(true)}
          <Text pb={4}>
            To help boost sales of your classes, we may run promotions with
            discounts up to 20% off by sacrificing part or all of our revenue
            share.
          </Text>
          <Text fontWeight="semibold">
            Rest assured that we’ll cover these discounts from our share of the
            revenue so you won’t be impacted.
          </Text>
        </Box>
      ),
    },
  ];
  if (context === 'setup') {
    steps.push({
      label: 'Payouts',
      icon: <GiReceiveMoney />,
      nextButtonText: isConnected ? 'Got It 👍' : 'Get Started',
      content: (
        <Box py={4} textAlign="center">
          <Text pb={4}>
            {`To start selling classes on ${PLATFORM_DISPLAY_NAME}, you’ll need to set up a Stripe account for your channel.`}
          </Text>
          <Text pb={4}>
            {`When students enrol on your classes, they’ll pay directly into your Stripe account, with ${PLATFORM_DISPLAY_NAME}’s share applied as an application fee.`}
          </Text>
          <Text pb={6} fontWeight="semibold">
            Once you’re set up and earning money, Stripe will make payouts to
            your bank account on a regular basis.
          </Text>
          <Box p={4} bg="background.warning" borderRadius="md">
            <Text fontWeight="semibold" color="text.warning">
              Please note that you are responsible for complying with all tax
              and regulatory policies in your country.
            </Text>
          </Box>
        </Box>
      ),
    });
  }
  if (context === 'pricing') {
    steps.push({
      label: 'Pro',
      icon: <FaGem />,
      nextButtonText: 'Got It 👍',
      content: (
        <Box py={4} textAlign="center">
          <Text pb={4}>
            {`With a free ${PLATFORM_DISPLAY_NAME} channel, you can build and sell unlimited courses up to ${BASIC_UNIT_LIMIT} units long (each unit can hold up to 6 sessions).`}
          </Text>
          <Text>
            {`If you'd like to build bigger courses, run private classes, add team members to your channel and get more AI assistance, you can upgrade your channel to Pro for ${PRO_PRICE(
              location?.currency
            )} per month.`}
          </Text>
        </Box>
      ),
    });
  }
  return (
    <StepsModal
      heading={
        context === 'pricing'
          ? `Pricing on ${PLATFORM_DISPLAY_NAME}`
          : isConnected
          ? `Receiving Payments on ${PLATFORM_DISPLAY_NAME}`
          : `Start Selling on ${PLATFORM_DISPLAY_NAME}`
      }
      isOpen={isOpen}
      onClose={onClose}
      onCompleteStep={async (stepIndex) => {
        if (stepIndex === 2) {
          onClose();
        }
      }}
      steps={steps}
      modalSize="xl"
    />
  );
};

export default PaymentsWelcomeModal;
