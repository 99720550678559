import moment from 'moment';
import values from 'lodash/values';

import { PROGRESS_STATUS, ACCESS_TYPES, UNIT_TYPE } from 'constants/courses';

import {
  CourseDetails,
  CourseProgress,
  CourseSchedule,
  Module,
  ModuleProgressState,
  ModuleScheduleState,
  ModuleState,
  ModuleType,
  ModuleVideoClip,
  ProgressStatus,
  Unit,
  UnitProgress,
  UnitProgressState,
  UnitSchedule,
  UnitScheduleState,
  UnitState,
  JournalEntry,
} from 'types/learner';
import { CheckList, Cohort } from 'types/common';

import {
  getModuleCompletionStatus,
  getCohortSubcategoryId,
  getUnitLockedStatus,
} from 'utils/learner';

interface CourseModelConstructor {
  course: CourseDetails | null;
  courseProgress: CourseProgress | null;
  courseSchedule: CourseSchedule | null;
  units: UnitState | null;
  unitSchedule: UnitScheduleState | null;
  unitProgress: UnitProgressState | null;
  modules: ModuleState | null;
  moduleProgress: ModuleProgressState | null;
  moduleSchedule: ModuleScheduleState | null;
  journalEntries: JournalEntry[] | null;
  cohort: Cohort | null;
}

interface IncompleteSession extends Module {
  unitPrefix: string;
  progressStatus: ProgressStatus;
  scheduled: boolean;
  scheduledTime: string;
}

export interface DetailedUnitNote {
  title: string;
  moduleType: ModuleType;
  steps: {
    stepType: ModuleType;
    title: string;
    notes: string[];
  }[];
}

export type DetailedUnitNotes = DetailedUnitNote[];

export interface DetailedUnitCheckList {
  title: string;
  checkList: CheckList[];
}

export type DetailedUnitCheckLists = DetailedUnitCheckList[];

export interface DetailedUnit extends Omit<Unit, 'modules'> {
  tag: string;
  description: string;
  startDate: string;
  disabled: boolean;
  image: string;
  isScheduled: boolean;
  kitlist: string;
  modules: Module[];
  percentageComplete: number;
  progress: UnitProgress | null;
  schedule: UnitSchedule | null;
  summary: string;
  unitCheckLists: DetailedUnitCheckLists;
  unitNotes: DetailedUnitNotes;
  video: string;
}

class CourseModel {
  _course: CourseDetails | null;
  _courseProgress: CourseProgress | null;
  _courseSchedule: CourseSchedule | null;
  _units: UnitState | null;
  _unitProgress: UnitProgressState | null;
  _unitSchedule: UnitScheduleState | null;
  _modules: ModuleState | null;
  _moduleSchedule: ModuleScheduleState | null;
  _moduleProgress: ModuleProgressState | null;
  _journalEntries: JournalEntry[] | null;
  _cohort: Cohort | null;

  constructor({
    course,
    modules,
    units,
    courseSchedule,
    moduleSchedule,
    unitSchedule,
    courseProgress,
    moduleProgress,
    unitProgress,
    journalEntries,
    cohort,
  }: CourseModelConstructor) {
    this._course = course;
    this._modules = modules;
    this._units = units;
    this._courseSchedule = courseSchedule;
    this._moduleSchedule = moduleSchedule;
    this._unitSchedule = unitSchedule;
    this._courseProgress = courseProgress;
    this._moduleProgress = moduleProgress;
    this._unitProgress = unitProgress;
    this._journalEntries = journalEntries;
    this._cohort = cohort;
  }

  getUnits = () => values(this._units);

  getModules = () => values(this._modules);

  getModulesSchedules = () => values(this._moduleSchedule);

  // TODO: use intro constant from SESSION_TYPE
  getIntroUnit = () => this.getUnits().find((u) => u.unitType === 'intro');

  // TODO use outro constant from SESSION_TYPE
  getOutroUnit = () => this.getUnits().find((u) => u.unitType === 'outro');

  /**
   * Returns all UnitSchedule items with `weekCommencing`
   * in the past (i.e over 7 days ago)
   */
  getPastUnitSchedules = () => {
    const unitSchedule = values(this._unitSchedule);

    return unitSchedule
      .filter(({ weekCommencing }) =>
        moment().isAfter(moment(weekCommencing).add(1, 'week'))
      )
      .sort((a, b) => moment(a.weekCommencing).diff(moment(b.weekCommencing)));
  };

  /**
   * Retrieve the unit schedule for the current week.
   */
  getCurrentUnitSchedule = () => {
    const unitSchedules = values(this._unitSchedule);

    // Current week is found by defining a 7 day window after the week commencing
    // date of a unit and detecing whether the current date/time falls within
    // that window
    return unitSchedules.find(
      ({ weekCommencing }) =>
        moment().isAfter(moment(weekCommencing)) &&
        moment().isBefore(moment(weekCommencing).add(7, 'days'))
    );
  };

  /**
   * Returns the 'current' unit based on todays date
   *
   * i.e. `weekCommencing` is after this week's start date
   * and before this week's start date + 7 days
   */
  getCurrentUnit = () => {
    if (!this._units) return null;

    const currentSchedule = this.getCurrentUnitSchedule();

    // If a schedule doesn't exist (e.g. course has not started or has finished)
    // then return null.
    if (!currentSchedule) return null;

    const { unit } = currentSchedule;
    return this._units[unit];
  };

  /** Returns a units converted to DetailedUnit
   * (include modules + unit description text + notes)
   */
  getDetailedUnit = (unit: Unit): DetailedUnit => {
    const modules = this.getSessionsForUnit(unit);
    const schedule = this._unitSchedule && this._unitSchedule[unit.slug];
    const progress = this._unitProgress && this._unitProgress[unit.slug];
    const moduleSchedules = this._moduleSchedule;
    const cohort = this._cohort;
    const course = this._course;

    const tag = unit.prefix;
    const description = unit.detailedSummary;
    let startDate = '';

    const unitSchedule = this._unitSchedule && this._unitSchedule[unit.slug];
    // Only set startDate if the unit starts after today's date and the cohort isn't open
    if (
      cohort &&
      cohort.accessType !== ACCESS_TYPES.open &&
      unitSchedule &&
      moment(unitSchedule.weekCommencing).isAfter()
    ) {
      startDate = moment(unitSchedule.weekCommencing).format('MMM Do');
    }

    const moduleScheduled =
      schedule &&
      progress &&
      moduleSchedules &&
      schedule.moduleSchedules
        .map((slug) => moduleSchedules[slug])
        .find((moduleSchedule) => !moduleSchedule.scheduled);

    const isScheduled =
      Boolean(!moduleScheduled) ||
      Boolean(progress && progress.status === PROGRESS_STATUS.complete);

    const unitNotes = modules
      .filter(
        ({ steps }) =>
          steps.length && steps.find((step) => step.videoClips.length)
      )
      .map((module) => {
        const { steps, title, moduleType } = module;

        return {
          title,
          moduleType,
          steps: steps.map((step) => ({
            stepType: step.stepType,
            title: step.title,
            notes: step.videoClips.map((clip) => clip.summary),
          })),
        };
      });

    const unitCheckLists = modules
      .filter(({ checkList }) => checkList.length)
      .map(({ checkList, title }) => ({ checkList, title }));

    // The unit is locked if a future assessment is active or if the unit
    // is marked as locked and is in the future, but not if it's been completed (this
    // accounts for the case on mentors who automatically have 'completed' a course)
    // On 'open' cohorts, unit is only locked if an assessment is active
    const locked = getUnitLockedStatus(cohort, progress, schedule);

    return {
      ...unit,
      tag,
      description,
      startDate,
      disabled: false,
      image: unit.imageThumbnail || course?.imageLandscapeThumbnail || '',
      isScheduled,
      kitlist: unit.fullKitList,
      percentageComplete: progress
        ? 1 - progress.modulesRemaining / progress.moduleCount
        : 0,
      progress,
      modules,
      schedule,
      summary: unit.detailedSummary,
      unitCheckLists,
      unitNotes,
      video: unit.videoHls,
      locked,

      // TODO: Verify that this has all been added
      // from native app (see unitData() method in BrowseScreen)

      // On 'open' cohorts, don't show start date and don't mark 'current week'
      // startDate:
      //   userCohort && userCohort.accessType !== ACCESS_TYPES.open && schedule
      //     ? moment(schedule.weekCommencing).format('MMM D')
      //     : null,
      // Is this unit the 'current' (time based) unit
      // isCurrentWeek:
      //   userCohort &&
      //   userCohort.accessType !== ACCESS_TYPES.open &&
      //   currentWeekSchedule &&
      //   schedule
      //     ? schedule.weekCommencing === currentWeekSchedule.weekCommencing
      //     : false,
      // The unit is locked if a future assessment is active or if the unit
      // is marked as locked and is in the future, but not if it's been completed (this
      // accounts for the case on mentors who automatically have 'completed' a course)
      // On 'open' cohorts, unit is only locked if an assessment is active
      // locked:
      //   activeAssessment.isActive && unit.index < activeAssessment.unitIndex
      //     ? true
      //     : userCohort && userCohort.accessType === ACCESS_TYPES.open
      //     ? false
      //     : progress
      //     ? unitProgress.status !== PROGRESS_STATUS.complete &&
      //       progress.locked &&
      //       schedule &&
      //       moment(schedule.weekCommencing).isAfter(moment())
      //     : true,

      // Is the user 'behind' on the current unit. Progress or schedule won't exist
      // for assessment units
      // User cannot be 'behind' on 'open' cohorts
      // isBehind:
      //   userCohort &&
      //   userCohort.accessType !== ACCESS_TYPES.open &&
      //   currentWeekSchedule &&
      //   schedule &&
      //   progress
      //     ? moment(schedule.weekCommencing).isBefore(
      //         currentWeekSchedule.weekCommencing
      //       ) && progress.status !== PROGRESS_STATUS.complete
      //     : false,
    };
  };

  /** Returns all units converted to DetailedUnit as a list
   * (include modules + unit description text)
   */
  getDetailedUnits = (): DetailedUnit[] => {
    const units = this.getUnits().filter(
      (u) =>
        u.unitType === UNIT_TYPE.normal || u.unitType === UNIT_TYPE.assessment
    );
    return units.map((u) => this.getDetailedUnit(u));
  };

  /** Returns all modules relating the given unit */
  getSessionsForUnit = (unit: Unit): Module[] => {
    const journalEntries = this._journalEntries || [];
    const modules = this._modules || {};
    const cohort = this._cohort;
    const course = this._course;
    return unit.modules.map((moduleSlug) => {
      const module = modules[moduleSlug];
      const schedule = this._moduleSchedule
        ? this._moduleSchedule[moduleSlug]
        : undefined;
      const progress = this._moduleProgress
        ? this._moduleProgress[moduleSlug]
        : undefined;
      const uploads = journalEntries.filter(
        (je) =>
          je.destinationContentType === 'module' &&
          module.id === je.destinationObjectId
      );
      const moduleCompletionStatus = progress
        ? getModuleCompletionStatus({
            module,
            uploads,
            progress,
          })
        : null;
      const discourseCategoryId =
        cohort && course
          ? getCohortSubcategoryId(cohort, course) || undefined
          : undefined;
      const tags = [`module-${module.index - 1}`, `unit-${unit.index - 1}`];

      return {
        ...module,
        schedule,
        progress,
        uploadMissing: moduleCompletionStatus === 'uploadMissing',
        uploads,
        discourseCategoryId,
        tags,
      };
    });
  };

  getIncompleteSessions = (): IncompleteSession[] => {
    const modules = this._modules || {};
    const units = this._units || {};
    const moduleProgress = this._moduleProgress || {};
    const moduleSchedule = this._moduleSchedule || {};
    const journalEntries = this._journalEntries || [];
    const cohort = this._cohort;
    const course = this._course;

    const pastUnitSchedules = this.getPastUnitSchedules();

    // Loop through each of the past unit schedules, turning the
    // array of module slugs into an array of module items, and
    // combining/reducing into a single array of all historic
    // module items
    const historicModules = pastUnitSchedules.reduce<Module[]>(
      (acc, u) => [
        ...acc,
        // If modules haven't loaded or been provided, default to an
        // empty object
        ...u.moduleSchedules.map((modSlug) => modules[modSlug] || {}),
      ],
      []
    );

    const filteredModules = historicModules
      .filter((m) => {
        // Don't include module if corresponding
        // progress / unit / schedule are missing
        //
        // TODO: If any of these conditions are true then it's likely
        // an error and we need to know about it.

        const unit = units[m.unit];
        const progress = moduleProgress[m.slug];
        const schedule = moduleSchedule[m.slug];

        return unit && progress && schedule;
      })
      .filter((m) => {
        // Don't include completed modules (if the module requires an upload,
        // only count it as complete if the upload is present)
        const hasUpload = !!journalEntries.find(
          (je) =>
            je.destinationContentType === 'module' &&
            m.id === je.destinationObjectId
        );
        const progress = moduleProgress[m.slug];
        const isCompleteWithUpload =
          progress?.status === PROGRESS_STATUS.complete &&
          m.requiresUpload &&
          hasUpload;
        const isComplete =
          isCompleteWithUpload ||
          (progress?.status === PROGRESS_STATUS.complete && !m.requiresUpload);
        return !isComplete;
      })
      .filter((m) => units[m.unit].unitType !== UNIT_TYPE.assessment);

    return filteredModules.map((module) => {
      const unit = units[module.unit];
      const progress = moduleProgress[module.slug];
      const schedule = moduleSchedule[module.slug];

      const uploads = journalEntries.filter(
        (je) =>
          je.destinationContentType === 'module' &&
          module.id === je.destinationObjectId
      );

      const moduleCompletionStatus = getModuleCompletionStatus({
        module,
        uploads,
        progress,
      });

      const discourseCategoryId =
        cohort && course ? getCohortSubcategoryId(cohort, course) : undefined;

      const tags = [`module-${module.index - 1}`, `unit-${unit.index - 1}`];

      const unitHasIntro = !!unit.modules.find(
        (m) => modules[m].moduleType === 'intro'
      );

      return {
        ...module,
        unitPrefix: unit.prefix,
        progressStatus: progress.status,
        scheduled: schedule.scheduled,
        scheduledTime: schedule.scheduledTime,
        title: `Unit ${unit.index}, Session ${
          unitHasIntro ? module.index - 1 : module.index
        }: ${module.title}`,
        schedule,
        progress,
        uploadMissing: moduleCompletionStatus === 'uploadMissing',
        uploads,
        discourseCategoryId,
        tags,

        // Copied over from native app
        // slug: module.slug,
        // imageTiny: module.imageTiny,
        // imageThumbnail: module.imageThumbnail,
        // sessionFormat: module.moduleFormat,
        // title: module.title,
        // index: module.index,
        // onPress: () => this.navigateToStepsContainer(module.slug, module.unit),
        // onSchedulePress: () =>
        //   navigation.navigate('ScheduleUnit', {
        //     title: course.title,
        //     unitSlug: unit.slug,
        //     weekCommencing: unitSchedules[unit.slug].weekCommencing,
        //     courseSlug: this.slug,
        //     moduleSlug: slug,
        //   }),
      };
    });
  };
}

export default CourseModel;
