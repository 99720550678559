import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  FcFaq,
  FcIdea,
  FcCalendar,
  FcDiploma1,
  FcMoneyTransfer,
  FcSportsMode,
  FcGallery,
} from 'react-icons/fc';
import { IoFootsteps, IoVideocam } from 'react-icons/io5';
import { FaRegHandshake } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router';
import { useLottie } from 'lottie-react';
import moment from 'moment';

import { GlobalState } from 'types';

import { PLATFORM, discordUrl, aiFeatureUrl } from 'constants/env';
import {
  PRO_TEAM_MEMBERS_LIMIT,
  PRO_MONTHLY_ENROLMENTS_LIMIT,
  BASIC_UNIT_LIMIT,
  PRO_PRICE,
  PRO_95_DISCOUNT_PRICE,
} from 'constants/organisation';
import { NAV_HEIGHT } from 'containers/AppHeader';

import navRoutes from 'navigation/Routes';

import {
  LinkBox,
  LinkOverlay,
  Flex,
  Text,
  Box,
  Stack,
  StackDivider,
  LinkButton,
  MdIcon,
  Container,
  Heading,
  Image,
  Icon,
  createIcon,
  IconProps,
  SimpleGrid,
  Grid,
  GridItem,
  chakra,
  useColorModeValue,
  Divider,
  Card,
  Button,
  BoxProps,
  CircularProgress,
  CircularProgressLabel,
} from '@workshop/ui';

import Brand, { ProGem, WorkshopIcon } from 'components/Brand';
import { Footer } from 'components/Footer';
import { SessionModal } from 'components/Common';
import { AppHeader } from 'containers/AppHeader';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import { PaymentsWelcomeModal } from 'screens/cms/MyOrganisation/src';

import iSteps from 'assets/images/illustrations/i-steps.png';
import iGroups from 'assets/images/illustrations/i-groups.png';
import iClasses from 'assets/images/illustrations/i-classes.png';
import iSales from 'assets/images/illustrations/i-sales.png';
import iCourses from 'assets/images/illustrations/i-courses.png';
import iFilming from 'assets/images/illustrations/i-filming.png';
import iAi from 'assets/images/illustrations/i-ai.png';
import avatar1 from 'assets/images/illustrations/avatar1.png';
import avatar2 from 'assets/images/illustrations/avatar2.png';
import avatar3 from 'assets/images/illustrations/avatar3.png';
import avatar4 from 'assets/images/illustrations/avatar4.png';

import wootricLogo from 'assets/images/brands/wootric-logo.png';

// @ts-ignore
import cocktailSessionImage from 'assets/images/examples/cocktail.jpg';
// @ts-ignore
import cakeSessionImage from 'assets/images/examples/cake.jpg';
// @ts-ignore
import kitchenSessionImage from 'assets/images/examples/kitchen.jpg';

// @ts-ignore
import sortedReviewImage from 'assets/images/reviews/sorted.jpg';
// @ts-ignore
import sortedReviewBgImage from 'assets/images/reviews/sortedBg.jpg';
// @ts-ignore
import joshImage from 'assets/images/reviews/josh.jpg';

// @ts-ignore
import barAotd from 'assets/images/reviews/bar-aotd.png';
// @ts-ignore
import barNewApps from 'assets/images/reviews/bar-newApps.png';

import assistantAnimation from 'assets/lottie/assistant.json';

const discount =
  'Try Pro free for 7 days and get your own personal AI assistant, tailored to you.';
const discountLabel = 'Try Our Pro AI For Free ✨';

interface LandingScreenProps extends RouteComponentProps {}

const Hero = () => {
  const discountIsValid = true;
  return (
    <Container maxW="7xl">
      <Stack
        align="center"
        spacing={{ base: 8, md: 10 }}
        pt={{ base: 0, md: 20 }}
        pb={{ base: 0, md: 16 }}
        mt={{ base: 24, md: 12 }}
        direction={{ base: 'column-reverse', md: 'row' }}
      >
        <Stack
          flex={1}
          spacing={{ base: 4, md: 10 }}
          alignItems={{ base: 'center', md: 'normal' }}
          textAlign={{ base: 'center', md: 'left' }}
          zIndex={1}
          pt={{ base: 2, md: 0 }}
          pb={{ base: 8, md: 0 }}
          maxWidth={{ base: 600, md: 'none' }}
        >
          <Heading
            as="h1"
            lineHeight={1.1}
            fontWeight="extrabold"
            fontSize={{ base: 'xl', sm: '3xl', md: '4xl', lg: '5xl' }}
          >
            Create and sell{' '}
            <chakra.span color="common.primary">
              immersive online courses
            </chakra.span>
            , stress-free.
          </Heading>
          <Text
            color="text.muted"
            fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}
          >
            Steppit is the free step-by-step platform that saves you time and
            money, while helping you build better courses for your followers.
          </Text>
          <Box display={{ base: 'none', md: 'block' }}>
            <LinkButton
              size="lg"
              px={6}
              icon="ArrowForward"
              iconPosition="right"
              to={navRoutes.global.planner.path()}
            >
              Start for Free
            </LinkButton>
          </Box>
        </Stack>
        <Flex
          flex={1}
          justify="center"
          align="center"
          position="relative"
          w="full"
        >
          <Image
            alt="Steppit Course"
            fit="contain"
            align="center"
            w="100%"
            h="100%"
            htmlHeight="500"
            htmlWidth="500"
            maxHeight={{ base: '210px', md: '500px' }}
            src={iSteps}
          />
        </Flex>
      </Stack>
    </Container>
  );
};

const FounderMessage = () => {
  return (
    <Container maxW="7xl" pb={10}>
      <Box position="relative" pl={{ base: '40px', md: '40px' }}>
        <Flex
          position="absolute"
          bottom={{ base: '-40px', md: '-40px' }}
          left={0}
          zIndex={2}
        >
          <Image
            height="60px"
            width="60px"
            htmlHeight="60"
            htmlWidth="60"
            borderRadius="full"
            bg="background.primary"
            src={joshImage}
            alt="Steppit Founder"
          />
        </Flex>
        <Card
          padding={{ base: 4, md: 6 }}
          borderRadius="lg"
          overflow="visible"
          boxShadow="none"
          backgroundColor="neutral.700"
          bgGradient="linear(to-br, neutral.800, neutral.500)"
          mt={4}
        >
          <Flex flexDirection="column" flex={1}>
            <Text
              color="#fff"
              fontSize={{ base: 'md', md: 'lg', '2xl': 'xl' }}
              flex={1}
            >
              <chakra.span fontWeight="bold">Our Beta’s live!</chakra.span>{' '}
              After 6 years of producing courses for thousands of learners,
              we're launching Steppit to help every creator share their
              knowledge with the world. By combining AI-assisted creation with
              interactive and social learning, we hope to build the best online
              course platform available and{' '}
              <chakra.span fontWeight="bold">
                we'd love to hear your feedback
              </chakra.span>
              .
            </Text>
          </Flex>
        </Card>
        <Flex
          fontSize="sm"
          color="text.muted"
          position="absolute"
          right={0}
          left="70px"
          pt={2}
        >
          <Text py={1}>Josh T, Founder</Text>
          <Flex flex={1} />
          <LinkButton
            variant="ghost"
            size="sm"
            icon="ArrowForward"
            iconPosition="right"
            href={discordUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Join Us On Discord
          </LinkButton>
        </Flex>
      </Box>
    </Container>
  );
};

interface FeatureProps {
  title: string;
  text: string;
  icon: React.ReactElement;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
  return (
    <Stack alignItems="center" textAlign="center">
      <Flex
        w={16}
        h={16}
        align="center"
        justify="center"
        color="white"
        rounded="full"
        bg={useColorModeValue('background.tint3', 'background.default')}
        mb={1}
      >
        {icon}
      </Flex>
      <Text fontWeight="bold" fontSize="xl">
        {title}
      </Text>
      <Text color="text.muted" fontSize={{ base: 'md', md: 'lg' }}>
        {text}
      </Text>
    </Stack>
  );
};

const ProSection = () => {
  const learnMoreColor = useColorModeValue('blue', 'green');
  return (
    <Flex
      flexDirection={{ base: 'column', md: 'row' }}
      textAlign={{ base: 'center', md: 'left' }}
      bg="background.success"
      color="text.success"
      borderRadius="lg"
      alignItems="center"
      p={{ base: 4, md: 6 }}
      mx={{ base: 4, md: 0 }}
      alignSelf="center"
      mt={20}
      mb={20}
    >
      <Flex
        position="relative"
        width={{
          base: '220px',
          sm: '250px',
          md: '250px',
          lg: '340px',
          xl: '370px',
        }}
        height={{
          base: '100px',
          sm: '120px',
          md: '200px',
          lg: '220px',
          xl: '240px',
        }}
        alignItems="center"
        mt={{ base: 8, md: 0 }}
      >
        <Box
          width={{
            base: '220px',
            sm: '250px',
            md: '250px',
            lg: '350px',
            xl: '400px',
          }}
          position="absolute"
          bottom={{
            base: 'auto',
            md: 'auto',
            lg: '-100px',
            xl: '-120px',
          }}
          left={{ base: 'auto', md: '-40px', lg: '-60px', xl: '-85px' }}
          top={{ base: '-120px', md: 'auto' }}
        >
          <Image alt="AI course creator illustration" src={iAi} />
        </Box>
      </Flex>
      <Flex
        flexDirection="column"
        alignItems={{ base: 'center', md: 'flex-start' }}
        flex={1}
        maxWidth="700px"
        mx="auto"
      >
        <Heading
          as="h2"
          lineHeight={1.1}
          fontWeight="extrabold"
          fontSize={{ base: 'xl', sm: '2xl' }}
          mb={2}
        >
          Unlock AI tailored to you, with Pro
        </Heading>
        <Text mb={5} fontSize={{ base: 'md', md: 'lg' }}>
          Upgrade to Pro to coach your own AI assistant and discover how easy it
          can be to plan, produce and sell courses in your unique style.
        </Text>
        <Stack direction={{ base: 'column', md: 'row' }} alignItems="center">
          <LinkButton
            to={`${navRoutes.global.planner.path()}?p=pro`}
            icon="ArrowForward"
            iconPosition="right"
            // colorScheme="red"
          >
            Try Pro for Free
          </LinkButton>
          <LinkButton
            variant="outline"
            icon="ArrowForward"
            iconPosition="right"
            colorScheme={learnMoreColor}
            href={aiFeatureUrl}
          >
            Learn More
          </LinkButton>
        </Stack>
      </Flex>
    </Flex>
  );
};

const Features = () => {
  return (
    <Container maxW="7xl">
      <Box p={4} my={{ base: 8, md: 12 }}>
        <Text
          fontWeight={{ base: 'semibold', md: 'bold' }}
          fontSize={{ base: 'xl', md: '2xl' }}
          textAlign="center"
          mb={10}
        >
          Additional Features
        </Text>
        <SimpleGrid
          columns={{ base: 1, sm: 2, md: 3 }}
          spacing={{ base: 6, md: 10 }}
        >
          <Feature
            icon={<Icon as={FcFaq} w={10} h={10} />}
            title="Interactive Feedback"
            text={
              'Share feedback and mentor your class in private posting feeds and messages.'
            }
          />
          <Feature
            icon={<Icon as={FcGallery} w={10} h={10} />}
            title="Brand Your Channel"
            text={
              'Personalize your channel with your own colour scheme, banner and profile picture.'
            }
          />
          <Feature
            icon={<Icon as={FcCalendar} w={10} h={10} />}
            title="Drip-feed Content"
            text={
              'Guide your classes through a few sessions each week or let them access everything at once.'
            }
          />
          <Feature
            icon={<Icon as={FcDiploma1} w={10} h={10} />}
            title="Award Certificates"
            text={
              'Require uploads on specific sessions and reward certificates on completion of your courses.'
            }
          />
          <Feature
            icon={<Icon as={FcMoneyTransfer} w={10} h={10} />}
            title="Easy Payments"
            text={
              'Just connect your channel to Stripe to start selling class spaces to your audience.'
            }
          />
          <Feature
            icon={<Icon as={FcSportsMode} w={10} h={10} />}
            title="Build Faster with Pro"
            text={
              'Upgrade to Pro to auto-generate summaries on your videos and plan extended courses with AI.'
            }
          />
        </SimpleGrid>
      </Box>
    </Container>
  );
};

const Special = () => {
  const [assistantJson, setAssistantJson] = useState(null);
  useEffect(() => {
    // @ts-ignore
    setAssistantJson(assistantAnimation);
  }, []);
  const { View: lottieAssistant } = useLottie({
    animationData: assistantJson,
    style: {
      height: 120,
      width: 120,
    },
  });
  const phBg = useColorModeValue('light', 'dark');
  const aiBg = useColorModeValue('#fff', '#221D21');
  const aiBorder = useColorModeValue(1, 0);
  return (
    <Container maxW="7xl" my={8}>
      <Stack
        spacing={{ base: 6, md: 8 }}
        textAlign="center"
        alignItems="center"
        bg="background.primary"
        px={{ base: 5, sm: 8, md: 16 }}
        py={{ base: 10, sm: 10, md: 16 }}
        borderRadius="xl"
        color="text.primary"
        my={6}
      >
        <Flex p={4} borderRadius="full" bg="background.default">
          {lottieAssistant}
        </Flex>
        <Flex flexDirection="column" alignItems="center">
          <Text
            lineHeight={1.1}
            mb={4}
            fontSize={{ base: 'lg', sm: 'xl', md: '2xl', lg: '3xl' }}
          >
            "How much could I make from my course?"
          </Text>
          <Heading
            as="h2"
            lineHeight={1.1}
            fontWeight="extrabold"
            textAlign="center"
            fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '3xl' }}
          >
            Find out in 5 minutes with our AI course creator
          </Heading>
        </Flex>
        <Box>
          <Text fontSize={{ base: 'md', sm: 'lg', md: 'xl' }} pb={4}>
            Think about what you could offer with your skills, then let our free
            AI course planner guide you through a few questions to help you plan
            a course and estimate its earning potential.
          </Text>
          <Text
            fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}
            fontWeight="semibold"
            pb={{ base: 6, md: 2 }}
          >
            For a bespoke plan designed specifically around you, start a free
            trial of Pro to unlock your advanced AI assistant.
          </Text>
        </Box>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'center', md: 'normal' }}
          spacing={{ base: 4, md: 3 }}
        >
          <Flex flexDirection="column" alignItems="center">
            <LinkButton
              rounded="full"
              px={6}
              size="md"
              icon="ArrowForward"
              iconPosition="right"
              to={navRoutes.global.planner.path()}
              display={{ base: 'flex', sm: 'none' }}
            >
              Try Our Free AI Planner
            </LinkButton>
            <LinkButton
              rounded="full"
              px={6}
              size="lg"
              icon="ArrowForward"
              iconPosition="right"
              to={navRoutes.global.planner.path()}
              display={{ base: 'none', sm: 'flex' }}
            >
              Try Our Free AI Planner
            </LinkButton>
          </Flex>
          <Flex flexDirection="column" alignItems="center">
            <LinkButton
              size="md"
              px={6}
              icon="ArrowForward"
              iconPosition="right"
              variant="outline"
              to={`${navRoutes.global.planner.path()}?p=pro`}
              display={{ base: 'flex', sm: 'none' }}
            >
              Plan with Advanced AI
            </LinkButton>
            <LinkButton
              size="lg"
              px={6}
              icon="ArrowForward"
              iconPosition="right"
              variant="outline"
              to={`${navRoutes.global.planner.path()}?p=pro`}
              display={{ base: 'none', sm: 'flex' }}
            >
              Plan with Advanced AI
            </LinkButton>
            <Flex alignItems="center" fontSize="sm" mt={2}>
              <MdIcon name="AutoAwesome" mr={1.5} />
              <Text>Try Pro free for 7 days</Text>
            </Flex>
          </Flex>
          {/* <LinkButton
            variant="ghost"
            icon="ArrowForward"
            iconPosition="right"
            href={aiFeatureUrl}
            mt={2}
          >
            Learn About Our Pro AI
          </LinkButton> */}
        </Stack>
        <Flex
          mt={16}
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
        >
          <Flex m={3} mb={0}>
            <chakra.a
              href="https://www.producthunt.com/posts/steppit?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-steppit"
              // href="https://www.producthunt.com/posts/steppit-personal-ai-assistant?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-steppit&#0045;personal&#0045;ai&#0045;assistant"
              target="_blank"
            >
              <Image
                src={`https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=393691&theme=${phBg}`}
                // src={`https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=414196&theme=${phBg}`}
                alt="Featured on Product Hunt"
                style={{
                  width: '200px',
                  height: '54px',
                }}
                htmlWidth="250"
                htmlHeight="54"
              />
            </chakra.a>
          </Flex>
          <Flex m={3} mb={0}>
            <chakra.a
              href="https://theresanaiforthat.com/ai/steppit/?ref=embed"
              target="_blank"
            >
              <Flex
                borderWidth={aiBorder}
                borderColor="#7B8793"
                borderRadius="10px"
                my="4px"
                bg={aiBg}
                style={{
                  width: '216px',
                  height: '44px',
                }}
              >
                <Image
                  htmlWidth="216"
                  htmlHeight="44"
                  style={{
                    width: '216px',
                    height: '44px',
                  }}
                  src="https://media.theresanaiforthat.com/featured1.png"
                  alt="Featured on There's An AI For That"
                />
              </Flex>
            </chakra.a>
          </Flex>
        </Flex>
      </Stack>
    </Container>
  );
};

interface PointProps {
  text: string;
  iconBg: string;
  icon?: React.ReactElement;
}

const Point = ({ text, icon, iconBg }: PointProps) => {
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      align="center"
      justify={{ base: 'center', md: 'normal' }}
    >
      <Flex
        w={8}
        h={8}
        align="center"
        justify="center"
        rounded="full"
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text fontWeight="semibold">{text}</Text>
    </Stack>
  );
};

const Detail1 = () => {
  return (
    <Container maxW="7xl" py={20}>
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, md: 20 }}
        alignItems="center"
        display={{ base: 'flex', md: 'grid' }}
        flexDirection={{ base: 'column', md: 'initial' }}
      >
        <Flex
          flex={1}
          justifyContent="center"
          alignItems="center"
          position="relative"
        >
          <Box
            position="absolute"
            boxSize="300px"
            bg="#fff"
            borderRadius="full"
          />
          <Image
            alt="Easy to Film"
            src={iFilming}
            objectFit="contain"
            maxHeight="300px"
            htmlHeight="300"
            htmlWidth="320"
            position="relative"
          />
        </Flex>
        <Stack spacing={4} textAlign={{ base: 'center', md: 'left' }}>
          <Heading
            as="h2"
            lineHeight={1.1}
            fontWeight="extrabold"
            fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '4xl' }}
          >
            Build your courses in a fraction of the time
          </Heading>
          <Text
            fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}
            px={{ base: 4, md: 0 }}
            color="text.muted"
          >
            Making video courses has never been more cost-effective with
            Steppit’s easy step-by-step course builder, and intuitive AI
            available with Pro.
          </Text>
          <Stack
            spacing={4}
            pt={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />
            }
          >
            <Point
              icon={<MdIcon name="AutoAwesome" color="blue.500" w={5} h={5} />}
              iconBg={useColorModeValue('blue.100', 'blue.900')}
              text="Assistance from AI available at every step"
            />
            <Point
              icon={<Icon as={IoVideocam} color="red.500" w={5} h={5} />}
              iconBg={useColorModeValue('red.100', 'red.900')}
              text="No special equipment or video editing needed"
            />
            <Point
              icon={<MdIcon name="Payments" color="green.500" w={5} h={5} />}
              iconBg={useColorModeValue('green.100', 'green.900')}
              text="Free to make & sell unlimited courses"
            />
          </Stack>
        </Stack>
      </SimpleGrid>
    </Container>
  );
};

interface TestimonialCardProps {
  name: string;
  location: string;
  role: string;
  handle: string;
  content: string;
  personAvatar: string;
  channelAvatar: string;
  index: number;
  colSpan: number;
  showStars?: boolean;
}

const TestimonialCard = (props: TestimonialCardProps) => {
  const {
    name,
    location,
    content,
    personAvatar,
    channelAvatar,
    handle,
    showStars,
  } = props;
  return (
    <GridItem w="100%" alignItems="center">
      <Flex justifyContent="center" w="100%" mx="auto">
        <Flex
          justifyContent="center"
          position="relative"
          _before={{
            content: '""',
            position: 'absolute',
            zIndex: 0,
            height: 'full',
            width: 'full',
            filter: 'blur(40px)',
            transform: 'scale(0.98)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            top: 0,
            left: 0,
            backgroundColor: 'background.primary',
          }}
        >
          <Flex
            boxShadow="lg"
            direction="column"
            rounded="xl"
            py={{ base: 6, md: 6 }}
            px={{ base: 4, md: 4 }}
            justifyContent="center"
            position="relative"
            bg={useColorModeValue('white', 'gray.800')}
            zIndex={1}
            _after={{
              content: '""',
              position: 'absolute',
              height: '21px',
              width: '29px',
              left: '35px',
              top: '-10px',
              backgroundSize: 'cover',
              backgroundImage: `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='21' viewBox='0 0 29 21' fill='none'%3E%3Cpath d='M6.91391 21C4.56659 21 2.81678 20.2152 1.66446 18.6455C0.55482 17.0758 0 15.2515 0 13.1727C0 11.2636 0.405445 9.43939 1.21634 7.7C2.0699 5.91818 3.15821 4.3697 4.48124 3.05454C5.84695 1.69697 7.31935 0.678787 8.89845 0L13.3157 3.24545C11.5659 3.96667 9.98676 4.94242 8.57837 6.17273C7.21266 7.36061 6.25239 8.63333 5.69757 9.99091L6.01766 10.1818C6.27373 10.0121 6.55114 9.88485 6.84989 9.8C7.19132 9.71515 7.63944 9.67273 8.19426 9.67273C9.34658 9.67273 10.4776 10.097 11.5872 10.9455C12.7395 11.7939 13.3157 13.1091 13.3157 14.8909C13.3157 16.8848 12.6542 18.4121 11.3311 19.4727C10.0508 20.4909 8.57837 21 6.91391 21ZM22.5982 21C20.2509 21 18.5011 20.2152 17.3488 18.6455C16.2391 17.0758 15.6843 15.2515 15.6843 13.1727C15.6843 11.2636 16.0898 9.43939 16.9007 7.7C17.7542 5.91818 18.8425 4.3697 20.1656 3.05454C21.5313 1.69697 23.0037 0.678787 24.5828 0L29 3.24545C27.2502 3.96667 25.6711 4.94242 24.2627 6.17273C22.897 7.36061 21.9367 8.63333 21.3819 9.99091L21.702 10.1818C21.9581 10.0121 22.2355 9.88485 22.5342 9.8C22.8756 9.71515 23.3238 9.67273 23.8786 9.67273C25.0309 9.67273 26.1619 10.097 27.2715 10.9455C28.4238 11.7939 29 13.1091 29 14.8909C29 16.8848 28.3385 18.4121 27.0155 19.4727C25.7351 20.4909 24.2627 21 22.5982 21Z' fill='%239F7AEA'/%3E%3C/svg%3E")`,
            }}
          >
            <Flex direction={{ base: 'column-reverse' }} alignItems="center">
              <Flex
                direction="column"
                textAlign="center"
                justifyContent="space-between"
              >
                <Text pb={4} color="text.muted">
                  {content}
                </Text>
              </Flex>
              {showStars && (
                <Flex color="common.progress" fontSize="xl" mb={4}>
                  <MdIcon name="StarRate" />
                  <MdIcon name="StarRate" />
                  <MdIcon name="StarRate" />
                  <MdIcon name="StarRate" />
                  <MdIcon name="StarRate" />
                </Flex>
              )}
              <Box position="relative" height="80px" width="80px" mb={4}>
                <Image
                  src={personAvatar}
                  height="80px"
                  width="80px"
                  htmlHeight="80"
                  htmlWidth="80"
                  alignSelf="center"
                  alt={name}
                  borderRadius="full"
                />
                {channelAvatar ? (
                  <Box position="absolute" bottom="-10px" right="-10px">
                    <Image
                      src={channelAvatar}
                      height="40px"
                      width="40px"
                      htmlHeight="40"
                      htmlWidth="40"
                      alignSelf="center"
                      alt={handle}
                      borderRadius="full"
                    />
                  </Box>
                ) : null}
              </Box>
            </Flex>
            <Flex
              direction="column"
              textAlign="center"
              justifyContent="space-between"
            >
              <Text fontWeight="bold" fontSize="md">
                {name}
              </Text>
              <Text color="text.muted" fontSize="sm">
                {`from ${location}`}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </GridItem>
  );
};

const Detail2 = () => {
  return (
    <Container maxW="7xl" py={20}>
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, md: 20 }}
        alignItems="center"
        display={{ base: 'flex', md: 'grid' }}
        flexDirection={{ base: 'column-reverse', md: 'initial' }}
      >
        <Stack spacing={4} textAlign={{ base: 'center', md: 'left' }}>
          <Heading
            as="h2"
            lineHeight={1.1}
            fontWeight="extrabold"
            fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '4xl' }}
          >
            Offer better learning experiences
          </Heading>
          <Text
            fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}
            px={{ base: 4, md: 0 }}
            color="text.muted"
          >
            Forget the lectures and guide your followers through an immersive
            course, while getting hands-on together in dedicated classes.
          </Text>
          <Stack
            spacing={4}
            pt={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />
            }
          >
            <Point
              icon={<Icon as={IoFootsteps} color="neutral.500" w={5} h={5} />}
              iconBg={useColorModeValue('neutral.100', 'neutral.900')}
              text="Step-by-step, easy-to-follow videos"
            />
            <Point
              icon={<Icon as={FaRegHandshake} color="purple.500" w={5} h={5} />}
              iconBg={useColorModeValue('purple.100', 'purple.900')}
              text="Private classes to bring you closer to your audience"
            />
            <Point
              icon={<MdIcon name="Stars" color="orange.500" w={5} h={5} />}
              iconBg={useColorModeValue('orange.100', 'orange.900')}
              text="Premium content your followers will love"
            />
          </Stack>
        </Stack>
        <Flex position="relative" justifyContent="center" alignItems="center">
          <Box
            position="absolute"
            boxSize="300px"
            bg="#fff"
            borderRadius="full"
          />
          <Image
            alt="Guide Your Followers"
            src={iGroups}
            objectFit="contain"
            maxHeight={{ base: '250px', md: '300px' }}
            position="relative"
            htmlHeight="300"
            htmlWidth="400"
          />
        </Flex>
      </SimpleGrid>
    </Container>
  );
};

const FlowItem = ({
  index,
  colorScheme,
  description,
  title,
}: {
  index: number;
  colorScheme: string;
  description: string;
  title: string;
}) => {
  return (
    <Flex
      my={4}
      padding={16}
      borderRadius="lg"
      backgroundColor={`background.${colorScheme}`}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Icon as={FcIdea} w={20} h={20} mb={4} />
      <Text
        fontSize="3xl"
        fontWeight="bold"
        color={`text.${colorScheme}`}
        mb={4}
      >{`${title}`}</Text>
      <Text color={`text.${colorScheme}`}>{`${description}`}</Text>
    </Flex>
  );
};

const steps = [
  {
    label: 'Create Courses',
    icon: () => (
      <Flex
        w="36px"
        h="36px"
        backgroundColor="background.primary"
        borderRadius="full"
        alignItems="center"
        justifyContent="center"
      >
        <MdIcon name="BubbleChart" color="text.primary" />
      </Flex>
    ),
    colorScheme: 'primary',
    title: 'Create courses',
    description:
      'Lay out a quick plan with the help of AI, then pick up your phone or camera to film a series of practical sessions step by step, with no edit required.',
    image: iCourses,
    imageAlt: 'Simple Course Builder',
  },
  {
    label: 'Sell Spaces',
    icon: () => (
      <Flex
        w="36px"
        h="36px"
        backgroundColor="background.success"
        borderRadius="full"
        alignItems="center"
        justifyContent="center"
      >
        <MdIcon name="Sell" color="text.success" />
      </Flex>
    ),
    colorScheme: 'success',
    title: 'Sell spaces',
    description:
      'Create your branded channel, then sell spaces for your course in either small classes or one big community. Get paid directly through Stripe with our easy checkout integration.',
    image: iSales,
    imageAlt: 'Integrated Sales Tools',
  },
  {
    label: 'Run Classes',
    icon: () => (
      <Flex
        w="36px"
        h="36px"
        backgroundColor="background.error"
        borderRadius="full"
        alignItems="center"
        justifyContent="center"
      >
        <MdIcon name="GroupWork" color="text.error" />
      </Flex>
    ),
    colorScheme: 'error',
    title: 'Run classes',
    description:
      'Encourage, connect and chat with your class as they post photos of their progress. Celebrate their success as they finish with a digital certificate.',
    image: iClasses,
    imageAlt: 'Private Classes',
  },
];

const Flow = () => {
  const bg = useColorModeValue('background.tint3', 'background.tint1');
  return (
    <Container maxW="7xl" py={{ base: 10, md: 20 }}>
      <Heading
        as="h2"
        lineHeight={1.1}
        fontWeight="extrabold"
        fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '4xl' }}
        mt={2}
        mb={6}
        zIndex={1}
        textAlign="center"
        display={{ base: 'block', md: 'none' }}
      >
        How it works
      </Heading>
      {steps.map((step, index) => (
        <Flex
          key={`flow-item-${index}`}
          position="relative"
          px={{ base: 0, sm: 6, md: 0 }}
        >
          <Flex alignSelf="center" flexDirection="column" alignItems="center">
            <Flex
              alignItems="center"
              justifyContent="center"
              borderRadius="full"
              w={10}
              h={10}
              bg="background.success"
              borderWidth={2}
              borderColor="common.progress"
              zIndex={1}
            >
              <Text fontWeight="semibold" color="text.success">
                {index + 1}
              </Text>
            </Flex>
            <Box
              position="absolute"
              top={index === 0 ? '50%' : 0}
              bottom={index === steps.length - 1 ? '50%' : 0}
              w={0.5}
              bg="common.progress"
            />
          </Flex>
          <Flex
            flex={1}
            flexDirection={{ base: 'column-reverse', md: 'row' }}
            bg={{
              base: bg,
              md: 'none',
            }}
            borderRadius="lg"
            ml={{ base: 4, md: 0 }}
            my={{ base: 4, md: 0 }}
            p={{ base: 4, md: 0 }}
            position="relative"
          >
            <Box
              borderTopWidth="12px"
              borderBottomWidth="12px"
              borderRightWidth="12px"
              borderTopColor="transparent"
              borderBottomColor="transparent"
              borderRightColor="background.tint3"
              display={{ base: 'block', md: 'none' }}
              position="absolute"
              left="-12px"
              top="calc(50% - 12px)"
            />
            <Box
              alignSelf="center"
              flex={1}
              pl={{ base: 0, md: 6 }}
              py={4}
              textAlign={{ base: 'center', md: 'left' }}
            >
              <Text
                fontSize={{ base: 'lg', md: 'xl' }}
                fontWeight="bold"
              >{`Step ${index + 1}`}</Text>
              <Text
                fontWeight="extrabold"
                fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '4xl' }}
                pb={2}
              >
                {step.title}
              </Text>
              <Text color="text.muted" fontSize={{ base: 'md', md: 'lg' }}>
                {step.description}
              </Text>
            </Box>
            <Flex
              flex={1}
              alignItems="center"
              justifyContent="center"
              pl={{ base: 0, md: 4 }}
              py={6}
              position="relative"
            >
              <Box
                position="absolute"
                w="250px"
                h="180px"
                bg="background.default"
                borderRadius="xl"
                display={{ base: 'none', md: 'block' }}
              />
              <Image
                alt={step.imageAlt}
                src={step.image}
                objectFit="contain"
                maxHeight="200px"
                position="relative"
                htmlHeight="200"
                htmlWidth="310"
              />
            </Flex>
          </Flex>
        </Flex>
      ))}
    </Container>
  );
};

const learnerTestimonials = [
  {
    name: 'Melanie H',
    location: 'Mannheim, Germany',
    role: '',
    handle: '',
    content:
      'I’ve always learnt such a huge amount from each of the courses. They are easy to follow and well presented and the personal feedback you receive from both your mentor and fellow classmates is invaluable.',
    personAvatar: avatar1,
    channelAvatar: '',
    colSpan: 1,
  },
  {
    name: 'Birdy R',
    location: 'London, UK',
    role: '',
    handle: '',
    content:
      'The videos are clear and easy to follow. If you miss something you can rewind or follow the writing instructions. Each step is manageable size and straightforward, it’s really excellent. I highly recommend it.',
    personAvatar: avatar2,
    channelAvatar: '',
    colSpan: 1,
  },
  {
    name: 'Angela S',
    location: 'Kuala Lumpur, Malaysia',
    role: '',
    handle: '',
    content:
      'I learnt a lot of new skills. The courses are structured in such a way that you can fit them around your life. The tutors always answered questions promptly and gave good advice to help me progress.',
    personAvatar: avatar4,
    channelAvatar: '',
    colSpan: 1,
  },
];

const Testimonials = () => {
  const wsLogoColor = useColorModeValue('#16171D', '#FFFFFF');
  return (
    <Container maxW="7xl" pb={10} pt={10}>
      <Box position="relative" pl={{ base: '40px', md: '40px' }}>
        <Flex
          position="absolute"
          bottom={{ base: '-40px', md: '-40px' }}
          left={0}
          zIndex={2}
        >
          <Image
            height="60px"
            width="60px"
            htmlHeight="60"
            htmlWidth="60"
            borderRadius="full"
            bg="background.primary"
            src={sortedReviewImage}
            alt="Sorted Food logo"
          />
        </Flex>
        <Card
          borderRadius="lg"
          boxShadow="none"
          backgroundColor="neutral.900"
          backgroundImage={{ base: 'none', md: sortedReviewBgImage }}
          backgroundSize="cover"
          backgroundPosition="top"
          padding={0}
          mt={4}
          _after={{
            content: '""',
            position: 'absolute',
            height: '42px',
            width: '58px',
            left: '70px',
            top: '-20px',
            backgroundSize: 'cover',
            backgroundImage: `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='21' viewBox='0 0 29 21' fill='none'%3E%3Cpath d='M6.91391 21C4.56659 21 2.81678 20.2152 1.66446 18.6455C0.55482 17.0758 0 15.2515 0 13.1727C0 11.2636 0.405445 9.43939 1.21634 7.7C2.0699 5.91818 3.15821 4.3697 4.48124 3.05454C5.84695 1.69697 7.31935 0.678787 8.89845 0L13.3157 3.24545C11.5659 3.96667 9.98676 4.94242 8.57837 6.17273C7.21266 7.36061 6.25239 8.63333 5.69757 9.99091L6.01766 10.1818C6.27373 10.0121 6.55114 9.88485 6.84989 9.8C7.19132 9.71515 7.63944 9.67273 8.19426 9.67273C9.34658 9.67273 10.4776 10.097 11.5872 10.9455C12.7395 11.7939 13.3157 13.1091 13.3157 14.8909C13.3157 16.8848 12.6542 18.4121 11.3311 19.4727C10.0508 20.4909 8.57837 21 6.91391 21ZM22.5982 21C20.2509 21 18.5011 20.2152 17.3488 18.6455C16.2391 17.0758 15.6843 15.2515 15.6843 13.1727C15.6843 11.2636 16.0898 9.43939 16.9007 7.7C17.7542 5.91818 18.8425 4.3697 20.1656 3.05454C21.5313 1.69697 23.0037 0.678787 24.5828 0L29 3.24545C27.2502 3.96667 25.6711 4.94242 24.2627 6.17273C22.897 7.36061 21.9367 8.63333 21.3819 9.99091L21.702 10.1818C21.9581 10.0121 22.2355 9.88485 22.5342 9.8C22.8756 9.71515 23.3238 9.67273 23.8786 9.67273C25.0309 9.67273 26.1619 10.097 27.2715 10.9455C28.4238 11.7939 29 13.1091 29 14.8909C29 16.8848 28.3385 18.4121 27.0155 19.4727C25.7351 20.4909 24.2627 21 22.5982 21Z' fill='%2372E9B7'/%3E%3C/svg%3E")`,
          }}
        >
          <Flex flex={1} position="relative">
            <Flex
              position="absolute"
              bg="#000"
              opacity="0.5"
              top={0}
              right={0}
              bottom={0}
              left={0}
              display={{ base: 'none', md: 'flex', lg: 'none' }}
            />
            <Flex flexDirection="column" flex={1} position="relative">
              <Image
                src={sortedReviewBgImage}
                w="100%"
                h="200px"
                htmlHeight="200"
                htmlWidth="524"
                objectFit="cover"
                objectPosition="right"
                display={{ base: 'block', md: 'none' }}
                alt="Sorted Food team"
              />
              <Text
                color="#fff"
                fontSize={{ base: 'md', sm: 'lg', xl: 'xl', '2xl': '2xl' }}
                flex={1}
                padding={{ base: 6, md: 8, lg: 12 }}
              >
                Steppit lets us offer a whole new type of digital experience for
                our community. Learning by doing has never been easier, and the
                sense of togetherness it creates is amazing 👌 It’s been a
                game-changer for us, and we’re excited to keep using it!
              </Text>
            </Flex>
            <Flex flex={{ base: 0, md: 0.5, lg: 1 }} />
          </Flex>
        </Card>
        <Flex
          fontSize="sm"
          color="text.muted"
          position="absolute"
          right={0}
          left="70px"
          pt={2}
        >
          <Text py={1}>Barry T, @sortedfood</Text>
        </Flex>
      </Box>
      <Flex
        flexDirection="column"
        borderWidth={1}
        borderColor="border.muted"
        borderRadius="lg"
        position="relative"
        mt={24}
        padding={{ base: 6, md: 12 }}
      >
        <Flex
          pt={{ base: 2, md: 0 }}
          alignItems={{ base: 'center', lg: 'normal' }}
          flexDirection={{ base: 'column', lg: 'row' }}
        >
          <Flex
            flex={1}
            flexDirection="column"
            alignItems={{ base: 'center', lg: 'normal' }}
            textAlign={{ base: 'center', lg: 'left' }}
          >
            <Flex alignItems="center" mb={4}>
              <Text fontWeight="bold" fontSize="lg" textAlign="right" mr={3}>
                Made by
              </Text>
              <WorkshopIcon size="sm" color={wsLogoColor} />
            </Flex>
            <Text color="text.muted">
              Before launching Steppit, we produced dozens of experiential
              courses for thousands of learners ourselves, and the feedback has
              always been amazing!
            </Text>
          </Flex>
          <Flex
            mt={{ base: 8, lg: 0 }}
            alignItems="center"
            flexDirection={{ base: 'column', sm: 'row' }}
          >
            <Flex
              ml={{ base: 0, lg: 10 }}
              flexDirection="column"
              alignItems="center"
              textAlign="center"
            >
              <CircularProgress
                value={97}
                size={20}
                mb={1}
                thickness="8px"
                color="common.progress"
                trackColor="background.tint2"
                sx={{
                  '& > div:first-child': {
                    transitionProperty: 'width',
                  },
                }}
                aria-label="Learner Satisfaction"
              >
                <CircularProgressLabel>
                  <Text
                    color="common.progress"
                    fontWeight="extrabold"
                    fontSize="2xl"
                  >
                    97%
                  </Text>
                </CircularProgressLabel>
              </CircularProgress>
              <Text color="text.success" fontWeight="bold">
                Learner satisfaction
              </Text>
              <Text color="text.success" fontSize="sm">
                From 60k+ learner ratings
              </Text>
            </Flex>

            <Flex
              ml={{ base: 0, sm: 6, md: 10 }}
              mt={{ base: 6, sm: 0 }}
              flexDirection="column"
              alignItems="center"
              textAlign="center"
            >
              <CircularProgress
                value={92}
                size={20}
                mb={1}
                thickness="8px"
                color="text.muted"
                trackColor="background.tint2"
                sx={{
                  '& > div:first-child': {
                    transitionProperty: 'width',
                  },
                }}
                aria-label="Customer Support Rating"
              >
                <CircularProgressLabel>
                  <Text
                    color="text.muted"
                    fontWeight="extrabold"
                    fontSize="2xl"
                  >
                    92%
                  </Text>
                </CircularProgressLabel>
              </CircularProgress>
              <Flex alignItems="center">
                <Image
                  alt="Wootric Logo"
                  src={wootricLogo}
                  objectFit="contain"
                  width="104px"
                  height="24px"
                  htmlWidth="104"
                  htmlHeight="24"
                  position="relative"
                />
              </Flex>
              <Text color="text.muted" fontSize="sm">
                Customer support rating
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Box mt={16}>
          <Box display={{ base: 'block', lg: 'none' }}>
            <TestimonialCard {...learnerTestimonials[1]} index={0} showStars />
          </Box>
          <Grid
            templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }}
            gap={5}
            mx="auto"
            w="full"
            display={{ base: 'none', lg: 'grid' }}
          >
            {learnerTestimonials.map((cardInfo, index) => (
              <TestimonialCard
                key={`review-card-${index}`}
                {...cardInfo}
                index={index}
                showStars
              />
            ))}
          </Grid>
        </Box>
        <Flex
          alignItems="center"
          justifyContent="center"
          mt={6}
          flexWrap="wrap"
        >
          <Image
            alt="App Store App of the Day"
            src={barAotd}
            objectFit="contain"
            height="60px"
            width="240px"
            htmlHeight="60"
            htmlWidth="240"
            position="relative"
            mt={4}
          />
          <Image
            alt="App Store New Apps We Love"
            src={barNewApps}
            objectFit="contain"
            height="60px"
            width="240px"
            htmlHeight="60"
            htmlWidth="240"
            position="relative"
            mt={4}
          />
        </Flex>
      </Flex>
    </Container>
  );
};

const Carousel = () => {
  const arrowStyles: BoxProps = {
    cursor: 'pointer',
    position: 'absolute',
    top: { base: 'auto', md: '50%' },
    bottom: { base: '0', md: 'auto' },
    w: 'auto',
    mt: { base: 'auto', md: '-28px' },
    mb: { base: '-12px', md: 'auto' },
    p: '16px',
    color: 'text.primary',
    fontSize: '24px',
    transition: '0.3s ease',
    borderRadius: 'full',
    userSelect: 'none',
    _hover: {
      opacity: 0.8,
      bg: 'background.primary',
    },
  };
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sessionPopupSlug, setSessionPopupSlug] = useState('');

  const history = useHistory();
  const location = useLocation();

  const slidesCount = POPUP_SESSIONS.length;

  const prevSlide = () => {
    setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
  };

  const nextSlide = () => {
    setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
  };

  const setSlide = (slide: number) => {
    setCurrentSlide(slide);
  };

  const carouselStyle = {
    transition: 'all .5s',
    ml: `-${currentSlide * 100}%`,
  };
  return (
    <Flex m={-3} pb={6} overflow="hidden">
      <Flex w="full" pos="relative">
        <Flex w="full" {...carouselStyle}>
          {POPUP_SESSIONS.map((slide, sid) => (
            <Flex
              key={`slide-${sid}`}
              boxSize="full"
              flex="none"
              py={20}
              opacity={currentSlide === sid ? 1 : 0}
              transition="opacity 0.25s ease"
            >
              <Flex
                boxSize="full"
                maxWidth={{ base: '300px', md: '550px' }}
                minHeight={{ base: '400px', md: 'auto' }}
                mx="auto"
                justifyContent="center"
                position="relative"
                _before={{
                  content: '""',
                  position: 'absolute',
                  zIndex: 0,
                  height: 'full',
                  width: 'full',
                  filter: 'blur(40px)',
                  transform: 'scale(0.98)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  top: 0,
                  left: 0,
                  backgroundImage: backgrounds[sid % 4],
                }}
              >
                <Card
                  padding={4}
                  paddingTop={{ base: 6, md: 4 }}
                  paddingBottom={{ base: 6, md: 4 }}
                  h="100%"
                  w="90%"
                  mx="auto"
                  position={{ base: 'absolute', md: 'relative' }}
                  borderRadius="xl"
                  boxShadow="lg"
                  alignItems="center"
                  zIndex={1}
                >
                  <Stack
                    width="100%"
                    direction={{ base: 'column', md: 'row' }}
                    alignItems="center"
                    gap={4}
                  >
                    <Image
                      src={slide.image}
                      alt={slide.alt}
                      backgroundSize="cover"
                      borderRadius={{ base: 'full', md: 'lg' }}
                      width={{ base: '175px', sm: '200px' }}
                      height={{ base: '175px', sm: '200px', md: '300px' }}
                      htmlWidth="200"
                      htmlHeight="300"
                      objectFit="cover"
                    />
                    <Flex
                      flexDirection="column"
                      textAlign={{ base: 'center', md: 'left' }}
                      alignItems={{ base: 'center', md: 'flex-start' }}
                    >
                      <Text
                        fontSize="2xl"
                        fontWeight="bold"
                        lineHeight={1.2}
                        mb={0.5}
                      >
                        {slide.label}
                      </Text>
                      <Text
                        mb={4}
                        fontSize="lg"
                      >{`with ${slide.creator}`}</Text>
                      <Button
                        onClick={() => setSessionPopupSlug(slide.courseSlug)}
                        variant="outline"
                      >
                        Watch Session
                      </Button>
                    </Flex>
                  </Stack>
                </Card>
              </Flex>
            </Flex>
          ))}
        </Flex>
        <Box {...arrowStyles} left="0" onClick={prevSlide} zIndex={1}>
          <MdIcon name="ArrowBack" />
        </Box>
        <Box {...arrowStyles} right="0" onClick={nextSlide} zIndex={1}>
          <MdIcon name="ArrowForward" />
        </Box>
        <Stack
          direction="row"
          justify="center"
          pos="absolute"
          bottom="8px"
          w="full"
        >
          {Array.from({
            length: slidesCount,
          }).map((_, slide) => (
            <Box
              key={`dots-${slide}`}
              cursor="pointer"
              height={3}
              width={currentSlide === slide ? 16 : 3}
              m="0 2px"
              bg={currentSlide < slide ? 'background.tint2' : 'common.progress'}
              borderRadius="full"
              display="inline-block"
              transition="all 0.3s ease"
              _hover={{
                bg: 'text.success',
              }}
              onClick={() => setSlide(slide)}
            ></Box>
          ))}
        </Stack>
      </Flex>
      <SessionModal
        isOpen={!!sessionPopupSlug}
        onClose={() => {
          setSessionPopupSlug('');
          history.replace(location.pathname);
        }}
        courseSlug={sessionPopupSlug}
        title={
          POPUP_SESSIONS.find((s) => s.courseSlug === sessionPopupSlug)
            ?.label || ''
        }
        moduleId={
          POPUP_SESSIONS.find((s) => s.courseSlug === sessionPopupSlug)
            ?.moduleId || undefined
        }
        link={
          POPUP_SESSIONS.find((s) => s.courseSlug === sessionPopupSlug)?.link ||
          undefined
        }
      />
    </Flex>
  );
};

const backgrounds = [
  `url("data:image/svg+xml, %3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'560\' height=\'185\' viewBox=\'0 0 560 185\' fill=\'none\'%3E%3Cellipse cx=\'102.633\' cy=\'61.0737\' rx=\'102.633\' ry=\'61.0737\' fill=\'%23ED64A6\' /%3E%3Cellipse cx=\'399.573\' cy=\'123.926\' rx=\'102.633\' ry=\'61.0737\' fill=\'%23F56565\' /%3E%3Cellipse cx=\'366.192\' cy=\'73.2292\' rx=\'193.808\' ry=\'73.2292\' fill=\'%2338B2AC\' /%3E%3Cellipse cx=\'222.705\' cy=\'110.585\' rx=\'193.808\' ry=\'73.2292\' fill=\'%23ED8936\' /%3E%3C/svg%3E")`,
  `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='457.367' cy='123.926' rx='102.633' ry='61.0737' transform='rotate(-180 457.367 123.926)' fill='%23ED8936'/%3E%3Cellipse cx='160.427' cy='61.0737' rx='102.633' ry='61.0737' transform='rotate(-180 160.427 61.0737)' fill='%2348BB78'/%3E%3Cellipse cx='193.808' cy='111.771' rx='193.808' ry='73.2292' transform='rotate(-180 193.808 111.771)' fill='%230BC5EA'/%3E%3Cellipse cx='337.295' cy='74.415' rx='193.808' ry='73.2292' transform='rotate(-180 337.295 74.415)' fill='%23ED64A6'/%3E%3C/svg%3E")`,
  `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='102.633' cy='61.0737' rx='102.633' ry='61.0737' fill='%23ED8936'/%3E%3Cellipse cx='399.573' cy='123.926' rx='102.633' ry='61.0737' fill='%2348BB78'/%3E%3Cellipse cx='366.192' cy='73.2292' rx='193.808' ry='73.2292' fill='%230BC5EA'/%3E%3Cellipse cx='222.705' cy='110.585' rx='193.808' ry='73.2292' fill='%23ED64A6'/%3E%3C/svg%3E")`,
  `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='457.367' cy='123.926' rx='102.633' ry='61.0737' transform='rotate(-180 457.367 123.926)' fill='%23ECC94B'/%3E%3Cellipse cx='160.427' cy='61.0737' rx='102.633' ry='61.0737' transform='rotate(-180 160.427 61.0737)' fill='%239F7AEA'/%3E%3Cellipse cx='193.808' cy='111.771' rx='193.808' ry='73.2292' transform='rotate(-180 193.808 111.771)' fill='%234299E1'/%3E%3Cellipse cx='337.295' cy='74.415' rx='193.808' ry='73.2292' transform='rotate(-180 337.295 74.415)' fill='%2348BB78'/%3E%3C/svg%3E")`,
];

const POPUP_SESSIONS = [
  {
    icon: 'Cake',
    label: 'Cake Decorating',
    courseSlug: 'cKau4Bls',
    moduleId: 1994,
    image: cakeSessionImage,
    alt: 'Cupcake',
    link: '',
    creator: 'Benjamina',
  },
  {
    icon: 'Kitchen',
    label: 'Fitting a Kitchen Plinth',
    courseSlug: 'H7RgwHym',
    moduleId: 1989,
    image: kitchenSessionImage,
    alt: 'Kitchen',
    link: '',
    creator: 'Anthony',
  },
  {
    icon: 'LocalBar',
    label: 'Making Quick Cocktails',
    courseSlug: 'TrO1qWsI',
    moduleId: 2016,
    image: cocktailSessionImage,
    alt: 'Cocktails',
    link: '',
    creator: 'Ben',
  },
];

const Examples = () => {
  return (
    <Container maxW="3xl">
      <Flex
        textAlign="center"
        pt={{ base: 10, md: 16 }}
        justifyContent="center"
        direction="column"
        width="full"
        pb={16}
      >
        <Heading
          as="h2"
          lineHeight={1.1}
          fontWeight="extrabold"
          fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '4xl' }}
          mb={6}
          zIndex={1}
        >
          Watch Steppit in action
        </Heading>
        <Text
          fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}
          px={{ base: 4, md: 0 }}
          mb={6}
          color="text.muted"
        >
          By building your courses around step-by-step sessions, the
          possibilities for engaging content are endless.
        </Text>
        <Carousel />
      </Flex>
    </Container>
  );
};

const PricingFeature = ({
  infoOnClick,
  children,
}: {
  infoOnClick?: () => void;
  children: React.ReactElement | string;
}) => {
  return (
    <Flex alignItems="center" mt={2}>
      <Flex shrink={0}>
        <MdIcon name="TaskAlt" color="common.progress" />
      </Flex>
      <Box ml={4}>
        <chakra.span>{children}</chakra.span>
      </Box>
      {infoOnClick ? (
        <Box
          ml={1}
          mb={2}
          onClick={infoOnClick}
          cursor="pointer"
          _hover={{ opacity: 0.8 }}
        >
          <MdIcon name="HelpOutline" boxSize={3.5} color="text.muted" />
        </Box>
      ) : null}
    </Flex>
  );
};

const Pricing = () => {
  const [showPricing, setShowPricing] = useState(false);
  const proColor = useColorModeValue('orange.550', 'orange.200');
  const learnMoreColor = useColorModeValue('blue', 'green');
  const location = useSelector(
    (state: GlobalState) => state.background.location
  );
  const discountIsValid = true;
  return (
    <Container maxW="4xl">
      <Stack
        as={Box}
        textAlign="center"
        spacing={{ base: 8, md: 10 }}
        py={{ base: 16, md: 24 }}
        maxWidth={{ base: 500, md: 'none' }}
        mx="auto"
      >
        <Heading
          fontWeight="extrabold"
          fontSize={{ base: '2xl', sm: '4xl', md: '5xl' }}
          lineHeight="110%"
        >
          {'Build and sell your courses for '}
          <Text as="span" color="common.primary">
            free
          </Text>
        </Heading>
        <Box pb={4}>
          <Text
            pb={3}
            color="text.muted"
            fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}
          >
            Basic channels are completely free on Steppit. Create and share
            unlimited courses and we'll take a small revenue share if you decide
            to start selling.
          </Text>
          <Text
            color="text.muted"
            fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}
            fontWeight="semibold"
          >
            Upgrade to Pro to take your channel to the next level, with advanced
            features such as expanded courses, private classes and personal AI
            assistance.
          </Text>
        </Box>
        {discountIsValid && (discountLabel || discount) ? (
          <Box>
            {discountLabel ? (
              <Text
                fontWeight="bold"
                fontSize="sm"
                textTransform="uppercase"
                color="text.success"
                textAlign="center"
                mb={2}
                mt={-2}
                // mt={{ base: 8, md: 12 }}
              >
                {discountLabel}
              </Text>
            ) : null}
            {discount ? (
              <Flex
                bg="background.success"
                py={3}
                px={4}
                borderRadius={{ base: 'lg', lg: 'full' }}
                textAlign={{ base: 'center' }}
                alignItems="center"
                justifyContent="center"
                color="text.success"
                flexDirection={{ base: 'column', lg: 'row' }}
              >
                <MdIcon
                  name="AutoAwesome"
                  mr={{ base: 0, lg: 3 }}
                  mb={{ base: 2, lg: 0 }}
                />
                <Text fontWeight="bold">{discount}</Text>
                <LinkButton
                  colorScheme={learnMoreColor}
                  variant="outline"
                  icon="ArrowForward"
                  iconPosition="right"
                  size="sm"
                  href={aiFeatureUrl}
                  ml={{ base: 0, lg: 4 }}
                  mt={{ base: 3, lg: 0 }}
                  mb={{ base: 2, lg: 0 }}
                >
                  Learn More
                </LinkButton>
              </Flex>
            ) : null}
          </Box>
        ) : null}

        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          gap={4}
          bg="background.tint3"
          p={4}
          borderRadius="lg"
        >
          <Card flexDirection="column" borderRadius="lg">
            <Flex
              direction="column"
              justify="space-between"
              p={6}
              borderBottomWidth="1px"
            >
              <Text
                mb={1}
                fontSize="lg"
                fontWeight="semibold"
                color="text.primary"
              >
                Basic Channel
              </Text>
              <Text
                mb={2}
                fontSize="5xl"
                fontWeight={['bold', 'extrabold']}
                lineHeight="tight"
              >
                Free
              </Text>
              <Text color="text.muted" mb={6}>
                Create & sell online courses for free. No credit card required.
              </Text>
              <LinkButton
                to={navRoutes.global.planner.path()}
                icon="ArrowForward"
                iconPosition="right"
              >
                Start Now
              </LinkButton>
            </Flex>
            <Stack
              direction="column"
              p={6}
              spacing={3}
              flexGrow={1}
              textAlign="left"
              mb={2}
            >
              <PricingFeature infoOnClick={() => setShowPricing(true)}>
                80% revenue share on sales
              </PricingFeature>
              <PricingFeature>Unlimited courses</PricingFeature>
              <PricingFeature>Course landing pages</PricingFeature>
              <PricingFeature>Branded channel page</PricingFeature>
              <PricingFeature>Sharable links to public content</PricingFeature>
              <PricingFeature>{`Up to ${BASIC_UNIT_LIMIT} units per course`}</PricingFeature>
              <PricingFeature>Portrait & landscape video</PricingFeature>
              <PricingFeature>Optimized for short-form video</PricingFeature>
              <PricingFeature>Basic AI-assisted course plans</PricingFeature>
              <PricingFeature>Cohort-based classes</PricingFeature>
              <PricingFeature>Weekly unlocking courses</PricingFeature>
              <PricingFeature>Class photo posting feeds</PricingFeature>
              <PricingFeature>Class group chats</PricingFeature>
              <PricingFeature>PDF downloads</PricingFeature>
            </Stack>
          </Card>
          <Card flexDirection="column" borderRadius="lg">
            <Flex
              direction="column"
              justify="space-between"
              p={6}
              borderBottomWidth="1px"
            >
              <Flex mb={1} alignItems="center" justifyContent="center">
                <ProGem />
                <Text
                  fontSize="lg"
                  fontWeight="semibold"
                  color={proColor}
                  ml={2}
                >
                  Pro Channel
                </Text>
              </Flex>
              {/* {discountIsValid && discount ? (
                // <Box mb={2}>
                //   <Text
                //     fontSize="5xl"
                //     fontWeight="extrabold"
                //     lineHeight="tight"
                //     color="text.error"
                //   >
                //     <chakra.span
                //       fontSize="4xl"
                //       textDecoration="line-through"
                //       color="gray.200"
                //       _dark={{
                //         color: 'gray.600',
                //       }}
                //     >
                //       {PRO_PRICE(location?.currency)}
                //     </chakra.span>
                //     {` ${PRO_95_DISCOUNT_PRICE(location?.currency)}`}
                //   </Text>
                //   <Text mb={2} fontSize="xl" color="text.error">
                //     {`for your 1st month`}
                //   </Text>
                //   <Text mb={2} fontSize="xl" color="text.muted">
                //     {`then ${PRO_PRICE(location?.currency)} per month`}
                //   </Text>
                // </Box>

                <Box mb={2}>
                  <Text
                    fontSize="4xl"
                    fontWeight="extrabold"
                    lineHeight="tight"
                    color="text.default"
                  >
                    {`${PRO_PRICE(location?.currency)} per month`}
                  </Text>
                  <Text mb={2} fontSize="xl" color="text.muted">
                    {`Try free for 7 days`}
                  </Text>
                </Box>
              ) : ( */}
              <Text
                mb={2}
                fontSize="5xl"
                fontWeight={['bold', 'extrabold']}
                lineHeight="tight"
              >
                {PRO_PRICE(location?.currency)}
                <chakra.span
                  fontSize="2xl"
                  fontWeight="medium"
                  color="gray.600"
                  _dark={{
                    color: 'gray.400',
                  }}
                >
                  {' '}
                  /month
                </chakra.span>
              </Text>
              {/* )} */}
              <Text color="text.muted" mb={6}>
                Grow your channel and create better courses more efficiently
                with AI.
              </Text>
              <LinkButton
                to={`${navRoutes.global.planner.path()}?p=pro`}
                icon="ArrowForward"
                iconPosition="right"
              >
                Try Free for 7 Days
              </LinkButton>
            </Flex>
            <Stack
              direction="column"
              p={6}
              spacing={3}
              flexGrow={1}
              textAlign="left"
              mb={2}
            >
              <PricingFeature>
                <chakra.b>Everything in Basic</chakra.b>
              </PricingFeature>
              <PricingFeature>
                <Box>
                  <chakra.b>Fully customisable AI-powered assistant</chakra.b>
                  <Box>
                    <LinkButton
                      size="sm"
                      variant="outline"
                      icon="ArrowForward"
                      iconPosition="right"
                      mt={2}
                      href={aiFeatureUrl}
                      colorScheme={learnMoreColor}
                    >
                      Learn More
                    </LinkButton>
                  </Box>
                </Box>
              </PricingFeature>
              <PricingFeature>Unlimited units per course</PricingFeature>
              <PricingFeature>{`Up to ${PRO_TEAM_MEMBERS_LIMIT} channel team members`}</PricingFeature>
              <PricingFeature>
                {`Private invite-only classes with up to ${PRO_MONTHLY_ENROLMENTS_LIMIT} new enrolments per month`}
              </PricingFeature>
              <PricingFeature>Priority customer support</PricingFeature>
            </Stack>
          </Card>
        </SimpleGrid>
      </Stack>
      {showPricing && (
        <PaymentsWelcomeModal
          isOpen
          onClose={() => setShowPricing(false)}
          context="pricing"
          isConnected={false}
        />
      )}
    </Container>
  );
};

const CallToAction = () => {
  return (
    <Container maxW="4xl">
      <Stack
        as={Box}
        textAlign="center"
        spacing={{ base: 8, md: 14 }}
        py={{ base: 16, md: 36 }}
        maxWidth={{ base: 500, md: 'none' }}
        mx="auto"
      >
        <Heading
          fontWeight="extrabold"
          fontSize={{ base: '2xl', sm: '4xl', md: '5xl' }}
          lineHeight="110%"
        >
          {'Unlock a new revenue stream, '}
          <Text as="span" color="common.primary">
            sharing what you love.
          </Text>
        </Heading>
        <Text
          color="text.muted"
          fontSize={{ base: 'md', sm: 'lg', md: 'xl' }}
          maxWidth={650}
          alignSelf="center"
        >
          Create unique experiences for your biggest fans, one step at a time.
          Get started for free today.
        </Text>
        <Stack
          direction="column"
          spacing={6}
          align="center"
          alignSelf="center"
          position="relative"
        >
          <LinkButton
            rounded="full"
            px={6}
            size="lg"
            icon="ArrowForward"
            iconPosition="right"
            to={navRoutes.global.planner.path()}
          >
            Start Making Your Course
          </LinkButton>
        </Stack>
      </Stack>
    </Container>
  );
};

const LandingScreen: React.FC<LandingScreenProps> = ({}) => {
  const bg = useColorModeValue('background.default', 'background.tint3');
  const learnMoreColor = useColorModeValue('blue', 'green');

  const discountIsValid = true;

  const history = useHistory();
  if (PLATFORM !== 'steppit') {
    history.push(navRoutes.public.login.path());
    return null;
  }

  return (
    <Box bg={bg}>
      <Flex
        as="nav"
        role="navigation"
        position="fixed"
        top={0}
        left={0}
        padding={{ base: 0, md: 0.5 }}
        zIndex={1100}
        width="100%"
        backgroundColor={bg}
        aria-label="Main Navigation"
      >
        <LinkBox padding={3} zIndex={1101}>
          <LinkOverlay href="/" aria-label="Steppit Homepage">
            <Brand navbarTransparent={false} />
          </LinkOverlay>
        </LinkBox>
        <AppHeader topPosition={0} onBg />
      </Flex>
      <Box id="steppit-newsletter" />
      <ScreenWrapper>
        <Hero />
        <Box>
          <Box
            position="sticky"
            top={NAV_HEIGHT}
            w="100%"
            py={2}
            px={4}
            bg={bg}
            zIndex={100}
            display={{ base: 'block', md: 'none' }}
          >
            <LinkButton
              size="lg"
              px={6}
              icon="ArrowForward"
              iconPosition="right"
              to={navRoutes.global.planner.path()}
              w="100%"
            >
              Start for Free
            </LinkButton>
          </Box>
          <Container maxW="7xl" pt={{ base: 8, md: 0 }}>
            {discountIsValid && discountLabel ? (
              <Text
                fontWeight="bold"
                fontSize="sm"
                textTransform="uppercase"
                color="text.success"
                textAlign="center"
                mb={2}
                mt={{ base: 0, md: -6 }}
                display={{ base: 'none', md: 'block' }}
              >
                {discountLabel}
              </Text>
            ) : null}
            {discountIsValid && discount ? (
              <Flex
                bg="background.success"
                py={3}
                px={4}
                borderRadius={{ base: 'lg', lg: 'full' }}
                textAlign={{ base: 'center' }}
                alignItems="center"
                justifyContent="center"
                mb={12}
                color="text.success"
                flexDirection={{ base: 'column', lg: 'row' }}
              >
                <Text fontWeight="bold" mt={{ base: 1, md: 0 }}>
                  {discount}
                </Text>
                <LinkButton
                  colorScheme={learnMoreColor}
                  variant="outline"
                  icon="ArrowForward"
                  iconPosition="right"
                  size="sm"
                  href={aiFeatureUrl}
                  ml={{ base: 0, lg: 4 }}
                  mt={{ base: 3, lg: 0 }}
                  mb={{ base: 2, lg: 0 }}
                >
                  Learn More
                </LinkButton>
              </Flex>
            ) : null}
          </Container>
          <FounderMessage />
          <Detail1 />
          <Divider />
          <Special />
          <Divider />
          <Detail2 />
          <Divider />
          <Testimonials />
          <Examples />
          <Divider />
          <Flow />
          <Divider />
          <ProSection />
          <Divider />
          <Features />
          <Divider />
          <Pricing />
        </Box>
        <Divider />
        <CallToAction />
      </ScreenWrapper>
      <Footer />
    </Box>
  );
};

export default LandingScreen;
