import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Flex,
  Card,
  Link,
  Skeleton,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  ModalFooter,
  Button,
  Text,
} from '@workshop/ui';

import { PLATFORM_DISPLAY_NAME, PLATFORM_EMAIL } from 'constants/common';

import { uiAction } from 'redux/actions/common';

import FacebookButton, {
  FacebookResponse,
} from 'components/SocialAuth/FacebookButton';
import AppleButton, { AppleResponse } from 'components/SocialAuth/AppleButton';

import { SocialProvider, SocialConnection, ProviderTypes } from 'types/common';

interface AccountManagementProps {
  isLoading?: boolean;
  socialConnectionLoadingState?: Record<ProviderTypes, boolean>;
  handleDeleteAccount: (deleteType: 'DEACTIVATE' | 'DELETE') => void;
  requestDataLink: string;
  socialProviders: SocialProvider[];
  socialConnections: SocialConnection[];
  removeSocialConnection: (id: string, provider: ProviderTypes) => void;
  handleResetPassword: () => void;
  handleConnect: ({
    provider,
    response,
  }:
    | {
        response: AppleResponse;
        provider: 'apple';
      }
    | { response: FacebookResponse; provider: 'facebook' }) => Promise<void>;
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
}

const AccountDeactivateModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onClick,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent borderRadius="md" justifyContent="center">
        <ModalHeader textAlign="center">
          Confirm Account Deactivation
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text textAlign="center">
            You will lose access to your content and not be able to log in.
          </Text>
          <Text fontWeight="bold" textAlign="center">
            To reactivate your account please email{' '}
            <Link
              href={`mailto:support${PLATFORM_EMAIL}`}
            >{`support${PLATFORM_EMAIL}`}</Link>
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClick} colorScheme="red" mr="defaultMargin">
            Deactivate Account
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const AccountDeleteModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onClick,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent borderRadius="md" justifyContent="center">
        <ModalHeader textAlign="center">Confirm Account Deletion</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text textAlign="center">
            {`Your account will be deactivated for 2 weeks. After which it will be permanently deleted and you will not be able to access your ${PLATFORM_DISPLAY_NAME} account. You will have to create a new account. `}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClick} colorScheme="red" mr="defaultMargin">
            Delete Account
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
const SocialConnectButton = (props: {
  provider: ProviderTypes;
  onConnect: ({
    response,
    provider,
  }:
    | {
        response: AppleResponse;
        provider: 'apple';
      }
    | { response: FacebookResponse; provider: 'facebook' }) => void;
  onError: () => void;
  isLoading: boolean | undefined;
}) => {
  const { provider, onConnect, onError, isLoading = false } = props;
  if (provider === 'apple') {
    return (
      <AppleButton
        isLoading={isLoading}
        isDisabled={isLoading}
        callback={(response) => onConnect({ response, provider })}
      >
        {(props) => (
          <Button
            secondary
            onClick={props.onClick}
            onError={onError}
            isLoading={props.isLoading}
            isDisabled={props.isDisabled}
            size="sm"
          >
            Connect Apple Account
          </Button>
        )}
      </AppleButton>
    );
  }

  if (provider === 'facebook') {
    return (
      <FacebookButton
        isLoading={isLoading}
        isDisabled={isLoading}
        callback={(response) => onConnect({ response, provider })}
      >
        {(props) => (
          <Button
            secondary
            onClick={props.onClick}
            onError={onError}
            isLoading={props.isLoading}
            isDisabled={props.isDisabled}
            size="sm"
          >
            Connect Facebook Account
          </Button>
        )}
      </FacebookButton>
    );
  }

  return null;
};

const AccountManagement: React.FC<AccountManagementProps> = ({
  isLoading,
  socialConnectionLoadingState,
  handleDeleteAccount,
  requestDataLink,
  socialProviders,
  socialConnections,
  removeSocialConnection,
  handleResetPassword,
  handleConnect,
}) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState<'deactivate' | 'delete'>(
    'deactivate'
  );
  const [isUpdating, setIsUpdating] = useState(false);

  const renderProviders = () => {
    return socialProviders.map((provider, key) => {
      const isConnected = socialConnections.find(
        (conn) => conn.provider === provider.id
      );
      const isLoading = socialConnectionLoadingState?.[provider.id];
      return (
        <Flex key={key} mb={2} alignItems="center">
          <Flex>
            <Flex flex={1} alignItems="center" justifyContent="flex-end">
              {isConnected ? (
                <Button
                  colorScheme="common.secondaryPalette"
                  color="text.default"
                  isLoading={isLoading}
                  onClick={() =>
                    removeSocialConnection(
                      isConnected.id.toString(),
                      isConnected.provider
                    )
                  }
                >
                  Disconnect
                </Button>
              ) : (
                <SocialConnectButton
                  provider={provider.id}
                  onConnect={handleConnect}
                  onError={() =>
                    dispatch(
                      uiAction.errorToastMessage('Unable to connect account.')
                    )
                  }
                  isLoading={isLoading}
                />
              )}
            </Flex>
          </Flex>
        </Flex>
      );
    });
  };

  return (
    <>
      <Card flexDir="column" padding="defaultPadding">
        <Flex
          flexDirection={{ base: 'column', sm: 'row' }}
          alignItems={{ base: 'normal', sm: 'center' }}
          mb="defaultMargin"
        >
          <Skeleton display="flex" flex={4} isLoaded={!isLoading}>
            <Text color="text.muted">Change Password:</Text>
          </Skeleton>
          <Flex
            flex={1}
            alignItems="center"
            justifyContent="flex-end"
            mt={{ base: 2, sm: 0 }}
          >
            {!isLoading && (
              <Button
                marginLeft="defaultMargin"
                size="sm"
                onClick={async () => {
                  setIsUpdating(true);
                  await handleResetPassword();
                  setIsUpdating(false);
                }}
                isLoading={isUpdating}
              >
                Request Password Reset Link
              </Button>
            )}
          </Flex>
        </Flex>
        <Flex flexDir="column" mb="defaultMargin">
          {/* mb to match form label */}
          <Text color="text.muted" mb={2}>
            Social Accounts:
          </Text>
          <Text fontSize="sm" mb={4}>
            {`Connect to a social account for easier access to ${PLATFORM_DISPLAY_NAME} - you'll be able to log in with 1-click. We'll never use your social account's data without asking for permission.`}
          </Text>
          {isLoading ? <Skeleton height={6} width="75%" /> : renderProviders()}
        </Flex>
        {/* mb to match form label */}
        <Text color="text.muted" mb={2}>
          {`Deactivate your ${PLATFORM_DISPLAY_NAME} Account:`}
        </Text>
        <Flex
          flexDirection={{ base: 'column', sm: 'row' }}
          alignItems={{ base: 'normal', sm: 'center' }}
          mb="defaultMargin"
        >
          <Skeleton display="flex" flex={3} isLoaded={!isLoading}>
            <Text fontSize="sm">
              You will lose access to all of your content and will no longer be
              able to access your account. To reactivate your account please
              email{' '}
              <Link
                href={`mailto:support${PLATFORM_EMAIL}`}
              >{`support${PLATFORM_EMAIL}`}</Link>
            </Text>
          </Skeleton>
          <Flex flex={1} alignItems="center" justifyContent="flex-end">
            {!isLoading && (
              <Button
                onClick={() => {
                  setModalOpen(true);
                  setModalType('deactivate');
                }}
                size="sm"
                variant="ghost"
              >
                Deactivate Account
              </Button>
            )}
          </Flex>
        </Flex>
        {/* mb to match form label */}
        <Text color="text.muted" mb={2}>
          {`Delete your ${PLATFORM_DISPLAY_NAME} Account:`}
        </Text>
        <Flex
          flexDirection={{ base: 'column', sm: 'row' }}
          alignItems={{ base: 'normal', sm: 'center' }}
          mb="defaultMargin"
        >
          <Skeleton display="flex" flex={3} isLoaded={!isLoading}>
            <Text fontSize="sm" color="text.error">
              {`This will erase all your account and all of your ${PLATFORM_DISPLAY_NAME} data including your purchases. `}
              <Text fontWeight="bold" as="u">
                Warning: This action is irreversible.
              </Text>
            </Text>
          </Skeleton>
          <Flex flex={1} alignItems="center" justifyContent="flex-end">
            {!isLoading && (
              <Button
                onClick={() => {
                  setModalOpen(true);
                  setModalType('delete');
                }}
                size="sm"
                colorScheme="red"
                variant="ghost"
              >
                Delete Account
              </Button>
            )}
          </Flex>
        </Flex>
        <Skeleton display="flex" alignItems="center" isLoaded={!isLoading}>
          <Text color="text.muted">
            <Link href={requestDataLink} target="_blank">
              Click here
            </Link>
            {` to request a copy of your ${PLATFORM_DISPLAY_NAME} user data`}
          </Text>
        </Skeleton>
      </Card>
      <AccountDeactivateModal
        isOpen={modalOpen && modalType === 'deactivate'}
        onClose={() => setModalOpen(false)}
        onClick={() => handleDeleteAccount('DEACTIVATE')}
      />
      <AccountDeleteModal
        isOpen={modalOpen && modalType === 'delete'}
        onClose={() => setModalOpen(false)}
        onClick={() => handleDeleteAccount('DELETE')}
      />
    </>
  );
};

export default AccountManagement;
