import React, { useEffect, useState, useMemo, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useSpring, animated, Interpolation, Transition } from 'react-spring';
import { useLottie } from 'lottie-react';
import createScrollSnap from 'scroll-snap';
import throttle from 'lodash/throttle';

import { PLATFORM } from 'constants/env';

import navRoutes from 'navigation/Routes';

import { useWindowDimensions } from 'utils/hooks/useDimensions';
import { hooks } from 'utils';

import {
  LinkBox,
  LinkOverlay,
  Flex,
  Text,
  Box,
  Stack,
  Button,
  LinkButton,
  MdIcon,
  chakra,
  useColorModeValue,
  useTheme,
} from '@workshop/ui';
import Brand from 'components/Brand';
import { AppHeader, NAV_HEIGHT } from 'containers/AppHeader';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import { PaymentsWelcomeModal } from 'screens/cms/MyOrganisation/src';

import { SessionModal } from 'components/Common';
import { BrandPlus } from 'components/Brand';

// @ts-ignore
import doItImage from 'assets/images/phrases/do-it.jpg'; // @ts-ignore
import makeItImage from 'assets/images/phrases/make-it.jpg'; // @ts-ignore
import codeItImage from 'assets/images/phrases/code-it.jpg'; // @ts-ignore
import workItImage from 'assets/images/phrases/work-it.jpg'; // @ts-ignore
import writeItImage from 'assets/images/phrases/write-it.jpg'; // @ts-ignore
import paintItImage from 'assets/images/phrases/paint-it.jpg'; // @ts-ignore
import cookItImage from 'assets/images/phrases/cook-it.jpg'; // @ts-ignore
import playItImage from 'assets/images/phrases/play-it.jpg'; // @ts-ignore
import clipImageL from 'assets/images/examples/clip-image-l.jpg'; // @ts-ignore
import clipImageC from 'assets/images/examples/clip-image-c.jpg'; // @ts-ignore
import clipImageR from 'assets/images/examples/clip-image-r.jpg'; // @ts-ignore
import oilSessionImage from 'assets/images/examples/oil.jpg'; // @ts-ignore
import cocktailSessionImage from 'assets/images/examples/cocktail.jpg'; // @ts-ignore
import makeupSessionImage from 'assets/images/examples/makeup.jpg'; // @ts-ignore
import cakeSessionImage from 'assets/images/examples/cake.jpg'; // @ts-ignore
import kitchenSessionImage from 'assets/images/examples/kitchen.jpg';
import brainAnimation from 'assets/lottie/brain.json';
import classAnimation from 'assets/lottie/class.json';

const PHRASES = [
  {
    idx: 0,
    label: 'Do it',
    image: doItImage,
  },
  {
    idx: 1,
    label: 'Make it',
    image: makeItImage,
  },
  {
    idx: 2,
    label: 'Code it',
    image: codeItImage,
  },
  {
    idx: 3,
    label: 'Work it',
    image: workItImage,
  },
  {
    idx: 4,
    label: 'Write it',
    image: writeItImage,
  },
  {
    idx: 5,
    label: 'Paint it',
    image: paintItImage,
  },
  {
    idx: 6,
    label: 'Cook it',
    image: cookItImage,
  },
  {
    idx: 7,
    label: 'Play it',
    image: playItImage,
  },
];

const XXS_CIRCLE_SIZE = 8;
const XS_CIRCLE_SIZE = 20;
const SM_CIRCLE_SIZE = 40;
const MD_CIRCLE_SIZE = 100;
const LG_CIRCLE_SIZE = 250;
const XL_CIRCLE_SIZE = 500;

const XS_MARGIN = 4;
const SM_MARGIN = 16;
const LG_MARGIN = 32;

const POPUP_SESSIONS = [
  // {
  //   icon: 'Draw',
  //   label: 'Fine Art Oil Painting',
  //   courseSlug: 'oil-painting',
  //   moduleId: 166,
  //   link: 'https://www.workshop.ws/courses/fine-arts-master-oil-painting/',
  //   image: oilSessionImage,
  // },

  // {
  //   icon: 'Mood',
  //   label: 'Flawless Face Makeup',
  //   courseSlug: 'fashion-tv-media-makeup',
  //   moduleId: 149,
  //   link: 'https://www.workshop.ws/courses/dfma-fashion-tv-media-makeup-professional-online-course/',
  //   image: makeupSessionImage,
  // },
  {
    icon: 'Cake',
    label: 'Cake Decorating',
    courseSlug: 'cKau4Bls',
    moduleId: 1994,
    image: cakeSessionImage,
    link: '',
  },
  {
    icon: 'Kitchen',
    label: 'Fitting a Kitchen Plinth',
    courseSlug: 'H7RgwHym',
    moduleId: 1989,
    image: kitchenSessionImage,
    link: '',
  },
  {
    icon: 'LocalBar',
    label: 'Making Quick Cocktails',
    courseSlug: 'TrO1qWsI',
    moduleId: 2016,
    image: cocktailSessionImage,
    link: '',
  },
];

interface HowItWorksScreenProps extends RouteComponentProps {}

type LandingAnimationProps = {
  page: number;
};

const LandingAnimation: React.FC<LandingAnimationProps> = ({ page }) => {
  const AnimatedFlex = animated(Flex);

  const theme = useTheme();
  const [{ progress }, animateProgress] = useSpring(() => ({
    progress: 0,
    config: { mass: 2, tension: 150, friction: 25 },
  }));

  useEffect(() => {
    animateProgress({ progress: page });
  }, [page]);

  const animatedStyles = useMemo(() => {
    const defaultStyles = {
      wrapper: {
        scale: 1,
      },
      stepLeft: {
        opacity: 1,
        width: SM_CIRCLE_SIZE,
        height: SM_CIRCLE_SIZE,
        scale: 1,
        translateX: 0,
      },
      stepMain: {
        width: SM_CIRCLE_SIZE,
        height: SM_CIRCLE_SIZE,
        background: theme.colors.common.primary,
        marginLeft: LG_MARGIN,
        marginRight: LG_MARGIN,
      },
      stepRight: {
        opacity: 1,
        width: SM_CIRCLE_SIZE,
        height: SM_CIRCLE_SIZE,
        scale: 1,
        translateX: 0,
      },
      clipLeft: {
        width: 0,
        height: 0,
        opacity: 0,
      },
      clipMain: {
        width: 0,
        height: 0,
        marginLeft: LG_MARGIN,
        marginRight: LG_MARGIN,
        opacity: 0,
      },
      clipRight: {
        width: 0,
        height: 0,
        opacity: 0,
      },
      clipImageSide: {
        height: 0,
        opacity: 0,
      },
      clipImageMain: {
        height: 0,
        opacity: 0,
      },
      sessionMain: {
        width: XL_CIRCLE_SIZE,
        height: XL_CIRCLE_SIZE,
        translateY: 0,
        translateX: 0,
      },
      sessionMainBg: {
        opacity: 0,
        background: theme.colors.background.primary,
      },
      courseBubbleMid: {
        scale: 0.9,
        opacity: 0,
        background: theme.colors.background.primary,
      },
      courseBubbleLow: {
        scale: 0.9,
        opacity: 0,
        background: theme.colors.background.primary,
      },
      classes: {
        opacity: 0,
        translateX: -20,
      },
    };
    const pageStyles = [
      {
        ...defaultStyles,
        wrapper: {
          scale: 2,
        },
        clipLeft: {
          width: 0,
          height: 0,
          opacity: [0, 0, 0, 1, 1],
        },
      },
      {
        ...defaultStyles,
        stepLeft: {
          opacity: 0.4,
          width: LG_CIRCLE_SIZE,
          height: LG_CIRCLE_SIZE,
          scale: 0.8,
          translateX: 100,
        },
        stepMain: {
          width: LG_CIRCLE_SIZE,
          height: LG_CIRCLE_SIZE,
          background: theme.colors.common.primary,
          marginLeft: LG_MARGIN,
          marginRight: LG_MARGIN,
        },
        stepRight: {
          opacity: 0.4,
          width: LG_CIRCLE_SIZE,
          height: LG_CIRCLE_SIZE,
          scale: 0.8,
          translateX: -100,
        },
        clipLeft: {
          width: SM_CIRCLE_SIZE,
          height: SM_CIRCLE_SIZE,
          opacity: [1, 1, 1, 1, 1],
        },
        clipMain: {
          width: SM_CIRCLE_SIZE,
          height: SM_CIRCLE_SIZE,
          marginLeft: LG_MARGIN,
          marginRight: LG_MARGIN,
          opacity: [1, 1, 1, 1, 1],
        },
        clipRight: {
          width: SM_CIRCLE_SIZE,
          height: SM_CIRCLE_SIZE,
          opacity: [0, 0, 1, 1, 1],
        },
        clipImageSide: {
          height: [0, 0, 0, 0, 100],
          opacity: 0,
        },
        clipImageMain: {
          height: [0, 0, 0, 0, 100],
          opacity: 0,
        },
      },
      {
        ...defaultStyles,
        wrapper: {
          scale: 0.85,
        },
        stepLeft: {
          opacity: [0, 0, 0, 0, 0],
          width: LG_CIRCLE_SIZE,
          height: LG_CIRCLE_SIZE,
          scale: 0.8,
          translateX: -100,
        },
        stepMain: {
          width: LG_CIRCLE_SIZE,
          height: LG_CIRCLE_SIZE,
          background: 'transparent',
          marginLeft: LG_MARGIN,
          marginRight: LG_MARGIN,
        },
        stepRight: {
          opacity: [0, 0, 0, 0, 0],
          width: LG_CIRCLE_SIZE,
          height: LG_CIRCLE_SIZE,
          scale: 0.8,
          translateX: 100,
        },
        clipLeft: {
          width: XS_CIRCLE_SIZE,
          height: XS_CIRCLE_SIZE,
          opacity: 1,
        },
        clipMain: {
          width: 100,
          height: XS_CIRCLE_SIZE,
          marginLeft: SM_MARGIN,
          marginRight: SM_MARGIN,
          opacity: 1,
        },
        clipRight: {
          width: XS_CIRCLE_SIZE,
          height: XS_CIRCLE_SIZE,
          opacity: 1,
        },
        clipImageSide: {
          height: [200, 300, 400, 300, 200],
          opacity: 1,
        },
        clipImageMain: {
          height: [200, 300, 400, 300, 200],
          opacity: 1,
        },
      },
      {
        ...defaultStyles,
        sessionMain: {
          width: LG_CIRCLE_SIZE,
          height: LG_CIRCLE_SIZE,
          translateY: 0,
          translateX: [0, 0, 0, 0, 0],
        },
        sessionMainBg: {
          opacity: 1,
          background: theme.colors.background.primary,
        },
        stepLeft: {
          opacity: 1,
          width: SM_CIRCLE_SIZE,
          height: SM_CIRCLE_SIZE,
          scale: 1,
          translateX: 0,
        },
        stepMain: {
          width: [
            LG_CIRCLE_SIZE * 0.8 + SM_CIRCLE_SIZE * 0.2,
            LG_CIRCLE_SIZE * 0.2 + SM_CIRCLE_SIZE * 0.8,
            SM_CIRCLE_SIZE,
            SM_CIRCLE_SIZE,
            SM_CIRCLE_SIZE,
          ],
          height: [
            LG_CIRCLE_SIZE * 0.8 + SM_CIRCLE_SIZE * 0.2,
            LG_CIRCLE_SIZE * 0.2 + SM_CIRCLE_SIZE * 0.8,
            SM_CIRCLE_SIZE,
            SM_CIRCLE_SIZE,
            SM_CIRCLE_SIZE,
          ],
          background: theme.colors.common.primary,
          marginLeft: LG_MARGIN,
          marginRight: LG_MARGIN,
        },
        stepRight: {
          opacity: 1,
          width: SM_CIRCLE_SIZE,
          height: SM_CIRCLE_SIZE,
          scale: 1,
          translateX: 0,
        },
        clipLeft: {
          width: XXS_CIRCLE_SIZE,
          height: XXS_CIRCLE_SIZE,
          opacity: 1,
        },
        clipMain: {
          width: XXS_CIRCLE_SIZE,
          height: XXS_CIRCLE_SIZE,
          marginLeft: XS_MARGIN,
          marginRight: XS_MARGIN,
          opacity: 1,
        },
        clipRight: {
          width: XXS_CIRCLE_SIZE,
          height: XXS_CIRCLE_SIZE,
          opacity: 1,
        },
        clipImageSide: {
          height: [100, 0, 0, 0, 0],
          opacity: 0,
        },
        clipImageMain: {
          height: [100, 0, 0, 0, 0],
          opacity: 0,
        },
      },
      {
        ...defaultStyles,
        wrapper: {
          scale: 2,
        },
        sessionMain: {
          width: MD_CIRCLE_SIZE,
          height: MD_CIRCLE_SIZE,
          translateY: -33.333,
          translateX: 33.333,
        },
        sessionMainBg: {
          opacity: 1,
          background: theme.colors.common.primaryDark,
        },
        stepLeft: {
          opacity: 1,
          width: 0,
          height: 0,
          scale: 1,
          translateX: 0,
        },
        stepMain: {
          width: 0,
          height: 0,
          background: theme.colors.common.primary,
          marginLeft: LG_MARGIN,
          marginRight: LG_MARGIN,
        },
        stepRight: {
          opacity: 1,
          width: 0,
          height: 0,
          scale: 1,
          translateX: 0,
        },
        courseBubbleMid: {
          scale: [0.9, 1, 1, 1.1, 1.1],
          opacity: [1, 1, 1, 1, 1],
          background: theme.colors.common.primaryDark,
        },
        courseBubbleLow: {
          scale: [0.9, 0.95, 1, 1.1, 1.1],
          opacity: [0, 0.5, 1, 1, 1],
          background: theme.colors.common.primaryDark,
        },
      },
    ];

    const pageIncrements = [...new Array(pageStyles.length * 5)].map(
      (i, index) => index / 5
    );

    const result = Object.keys(defaultStyles).reduce(
      (prevComponents, componentKey) => ({
        ...prevComponents,
        // @ts-ignore
        [componentKey]: Object.keys(defaultStyles[componentKey]).reduce(
          (prevStyles, styleKey) => {
            return {
              ...prevStyles,
              [styleKey]: progress.to({
                range: pageIncrements.map((i) => i - 0.4),
                output: pageIncrements.map((i) => {
                  const pageStyleIndex = Math.floor(i);
                  const style =
                    // @ts-ignore
                    pageStyles[pageStyleIndex][componentKey][styleKey];
                  const styleArrayIndex =
                    Math.round((i - pageStyleIndex) * 50) / 10;
                  if (Array.isArray(style)) {
                    return style[styleArrayIndex];
                  }
                  if (isNaN(style)) return style;
                  let prevStyle = pageStyles[pageStyleIndex - 1]
                    ? // @ts-ignore
                      pageStyles[pageStyleIndex - 1][componentKey][styleKey]
                    : null;
                  if (Array.isArray(prevStyle)) {
                    prevStyle = prevStyle[4];
                  }
                  let nextStyle = pageStyles[pageStyleIndex + 1]
                    ? // @ts-ignore
                      pageStyles[pageStyleIndex + 1][componentKey][styleKey]
                    : null;
                  if (Array.isArray(nextStyle)) {
                    nextStyle = nextStyle[0];
                  }
                  if (styleArrayIndex === 0) {
                    // 40% prev
                    // 60% current
                    return prevStyle * 0.4 + style * 0.6;
                  }
                  if (styleArrayIndex === 1) {
                    // 20% prev
                    // 80% current
                    return prevStyle * 0.2 + style * 0.8;
                  }
                  if (styleArrayIndex === 2) {
                    // 100% current
                    return style;
                  }
                  if (styleArrayIndex === 3) {
                    // 80% current
                    // 20% next
                    return style * 0.8 + nextStyle * 0.2;
                  }
                  if (styleArrayIndex === 4) {
                    // 60% current
                    // 40% next
                    return style * 0.6 + nextStyle * 0.4;
                  }
                  return style;
                }),
              }),
            };
          },
          {} as { [styleKey: string]: Interpolation<number> }
        ),
      }),
      {} as {
        [componentKey: string]: { [styleKey: string]: Interpolation<number> };
      }
    );
    return result;
  }, []);

  return (
    <AnimatedFlex
      alignItems="center"
      justifyContent="center"
      style={animatedStyles.wrapper}
    >
      <Flex position="absolute" transform="translateX(-50px) translateY(25px)">
        <AnimatedFlex
          boxSize={`${MD_CIRCLE_SIZE * 0.667}px`}
          borderRadius="full"
          style={animatedStyles.courseBubbleMid}
        />
      </Flex>
      <Flex position="absolute" transform="translateX(29px) translateY(62.5px)">
        <AnimatedFlex
          boxSize={`${MD_CIRCLE_SIZE * 0.42}px`}
          borderRadius="full"
          style={animatedStyles.courseBubbleLow}
        />
      </Flex>
      <AnimatedFlex
        borderRadius="full"
        alignItems="center"
        justifyContent="center"
        position="relative"
        style={animatedStyles.sessionMain}
      >
        <AnimatedFlex
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          borderRadius="full"
          style={animatedStyles.sessionMainBg}
        />
        <Flex position="absolute" alignItems="center" justifyContent="center">
          <Transition
            items={page >= 0}
            trail={500}
            from={{
              opacity: 0,
            }}
            enter={{
              opacity: 1,
            }}
            leave={{
              opacity: 0,
            }}
            config={{ duration: 500 }}
          >
            {(styles, item) =>
              item && (
                <animated.span style={styles}>
                  <AnimatedFlex
                    borderRadius="full"
                    bg="common.primary"
                    alignItems="center"
                    justifyContent="center"
                    style={animatedStyles.stepLeft}
                    zIndex={0}
                  >
                    <AnimatedFlex
                      borderRadius="full"
                      bg="common.progress"
                      style={animatedStyles.clipLeft}
                    />
                    <AnimatedFlex
                      borderRadius="full"
                      bg="common.progress"
                      style={animatedStyles.clipMain}
                    />
                    <AnimatedFlex
                      borderRadius="full"
                      bg="common.progress"
                      style={animatedStyles.clipRight}
                    />
                  </AnimatedFlex>
                </animated.span>
              )
            }
          </Transition>
          <Transition
            items={page >= 0}
            trail={1000}
            from={{
              opacity: 0,
            }}
            enter={{
              opacity: 1,
            }}
            leave={{
              opacity: 0,
            }}
            config={{ duration: 500 }}
          >
            {(styles, item) =>
              item && (
                <animated.span style={styles}>
                  <AnimatedFlex
                    borderRadius="full"
                    alignItems="center"
                    justifyContent="center"
                    style={animatedStyles.stepMain}
                    zIndex={1}
                  >
                    <Flex
                      position="absolute"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Flex pb={8}>
                        <AnimatedFlex
                          w={`${LG_CIRCLE_SIZE}px`}
                          borderRadius="lg"
                          zIndex={0}
                          transform="translateX(100px) scale(0.7)"
                          backgroundColor="background.tint2"
                          backgroundImage={`url(${clipImageL})`}
                          backgroundSize="cover"
                          backgroundPosition="center"
                          style={animatedStyles.clipImageSide}
                        />
                        <AnimatedFlex
                          w={`${LG_CIRCLE_SIZE}px`}
                          borderRadius="lg"
                          zIndex={1}
                          backgroundColor="background.tint2"
                          backgroundImage={`url(${clipImageC})`}
                          backgroundSize="cover"
                          backgroundPosition="center"
                          boxShadow="lg"
                          style={animatedStyles.clipImageMain}
                        />
                        <AnimatedFlex
                          w={`${LG_CIRCLE_SIZE}px`}
                          borderRadius="lg"
                          zIndex={0}
                          transform="translateX(-100px) scale(0.7)"
                          backgroundColor="background.tint2"
                          backgroundImage={`url(${clipImageR})`}
                          backgroundSize="cover"
                          backgroundPosition="center"
                          style={animatedStyles.clipImageSide}
                        />
                      </Flex>
                      <Flex alignItems="center" pb={8}>
                        <Transition
                          items={page >= 1}
                          trail={0}
                          from={{
                            opacity: 0,
                          }}
                          enter={{
                            opacity: 1,
                          }}
                          leave={{
                            opacity: 0,
                          }}
                          config={{ duration: 500 }}
                        >
                          {(styles, item) =>
                            item && (
                              <animated.span style={styles}>
                                <AnimatedFlex
                                  borderRadius="full"
                                  bg="common.progress"
                                  style={animatedStyles.clipLeft}
                                />
                              </animated.span>
                            )
                          }
                        </Transition>
                        <Transition
                          items={page >= 1}
                          trail={500}
                          from={{
                            opacity: 0,
                          }}
                          enter={{
                            opacity: 1,
                          }}
                          leave={{
                            opacity: 0,
                          }}
                          config={{ duration: 500 }}
                        >
                          {(styles, item) =>
                            item && (
                              <animated.span style={styles}>
                                <AnimatedFlex
                                  borderRadius="full"
                                  bg="common.progress"
                                  style={animatedStyles.clipMain}
                                />
                              </animated.span>
                            )
                          }
                        </Transition>
                        <Transition
                          items={page >= 1}
                          trail={1000}
                          from={{
                            opacity: 0,
                          }}
                          enter={{
                            opacity: 1,
                          }}
                          leave={{
                            opacity: 0,
                          }}
                          config={{ duration: 500 }}
                        >
                          {(styles, item) =>
                            item && (
                              <animated.span style={styles}>
                                <AnimatedFlex
                                  borderRadius="full"
                                  bg="common.progress"
                                  style={animatedStyles.clipRight}
                                />
                              </animated.span>
                            )
                          }
                        </Transition>
                      </Flex>
                    </Flex>
                  </AnimatedFlex>
                </animated.span>
              )
            }
          </Transition>
          <Transition
            items={page >= 0}
            trail={1500}
            from={{
              opacity: 0,
            }}
            enter={{
              opacity: 1,
            }}
            leave={{
              opacity: 0,
            }}
            config={{ duration: 500 }}
          >
            {(styles, item) =>
              item && (
                <animated.span style={styles}>
                  <AnimatedFlex
                    borderRadius="full"
                    bg="common.primary"
                    style={animatedStyles.stepRight}
                    zIndex={0}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <AnimatedFlex
                      borderRadius="full"
                      bg="common.progress"
                      style={animatedStyles.clipLeft}
                    />
                    <AnimatedFlex
                      borderRadius="full"
                      bg="common.progress"
                      style={animatedStyles.clipMain}
                    />
                    <AnimatedFlex
                      borderRadius="full"
                      bg="common.progress"
                      style={animatedStyles.clipRight}
                    />
                  </AnimatedFlex>
                </animated.span>
              )
            }
          </Transition>
        </Flex>
      </AnimatedFlex>
    </AnimatedFlex>
  );
};

const HowItWorksScreen: React.FC<HowItWorksScreenProps> = ({ location }) => {
  const history = useHistory();

  const scrollContainer = useRef<HTMLDivElement>(null);

  const [page, setPage] = useState(0);
  const [titleIdx, setTitleIdx] = useState(PHRASES[0].idx);
  const [brainPhase, setBrainPhase] = useState(0);
  const [brainStepsToggle, setBrainStepsToggle] = useState(false);
  const [brainSpeedSetting, setBrainSpeedSetting] = useState(1);
  const [stepsPhase, setStepsPhase] = useState(-1);
  const [sessionPopupSlug, setSessionPopupSlug] = useState('');
  const [brainJson, setBrainJson] = useState(null);
  const [classJson, setClassJson] = useState(null);
  const [showPricing, setShowPricing] = useState(false);

  const bg = useColorModeValue('background.default', 'background.tint3');
  const bg2 = useColorModeValue('background.tint1', 'background.default');

  const { imagesPreloaded } = hooks.useImagePreloader([
    doItImage,
    makeItImage,
    codeItImage,
    workItImage,
    writeItImage,
    paintItImage,
    cookItImage,
    playItImage,
  ]);

  const windowDimensions = useWindowDimensions();
  const isLowHeight =
    windowDimensions.height === 0
      ? window.innerHeight < 800
      : windowDimensions.height < 800;

  const mainFontSize = isLowHeight
    ? { base: 'md', sm: 'lg', md: 'xl' }
    : { base: 'xl', sm: '2xl', md: '3xl' };

  const titleTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const brainPhase0Timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const brainPhase1Timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const brainPhase2aTimeout = useRef<ReturnType<typeof setTimeout> | null>(
    null
  );
  const brainPhase2bTimeout = useRef<ReturnType<typeof setTimeout> | null>(
    null
  );
  const brainPhase2cTimeout = useRef<ReturnType<typeof setTimeout> | null>(
    null
  );
  const brainPhase3Timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const stepsPhase0Timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const stepsPhase3Timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const stepsPhase5Timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const clearTitleTimeout = () => {
    titleTimeout.current && clearTimeout(titleTimeout.current);
  };

  const clearBrainSpeedTimeouts = () => {
    brainPhase0Timeout.current && clearTimeout(brainPhase0Timeout.current);
    brainPhase1Timeout.current && clearTimeout(brainPhase1Timeout.current);
    brainPhase2aTimeout.current && clearTimeout(brainPhase2aTimeout.current);
    brainPhase2bTimeout.current && clearTimeout(brainPhase2bTimeout.current);
    brainPhase2cTimeout.current && clearTimeout(brainPhase2cTimeout.current);
    brainPhase3Timeout.current && clearTimeout(brainPhase3Timeout.current);
  };

  const clearStepsPhaseTimeouts = () => {
    stepsPhase0Timeout.current && clearTimeout(stepsPhase0Timeout.current);
    stepsPhase3Timeout.current && clearTimeout(stepsPhase3Timeout.current);
    stepsPhase5Timeout.current && clearTimeout(stepsPhase5Timeout.current);
  };

  const { View: lottieBrain, setSpeed: setBrainSpeed } = useLottie({
    animationData: brainJson,
    style: {
      height: isLowHeight ? 250 : 350,
      width: isLowHeight ? 250 : 350,
    },
  });

  const { View: lottieClass } = useLottie({
    animationData: classJson,
    style: {
      height: isLowHeight ? 200 : 400,
    },
  });

  const brainStepDuration = Math.min(
    Math.max(1500 / brainSpeedSetting, 300),
    600
  );

  const scrollToPage = (pageNumber: number) => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollTo({
        top: pageNumber * windowDimensions.height,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    // @ts-ignore
    setBrainJson(brainAnimation);
    // @ts-ignore
    setClassJson(classAnimation);
    return () => {
      clearTitleTimeout();
      clearBrainSpeedTimeouts();
      clearStepsPhaseTimeouts();
    };
  }, []);

  useEffect(() => {
    // TODO: Fix scrollTo on iOS – use windowDimensions.height?
    // Use simulator to test
    if (scrollContainer.current && scrollContainer.current.addEventListener) {
      scrollContainer.current.addEventListener(
        'scroll',
        throttle(() => {
          if (scrollContainer.current) {
            const { scrollTop, clientHeight } = scrollContainer.current;
            const page = Math.round(scrollTop / clientHeight);
            setPage(page);
          }
        }, 500)
      );
    }
  }, [scrollContainer.current]);

  useEffect(() => {
    if (scrollContainer.current) {
      createScrollSnap(scrollContainer.current, {
        snapDestinationY: `${windowDimensions.height}px`,
        timeout: 0,
      });
      scrollToPage(page);
    }
  }, [scrollContainer.current, windowDimensions.height]);

  useEffect(() => {
    if (page === 1 && brainPhase !== 0 && brainPhase !== 1) {
      setBrainPhase(1);
    }
    if (page === 2) {
      setBrainPhase(2);
    }
    if (page > 2 && brainPhase !== 3) {
      setBrainPhase(3);
    }
    if (page < 3 && stepsPhase !== -1) {
      setStepsPhase(-1);
    }
    if (page === 3) {
      setStepsPhase(0);
    }
    if (page === 4) {
      setStepsPhase(2);
    }
    if (page === 5) {
      setStepsPhase(3);
    }
  }, [page]);

  useEffect(() => {
    if (brainPhase === 0) {
      // Set speed low
      setBrainSpeed(1);
      setBrainSpeedSetting(1);
      brainPhase0Timeout.current = setTimeout(() => setBrainPhase(1), 2000);
    }
    if (brainPhase === 1) {
      // Set speed high, set phase back to 0
      setBrainStepsToggle(!brainStepsToggle);
      setBrainSpeed(2);
      setBrainSpeedSetting(2);
      brainPhase1Timeout.current = setTimeout(() => setBrainPhase(0), 1000);
    }
    if (brainPhase === 2) {
      // Set speed very high, set phase to 3
      setBrainStepsToggle(!brainStepsToggle);
      setBrainSpeed(3);
      setBrainSpeedSetting(3);
      brainPhase2aTimeout.current = setTimeout(() => {
        setBrainSpeed(5);
        setBrainSpeedSetting(5);
        brainPhase2bTimeout.current = setTimeout(() => {
          setBrainSpeed(10);
          setBrainSpeedSetting(10);
          brainPhase2cTimeout.current = setTimeout(
            () => setBrainPhase(3),
            1000
          );
        }, 1000);
      }, 1000);
    }
    if (brainPhase === 3) {
      setBrainSpeed(0.5);
      setBrainSpeedSetting(0.5);
      brainPhase3Timeout.current = setTimeout(() => setBrainSpeed(0.1), 1000);
    }
    return () => clearBrainSpeedTimeouts();
  }, [brainPhase]);

  useEffect(() => {
    if (brainPhase === 2) {
      setTimeout(() => {
        setBrainStepsToggle(!brainStepsToggle);
      }, brainStepDuration);
    }
  }, [brainStepsToggle]);

  useEffect(() => {
    if (stepsPhase === 0 && page === 3) {
      stepsPhase0Timeout.current = setTimeout(() => setStepsPhase(1), 2500);
    }
    if (stepsPhase === 3) {
      stepsPhase3Timeout.current = setTimeout(() => setStepsPhase(4), 1000);
    }
    if (stepsPhase === 5) {
      stepsPhase5Timeout.current = setTimeout(() => setStepsPhase(6), 1000);
    }
    return () => clearStepsPhaseTimeouts();
  }, [stepsPhase, page]);

  if (PLATFORM !== 'steppit') {
    history.push(navRoutes.public.login.path());
    return null;
  }

  const pageNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const lastMainPage = 9;

  const bigButtonProps = {
    size: isLowHeight ? 'md' : 'lg',
    fontSize: isLowHeight
      ? { base: 'md', sm: 'lg', md: 'lg' }
      : { base: 'lg', sm: 'xl', md: '2xl' },
    h: isLowHeight
      ? { base: 10, sm: 10, md: 12 }
      : { base: 12, sm: 14, md: 16 },
    px: { base: 4, sm: 4, md: 6 },
    icon: 'ArrowDownward',
    iconPosition: 'right' as 'right',
    fontWeight: 'bold',
  };

  const pageHeight = `${windowDimensions.height}px`;
  const pageHeightX2 = `${windowDimensions.height * 2}px`;
  const pageHeightX3 = `${windowDimensions.height * 3}px`;

  const currentYear = new Date().getFullYear();

  return (
    <>
      <Flex
        position="fixed"
        top={0}
        left={0}
        padding={{ base: 0, md: 0.5 }}
        zIndex={1100}
        width="100%"
        backgroundColor={bg}
      >
        <LinkBox padding={3} zIndex={1101}>
          <LinkOverlay href="/" aria-label="Steppit Homepage">
            <Brand navbarTransparent={false} />
          </LinkOverlay>
        </LinkBox>
        <AppHeader topPosition={0} onBg />
      </Flex>
      <Flex
        position="fixed"
        bottom={6}
        left={0}
        padding={{ base: 0, md: 0.5 }}
        zIndex={1100}
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Transition
          items={page < lastMainPage}
          from={{
            opacity: 0,
            translateY: 100,
          }}
          enter={{
            opacity: 1,
            translateY: 0,
          }}
          leave={{
            opacity: 0,
            translateY: 100,
          }}
          config={{ duration: 500 }}
        >
          {(styles, item) =>
            item && (
              <animated.div style={{ position: 'absolute', ...styles }}>
                <Flex>
                  {pageNumbers
                    .filter((n) => n < lastMainPage)
                    .map((n) => (
                      <Box
                        key={`indicator-${n}`}
                        backgroundColor={
                          n <= page ? 'common.progress' : 'icon.muted'
                        }
                        opacity={n <= page ? 1 : 0.2}
                        borderRadius="full"
                        height={2.5}
                        marginX={1.5}
                        width={page === n ? 24 : 2.5}
                        transition="width 1s"
                        onClick={() => scrollToPage(n)}
                        cursor="pointer"
                        _hover={{ opacity: n <= page ? 0.8 : 0.3 }}
                      />
                    ))}
                </Flex>
              </animated.div>
            )
          }
        </Transition>
        <Transition
          items={page >= lastMainPage}
          from={{
            opacity: 0,
            translateY: 100,
          }}
          enter={{
            opacity: 1,
            translateY: 0,
          }}
          leave={{
            opacity: 0,
            translateY: 100,
          }}
          config={{ duration: 500 }}
        >
          {(styles, item) =>
            item && (
              <animated.div style={{ position: 'absolute', ...styles }}>
                <Flex>
                  {pageNumbers
                    .filter((n) => n >= lastMainPage)
                    .map((n) => (
                      <Box
                        key={`indicator-${n}`}
                        backgroundColor={
                          n <= page ? 'common.primary' : 'icon.muted'
                        }
                        opacity={n <= page ? 1 : 0.2}
                        borderRadius="full"
                        height={2.5}
                        marginX={1.5}
                        width={page === n ? 24 : 2.5}
                        transition="width 1s"
                        onClick={() => scrollToPage(n)}
                        cursor="pointer"
                        _hover={{ opacity: n <= page ? 0.8 : 0.3 }}
                      />
                    ))}
                </Flex>
              </animated.div>
            )
          }
        </Transition>
      </Flex>
      <Flex
        ref={scrollContainer}
        h={pageHeight}
        minH={pageHeight}
        flexDirection="column"
        overflowY="auto"
        textAlign="center"
        width="100%"
        overflowX="hidden"
      >
        <Flex backgroundColor={bg} h={pageHeight} minH={pageHeight}>
          <Flex flex={1} direction="column">
            <Flex mt={NAV_HEIGHT}>
              <ScreenWrapper mb={0}>
                <Flex
                  flex={1}
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                >
                  {page <= 1 && (
                    <Transition
                      items={PHRASES[titleIdx]}
                      from={{
                        opacity: 0,
                        translateX: -100,
                        scale: 0.8,
                      }}
                      enter={{
                        opacity: 1,
                        translateX: 0,
                        scale: 1,
                      }}
                      leave={{
                        opacity: 0,
                        translateX: 100,
                        scale: 0.8,
                      }}
                      config={{ mass: 1.2, tension: 200, friction: 20 }}
                      exitBeforeEnter
                      onRest={(_a, _b, item) => {
                        if (titleIdx === item.idx) {
                          titleTimeout.current = setTimeout(
                            () =>
                              setTitleIdx(
                                (state) => (state + 1) % PHRASES.length
                              ),
                            1500
                          );
                        }
                      }}
                    >
                      {/* @ts-ignore */}
                      {(styles, item) =>
                        item &&
                        imagesPreloaded && (
                          <animated.span style={styles}>
                            <Text
                              textTransform="uppercase"
                              fontSize={{ base: 60, sm: 80, md: 100, lg: 140 }}
                              lineHeight={1}
                              fontWeight="extrabold"
                              maxW={{ base: '500px', md: '650px' }}
                              overflow="hidden"
                              letterSpacing={-0.3}
                              mb={4}
                              backgroundImage={item.image}
                              backgroundClip="text"
                              backgroundSize="cover"
                              backgroundPosition="center"
                              color="transparent"
                              backgroundColor="background.tint2"
                              marginX="auto"
                            >
                              {item.label}
                            </Text>
                          </animated.span>
                        )
                      }
                    </Transition>
                  )}
                  <Text
                    fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
                    mb={10}
                    mx={4}
                  >
                    One step at a time.
                  </Text>
                  <Button {...bigButtonProps} onClick={() => scrollToPage(1)}>
                    Learn More
                  </Button>
                  <LinkButton
                    icon=""
                    mt={6}
                    to={navRoutes.global.planner.path()}
                    variant="ghost"
                    size="lg"
                  >
                    Start Now
                  </LinkButton>
                </Flex>
              </ScreenWrapper>
            </Flex>
          </Flex>
        </Flex>

        <Box position="relative">
          <Flex
            h={pageHeightX2}
            minH={pageHeightX2}
            flexDirection="column"
            textAlign="center"
          >
            <Flex
              position="absolute"
              top={0}
              left={0}
              h="100%"
              w="100%"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
            >
              <Flex
                position="sticky"
                top={0}
                h={pageHeight}
                minH={pageHeight}
                alignItems="center"
                justifyContent="center"
                pb={isLowHeight ? 150 : 300}
              >
                <Flex
                  transform={`rotate(${
                    brainPhase === 0
                      ? 0
                      : brainPhase === 1
                      ? -20
                      : brainPhase === 2
                      ? -30
                      : brainPhase === 3
                      ? 20
                      : 0
                  }deg)`}
                  transition="transform 4s"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    svg: {
                      path: {
                        ...(brainPhase === 0
                          ? {
                              stroke: 'text.primary',
                              fill: 'background.default',
                            }
                          : brainPhase === 1
                          ? {
                              stroke: 'text.success',
                              fill: 'background.success',
                            }
                          : brainPhase === 2
                          ? {
                              stroke: 'text.warning',
                              fill: 'background.warning',
                            }
                          : brainPhase === 3
                          ? {
                              stroke: 'text.error',
                              fill: 'background.default',
                            }
                          : {
                              stroke: 'common.primary',
                              fill: 'background.primary',
                            }),
                        transition: 'stroke 1s, fill 1s',
                      },
                    },
                  }}
                >
                  <Flex position="absolute">
                    {page <= 2 && (
                      <Transition
                        items={brainStepsToggle}
                        from={{
                          opacity: 0,
                          translateX: 500,
                        }}
                        enter={{
                          opacity: 1,
                          translateX: isLowHeight ? -60 : -80,
                        }}
                        leave={{
                          opacity: 0,
                          translateX: isLowHeight ? -60 : -80,
                        }}
                        config={{ duration: brainStepDuration }}
                      >
                        {(styles, item) =>
                          (brainPhase === 1 || brainPhase === 2) && (
                            <animated.div
                              style={{
                                position: 'absolute',
                                translateY: -50,
                                ...styles,
                              }}
                            >
                              {brainPhase === 1 ? (
                                <Flex
                                  position="absolute"
                                  boxSize={12}
                                  borderRadius="full"
                                  bg="common.progress"
                                  transition="background 1s"
                                />
                              ) : (
                                <Stack
                                  direction="column"
                                  position="absolute"
                                  spacing={3}
                                >
                                  <Flex
                                    w={24}
                                    h={4}
                                    borderRadius="full"
                                    bg="text.warning"
                                    transition="background 1s"
                                  />
                                  <Flex
                                    w={24}
                                    h={4}
                                    borderRadius="full"
                                    bg="text.warning"
                                    transition="background 1s"
                                  />
                                  <Flex
                                    w={24}
                                    h={4}
                                    borderRadius="full"
                                    bg="text.warning"
                                    transition="background 1s"
                                  />
                                </Stack>
                              )}
                            </animated.div>
                          )
                        }
                      </Transition>
                    )}
                  </Flex>

                  {lottieBrain}
                </Flex>
              </Flex>
            </Flex>
            <Flex bg="background.primary" color="text.primary" w="100%">
              <ScreenWrapper mb={0}>
                <Flex
                  h={pageHeight}
                  minH={pageHeight}
                  justifyContent="center"
                  alignItems="center"
                  pt={isLowHeight ? 200 : 300}
                  flexDirection="column"
                >
                  <Text
                    fontSize={mainFontSize}
                    mb={isLowHeight ? 6 : 12}
                    mx={4}
                  >
                    When you try something for the first time, the new challenge
                    should feel{' '}
                    <chakra.span fontWeight="bold" color="common.progress">
                      refreshing
                    </chakra.span>{' '}
                    and{' '}
                    <chakra.span fontWeight="bold" color="common.progress">
                      exciting
                    </chakra.span>
                    .
                  </Text>
                  <Button {...bigButtonProps} onClick={() => scrollToPage(2)}>
                    Continue
                  </Button>
                </Flex>
              </ScreenWrapper>
            </Flex>
            <Flex bg="background.error" color="text.error" w="100%">
              <ScreenWrapper mb={0}>
                <Flex
                  h={pageHeight}
                  minH={pageHeight}
                  justifyContent="center"
                  alignItems="center"
                  pt={isLowHeight ? 200 : 300}
                  flexDirection="column"
                >
                  <Text
                    fontSize={mainFontSize}
                    mb={isLowHeight ? 6 : 12}
                    mx={4}
                  >
                    But when it all comes at once, it'll make your head hurt and
                    you'll feel{' '}
                    <chakra.span fontWeight="bold">overwhelmed</chakra.span>.
                  </Text>
                  <Button {...bigButtonProps} onClick={() => scrollToPage(3)}>
                    Continue
                  </Button>
                </Flex>
              </ScreenWrapper>
            </Flex>
          </Flex>
        </Box>

        <Box position="relative">
          <Flex h={pageHeightX3} minH={pageHeightX3} flexDirection="column">
            <Flex
              position="absolute"
              top={0}
              left={0}
              h="100%"
              w="100%"
              flexDirection="column"
              alignItems="center"
            >
              <Flex
                position="sticky"
                top={0}
                h={pageHeight}
                minH={pageHeight}
                alignItems="center"
                justifyContent="center"
                pb={isLowHeight ? 250 : 300}
                transform={isLowHeight ? 'scale(0.7)' : 'scale(1)'}
              >
                {page >= 3 && <LandingAnimation page={stepsPhase} />}
              </Flex>
            </Flex>

            <Flex bg={bg} color="text.default" w="100%">
              <ScreenWrapper mb={0}>
                <Flex
                  h={pageHeight}
                  minH={pageHeight}
                  justifyContent="center"
                  alignItems="center"
                  pt={isLowHeight ? 200 : 300}
                  flexDirection="column"
                >
                  <Text fontSize={mainFontSize} mx={4}>
                    The best way to learn something new is to take it
                  </Text>
                  <Text
                    fontSize={mainFontSize}
                    mb={isLowHeight ? 4 : 6}
                    mx={4}
                    color="common.primary"
                    fontWeight="bold"
                  >
                    <Transition
                      items={page === 3}
                      trail={550}
                      from={{
                        opacity: 0,
                      }}
                      enter={{
                        opacity: 1,
                      }}
                      leave={{
                        opacity: 0,
                      }}
                      config={{ duration: 500 }}
                    >
                      {(wrapperStyles, wrapperItem) =>
                        wrapperItem && (
                          <animated.span style={wrapperStyles}>
                            <Transition
                              items={
                                page === 3 ? ['step ', 'by ', 'step...'] : []
                              }
                              trail={800}
                              from={{
                                opacity: 0,
                                scale: 0.9,
                              }}
                              enter={{
                                opacity: 1,
                                scale: 1,
                              }}
                              leave={{
                                opacity: 0,
                                scale: 0.9,
                              }}
                              config={{ duration: 500 }}
                            >
                              {(styles, item) =>
                                item && (
                                  <animated.span style={styles}>
                                    {item}
                                  </animated.span>
                                )
                              }
                            </Transition>
                          </animated.span>
                        )
                      }
                    </Transition>
                  </Text>
                  <Transition
                    items={stepsPhase === 1}
                    from={{
                      opacity: 0,
                    }}
                    enter={{
                      opacity: 1,
                    }}
                    leave={{
                      opacity: 0,
                    }}
                    config={{ duration: 500 }}
                    exitBeforeEnter
                  >
                    {(styles, item) => (
                      <animated.div
                        style={{
                          ...(item ? styles : { opacity: 0 }),
                        }}
                      >
                        <Text
                          fontSize={mainFontSize}
                          mb={isLowHeight ? 7 : 12}
                          mx={4}
                          color="common.progress"
                          fontWeight="bold"
                        >
                          ...
                          <Transition
                            items={['bit ', 'by ', 'bit.']}
                            trail={500}
                            from={{
                              opacity: 0,
                              scale: 0.9,
                            }}
                            enter={{
                              opacity: 1,
                              scale: 1,
                            }}
                            leave={{
                              opacity: 0,
                              scale: 0.9,
                            }}
                            config={{ duration: 500 }}
                          >
                            {(styles, item) =>
                              item && (
                                <animated.span style={styles}>
                                  {item}
                                </animated.span>
                              )
                            }
                          </Transition>
                        </Text>
                        <Button
                          {...bigButtonProps}
                          onClick={() => scrollToPage(4)}
                        >
                          Continue
                        </Button>
                      </animated.div>
                    )}
                  </Transition>
                </Flex>
              </ScreenWrapper>
            </Flex>
            <Flex bg={bg2} color="text.default" w="100%">
              <ScreenWrapper mb={0}>
                <Flex
                  h={pageHeight}
                  minH={pageHeight}
                  justifyContent="center"
                  alignItems="center"
                  pt={isLowHeight ? 300 : 400}
                  flexDirection="column"
                >
                  <Text fontSize={mainFontSize} mb={isLowHeight ? 2 : 4} mx={4}>
                    Steppit lets you teach skills and make quick how-tos bit by
                    bit.
                  </Text>
                  <Text
                    fontSize={
                      isLowHeight
                        ? { base: 'xs', sm: 'sm', md: 'md' }
                        : { base: 'sm', sm: 'md', md: 'lg' }
                    }
                    mb={isLowHeight ? 6 : 12}
                    mx={4}
                  >
                    Each bit can be any shape or size and made from video clips,
                    audio, pictures or just a few words.
                  </Text>
                  <Button {...bigButtonProps} onClick={() => scrollToPage(5)}>
                    Continue
                  </Button>
                </Flex>
              </ScreenWrapper>
            </Flex>
            <Flex bg={bg} color="text.default" w="100%">
              <ScreenWrapper mb={0}>
                <Flex
                  h={pageHeight}
                  minH={pageHeight}
                  justifyContent="center"
                  alignItems="center"
                  pt={isLowHeight ? 250 : 300}
                  flexDirection="column"
                >
                  <Text
                    fontSize={mainFontSize}
                    mb={isLowHeight ? 6 : 12}
                    mx={4}
                  >
                    Bits combine into step-by-step sessions, and sessions can be
                    put together to form full courses.
                  </Text>
                  <Button {...bigButtonProps} onClick={() => scrollToPage(6)}>
                    Continue
                  </Button>
                </Flex>
              </ScreenWrapper>
            </Flex>
          </Flex>
        </Box>
        <Box position="relative">
          <Flex bg="background.success" color="text.success" w="100%">
            <ScreenWrapper mb={0}>
              <Flex
                h={pageHeight}
                minH={pageHeight}
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Text fontSize={mainFontSize} mb={isLowHeight ? 6 : 12} mx={4}>
                  Here's a taste of what you can do with a session:
                </Text>
                <Stack
                  direction="row"
                  spacing={{ base: 2, md: 4 }}
                  mb={{ base: 4, md: 8 }}
                  flexFlow="wrap"
                  justifyContent="center"
                  mx={4}
                  minH="162px"
                >
                  {page === 6 && (
                    <Transition
                      items={page === 6 ? POPUP_SESSIONS : []}
                      trail={100}
                      from={{
                        opacity: 0,
                        scale: 0.5,
                      }}
                      enter={{
                        opacity: 1,
                        scale: 1,
                      }}
                      leave={{
                        opacity: 0,
                        scale: 0.5,
                      }}
                    >
                      {(styles, item) => {
                        if (!item) return null;
                        const { icon, label, courseSlug, image } = item;
                        return (
                          <animated.span style={styles}>
                            <Flex
                              key={`feature-${label}`}
                              flex={1}
                              flexDirection="column"
                              alignItems="center"
                              maxW="100px"
                              textAlign="center"
                              cursor="pointer"
                              onClick={() => setSessionPopupSlug(courseSlug)}
                              _hover={{
                                transform: 'scale(1.1)',
                              }}
                              transition="transform 0.3s"
                              role="group"
                              mb={4}
                            >
                              <Flex
                                boxSize={{
                                  base: isLowHeight ? 'image.xs' : 'image.sm',
                                  md: 'image.md',
                                }}
                                borderRadius="full"
                                backgroundColor="background.tint2"
                                backgroundImage={`url(${image})`}
                                backgroundSize="cover"
                                backgroundPosition="center"
                                boxShadow="md"
                                _groupHover={{ boxShadow: 'lg' }}
                                transition="box-shadow 0.3s"
                              />
                              <Flex
                                boxSize={8}
                                borderRadius="full"
                                bg={bg}
                                alignItems="center"
                                justifyContent="center"
                                boxShadow="md"
                                _groupHover={{ boxShadow: 'lg' }}
                                transition="box-shadow 0.3s"
                                mb={2}
                                mt={-4}
                              >
                                <MdIcon
                                  name={icon}
                                  color="icon.success"
                                  boxSize={5}
                                />
                              </Flex>
                              <Text
                                color="text.success"
                                fontSize="sm"
                                fontWeight="semibold"
                                whiteSpace="break-spaces"
                              >
                                {label}
                              </Text>
                            </Flex>
                          </animated.span>
                        );
                      }}
                    </Transition>
                  )}
                </Stack>
                <Button {...bigButtonProps} onClick={() => scrollToPage(7)}>
                  Continue
                </Button>
              </Flex>
            </ScreenWrapper>
          </Flex>
        </Box>
        <Box position="relative">
          <Flex bg={bg} color="text.default" w="100%">
            <ScreenWrapper mb={0}>
              <Flex
                h={pageHeight}
                minH={pageHeight}
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                maxWidth={750}
                mx="auto"
              >
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  display={{ base: isLowHeight ? 'none' : 'flex', md: 'flex' }}
                  sx={{
                    svg: {
                      '.head, .body': {
                        path: {
                          fill: 'text.primary',
                          strokeWidth: 0,
                        },
                      },
                      '.head': {
                        path: {
                          stroke: bg,
                          strokeWidth: 2.5,
                        },
                      },
                      '.dots': {
                        path: {
                          fill: 'text.success',
                          strokeWidth: 0,
                        },
                      },
                      '.circle': {
                        path: {
                          fill: bg,
                        },
                      },
                      '.bubble': {
                        path: {
                          stroke: 'text.success',
                          fill: 'background.success',
                        },
                      },
                      path: {
                        stroke: 'text.primary',
                      },
                    },
                  }}
                >
                  <MdIcon
                    name="GroupWork"
                    position="absolute"
                    boxSize={{ base: 12, md: isLowHeight ? 12 : 16 }}
                    color="text.primary"
                  />
                  {lottieClass}
                </Flex>
                <Text fontSize={mainFontSize} mb={isLowHeight ? 6 : 12} mx={4}>
                  You can share anything you make for <b>free</b>, or provide
                  premium experiences with <b>classes</b>.
                </Text>
                <Stack
                  direction="row"
                  spacing={{ base: 2, md: 4 }}
                  mb={isLowHeight ? 4 : 8}
                  flexFlow="wrap"
                  justifyContent="center"
                >
                  {[
                    { icon: 'QuestionAnswer', label: 'Chat with the Group' },
                    { icon: 'DynamicFeed', label: 'Post to a Private Feed' },
                    {
                      icon: 'SupervisedUserCircle',
                      label: 'Give Personal Feedback',
                    },
                    { icon: 'DateRange', label: 'Unlock Units Weekly' },
                    {
                      icon: 'AssignmentTurnedIn',
                      label: 'Award Certificates',
                    },
                  ].map(({ icon, label }) => (
                    <Flex
                      key={`feature-${label}`}
                      flex={1}
                      flexDirection="column"
                      alignItems="center"
                      w={isLowHeight ? '90px' : '100px'}
                      minW={isLowHeight ? '90px' : '100px'}
                      mb={4}
                    >
                      <MdIcon
                        name={icon}
                        color="icon.primary"
                        boxSize={isLowHeight ? 8 : 12}
                        mb={4}
                      />
                      <Text
                        color="text.primary"
                        fontSize={isLowHeight ? 'xs' : 'sm'}
                        fontWeight="semibold"
                        whiteSpace="break-spaces"
                      >
                        {label}
                      </Text>
                    </Flex>
                  ))}
                </Stack>
                <Button {...bigButtonProps} onClick={() => scrollToPage(8)}>
                  Continue
                </Button>
              </Flex>
            </ScreenWrapper>
          </Flex>
        </Box>

        <Box position="relative">
          <Flex bg={bg} color="text.default" w="100%">
            <ScreenWrapper mb={0}>
              <Flex
                h={pageHeight}
                minH={pageHeight}
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                maxWidth={750}
                mx="auto"
              >
                <Text
                  fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
                  mb={6}
                  mx={4}
                  fontWeight="bold"
                >
                  Get Started
                </Text>
                <Text fontSize={mainFontSize} mb={isLowHeight ? 6 : 12} mx={4}>
                  Build and share your own sessions and courses for free, and
                  start selling classes whenever you're ready.
                </Text>
                <LinkButton
                  {...{ ...bigButtonProps, icon: 'ArrowForward' }}
                  to={navRoutes.global.planner.path()}
                >
                  Start Now
                </LinkButton>
                <Button
                  icon="HelpOutline"
                  mt={4}
                  variant="ghost"
                  onClick={() => setShowPricing(true)}
                  size="lg"
                >
                  Pricing Details
                </Button>
              </Flex>
            </ScreenWrapper>
          </Flex>
          <Box
            position="absolute"
            bottom={2}
            left={2}
            display={{ base: 'none', md: 'block' }}
          >
            <Text fontSize="sm" color="text.muted">
              {`©${currentYear} Workshop Tech Solutions Ltd.`}
            </Text>
          </Box>
          <Stack
            direction="row"
            position="absolute"
            bottom={0}
            right={0}
            display={{ base: 'none', md: 'flex' }}
          >
            <Link to={navRoutes.global.terms.path()}>
              <Flex
                alignItems="center"
                paddingX={2}
                paddingY={1}
                marginY={1}
                cursor="pointer"
                _hover={{ bg: 'background.tint3' }}
                borderRadius="full"
                mr={2}
              >
                <Text fontSize="sm" color="text.muted">
                  Terms
                </Text>
              </Flex>
            </Link>
            <Link to={navRoutes.global.privacy.path()}>
              <Flex
                alignItems="center"
                paddingX={2}
                paddingY={1}
                marginY={1}
                cursor="pointer"
                _hover={{ bg: 'background.tint3' }}
                borderRadius="full"
                mr={2}
              >
                <Text fontSize="sm" color="text.muted">
                  Privacy
                </Text>
              </Flex>
            </Link>
            <Link to={navRoutes.global.cookies.path()}>
              <Flex
                alignItems="center"
                paddingX={2}
                paddingY={1}
                marginY={1}
                cursor="pointer"
                _hover={{ bg: 'background.tint3' }}
                borderRadius="full"
                mr={2}
              >
                <Text fontSize="sm" color="text.muted">
                  Cookies
                </Text>
              </Flex>
            </Link>
          </Stack>
        </Box>
        {showPricing && (
          <PaymentsWelcomeModal
            isOpen
            onClose={() => setShowPricing(false)}
            context="pricing"
            isConnected={false}
          />
        )}
      </Flex>
      <SessionModal
        isOpen={!!sessionPopupSlug}
        onClose={() => {
          setSessionPopupSlug('');
          history.replace(location.pathname);
        }}
        courseSlug={sessionPopupSlug}
        title={
          POPUP_SESSIONS.find((s) => s.courseSlug === sessionPopupSlug)
            ?.label || ''
        }
        moduleId={
          POPUP_SESSIONS.find((s) => s.courseSlug === sessionPopupSlug)
            ?.moduleId || undefined
        }
        link={
          POPUP_SESSIONS.find((s) => s.courseSlug === sessionPopupSlug)?.link ||
          undefined
        }
      />
    </>
  );
};

export default HowItWorksScreen;
