import React from 'react';
import { RxCookie } from 'react-icons/rx';
import { Flex, Text, Button, chakra } from '@workshop/ui';

import navRoutes from 'navigation/Routes';

import { PLATFORM_DISPLAY_NAME } from 'constants/common';

import { analytics, hooks } from 'utils';

import { EditModal } from 'components/Common/EditModal';

interface CookieModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CookieModal: React.FC<CookieModalProps> = ({ isOpen, onClose }) => {
  const cookieChoice = localStorage.getItem('cookies');
  const currentRoute = hooks.useCurrentRoute();
  if (cookieChoice || currentRoute?.blockCookiePopup) return null;

  return (
    <EditModal
      title=""
      isOpen={isOpen}
      onSave={() => null}
      onClose={onClose}
      modalSize="lg"
      showFooter={false}
      closeOnOverlayClick={false}
      showCloseButton={false}
    >
      <Flex flexDirection="column" alignItems="center" textAlign="center">
        <Flex color="text.muted" mb={4}>
          <RxCookie size={120} />
        </Flex>
        <Text fontWeight="semibold" mb={4} fontSize="xl">
          Set your cookie preferences
        </Text>
        <Text color="text.muted">
          {`${PLATFORM_DISPLAY_NAME} uses cookies to offer you a better experience.`}
        </Text>
        <Text color="text.muted" mb={8}>
          {`See our `}
          <chakra.a
            href={navRoutes.global.cookies.path()}
            target="_blank"
            rel="noreferrer noopener"
            fontWeight="semibold"
            _focusVisible={{ outline: 'none' }}
          >
            Cookie Policy
          </chakra.a>
          {' for details.'}
        </Text>
        <Button
          width="100%"
          maxWidth={250}
          onClick={() => {
            localStorage.setItem('cookies', 'accept');
            analytics.setOptOut(false);
            onClose();
            window.location.reload();
          }}
          mb={3}
        >
          Accept all
        </Button>
        <Button
          variant="ghost"
          color="text.muted"
          onClick={() => {
            localStorage.setItem('cookies', 'reject');
            analytics.setOptOut(true);
            onClose();
            window.location.reload();
          }}
          mb={5}
        >
          Reject cookies
        </Button>
      </Flex>
    </EditModal>
  );
};

export default CookieModal;
