import { combineReducers } from 'redux';

import { Auth } from 'redux/actionTypes/common';
import { GlobalState } from 'types';

// admin reducers
import { organisationsReducer } from './admin/organisation';

// common reducers
import { authReducer } from './common/auth';
import { backgroundReducer } from './common/background';
import { categoryReducer } from './common/category';
import {
  discourseCategoryReducer,
  discourseMembersReducer,
  discourseTopicsReducer,
  discourseUserSummaryReducer,
  discourseNotificationsReducer,
  discourseNotificationsCountReducer,
  discourseMessagesReducer,
} from './common/discourse';
import { userProfileReducer, userUIReducer } from './common/profile';
import {
  currentTeamReducer,
  currentViewReducer,
  invitationsReducer,
  myTeamsReducer,
  organisationProfileReducer,
  orgSubscriptionsReducer,
  organisationUIReducer,
  permissionReducer,
  roleReducer,
  teamMemberReducer,
} from './common/organisation';
import { roomReducer, roomDeviceReducer } from './common/room';
import { assistantReducer } from './common/assistant';
import { toastReducer, show404Reducer, messagingReducer } from './common/ui';

// cms reducers
import { analyticsReducer } from './cms/analytics';
import { assessmentReducer } from './cms/assessment';
import {
  courseListReducer,
  courseReducer,
  courseUIReducer,
} from './cms/course';
import { cohortReducer, cohortUIReducer } from './cms/cohort';
import { courseProgressReducer as cmsCourseProgressReducer } from './cms/courseProgress';
import { journalEntriesReducer } from './cms/journal';
import {
  unitListReducer,
  unitReducer,
  unitSummaryReducer,
  unitUIReducer,
} from './cms/unit';
import {
  sessionListReducer,
  sessionReducer,
  sessionSummaryReducer,
  sessionUIReducer,
} from './cms/session';
import {
  stepQuestionReducer,
  stepListReducer,
  stepReducer,
  stepUIReducer,
} from './cms/step';
import {
  videoClipListReducer,
  videoClipReducer,
  videoClipUIReducer,
} from './cms/videoClip';
import {
  licenseListReducer,
  licenseUIReducer,
  enrolmentListReducer,
  enrolmentUIReducer,
  costRecordListReducer,
  costRecordUIReducer,
} from './cms/enrolment';

// learner reducers
import { goalsReducer } from './learner/goal';
import { userChecklistReducer, userLibraryReducer } from './learner/profile';
import {
  workshopsReducer,
  userWorkshopRelationshipsReducer,
} from './learner/workshop';
import {
  coursesReducer,
  courseProgressReducer,
  courseScheduleReducer,
} from './learner/course';
import { journalReducer } from './learner/journal';
import { userCohortReducer } from './learner/cohort';

const appReducer = combineReducers<GlobalState>({
  admin: combineReducers({
    organisations: organisationsReducer,
  }),
  auth: authReducer,
  user: userProfileReducer,
  category: categoryReducer,
  organisation: combineReducers({
    invitations: invitationsReducer,
    roles: roleReducer,
    permissions: permissionReducer,
    myTeams: myTeamsReducer,
    currentTeam: currentTeamReducer,
    currentTeamMemberList: teamMemberReducer,
    teamProfiles: organisationProfileReducer,
    subscriptions: orgSubscriptionsReducer,
  }),
  cms: combineReducers({
    analytics: analyticsReducer,
    assessment: assessmentReducer,
    course: combineReducers({
      courseList: courseListReducer,
      course: courseReducer,
    }),
    courseProgress: cmsCourseProgressReducer,
    cohort: cohortReducer,
    journal: combineReducers({
      journalEntries: journalEntriesReducer,
    }),
    unit: combineReducers({
      unitList: unitListReducer,
      unit: unitReducer,
      unitSummary: unitSummaryReducer,
    }),
    session: combineReducers({
      sessionList: sessionListReducer,
      session: sessionReducer,
      sessionSummary: sessionSummaryReducer,
    }),
    step: combineReducers({
      question: stepQuestionReducer,
      stepList: stepListReducer,
      step: stepReducer,
    }),
    videoClip: combineReducers({
      videoClipList: videoClipListReducer,
      videoClip: videoClipReducer,
    }),
    enrolment: combineReducers({
      costRecord: costRecordListReducer,
      license: licenseListReducer,
      enrolment: enrolmentListReducer,
    }),
  }),
  learner: combineReducers({
    courses: coursesReducer,
    courseProgress: courseProgressReducer,
    courseSchedule: courseScheduleReducer,
    cohort: userCohortReducer,
    goal: goalsReducer,
    journal: journalReducer,
    library: userLibraryReducer,
    userCheckList: userChecklistReducer,
    userWorkshops: userWorkshopRelationshipsReducer,
    workshops: workshopsReducer,
  }),
  discourse: combineReducers({
    categories: discourseCategoryReducer,
    members: discourseMembersReducer,
    topics: discourseTopicsReducer,
    userSummaries: discourseUserSummaryReducer,
    notifications: discourseNotificationsReducer,
    notificationsCount: discourseNotificationsCountReducer,
    messages: discourseMessagesReducer,
  }),
  room: combineReducers({
    rooms: roomReducer,
    roomDevices: roomDeviceReducer,
  }),
  background: backgroundReducer,
  assistant: assistantReducer,
  ui: combineReducers({
    toast: toastReducer,
    user: userUIReducer,
    course: courseUIReducer,
    cohort: cohortUIReducer,
    unit: unitUIReducer,
    session: sessionUIReducer,
    step: stepUIReducer,
    videoClip: videoClipUIReducer,
    organisation: organisationUIReducer,
    currentView: currentViewReducer,
    show404ForPath: show404Reducer,
    messaging: messagingReducer,
    costRecord: costRecordUIReducer,
    license: licenseUIReducer,
    enrolment: enrolmentUIReducer,
  }),
});

const rootReducer: typeof appReducer = (state, action) => {
  // When a user logs out, clear the application state.
  //
  // There are some entities which can be persisted between users
  // so they are included explicitly here.
  if (action.type === Auth.DELETE_TOKEN_SUCCESS) {
    if (state) {
      const {
        organisation: { invitations },
        learner: { courses, workshops },
      } = state;
      return appReducer(
        {
          // @ts-ignore
          organisation: { invitations },
          // @ts-ignore
          learner: { courses, workshops },
        },
        action
      );
    }
  }
  return appReducer(state, action);
};

export default rootReducer;
