import React, { useState } from 'react';
import Form from '@rjsf/chakra-ui';
import validator from '@rjsf/validator-ajv8';
import { ArrayFieldTemplateProps, TitleFieldProps } from '@rjsf/utils';

import { SessionPlanResponse } from 'types/common';

import { capitalize } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { Flex, Text, Box, useTheme, Button, ButtonGroup } from '@workshop/ui';

import TextareaWidget from 'screens/common/Plan/src/TextareaWidget';

const disabledStyles = {
  '[disabled]': {
    opacity: '1!important',
    backgroundColor: 'background.tint3',
    border: 'none',
  },
};

const SESSION_PLAN_TEMPLATE = {
  title: '',
  type: 'object',
  properties: {
    sessionIntro: {
      type: 'string',
      title: 'Introduction',
    },
    steps: {
      type: 'array',
      title: 'Steps',
      items: {
        type: 'object',
        properties: {
          stepTitle: {
            type: 'string',
            title: '',
          },
          bits: {
            type: 'array',
            title: 'Bits',
            items: {
              type: 'object',
              properties: {
                bitSummary: {
                  type: 'string',
                  title: '',
                },
              },
            },
          },
        },
      },
    },
    sessionOutro: {
      type: 'string',
      title: 'Congratulations',
    },
  },
};

interface SessionAIPlanProps {
  planJson?: SessionPlanResponse;
  formData?: SessionPlanResponse;
  setFormData: (formData: SessionPlanResponse) => void;
}

const ArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
  // Restrict number of units & sessions allowed
  const maxNumItems =
    props.title === 'Steps' ? null : props.title === 'Bits' ? 6 : 6;
  const limitReached = maxNumItems && props.items.length >= maxNumItems;
  return (
    <Box pl={{ base: 2, md: 4 }} borderLeftWidth={1} borderColor="blue.100">
      {props.items.map(
        ({
          children,
          disabled,
          hasToolbar,
          hasMoveDown,
          hasMoveUp,
          hasRemove,
          index,
          onDropIndexClick,
          onReorderClick,
          readonly,
          uiSchema,
          registry,
        }) => {
          const { MoveDownButton, MoveUpButton, RemoveButton } =
            registry.templates.ButtonTemplates;
          return (
            // @ts-ignore
            <Flex
              flexDirection="column"
              position="relative"
              key={`arrayfield-item-${index}`}
            >
              <Box w="100%">{children}</Box>
              {hasToolbar && !disabled && (
                <Box alignSelf="flex-end" mb={4} position="absolute">
                  <ButtonGroup isAttached>
                    {(hasMoveUp || hasMoveDown) && (
                      // @ts-ignore
                      <MoveUpButton
                        disabled={disabled || readonly || !hasMoveUp}
                        onClick={onReorderClick(index, index - 1)}
                        uiSchema={uiSchema}
                        registry={registry}
                        // @ts-ignore
                        size="xs"
                        colorScheme="blue"
                        variant="ghost"
                      />
                    )}
                    {(hasMoveUp || hasMoveDown) && (
                      // @ts-ignore
                      <MoveDownButton
                        disabled={disabled || readonly || !hasMoveDown}
                        onClick={onReorderClick(index, index + 1)}
                        uiSchema={uiSchema}
                        registry={registry}
                        // @ts-ignore
                        size="xs"
                        colorScheme="blue"
                        variant="ghost"
                      />
                    )}
                    {hasRemove && (
                      // @ts-ignore
                      <RemoveButton
                        disabled={disabled || readonly}
                        onClick={onDropIndexClick(index)}
                        uiSchema={uiSchema}
                        registry={registry}
                        // @ts-ignore
                        size="xs"
                        colorScheme="gray"
                        variant="ghost"
                        opacity={0.5}
                      />
                    )}
                  </ButtonGroup>
                </Box>
              )}
            </Flex>
          );
        }
      )}
      <Flex justifyContent="flex-end">
        {props.canAdd && !limitReached && !props.disabled && (
          <Button
            variant="outline"
            size="sm"
            onClick={props.onAddClick}
            icon="Add"
          >
            {`Add ${props.title.slice(0, -1)}`}
          </Button>
        )}
      </Flex>
    </Box>
  );
};

const TitleFieldTemplate = (props: TitleFieldProps) => {
  const { id, required, title } = props;
  if (title.slice(0, -3) === 'bit') return null;
  return (
    <Text id={id} mb={2} fontSize="sm">
      {capitalize(`${title.slice(0, -3)} ${parseInt(title.slice(-1)) + 1}`)}
    </Text>
  );
};

const SessionAIPlan: React.FC<SessionAIPlanProps> = ({
  planJson,
  formData,
  setFormData,
}) => {
  const [savingPlan, setSavingPlan] = useState(false);
  const [planChanged, setPlanChanged] = useState(false);

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);
  const isSmallScreen =
    windowDimensions.width < parseInt(theme.breakpoints.lg, 10);

  return (
    <Box
      sx={{
        'input, textarea': {
          bg: 'background.default',
          pl: 3,
        },
        // scrollMarginTop: NAV_HEIGHT + 100,
        // scrollSnapMarginTop: NAV_HEIGHT + 100,
      }}
      position="relative"
    >
      <Form
        // @ts-ignore
        schema={SESSION_PLAN_TEMPLATE}
        formData={formData || planJson}
        validator={validator}
        uiSchema={{
          sessionIntro: {
            'ui:widget': TextareaWidget,
            'ui:options': {
              chakra: {
                mb: -1,
                sx: {
                  label: {
                    fontSize: 'sm',
                    ml: { base: 2, md: 0 },
                    opacity: '1!important',
                  },
                  ...disabledStyles,
                },
              },
            },
          },
          steps: {
            'ui:options': {
              addable: true,
              orderable: false,
              removable: true,
            },
            items: {
              stepTitle: {
                'ui:widget': TextareaWidget,
                'ui:options': {
                  chakra: {
                    mb: -3,
                    sx: {
                      textarea: {
                        fontWeight: 'semibold',
                      },
                      ...disabledStyles,
                    },
                  },
                },
              },
              bits: {
                'ui:options': {
                  addable: true,
                  orderable: false,
                  removable: true,
                },
                items: {
                  bitSummary: {
                    'ui:widget': TextareaWidget,
                    'ui:options': {
                      chakra: {
                        mb: -3,
                        sx: {
                          ...disabledStyles,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          sessionOutro: {
            'ui:widget': TextareaWidget,
            'ui:options': {
              chakra: {
                sx: {
                  label: {
                    fontSize: 'sm',
                    ml: { base: 2, md: 0 },
                    opacity: '1!important',
                  },
                  ...disabledStyles,
                },
              },
            },
          },
        }}
        onSubmit={async (data) => {
          if (planChanged) {
            setSavingPlan(true);
            setFormData(data.formData);
            // await dispatch(
            //   assistantActions.updateCoursePlan(slug, {
            //     planJson: {
            //       ...data.formData,
            //       status: 200,
            //       error: false,
            //     },
            //   })
            // );
            setSavingPlan(false);
            setPlanChanged(false);
          }
        }}
        onChange={(data) => {
          setFormData(data.formData);
          if (!planChanged) setPlanChanged(true);
        }}
        templates={{
          ArrayFieldTemplate,
          TitleFieldTemplate,
        }}
      >
        <Button
          type="submit"
          size={isMobile ? 'sm' : 'md'}
          float="right"
          mb={1}
          isLoading={Boolean(savingPlan)}
          variant="outline"
          disabled={!planChanged}
          display="none"
          // {...(!!builtCourse ? { display: 'none' } : {})}
        >
          Save Plan
        </Button>
      </Form>
    </Box>
  );
};

export default SessionAIPlan;
