const UserLibraryAT = {
  FETCH_USER_LIBRARY_REQUEST: 'FETCH_USER_LIBRARY_REQUEST',
  FETCH_USER_LIBRARY_SUCCESS: 'FETCH_USER_LIBRARY_SUCCESS',
  FETCH_USER_LIBRARY_FAILURE: 'FETCH_USER_LIBRARY_FAILURE',
} as const;

const UserProfileAT = {
  FETCH_USER_PROFILE_REQUEST: 'FETCH_USER_PROFILE_REQUEST',
  FETCH_USER_PROFILE_SUCCESS: 'FETCH_USER_PROFILE_SUCCESS',
  FETCH_USER_PROFILE_FAILURE: 'FETCH_USER_PROFILE_FAILURE',

  UPDATE_USER_PROFILE_REQUEST: 'UPDATE_USER_PROFILE_REQUEST',
  UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',
  UPDATE_USER_PROFILE_FAILURE: 'UPDATE_USER_PROFILE_FAILURE',

  FETCH_USER_COUPONS_REQUEST: 'FETCH_USER_COUPONS_REQUEST',
  FETCH_USER_COUPONS_SUCCESS: 'FETCH_USER_COUPONS_SUCCESS',
  FETCH_USER_COUPONS_FAILURE: 'FETCH_USER_COUPONS_FAILURE',

  REDEEM_COUPON_REQUEST: 'REDEEM_COUPON_REQUEST',
  REDEEM_COUPON_SUCCESS: 'REDEEM_COUPON_SUCCESS',
  REDEEM_COUPON_FAILURE: 'REDEEM_COUPON_FAILURE',

  FETCH_USER_SUBSCRIPTIONS_REQUEST: 'FETCH_USER_SUBSCRIPTIONS_REQUEST',
  FETCH_USER_SUBSCRIPTIONS_SUCCESS: 'FETCH_USER_SUBSCRIPTIONS_SUCCESS',
  FETCH_USER_SUBSCRIPTIONS_FAILURE: 'FETCH_USER_SUBSCRIPTIONS_FAILURE',

  FETCH_PAYMENT_SETTINGS_REQUEST: 'FETCH_PAYMENT_SETTINGS_REQUEST',
  FETCH_PAYMENT_SETTINGS_SUCCESS: 'FETCH_PAYMENT_SETTINGS_SUCCESS',
  FETCH_PAYMENT_SETTINGS_FAILURE: 'FETCH_PAYMENT_SETTINGS_FAILURE',

  UPDATE_PAYMENT_SETTINGS_REQUEST: 'UPDATE_PAYMENT_SETTINGS_REQUEST',
  UPDATE_PAYMENT_SETTINGS_SUCCESS: 'UPDATE_PAYMENT_SETTINGS_SUCCESS',
  UPDATE_PAYMENT_SETTINGS_FAILURE: 'UPDATE_PAYMENT_SETTINGS_FAILURE',

  DELETE_ACCOUNT_FAILURE: 'DELETE_ACCOUNT_FAILURE',
  DELETE_ACCOUNT_REQUEST: 'DELETE_ACCOUNT_REQUEST',
  DELETE_ACCOUNT_SUCCESS: 'DELETE_ACCOUNT_SUCCESS',

  RESEND_VERIFICATION_EMAIL_FAILURE: 'RESEND_VERIFICATION_EMAIL_FAILURE',
  RESEND_VERIFICATION_EMAIL_REQUEST: 'RESEND_VERIFICATION_EMAIL_REQUEST',
  RESEND_VERIFICATION_EMAIL_SUCCESS: 'RESEND_VERIFICATION_EMAIL_SUCCESS',

  CANCEL_EMAIL_CHANGE_FAILURE: 'CANCEL_EMAIL_CHANGE_FAILURE',
  CANCEL_EMAIL_CHANGE_REQUEST: 'CANCEL_EMAIL_CHANGE_REQUEST',
  CANCEL_EMAIL_CHANGE_SUCCESS: 'CANCEL_EMAIL_CHANGE_SUCCESS',
} as const;

export default {
  ...UserLibraryAT,
  ...UserProfileAT,
};
