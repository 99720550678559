import React, { useReducer, useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Flex, Input, FormLabel } from '@workshop/ui';

import { hooks } from 'utils';

type ChangeEvent =
  | React.ChangeEvent<HTMLInputElement>
  | React.ClipboardEvent<HTMLInputElement>;

export type HandleChangeEvent = (e: ChangeEvent) => void;

type Children<IDefaultValue> = (props: {
  handleChangeEvent: HandleChangeEvent;
  inputId: number;
  inputIndex: number;
  itemIsFinal?: boolean;
  value?: IDefaultValue;
  defaultValue?: IDefaultValue;
  hasInputError?: (label: string) => boolean;
  handleRemove?: () => void;
  handleOnBlurEvent?: (e: React.FocusEvent<HTMLInputElement>) => void;
}) => JSX.Element;

type IDynamicInput<IDefaultValue> = {
  inputId: number;
  inputIndex: number;
  finalIndex: number;
  value?: IDefaultValue;
  defaultValue?: IDefaultValue;
  children?: Children<IDefaultValue>;
  // TODO: Pull these out into shared type with DynamicList
  hasFieldError?: (id: number, label: string, itemIsFinal?: boolean) => boolean;
  onFieldChange?: (
    id: number,
    label: string,
    value: string,
    itemIsFinal?: boolean
  ) => void;
  validateField?: (
    id: number,
    label: string,
    value: string,
    itemIsFinal?: boolean
  ) => void;
  dispatch: React.Dispatch<IAction>;
};

type IDynamicList = {
  formData: IDynamicTable;
  isValid?: boolean;
  shouldReset?: boolean;
  isDirty?: boolean;
  setFormData: React.Dispatch<React.SetStateAction<IDynamicTable>>;
  setReset: React.Dispatch<React.SetStateAction<boolean>>;
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
  hasFieldError: (id: number, label: string, itemIsFinal?: boolean) => boolean;
  onFieldChange: (
    id: number,
    label: string,
    value: string,
    itemIsFinal?: boolean
  ) => void;
  validateField: (
    id: number,
    label: string,
    value: string,
    itemIsFinal?: boolean
  ) => void;
};

type Props<IDefaultValue> = Partial<IDynamicList> & {
  label?: string;
  defaultValues?: IDefaultValue[];
  children?: Children<IDefaultValue>;
  getDynamicListData?: (data: IDynamicTable) => void;
};

export interface IDynamicTable {
  // TODO: This should be IDefaultValue - but I can't get that to work
  // everywhere right now
  [key: string]: {
    [key: string]: string | undefined;
  };
}

enum ActionType {
  AddRowToEnd = 'ADD_ROW_TO_END',
  RemoveRowFromEnd = 'REMOVE_ROW_FROM_END',
  RemoveRow = 'REMOVE_ROW',
  UpdateCell = 'UPDATE_CELL',
  ResetTable = 'RESET_TABLE',
  InitTable = 'INIT_TABLE',
}

type IAction =
  | {
      type: typeof ActionType.AddRowToEnd;
    }
  | {
      type: typeof ActionType.RemoveRowFromEnd;
      payload?: {
        rowId: number;
      };
    }
  | {
      type: typeof ActionType.ResetTable;
    }
  | {
      type: typeof ActionType.RemoveRow;
      payload: {
        rowId: number;
      };
    }
  | {
      type: typeof ActionType.UpdateCell;
      payload: {
        rowId: number;
        colId?: string;
        value: string;
      };
    }
  | {
      type: typeof ActionType.InitTable;
      payload: { [key: string]: string | undefined }[];
    };

// TODO: This will break if multiple DynamicList's are on the same page
let previousColId = '';

const DynamicListReducer: React.Reducer<IDynamicTable, IAction> = (
  state,
  action
): IDynamicTable => {
  // Get the ID of the last row in the table
  const [lastInputId] = Object.keys(state).slice(-1);

  switch (action.type) {
    case ActionType.ResetTable: {
      return { 0: {} };
    }
    // Add an empty row at the end of the table(list)
    case ActionType.AddRowToEnd: {
      // If the last row in table is empty, don't add
      // a new empty row
      if (!isEmpty(state[lastInputId])) {
        return {
          ...state,
          [parseInt(lastInputId) + 1]: {},
        };
      }
      return state;
    }
    // Remove last row from the table(list)
    case ActionType.RemoveRowFromEnd: {
      // If the last row of the table is empty, then
      // it can be removed
      if (isEmpty(state[lastInputId])) {
        // Use ES7 destructuring to remove the unwanted
        // value from the state, returning the rest.
        const { [lastInputId]: omit, ...rest } = state;
        if (action.payload) {
          const { rowId } = action.payload;
          rest[rowId] = {};
        }
        return rest;
      }
      return state;
    }
    // Update a cell of the data table state
    case ActionType.UpdateCell: {
      const { rowId, colId, value } = action.payload;

      const newState = { ...state };

      // If the row does not exist yet, create it
      if (!newState[rowId]) {
        newState[rowId] = {};
      }

      // Add the value to the row & column combination i.e.
      // to the specified cell
      if (colId) {
        newState[rowId][colId] = value;
        previousColId = colId;
      }

      // When the last char of an input is deleted, colId = undefined
      // we use the previous colId to update
      if (!colId && previousColId) {
        newState[rowId][previousColId] = value;
      }

      return newState;
    }
    // Remove a row of the data table state
    case ActionType.RemoveRow: {
      const { rowId } = action.payload;
      // Use ES7 destructuring to remove the unwanted
      // value from the state, returning the rest.
      const { [rowId]: omit, ...rest } = state;
      return rest;
    }
    // Init state with default Values
    case ActionType.InitTable:
      return action.payload.reduce<IDynamicTable>((acc, value, rowId) => {
        return {
          ...acc,
          [rowId]: { ...value },
        };
      }, {});
    default:
      return state;
  }
};

const isInputEvent = (
  toBeDetermined: ChangeEvent
): toBeDetermined is React.ChangeEvent<HTMLInputElement> => {
  return (
    (toBeDetermined as React.ChangeEvent<HTMLInputElement>).type !== 'paste'
  );
};

function DynamicInput<IDefaultValue extends {}>(
  props: IDynamicInput<IDefaultValue>
) {
  const {
    inputId,
    inputIndex,
    finalIndex,
    children,
    value,
    defaultValue,
    dispatch,
    ...dynamicInputProps
  } = props;

  const { hasFieldError, onFieldChange } = dynamicInputProps;

  const itemIsFinal = inputIndex === finalIndex - 1;

  const handleChange = (value: string, name?: string) => {
    dispatch({
      type: ActionType.UpdateCell,
      payload: { rowId: inputId, colId: name, value },
    });

    // If the modified input is the last one, Add an new input
    if (value.length >= 1 && itemIsFinal) {
      dispatch({ type: ActionType.AddRowToEnd });
    }
    // If the modified input is the last one and empty, remove last input
    if (value.length === 0 && inputIndex === finalIndex - 2) {
      dispatch({
        type: ActionType.RemoveRowFromEnd,
        payload: { rowId: inputIndex },
      });
    }
    // notified the dynamic list was changed & check for errors
    if (name && onFieldChange) {
      onFieldChange(inputId, name, value, itemIsFinal);
    }
  };

  const handlePasteMultipleValues = (values: string[], name: string) => {
    values
      .filter((v) => v)
      .forEach((value, idx) => {
        if (value.trim()) {
          dispatch({
            type: ActionType.UpdateCell,
            payload: {
              rowId: inputId + idx,
              colId: name,
              value: value,
            },
          });
          onFieldChange && onFieldChange(inputId, name, value, itemIsFinal);
        }
      });
    dispatch({ type: ActionType.AddRowToEnd });
  };

  const handleChangeEvent = (e: ChangeEvent) => {
    if (isInputEvent(e)) {
      handleChange(e.target.value, e.target.name);
    } else {
      const val = e?.clipboardData?.getData('text');
      const { name } = e.currentTarget;

      const multiValue = val ? val.split('\n') : val;
      if (Array.isArray(multiValue) && multiValue.length > 1) {
        // multi line paste
        handlePasteMultipleValues(multiValue, name);
        e.preventDefault();
      } else {
        // single line paste
        handleChange(val || '', name);
        // Prevent further events from triggering as a result
        // of the paste.
        e.preventDefault();
      }
    }
  };

  const handleRemove = () => {
    if (!itemIsFinal) {
      dispatch({
        type: ActionType.RemoveRow,
        payload: { rowId: inputId },
      });
    }
  };

  const handleOnBlurEvent = () => {};

  const hasInputError = (label: string) => {
    if (hasFieldError) return hasFieldError(inputId, label, itemIsFinal);
    return false;
  };

  return children ? (
    children({
      handleChangeEvent,
      inputId,
      inputIndex,
      itemIsFinal,
      value,
      defaultValue,
      hasInputError,
      handleRemove,
      handleOnBlurEvent,
    })
  ) : (
    <Input name="name" onChange={handleChangeEvent} />
  );
}

/*
  This component can still be used as a Uncontrolled component with React-hook-form
  However, the multi copy-paste feature will not work properly
 */
function DynamicList<IDefaultValue extends { [key: string]: any }>(
  props: Props<IDefaultValue>
) {
  const { label, children, defaultValues = [], ...dynamicProps } = props;
  const { shouldReset, setFormData, ...dynamicInputProps } = dynamicProps;

  // Initialise the dynamic list table state. By default this comprises of a single
  // empty row
  const [state, dispatch] = useReducer(DynamicListReducer, { 0: {} });

  // Initiate the table with default value or reset it to original state
  hooks.useDeepEqualEffect(() => {
    // When resetting the form, determine whether or not there are default
    // values available. If so, reset to those default values, otherwise it
    // means we should reset the entire table
    //
    // If the default values themselves have changed, then re-initialise the
    // table with those.
    if (shouldReset) {
      if (defaultValues && defaultValues.length > 0) {
        dispatch({
          type: ActionType.InitTable,
          payload: defaultValues,
        });
        dispatch({ type: ActionType.AddRowToEnd });
      } else {
        dispatch({
          type: ActionType.ResetTable,
        });
      }
    } else if (defaultValues && defaultValues.length > 0) {
      dispatch({
        type: ActionType.InitTable,
        payload: defaultValues,
      });
      dispatch({ type: ActionType.AddRowToEnd });
    }
  }, [defaultValues, shouldReset]);

  // Provide a way to access the state outside the dynamic list
  hooks.useDeepEqualEffect(() => {
    if (setFormData) {
      setFormData(state);
    }
  }, [state]);

  return (
    <Flex mb={2}>
      {label && (
        <Flex justifyContent="flex-end">
          <FormLabel color="text.muted" fontSize="md" textAlign="right">
            {label}:
          </FormLabel>
        </Flex>
      )}
      <Flex flexDir="column" justifyContent="flex-start" flex={1}>
        {Object.keys(state).map((inputId, inputIndex) => {
          return (
            <DynamicInput
              key={inputId}
              inputId={parseInt(inputId)}
              inputIndex={inputIndex}
              finalIndex={Object.keys(state).length}
              // Keeping both value and defaultValue for legacy dependency
              value={state[parseInt(inputId)] as IDefaultValue}
              dispatch={dispatch}
              // defaultValue={
              //   defaultValues && defaultValues[inputIndex]
              //     ? defaultValues[inputIndex]
              //     : undefined
              // }
              {...dynamicInputProps}
            >
              {children}
            </DynamicInput>
          );
        })}
      </Flex>
    </Flex>
  );
}

export default DynamicList;

function useDynamicList(rules: {
  [key: string]: (value: string) => boolean;
}): IDynamicList {
  // Theses hooks replicate the react-hooks-form behaviour
  // Get the Dynamic list data table
  const [formData, setFormData] = useState({});
  // Set to true if the user has modified an input
  const [isDirty, setDirty] = useState(false);
  // Track the errors in the input list
  const [error, setError] = useState<{ [key: string]: boolean }>({});
  // Reset the form to its original/ default values
  const [shouldReset, setReset] = useState(false);
  // DynamicList state is valid
  const [isValid, setDynamicListValid] = useState(false);

  // Check the validity of the form
  useEffect(() => {
    setDynamicListValid(
      Boolean(isDirty && !Object.values(error).find((e) => e))
    );
  }, [isDirty, shouldReset, error]);

  // Reset the form
  useEffect(() => {
    if (shouldReset) {
      setDirty(false);
      setError({});
    }
  }, [shouldReset]);

  // To validate a field
  const validateField = (
    id: number,
    label: string,
    value: string,
    itemIsFinal?: boolean
  ) => {
    // TODO improve this feature when copy paste multiple + delete
    if (!itemIsFinal && rules[label] && !rules[label](value)) {
      setError({ ...error, [`${id}_${label}`]: true });
    } else {
      setError({
        ...error,
        [`${id}_${label}`]: false,
      });
    }
  };

  const hasFieldError = (id: number, label: string, itemIsFinal?: boolean) => {
    return Boolean(!itemIsFinal && error[`${id}_${label}`]);
  };

  const onFieldChange = (
    id: number,
    label: string,
    value: string,
    itemIsFinal?: boolean
  ) => {
    setDirty(true);
    setReset(false);
    validateField(id, label, value, itemIsFinal);
  };
  return {
    formData,
    setFormData,
    shouldReset,
    setReset,
    isDirty,
    setDirty,
    isValid,
    hasFieldError,
    onFieldChange,
    validateField,
  };
}

export { useDynamicList };
