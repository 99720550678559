import React, { useState, useCallback, useEffect } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';

import { Box, Flex, Spinner, Text, Grid, GridItem } from '@workshop/ui';

import { hooks } from 'utils/index';
import { getCohortSubcategoryId } from 'utils/learner';

import { discourseActions } from 'redux/actions/common';
import { dashboardActions } from 'redux/actions/learner/dashboard';

import { DashboardModel } from 'models/learner';
import { ISectionItem, SECTION_COMPONENTS } from 'models/learner/Sections';

import { GlobalState } from 'types';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends PropsFromRedux {}

const DashboardSectionItem: React.FC<{
  item: ISectionItem;
  colSpan: number;
}> = ({ item, colSpan }) => {
  const section = SECTION_COMPONENTS[item.componentType];

  const Component = section.component;

  // Make a copy of the item as we'll need to modify the props
  // based on the colSpan - see below
  // (this is to avoid modifying the objects passed as parameters)
  const itemCopy = { ...item };

  if (itemCopy.componentType === 'itemsListCard' && colSpan === 1) {
    itemCopy.props.moduleItems.forEach(
      (p, idx) => (itemCopy.props.moduleItems[idx].showImage = false)
    );
  }

  if (itemCopy.componentType === 'featureCard' && colSpan === 1) {
    itemCopy.props.imageOnly = true;
  }

  return (
    <GridItem
      key={`gi-${itemCopy.id}`}
      colSpan={{ base: 1, md: colSpan }}
      display="flex"
      flexDir="column"
      position="relative"
    >
      <Box mt="4">
        {itemCopy.heading && (
          <Text
            mb="defaultMargin"
            fontWeight="bold"
            marginX={{ base: 'defaultMargin', md: 0 }}
          >
            {itemCopy.heading}
          </Text>
        )}
      </Box>
      {/* @ts-ignore */}
      <Component {...itemCopy.props} colSpan={colSpan} flex={1} />
    </GridItem>
  );
};

const Dashboard: React.FC<Props> = ({ learner, discourse, user }) => {
  const {
    courses: {
      courses: { summary },
    },
    cohort,
  } = learner;

  const dispatch = useDispatch();

  const [discourseDataLoaded, setDiscourseDataLoaded] = useState(false);

  // Load from the WS dashboard API and global user Discourse APIs on load
  const { dashboard: dashboardLoading } = hooks.useLoadingDataState(
    {
      dashboard: {
        actions: [() => dashboardActions.retrieve()],
      },
      discourse: {
        actions: [
          () => discourseActions.getPrivateMessages(),
          () => discourseActions.getNotifications(),
        ],
      },
    },
    []
  );

  // TODO: Eventually offload this to a separate API
  //
  // Once we've finished loading initial data, we can load any remaining
  // data from Discourse - specifically category & group data.
  const loadDiscourseData = useCallback(async () => {
    if (dashboardLoading) return;

    // Build an array of API calls to make
    const promises = Object.values(cohort).map((cohort) => {
      const { discourseCategoryId, discourseGroupName } = cohort;

      // Extract the discourse category ID for this cohort/course
      const subCategoryId = getCohortSubcategoryId(
        cohort,
        summary[cohort.course]
      );

      // Load the category and discourse group members for this cohort
      if (subCategoryId && discourseCategoryId) {
        return Promise.all([
          dispatch(
            discourseActions.getSubcategory(
              discourseCategoryId,
              subCategoryId,
              true
            )
          ),
          dispatch(discourseActions.getGroupMembers(discourseGroupName)),
        ]);
      }
      return Promise.resolve(null);
    });

    await Promise.all(promises);

    setDiscourseDataLoaded(true);
  }, [
    Object.keys(cohort).length,
    Object.keys(summary).length,
    dashboardLoading,
  ]);

  // Once we have cohort and course summary data available from out inital
  // `useLoadingDataState`, load cohort/course specific data.

  useEffect(() => {
    loadDiscourseData();
  }, [loadDiscourseData]);

  if (dashboardLoading || !discourseDataLoaded) {
    return (
      <ScreenWrapper>
        <Flex flex="1 0" justifyContent="center" marginTop="defaultScreenY">
          <Spinner size="lg" color="common.primary" />
        </Flex>
      </ScreenWrapper>
    );
  }

  // Initialise our dashboard model with data
  const dashboardModel = new DashboardModel(learner, discourse, user);

  // Extract the sections for rendering from the model
  const sectionItems = dashboardModel.getSectionItems();

  /**
   * We break down the sectionItems into rows to be rendered as grid items.
   *
   * The logic below assigns each item to a row, and gives each item a colSpan,
   * as follows:
   * - each row can have no more than one or two items
   * - we only display 2 items in the same row if they are of the same comoponentType
   * - if an item is on its own, it'll span across 3 columns
   * - otherwise, the item will get a colSpan of 1 or 2, depending on
   * the parity of the row index, and the position of the item inside that row
   *
   * i.e, even rows   -> first item span 2, second item span 1
   * and for odd rows -> first item span 1, second item span 2
   *
   * itemRows is an array of rows - each row is an array of objects
   * referencing an item and giving it a colSpan
   * */
  const itemRows: { item: ISectionItem; colSpan: number }[][] = [];

  sectionItems.forEach((item, idx) => {
    const itemRowsLength = itemRows.length;

    // currentRowIdx determines which row this item belongs to
    let currentRowIdx = 0;
    let lastRowFull = false;

    if (itemRowsLength > 0) {
      // The last row is considered full if it has more than 1 item,
      // or if the first item spans 3 columns
      const lastRow = itemRows[itemRowsLength - 1];
      lastRowFull = lastRow.length > 1 || lastRow[0].colSpan === 3;

      // If the last row is full the item will be added to a new row,
      // otherwise it may be added to the last row
      currentRowIdx = lastRowFull ? itemRowsLength : itemRowsLength - 1;
    }

    // Determine the colSpan based on the logic described above ☝️
    const evenRow = currentRowIdx % 2 === 0;
    const singleColumn = evenRow ? idx % 2 === 0 : idx % 2 !== 0;
    let colSpan = singleColumn ? 1 : 2;

    // We create a new row if the last row is full
    // or if the row at the current index doesn't exist yet
    const addItemToNewRow = lastRowFull || !itemRows[currentRowIdx];

    if (addItemToNewRow) {
      const nextItem =
        idx + 1 < sectionItems.length ? sectionItems[idx + 1] : undefined;

      // If this is the very last item or if the next item is of a different type
      // --> give the item a 3 column span
      if (
        !nextItem ||
        nextItem.componentType !== item.componentType ||
        nextItem.componentType === 'itemsListCard'
      ) {
        colSpan = 3;
      }

      itemRows.push([{ item, colSpan }]);
    } else {
      itemRows[currentRowIdx].push({ item, colSpan });
    }
  });

  return (
    <ScreenWrapper>
      {itemRows.length > 0 ? (
        <Flex flex={1} flexDirection="column" alignItems="center" w="100%">
          <Grid
            width="100%"
            autoRows="auto"
            templateColumns={{ base: '100%', md: '33% 34% 33%' }}
            gap="defaultMargin"
            marginBottom="defaultMargin"
          >
            {itemRows
              .flatMap((i) => i)
              .flatMap((rowItem, idx) => (
                <DashboardSectionItem
                  key={idx}
                  item={rowItem.item}
                  colSpan={rowItem.colSpan}
                />
              ))}
          </Grid>
        </Flex>
      ) : (
        <Text color="text.muted">You're all up to date!</Text>
      )}
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    learner: state.learner,
    discourse: state.discourse,
    user: state.user,
  };
};

const connector = connect(mapStateToProps);

export default connector(Dashboard);
