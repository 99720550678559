export const PROGRESS_STATUS = {
  upcoming: 'upcoming',
  inProgress: 'in_progress',
  complete: 'complete',
} as const;

export type ProgressStatus = ValueOf<typeof PROGRESS_STATUS>;

// TODO: Deprecate usage of this across both learner & cms sides (see IClassType)
export const CLASS_TYPE = {
  cohort: 'cohort',
  intake: 'intake',
  open: 'open',
} as const;

export type ClassType = ValueOf<typeof CLASS_TYPE>;

export const ACCESS_TYPES = {
  open: 'open',
  scheduled: 'scheduled',
  restricted: 'restricted',
} as const;

export type AccessType = ValueOf<typeof ACCESS_TYPES>;

export const SOCIAL_TYPES = {
  private: 'private',
  community: 'community',
} as const;

export type SocialType = ValueOf<typeof SOCIAL_TYPES>;

// Maximum number of students allowed on cohorts with socialType = "private"
export const PRIVATE_CLASS_SIZE_LIMIT = 40;

export type CohortType = {
  id: number;
  slug: string;
  title: string;
  icon: string;
  tagName: string;
  tagColor: 'info' | 'success' | 'warning' | 'error';
  description: string;
  socialType: SocialType;
  accessType: AccessType;
  isAnonymous: boolean;
  features: {
    icon: string;
    label: string;
    color: 'info' | 'success' | 'warning' | 'error';
  }[];
};

export const COHORT_TYPES: CohortType[] = [
  {
    id: 1,
    slug: 'personal',
    title: 'Personal',
    icon: 'GroupWork',
    tagName: 'For the Best Learning Experience',
    tagColor: 'success',
    description:
      'Content unlocks weekly, and students can post their work to receive feedback and message their class. Perfect for small, highly engaged classes.',
    socialType: SOCIAL_TYPES.private,
    accessType: ACCESS_TYPES.scheduled,
    isAnonymous: false,
    features: [
      { icon: 'Today', label: 'Weekly Access', color: 'info' },
      {
        icon: 'DynamicFeed',
        label: 'Private Feed',
        color: 'info',
      },
      { icon: 'MarkChatRead', label: 'Class Chat', color: 'info' },
      { icon: 'Workspaces', label: 'Small Classes', color: 'info' },
      {
        icon: 'SignalCellular2Bar',
        label: 'Medium Mentorship',
        color: 'info',
      },
    ],
  },
  {
    id: 2,
    slug: 'flexible',
    title: 'Flexible',
    icon: 'AllInclusive',
    tagName: 'For Fully Self-paced Classes',
    tagColor: 'info',
    description:
      'All content is accessible from the start, and students can post their work to receive feedback and message their class. Perfect for fast paced classes or students seeking flexibility.',
    socialType: SOCIAL_TYPES.private,
    accessType: ACCESS_TYPES.open,
    isAnonymous: false,
    features: [
      { icon: 'LockOpen', label: 'Instant Access', color: 'info' },
      {
        icon: 'DynamicFeed',
        label: 'Private Feed',
        color: 'info',
      },
      { icon: 'MarkChatRead', label: 'Class Chat', color: 'info' },
      { icon: 'Workspaces', label: 'Small Classes', color: 'info' },
      {
        icon: 'SignalCellular3Bar',
        label: 'High Mentorship',
        color: 'info',
      },
    ],
  },
  {
    id: 3,
    slug: 'intake',
    title: 'Intake',
    icon: 'Groups',
    tagName: 'For Guiding Communities',
    tagColor: 'info',
    description:
      'Content unlocks weekly and students can post to a community feed shared course-wide. Perfect for setting the course pace in a large open community.',
    socialType: SOCIAL_TYPES.community,
    accessType: ACCESS_TYPES.scheduled,
    isAnonymous: false,
    features: [
      { icon: 'Today', label: 'Weekly Access', color: 'info' },
      { icon: 'Language', label: 'Course-Wide Feed', color: 'info' },
      { icon: 'SpeakerNotesOff', label: 'No Chat', color: 'info' },
      { icon: 'Grain', label: 'Unlimited Size', color: 'info' },
      {
        icon: 'SignalCellular1Bar',
        label: 'Low Mentorship',
        color: 'info',
      },
    ],
  },
  {
    id: 4,
    slug: 'open',
    title: 'Open',
    icon: 'Public',
    tagName: 'For Minimal Mentor Engagement',
    tagColor: 'warning',
    description:
      'All content is accessible from the start and students can post to a community feed shared course-wide. Perfect for a hands-off approach – create a class once and watch it grow.',
    socialType: SOCIAL_TYPES.community,
    accessType: ACCESS_TYPES.open,
    isAnonymous: false,
    features: [
      { icon: 'LockOpen', label: 'Instant Access', color: 'info' },
      { icon: 'Language', label: 'Course-Wide Feed', color: 'info' },
      { icon: 'SpeakerNotesOff', label: 'No Chat', color: 'info' },
      { icon: 'Grain', label: 'Unlimited Size', color: 'info' },
      {
        icon: 'SignalCellular1Bar',
        label: 'Low Mentorship',
        color: 'info',
      },
    ],
  },
  {
    id: 5,
    slug: 'rooms',
    title: 'Live Rooms',
    icon: 'TapAndPlay',
    tagName: 'For Running Sessions in Real-time',
    tagColor: 'error',
    description:
      'Facilitate learning with live session events which require no student signup process. Perfect for a teacher-led approach, conducted remotely or in a classroom setting.',
    socialType: SOCIAL_TYPES.private,
    accessType: ACCESS_TYPES.scheduled,
    isAnonymous: true,
    features: [
      { icon: 'PersonOff', label: 'No Signups', color: 'info' },
      { icon: 'MeetingRoom', label: 'Classroom-Friendly', color: 'info' },
      {
        icon: 'SupervisedUserCircle',
        label: 'Suitable for Kids',
        color: 'info',
      },
      { icon: 'Grain', label: 'Unlimited Class Size', color: 'info' },
      {
        icon: 'SignalCellularNull',
        label: 'No In-App Mentorship',
        color: 'info',
      },
    ],
  },
];

export type IProgressStatus = ValueOf<typeof PROGRESS_STATUS>;

export const SESSION_TYPE = {
  intro: 'intro',
  normal: 'normal',
  outro: 'outro',
} as const;

export type ISessionType = ValueOf<typeof SESSION_TYPE>;

export const SESSION_FORMAT = {
  guided: 'guided',
  reflect: 'reflect',
  practice: 'practice',
  research: 'research',
  challenge: 'challenge',
  assessment: 'assessment',
  longform: 'longform',
} as const;

export type ISessionFormat = ValueOf<typeof SESSION_FORMAT>;

export const SESSION_FORMAT_NAME = {
  [SESSION_FORMAT.guided]: 'Guided',
  [SESSION_FORMAT.reflect]: 'Reflective',
  [SESSION_FORMAT.practice]: 'Practice',
  [SESSION_FORMAT.research]: 'Research',
  [SESSION_FORMAT.challenge]: 'Challenge',
  [SESSION_FORMAT.assessment]: 'Assessment',
  [SESSION_FORMAT.longform]: 'Extended',
} as const;

export const SESSION_FORMAT_ICON = {
  [SESSION_FORMAT.guided]: 'School',
  [SESSION_FORMAT.reflect]: 'QuestionAnswer',
  [SESSION_FORMAT.practice]: 'Autorenew',
  [SESSION_FORMAT.research]: 'LocalLibrary',
  [SESSION_FORMAT.challenge]: 'Star',
  [SESSION_FORMAT.assessment]: 'AssignmentTurnedIn',
  [SESSION_FORMAT.longform]: 'AllInclusive',
} as const;

export const SESSION_STEP_TYPE = {
  intro: 'intro',
  normal: 'normal',
  outro: 'outro',
} as const;

export const COURSE_PUBLISH_STATUS = {
  draft: 'draft',
  inReview: 'in_review',
  published: 'published',
} as const;

export type ICoursePublishStatus = ValueOf<typeof COURSE_PUBLISH_STATUS>;

export const COURSE_STATUS = {
  upcoming: 'upcoming',
  inProgress: 'in-progress',
  ended: 'ended',
  expiringSoon: 'expiring-soon',
  expired: 'expired',
} as const;

export type ICourseStatus = ValueOf<typeof COURSE_STATUS>;

export const UNIT_TYPE = {
  intro: 'intro',
  normal: 'normal',
  outro: 'outro',
  transition: 'transition',
  assessment: 'assessment',
} as const;

export type IUnitType = ValueOf<typeof UNIT_TYPE>;
