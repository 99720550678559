import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSpring } from 'react-spring';

import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { ButtonProps, BoxProps, Box, Flex, Text, useTheme } from '@workshop/ui';

import { AnimatedButton, AnimatedFlex } from 'components/Common';

import { NAV_HEIGHT } from 'containers/AppHeader';

interface AnimatedDropDownProps extends ButtonProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  onClick: (...args: any) => void;
  secondary?: boolean;
  extendedWidth?: number;
  minimisedWidth?: number;
  titleMaxWidth?: number;
  btnDirection?: 'left' | 'right';
  contentDirection?: 'up' | 'down';
  dropdownStyle?: BoxProps;
  minH?: string;
  maxH?: string;
  onBg?: boolean;
  name?: string;
}

const AnimatedDropDown: React.FC<AnimatedDropDownProps> = ({
  isOpen,
  setIsOpen,
  title = '',
  extendedWidth = 400,
  minimisedWidth,
  titleMaxWidth = extendedWidth,
  btnDirection = 'right',
  onClick,
  dropdownStyle = {},
  children,
  minH = '220px',
  maxH = 'calc(100vh - 200px)',
  onBg = false,
  name = 'Dropdown',
  ...props
}) => {
  const btnRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [btnWidth, setBtnWidth] = useState<number | string>('auto');
  const [isNotClosed, setIsNotClosed] = useState<boolean>(false);

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  /**
   * Set Button initial width as 'auto', react-spring only accept a
   * number => replicate css: {width: auto} with ref
   */
  useLayoutEffect(() => {
    if (minimisedWidth) {
      setBtnWidth(minimisedWidth);
      return;
    }
    if (btnRef.current !== null) {
      // Reset width to auto before calculating element width
      setBtnWidth('auto');
      setTimeout(
        () => setBtnWidth(btnRef?.current?.clientWidth || 'auto'),
        500
      );
    }
  }, [title, minimisedWidth]);

  /**
   * Handle the mouse down event.
   * If the user click outside our dropdown, the dropdown closes.
   */
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    if (document.addEventListener) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [wrapperRef]);

  useEffect(() => {
    set({
      transform: isOpen ? 'translate3D(0,0,0)' : 'translate3D(0,-40px,0)',
      opacity: isOpen ? 1 : 0,
      zIndex: isOpen ? 200 : -1,
      display: isOpen ? 'block' : 'none',
      onStart: () => (isOpen ? setIsNotClosed(true) : null),
      onRest: () => (!isOpen ? setIsNotClosed(false) : null),
    });
  }, [isOpen]);

  // Animation for the Button from its auto-width to extended-width
  const animatedBtnStyle = useSpring({
    config: { duration: 100 },
    width: isOpen ? extendedWidth : btnWidth,
  });

  const bg = props.backgroundColor || 'background.tint1';

  const flexBtn = btnDirection === 'left' ? 'flex-end' : 'flex-start';

  // Animation for the Content of the Dropdown
  const [animatedContentStyle, set] = useSpring(() => ({
    delay: 150,
    config: { duration: 400 },
    transform: 'translate3D(0,-40px,0)',
    opacity: 0,
    zIndex: -1,
    display: 'none',
  }));

  const rd = props.secondary ? 'lg' : 'md';

  return (
    <Flex direction="column" alignItems="flex-end" ref={wrapperRef}>
      <Flex flex="1 0" ref={btnRef} direction="column" alignItems="flex-end">
        {isNotClosed ? (
          <Box
            width={isMobile ? `${minimisedWidth}px` : btnWidth}
            ml="defaultMargin"
          />
        ) : null}
        <AnimatedButton
          fontSize="sm"
          ml="defaultMargin"
          justifyContent={isOpen && !isMobile ? flexBtn : 'center'}
          // @ts-ignore
          style={
            btnRef.current && !isMobile
              ? animatedBtnStyle
              : { width: minimisedWidth }
          }
          boxShadow={{ base: 'none', md: onBg ? 'none' : 'lg' }}
          roundedBottom={{ base: rd, md: isOpen ? 0 : rd }}
          onClick={onClick}
          overflow="hidden"
          position={isNotClosed ? 'absolute' : 'relative'}
          backgroundColor={{
            base: isOpen ? 'background.tint3' : 'background.tint2',
            md: onBg
              ? isOpen
                ? 'background.tint3'
                : 'background.tint2'
              : isOpen
              ? 'background.tint1'
              : 'background.default',
          }}
          color="text.default"
          _hover={{
            backgroundColor: {
              base: 'background.tint1',
              md: 'background.tint1',
            },
          }}
          aria-label={name}
          {...props}
        >
          {!isMobile && (
            <Text
              maxWidth={titleMaxWidth}
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {title}
            </Text>
          )}
        </AnimatedButton>
      </Flex>
      <Box position="relative" backgroundColor={bg}>
        {/* @ts-ignore */}
        <AnimatedFlex
          direction="column"
          flex="1 0"
          roundedBottom="md"
          backgroundColor={bg}
          style={animatedContentStyle}
          boxShadow="lg"
          position={{ base: 'fixed', md: 'absolute' }}
          top={{ base: NAV_HEIGHT, md: 0 }}
          right="0px"
          overflow="auto"
          zIndex={2000}
          width={{ base: '100%', md: extendedWidth }}
          minH={minH}
          maxH={maxH}
          {...dropdownStyle}
        >
          {children}
        </AnimatedFlex>
      </Box>
    </Flex>
  );
};

export default AnimatedDropDown;
