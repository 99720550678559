import React, { useState, useEffect, useRef } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import { useForm, FormContext } from 'react-hook-form';
import { useLottie } from 'lottie-react';
import Form from '@rjsf/chakra-ui';
import validator from '@rjsf/validator-ajv8';
import { ArrayFieldTemplateProps, TitleFieldProps } from '@rjsf/utils';
import { FaRegSadCry } from 'react-icons/fa';
import { useDismissedInformationCards } from 'redux/selectors/user';

import { GlobalState } from 'types';
import { CoursePlan, PERMISSION_SLUGS } from 'types/common';

import { assistantActions, organisationActions } from 'redux/actions/common';
import {
  useIsAuthenticated,
  useCurrentTeam,
  useCurrentTeamProfile,
  useTeamProfiles,
  useTeams,
} from 'redux/selectors';
import { useHasPermission } from 'redux/selectors/organisation';

import { PLATFORM, aiFeatureUrl } from 'constants/env';
import { PRO_ORGS, BASIC_UNIT_LIMIT } from 'constants/organisation';

import navRoutes from 'navigation/Routes';

import { capitalize, hooks, analytics, getParamFromUrl } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import {
  LinkBox,
  LinkOverlay,
  Flex,
  Text,
  Box,
  Stack,
  Divider,
  MdIcon,
  Container,
  useColorModeValue,
  useTheme,
  Button,
  ButtonGroup,
  LinkButton,
  UnorderedList,
  ListItem,
  Spinner,
  Image,
  chakra,
} from '@workshop/ui';

import Brand, { ProGem } from 'components/Brand';
import { MiniGame } from 'components/MiniGame';
import { Footer } from 'components/Footer';
import { Loading } from 'components/Loading';
import { LabelSelect } from 'components/Common';
import { AppHeader, NAV_HEIGHT } from 'containers/AppHeader';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import assistantAnimation from 'assets/lottie/assistant.json';
import iAi from 'assets/images/illustrations/i-ai.png';

import TextareaWidget from './TextareaWidget';
import Message from './Message';
import SignupModal from './SignupModal';

interface MatchParams {
  planSlug: string;
}

interface OwnProps extends RouteComponentProps<MatchParams> {}
type PropsFromRedux = ConnectedProps<typeof connector>;
interface CoursePlanScreenProps extends OwnProps, PropsFromRedux {}

export const ArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
  const currentTeam = useCurrentTeam();
  const currentTeamProfile = useCurrentTeamProfile();
  const isPro = Boolean(
    currentTeamProfile?.isPro || (currentTeam && PRO_ORGS.includes(currentTeam))
  );
  // Restrict number of units & sessions allowed
  const maxNumItems =
    props.title === 'Units'
      ? isPro
        ? null
        : BASIC_UNIT_LIMIT
      : props.title === 'Sessions'
      ? 6
      : 6;
  const limitReached = maxNumItems && props.items.length >= maxNumItems;
  return (
    <Box pl={{ base: 2, md: 4 }} borderLeftWidth={1} borderColor="blue.100">
      {props.items.map(
        ({
          children,
          disabled,
          hasToolbar,
          hasMoveDown,
          hasMoveUp,
          hasRemove,
          index,
          onDropIndexClick,
          onReorderClick,
          readonly,
          uiSchema,
          registry,
        }) => {
          const { MoveDownButton, MoveUpButton, RemoveButton } =
            registry.templates.ButtonTemplates;
          return (
            // @ts-ignore
            <Flex
              flexDirection="column"
              position="relative"
              key={`arrayfield-item-${index}`}
            >
              <Box w="100%">{children}</Box>
              {hasToolbar && !disabled && (
                <Box alignSelf="flex-end" mb={4} position="absolute">
                  <ButtonGroup isAttached>
                    {(hasMoveUp || hasMoveDown) && (
                      // @ts-ignore
                      <MoveUpButton
                        disabled={disabled || readonly || !hasMoveUp}
                        onClick={onReorderClick(index, index - 1)}
                        uiSchema={uiSchema}
                        registry={registry}
                        // @ts-ignore
                        size="xs"
                        colorScheme="blue"
                        variant="ghost"
                      />
                    )}
                    {(hasMoveUp || hasMoveDown) && (
                      // @ts-ignore
                      <MoveDownButton
                        disabled={disabled || readonly || !hasMoveDown}
                        onClick={onReorderClick(index, index + 1)}
                        uiSchema={uiSchema}
                        registry={registry}
                        // @ts-ignore
                        size="xs"
                        colorScheme="blue"
                        variant="ghost"
                      />
                    )}
                    {hasRemove && (
                      // @ts-ignore
                      <RemoveButton
                        disabled={disabled || readonly}
                        onClick={onDropIndexClick(index)}
                        uiSchema={uiSchema}
                        registry={registry}
                        // @ts-ignore
                        size="xs"
                        colorScheme="blue"
                        variant="ghost"
                      />
                    )}
                  </ButtonGroup>
                </Box>
              )}
            </Flex>
          );
        }
      )}
      <Flex justifyContent="flex-end">
        {props.canAdd && !limitReached && !props.disabled && (
          <Button
            variant="outline"
            size="sm"
            onClick={props.onAddClick}
            icon="Add"
          >
            {`Add ${props.title.slice(0, -1)}`}
          </Button>
        )}
      </Flex>
    </Box>
  );
};

export const TitleFieldTemplate = (props: TitleFieldProps) => {
  const { id, required, title } = props;
  return (
    <Text id={id} mb={2} fontSize="sm">
      {capitalize(`${title.slice(0, -3)} ${parseInt(title.slice(-1)) + 1}`)}
    </Text>
  );
};

const PLAN_TEMPLATE = {
  title: '',
  type: 'object',
  properties: {
    courseTitle: { type: 'string', title: '' },
    courseSubtitle: {
      type: 'string',
      title: '',
    },
    courseDescription: {
      type: 'string',
      title: 'About',
    },
    courseIntro: {
      type: 'string',
      title: 'Introduction',
    },
    units: {
      type: 'array',
      title: 'Units',
      items: {
        type: 'object',
        properties: {
          unitTitle: {
            type: 'string',
            title: '',
          },
          sessions: {
            type: 'array',
            title: 'Sessions',
            items: {
              type: 'object',
              properties: {
                sessionTitle: {
                  type: 'string',
                  title: '',
                },
                sessionDescription: {
                  type: 'string',
                  title: '',
                },
              },
            },
          },
        },
      },
    },
    courseOutro: {
      type: 'string',
      title: 'Congratulations',
    },
  },
};

const TABS = [
  {
    label: 'Market Insights',
    bg: 'background.success',
    color: 'text.success',
    id: 'market-insights',
  },
  {
    label: 'Suggested Price',
    bg: 'background.tint2',
    color: 'text.muted',
    id: 'suggested-price',
  },
  {
    label: 'Estimated Revenue',
    bg: 'background.none',
    color: 'text.none',
    id: 'estimated-revenue',
  },
  {
    label: 'Course Plan',
    bg: 'background.primary',
    color: 'text.primary',
    id: 'course-plan',
  },
];

const Plan = ({ editedResponse, slug, contentObject }: CoursePlan) => {
  const [buildingCourse, setBuildingCourse] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [savingPlan, setSavingPlan] = useState(false);
  const [planChanged, setPlanChanged] = useState(false);
  const [formData, setFormData] = useState(editedResponse);
  const [showSignupModal, setShowSignupModal] = useState<'build' | 'pro' | ''>(
    ''
  );

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);
  const isSmallScreen =
    windowDimensions.width < parseInt(theme.breakpoints.lg, 10);

  const location = useLocation();
  const history = useHistory();
  const currentTeam = useCurrentTeam();
  const currentTeamProfile = useCurrentTeamProfile();
  const userTeams = useTeams();
  const teamProfiles = useTeamProfiles();
  const dispatch = useDispatch();
  const hasEditPermissions = useHasPermission(
    PERMISSION_SLUGS.can_edit_content
  );
  const hasAccount = useIsAuthenticated();
  const bg = useColorModeValue('background.default', 'background.tint3');
  const dismissedInformationCards = useDismissedInformationCards();

  const isPro = Boolean(
    currentTeamProfile?.isPro || (currentTeam && PRO_ORGS.includes(currentTeam))
  );
  const planIsPro = !!editedResponse?.assistantSummary;

  const submitFormRef = useRef<HTMLButtonElement | null>(null);

  const popupParam = getParamFromUrl(location, 'p');

  useEffect(() => {
    submitFormRef.current = document.querySelector("button[type='submit']");
  }, []);

  useEffect(() => {
    if (popupParam === 'build') {
      setShowSignupModal('build');
    }
    if (popupParam === 'pro') {
      setShowSignupModal('pro');
    }
  }, [popupParam]);

  useEffect(() => {
    if (editedResponse) {
      setFormData(editedResponse);
    }
  }, [editedResponse]);

  const teamOptions = userTeams.reduce((acc, c) => {
    const teamProfile = teamProfiles[c.team];
    if (teamProfile) {
      return {
        ...acc,
        [c.team]: `${teamProfile?.name}`,
      };
    }
    return acc;
  }, {} as { [key: string]: string });

  const hasChannel = hasAccount && !!currentTeam;
  const courseBuiltOnCurrentChannel = Boolean(
    contentObject && contentObject.organisation === currentTeamProfile?.id
  );
  const courseBuiltOnDifferentChannel = Boolean(
    contentObject &&
      (!hasChannel || contentObject.organisation !== currentTeamProfile?.id)
  );

  const builtCourse = courseBuiltOnCurrentChannel ? contentObject : null;

  if (!editedResponse) return null;

  if (courseBuiltOnDifferentChannel) {
    return (
      <Flex>
        <Flex
          flexDirection="column"
          alignItems="center"
          p={6}
          mt={32}
          bg="background.tint3"
          maxWidth={620}
          mx="auto"
          textAlign="center"
          borderRadius="md"
        >
          {hasAccount ? (
            <>
              <Text>
                This plan was used to create a course on a different channel. To
                access the plan, please switch to the channel where the course
                was originally built.
              </Text>
              <Button
                mt={4}
                icon="Home"
                onClick={() => history.push(navRoutes.common.home.path())}
              >
                Home
              </Button>
            </>
          ) : (
            <>
              <Text>
                This plan has already been used to create a course. If this was
                your course, please log in to view your plan.
              </Text>
              <Button
                mt={4}
                icon="Login"
                onClick={() => history.push(navRoutes.public.login.path())}
              >
                Log In
              </Button>
            </>
          )}
        </Flex>
      </Flex>
    );
  }

  const disabledStyles = {
    '[disabled]': {
      opacity: '1!important',
      backgroundColor: 'background.tint3',
      border: 'none',
    },
  };

  const buildCourse = async () => {
    localStorage.removeItem('latestPlan');
    localStorage.removeItem('planEmail');
    setBuildingCourse(true);
    const buildRes = await dispatch(assistantActions.buildCourseFromPlan(slug));
    if (
      buildRes?.error &&
      buildRes?.payload &&
      'normalizedErrors' in buildRes.payload
    ) {
      setErrorMessage(buildRes.payload.normalizedErrors?.message as string);
    } else if (buildRes?.payload && 'courseId' in buildRes.payload) {
      localStorage.setItem('generatedCourse', `${buildRes.payload.courseId}`);
      analytics.track('Course Created From Plan');
      if (dismissedInformationCards?.includes('welcome_popup')) {
        history.push({
          pathname: navRoutes.cms.editCourse.path(buildRes.payload.courseId),
        });
      } else {
        history.push({
          pathname: navRoutes.common.home.path(),
          search: `p=welcome&c=${buildRes.payload.courseId}`,
        });
      }
    }
    setBuildingCourse(false);
  };

  const ctaSection = (
    <>
      {!builtCourse && !courseBuiltOnDifferentChannel ? (
        <>
          <Box
            pt={{ base: 3, lg: 3 }}
            pb={3}
            position="sticky"
            top={`${NAV_HEIGHT}px`}
            bg={bg}
            zIndex={1}
          >
            {hasChannel && !hasEditPermissions && (
              <Text textAlign="center" color="text.muted" pb={6}>
                You don't have permission to create and edit courses on this
                channel
              </Text>
            )}
            <Button
              alignSelf="flex-end"
              size="lg"
              icon="CheckCircle"
              w="100%"
              isLoading={buildingCourse}
              isDisabled={hasChannel && !hasEditPermissions}
              onClick={async () => {
                if (submitFormRef.current) {
                  await submitFormRef.current.click();
                }
                if (!hasAccount || !hasChannel) {
                  localStorage.setItem('latestPlan', slug);
                  setShowSignupModal('build');
                } else {
                  buildCourse();
                }
              }}
            >
              Build Course
            </Button>
            {errorMessage ? (
              <Box bg="background.error" p={4} borderRadius="md" mt={4}>
                <Text color="text.error" textAlign="center">
                  {errorMessage}
                </Text>
              </Box>
            ) : null}
          </Box>
          {hasChannel && Object.keys(teamOptions).length > 1 && (
            <Flex
              mt={6}
              bg="background.tint3"
              borderRadius="md"
              p={2}
              pb={0}
              mb={6}
            >
              <LabelSelect
                isDisabled={false}
                label="Active Channel:"
                onChange={(e) => {
                  dispatch(
                    organisationActions.setCurrentTeam(parseInt(e.target.value))
                  );
                  localStorage.setItem('defaultTeam', e.target.value);
                }}
                value={currentTeam || undefined}
                options={teamOptions}
                labelPosition="top"
                textAlign="center"
                labelStyleProps={{
                  textAlign: 'center',
                  fontSize: 'sm',
                }}
              />
            </Flex>
          )}
        </>
      ) : builtCourse ? (
        <Box
          pt={{ base: 6, lg: 3 }}
          pb={3}
          position="sticky"
          top={`${NAV_HEIGHT}px`}
          bg={bg}
          zIndex={1}
        >
          <Flex flexDirection="column" textAlign="center">
            <Text fontSize="sm">This plan was used to create</Text>
            <Text fontWeight="semibold">{builtCourse.title}</Text>
            <Button
              mt={4}
              mb={2}
              icon="ArrowForward"
              iconPosition="right"
              onClick={() =>
                history.push(navRoutes.cms.editCourse.path(builtCourse.id))
              }
            >
              Open Course
            </Button>
          </Flex>
        </Box>
      ) : null}
      <Text fontSize="xs" color="text.muted" mx={2} textAlign="center">
        This plan was made with machine learning algorithms and doesn't reflect
        anyone's views. We can't guarantee its accuracy, so please verify the
        information yourself.
      </Text>
    </>
  );

  const courseTitle = formData?.courseTitle || editedResponse?.courseTitle;
  const courseSubtitle =
    formData?.courseSubtitle || editedResponse?.courseSubtitle;

  return (
    <Box>
      <Stack
        mt={{ base: 6, md: 16 }}
        mb={6}
        display={{ base: 'flex', lg: 'none' }}
        spacing={3}
      >
        <Divider />
        <Box pt={6} textAlign="center">
          <Text fontWeight="extrabold" fontSize="3xl" lineHeight="1.2" mb={3}>
            {courseTitle}
          </Text>
          <Text fontSize="lg" pb={6}>
            {courseSubtitle}
          </Text>
        </Box>
        {TABS.map((tab) => (
          <chakra.a key={`tag-${tab.id}`} href={`#${tab.id}`}>
            <Flex
              bg={tab.bg}
              color={tab.color}
              px={4}
              py={3}
              borderRadius="full"
              alignItems="center"
              cursor="pointer"
              _hover={{
                opacity: 0.8,
              }}
            >
              <Text fontWeight="semibold" flex={1}>
                {tab.label}
              </Text>
              <MdIcon name="ArrowForward" />
            </Flex>
          </chakra.a>
        ))}
      </Stack>
      {!planIsPro && !isPro ? (
        <Stack
          direction={{ base: 'column', md: 'row' }}
          textAlign={{ base: 'center', md: 'left' }}
          bg="background.warning"
          color="text.warning"
          borderRadius="lg"
          alignItems="center"
          p={6}
          mt={{ base: 8, lg: 20 }}
          mb={{ base: 7, lg: -10 }}
          spacing={6}
        >
          <Box flex={1}>
            <Text fontSize="xl" fontWeight="bold" mb={1}>
              Get your plan even more tailored to you with Pro
            </Text>
            <Text>
              Upgrade your channel to use the Pro Course Planner, with AI
              trained in your unique tastes and course plans built to your exact
              specifications.
            </Text>
          </Box>
          <Button
            onClick={() => setShowSignupModal('pro')}
            icon="ArrowForward"
            iconPosition="right"
            colorScheme="orange"
          >
            {currentTeamProfile?.proTrialRedeemed
              ? 'Upgrade to Pro'
              : 'Try Pro for Free'}
          </Button>
        </Stack>
      ) : !planIsPro && isPro ? (
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          textAlign={{ base: 'center', lg: 'left' }}
          bg="background.warning"
          color="text.warning"
          borderRadius="lg"
          alignItems="center"
          p={6}
          mt={{ base: 8, lg: 20 }}
          mb={{ base: 7, lg: -10 }}
          spacing={6}
        >
          <Box flex={1}>
            <Text mb={1}>
              This plan was generated with the Basic Course Planner
            </Text>
            <Text fontSize={{ base: 'lg', sm: 'xl' }} fontWeight="bold">
              Generate tailored plans with your Pro Course Planner
            </Text>
          </Box>
          <Button
            onClick={() =>
              history.push({
                pathname: navRoutes.cms.catalogue.path(),
                search: 'p=course',
              })
            }
            icon="AutoAwesome"
            colorScheme="orange"
          >
            Plan a New Course
          </Button>
        </Stack>
      ) : planIsPro && isPro ? (
        <Stack
          direction={{ base: 'column' }}
          bg="background.warning"
          color="text.warning"
          borderRadius="lg"
          p={{ base: 4, md: 6 }}
          mt={{ base: 8, lg: 20 }}
          mb={{ base: 7, lg: -10 }}
          spacing={6}
        >
          <Box flex={1}>
            <Flex mb={2} alignItems="center">
              <ProGem />
              <Text ml={2} fontSize="sm" fontWeight="semibold">
                Pro Plan Summary
              </Text>
            </Flex>
            <Text fontSize={{ base: 'md', md: 'lg' }}>
              {editedResponse.assistantSummary}
            </Text>
          </Box>
          <Button
            onClick={() =>
              history.push({
                pathname: navRoutes.cms.catalogue.path(),
                search: 'p=course',
              })
            }
            icon="AutoAwesome"
            colorScheme="orange"
            alignSelf="flex-end"
          >
            Plan a New Course
          </Button>
        </Stack>
      ) : null}

      {isSmallScreen && windowDimensions.width ? ctaSection : null}
      <Flex mt={12} display={{ base: 'flex', lg: 'none' }}>
        <Divider />
      </Flex>

      <Stack
        direction={{ base: 'column-reverse', lg: 'row' }}
        spacing={{ base: 5, lg: 4, xl: 5 }}
        pt={{ base: 5, lg: 20 }}
      >
        <Box
          bg="background.primary"
          borderRadius="md"
          p={{ base: 2, md: 5 }}
          sx={{
            'input, textarea': {
              bg: 'background.default',
              pl: 3,
            },
            scrollMarginTop: NAV_HEIGHT + 100,
            scrollSnapMarginTop: NAV_HEIGHT + 100,
          }}
          flex={2}
          position="relative"
          id="course-plan"
        >
          <Text
            fontWeight="semibold"
            color="text.primary"
            fontSize="sm"
            p={{ base: 2, md: 0 }}
            pb={0}
            mb={4}
          >
            Course Plan
          </Text>
          <Form
            // @ts-ignore
            schema={PLAN_TEMPLATE}
            disabled={!!builtCourse}
            formData={formData || editedResponse}
            validator={validator}
            uiSchema={{
              'ui:submitButtonOptions': {
                norender: !!builtCourse,
              },
              courseTitle: {
                'ui:widget': TextareaWidget,
                'ui:options': {
                  chakra: {
                    mb: -7,
                    sx: {
                      textarea: {
                        fontWeight: 'bold',
                        fontSize:
                          isSmallScreen && windowDimensions.width
                            ? 'xl'
                            : '2xl',
                      },
                      ...disabledStyles,
                    },
                  },
                },
              },
              courseSubtitle: {
                'ui:widget': TextareaWidget,
                'ui:options': {
                  chakra: {
                    mb: -3,
                    sx: {
                      ...disabledStyles,
                    },
                  },
                },
              },
              courseDescription: {
                'ui:widget': TextareaWidget,
                'ui:options': {
                  chakra: {
                    mb: -3,
                    sx: {
                      label: {
                        fontSize: 'sm',
                        ml: { base: 2, md: 0 },
                        opacity: '1!important',
                      },
                      ...disabledStyles,
                    },
                  },
                },
              },
              courseIntro: {
                'ui:widget': TextareaWidget,
                'ui:options': {
                  chakra: {
                    mb: -1,
                    sx: {
                      label: {
                        fontSize: 'sm',
                        ml: { base: 2, md: 0 },
                        opacity: '1!important',
                      },
                      ...disabledStyles,
                    },
                  },
                },
              },
              units: {
                'ui:options': {
                  addable: true,
                  orderable: true,
                  removable: true,
                },
                items: {
                  unitTitle: {
                    'ui:widget': TextareaWidget,
                    'ui:options': {
                      chakra: {
                        mb: -3,
                        sx: {
                          textarea: {
                            fontWeight: 'semibold',
                          },
                          ...disabledStyles,
                        },
                      },
                    },
                  },
                  sessions: {
                    'ui:options': {
                      addable: true,
                      orderable: true,
                      removable: true,
                    },
                    items: {
                      sessionTitle: {
                        'ui:widget': TextareaWidget,
                        'ui:options': {
                          chakra: {
                            mb: -7,
                            sx: {
                              textarea: {
                                fontWeight: 'semibold',
                              },
                              ...disabledStyles,
                            },
                          },
                        },
                      },
                      sessionDescription: {
                        'ui:widget': TextareaWidget,
                        'ui:options': {
                          chakra: {
                            mb: 2,
                            sx: {
                              ...disabledStyles,
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              courseOutro: {
                'ui:widget': TextareaWidget,
                'ui:options': {
                  chakra: {
                    sx: {
                      label: {
                        fontSize: 'sm',
                        ml: { base: 2, md: 0 },
                        opacity: '1!important',
                      },
                      ...disabledStyles,
                    },
                  },
                },
              },
            }}
            onSubmit={async (data) => {
              if (planChanged) {
                setSavingPlan(true);
                setFormData(data.formData);
                await dispatch(
                  assistantActions.updateCoursePlan(slug, {
                    editedResponse: {
                      ...data.formData,
                      status: 200,
                      error: false,
                    },
                  })
                );
                setSavingPlan(false);
                setPlanChanged(false);
              }
            }}
            onChange={(data) => {
              setFormData(data.formData);
              if (!planChanged) setPlanChanged(true);
            }}
            templates={{
              ArrayFieldTemplate,
              TitleFieldTemplate,
            }}
          >
            <Button
              type="submit"
              size={isMobile ? 'sm' : 'md'}
              float="right"
              mb={1}
              isLoading={Boolean(savingPlan)}
              variant="outline"
              disabled={!planChanged}
              {...(!!builtCourse ? { display: 'none' } : {})}
            >
              Save Plan
            </Button>
          </Form>
        </Box>
        <Stack flex={1} spacing={{ base: 5, lg: 4, xl: 5 }}>
          {isSmallScreen ? null : ctaSection}
          <Flex
            bg="background.success"
            color="text.success"
            borderRadius="md"
            p={{ base: 4, md: 5 }}
            flexDirection="column"
            id="market-insights"
            sx={{
              scrollMarginTop: NAV_HEIGHT + 80,
              scrollSnapMarginTop: NAV_HEIGHT + 80,
            }}
          >
            <Text fontWeight="semibold" fontSize="sm" mb={2}>
              Market Insights
            </Text>
            <Text>{editedResponse.marketInsights}</Text>
            <Text fontWeight="semibold" fontSize="sm" mt={4}>
              Opportunities
            </Text>
            <UnorderedList>
              {editedResponse.alternativeMarkets.map(
                (m: string, idx: number) => (
                  <ListItem key={`alt-market-${idx}`} mt={2}>
                    {m}
                  </ListItem>
                )
              )}
            </UnorderedList>
          </Flex>
          <Flex
            bg="background.tint2"
            color="text.muted"
            borderRadius="md"
            p={{ base: 4, md: 5 }}
            flexDirection="column"
            id="suggested-price"
            sx={{
              scrollMarginTop: NAV_HEIGHT + 100,
              scrollSnapMarginTop: NAV_HEIGHT + 100,
            }}
          >
            <Text fontWeight="semibold" fontSize="sm">
              Suggested Price
            </Text>
            <Text fontWeight="semibold" fontSize="xl" mb={2}>
              {editedResponse.price}
            </Text>
            <Text>{editedResponse.priceReasoning}</Text>
          </Flex>
          <Flex
            bg="background.none"
            color="text.none"
            borderRadius="md"
            p={{ base: 4, md: 5 }}
            flexDirection="column"
            id="estimated-revenue"
            sx={{
              scrollMarginTop: NAV_HEIGHT + 100,
              scrollSnapMarginTop: NAV_HEIGHT + 100,
            }}
          >
            <Text fontWeight="semibold" fontSize="sm">
              Estimated Revenue
            </Text>
            <Text fontWeight="semibold" fontSize="xl" mb={2}>
              {`${editedResponse.monthlyIncome} per month`}
            </Text>
            <Text>{editedResponse.monthlyIncomeReasoning}</Text>
          </Flex>
        </Stack>
      </Stack>
      {!planIsPro && !isPro && (
        // <Box
        //   mt={20}
        //   bg="background.warning"
        //   color="text.warning"
        //   borderRadius="md"
        //   p={{ base: 2, md: 5 }}
        // >
        //   <Text fontSize={{ base: 'xl' }} fontWeight="extrabold">
        //     Make It Yours with Pro
        //   </Text>
        // </Box>
        <Flex
          flexDirection={{ base: 'column', md: 'row' }}
          textAlign={{ base: 'center', md: 'left' }}
          bg="background.error"
          color="text.error"
          borderRadius="lg"
          alignItems="center"
          p={6}
          mb={8}
          mt={20}
        >
          <Flex
            position="relative"
            width={{
              base: '220px',
              sm: '250px',
              md: '250px',
              lg: '340px',
              xl: '370px',
            }}
            height={{
              base: '100px',
              sm: '120px',
              md: '200px',
              lg: '220px',
              xl: '240px',
            }}
            alignItems="center"
            mt={{ base: 8, md: 0 }}
          >
            <Box
              width={{
                base: '220px',
                sm: '250px',
                md: '250px',
                lg: '350px',
                xl: '400px',
              }}
              position="absolute"
              bottom={{ base: 'auto', md: 'auto', lg: '-100px', xl: '-120px' }}
              left={{ base: 'auto', md: '-40px', lg: '-60px', xl: '-85px' }}
              top={{ base: '-120px', md: 'auto' }}
            >
              <Image
                alt="AI course creator illustration"
                // fit="contain"
                // align="center"
                // w="100%"
                // h="100%"
                // htmlHeight="500"
                // htmlWidth="500"
                // maxHeight={{ base: '210px', md: '500px' }}
                src={iAi}
              />
            </Box>
          </Flex>
          <Flex
            flexDirection="column"
            alignItems={{ base: 'center', md: 'flex-start' }}
            flex={1}
          >
            <Text
              fontSize="2xl"
              fontWeight="extrabold"
              display={{ base: 'none', md: 'inline' }}
            >
              Could be better?
            </Text>
            <Text fontSize="xl" fontWeight="bold" mb={2}>
              Take your course to the next level with Pro
            </Text>
            <Text mb={5}>
              Train your AI assistant in your unique tastes and discover how
              easy it can be to build courses your way.
            </Text>
            <Stack
              direction={{ base: 'column', md: 'row' }}
              alignItems="center"
            >
              <Button
                onClick={() => setShowSignupModal('pro')}
                size="lg"
                icon="ArrowForward"
                iconPosition="right"
                colorScheme="red"
              >
                {currentTeamProfile?.proTrialRedeemed
                  ? 'Upgrade to Pro'
                  : 'Try Pro for Free'}
              </Button>
              <LinkButton
                variant="ghost"
                icon="OpenInNew"
                iconPosition="right"
                colorScheme="red"
                href={aiFeatureUrl}
              >
                Learn More
              </LinkButton>
            </Stack>
          </Flex>
        </Flex>
      )}
      <SignupModal
        isOpen={!!showSignupModal}
        onClose={() => setShowSignupModal('')}
        onBuild={() => buildCourse()}
        context={showSignupModal}
      />
    </Box>
  );
};

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const CoursePlanScreen: React.FC<CoursePlanScreenProps> = ({
  planSlug,
  plan,
}) => {
  const { planLoading } = hooks.useLoadingDataState({
    planLoading: {
      actions: [() => assistantActions.retrieveCoursePlan(planSlug)],
    },
  });

  const [isGenerating, setIsGenerating] = useState(false);
  const [playingGame, setPlayingGame] = useState(false);
  const [assistantJson, setAssistantJson] = useState(null);

  const currentTeam = useCurrentTeam();
  const currentTeamProfile = useCurrentTeamProfile();
  const hasAccount = useIsAuthenticated();

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const dispatch = useDispatch();
  const { View: lottieAssistant } = useLottie({
    animationData: assistantJson,
    style: {
      height: isMobile ? 60 : 120,
      width: isMobile ? 60 : 120,
    },
  });
  const formMethods = useForm();

  const pollPlan = async () => {
    await timeout(10000);
    const planRes = await dispatch(
      assistantActions.retrieveCoursePlan(planSlug)
    );
    if (
      planRes?.payload &&
      'editedResponse' in planRes.payload &&
      planRes.payload.editedResponse
    ) {
      const res = planRes.payload.editedResponse;
      if (res.status === 202) {
        pollPlan();
      } else if (res.status === 200) {
        localStorage.setItem('latestPlan', planRes.payload.slug);
      } else {
        analytics.track('Course Plan Failed', {
          status: `${res.status}`,
          message: res.message || '',
        });
      }
    }
  };

  useEffect(() => {
    // @ts-ignore
    setAssistantJson(assistantAnimation);
  }, []);

  const hasChannel = hasAccount && !!currentTeam;
  const courseBuiltOnDifferentChannel = Boolean(
    plan &&
      plan.contentObject &&
      (!hasChannel ||
        plan.contentObject.organisation !== currentTeamProfile?.id)
  );

  const planStatus = plan?.editedResponse?.status;
  const planError =
    plan?.editedResponse?.error || courseBuiltOnDifferentChannel;
  const planErrorMessage = planError ? plan?.editedResponse?.message : '';

  useEffect(() => {
    if (planStatus === 202) {
      // Plan is still generating, so poll every 10 seconds
      setIsGenerating(true);
      pollPlan();
    }
    if (planStatus === 200 && !playingGame && isGenerating) {
      window.scrollTo(0, 0);
      setIsGenerating(false);
    }
  }, [planStatus]);

  const bg = useColorModeValue('background.default', 'background.tint3');

  const history = useHistory();
  if (PLATFORM !== 'steppit') {
    history.push(navRoutes.public.login.path());
    return null;
  }

  if (!planLoading && !plan) {
    history.push(navRoutes.global.planner.path());
    return null;
  }

  return (
    <Box bg={bg}>
      <Flex
        position="fixed"
        top={0}
        left={0}
        padding={{ base: 0, md: 0.5 }}
        zIndex={1100}
        width="100%"
        backgroundColor={bg}
      >
        <LinkBox padding={3} zIndex={1101}>
          <LinkOverlay href="/" aria-label="Steppit Homepage">
            <Brand navbarTransparent={false} />
          </LinkOverlay>
        </LinkBox>
        <AppHeader topPosition={0} onBg />
      </Flex>
      <ScreenWrapper>
        <Container maxW="7xl">
          <Stack
            spacing={{ base: 8, md: 10 }}
            py={{ base: 10, md: 16 }}
            mt={{ base: 6, md: 12 }}
          >
            <Box
              position="relative"
              pl={{ base: '40px', md: '80px' }}
              {...((planLoading && !isGenerating) || planError
                ? {
                    opacity: 0,
                    height: 0,
                  }
                : {})}
            >
              <Flex
                position="absolute"
                bottom={{ base: '-40px', md: '-80px' }}
                left={0}
                zIndex={2}
              >
                {lottieAssistant}
              </Flex>
              <FormContext {...formMethods}>
                <Message
                  message={
                    planLoading && !isGenerating
                      ? ''
                      : isGenerating
                      ? "Thanks! Generating your course plan may take a few minutes, so have a play while you wait! I'll also email it to you shortly."
                      : "Your bespoke course plan is ready! I've designed it to meet your preferences and set you on the path to success."
                  }
                />
              </FormContext>
            </Box>

            {isGenerating ? (
              <>
                <Flex position="sticky" top={NAV_HEIGHT} zIndex={1}>
                  <Flex
                    bg={bg}
                    flex={1}
                    justifyContent={{ base: 'center', md: 'flex-end' }}
                    marginLeft={{ base: -3, md: 0 }}
                    marginRight={{ base: -3, md: 0 }}
                    py={2}
                  >
                    <Flex
                      alignItems="center"
                      flexDirection={{ base: 'column', sm: 'row' }}
                    >
                      {planStatus === 200 ? (
                        <>
                          <Text
                            color="text.muted"
                            whiteSpace="nowrap"
                            mr={{ base: 0, sm: 4, md: 6 }}
                            mb={{ base: 4, sm: 0 }}
                          >
                            Your Plan is Ready!
                          </Text>
                          <Button
                            size={isMobile ? 'md' : 'lg'}
                            icon="ArrowForward"
                            iconPosition="right"
                            onClick={() => {
                              window.scrollTo(0, 0);
                              setIsGenerating(false);
                            }}
                          >
                            Open Plan
                          </Button>
                        </>
                      ) : (
                        <>
                          <Spinner size="md" color="text.muted" />
                          <Text
                            color="text.muted"
                            whiteSpace="nowrap"
                            ml={{ base: 0, sm: 4 }}
                            mt={{ base: 4, sm: 0 }}
                          >
                            Generating Plan
                          </Text>
                        </>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
                {!playingGame && (
                  <Flex justifyContent="center">
                    <Button
                      icon="Games"
                      size="lg"
                      onClick={() => setPlayingGame(true)}
                    >
                      Play Game
                    </Button>
                  </Flex>
                )}
                <Box
                  transition="0.6s"
                  opacity={playingGame ? 1 : 0}
                  transform={playingGame ? 'scale(1)' : 'scale(1.1)'}
                  pointerEvents={playingGame ? 'auto' : 'none'}
                >
                  <MiniGame />
                </Box>
              </>
            ) : planErrorMessage ? (
              <Flex flexDirection="column">
                <Flex color="text.muted" fontSize="4xl" mx="auto" mb={4}>
                  <FaRegSadCry />
                </Flex>
                <Text
                  fontSize="xl"
                  fontWeight="semibold"
                  textAlign="center"
                  mb={6}
                >
                  Uh oh... that didn't go to plan!
                </Text>
                <Box
                  mx="auto"
                  bg="background.error"
                  p={4}
                  borderRadius="md"
                  maxWidth={600}
                  mb={8}
                >
                  <Text color="text.error">{planErrorMessage}</Text>
                </Box>
                <Button
                  icon="RestartAlt"
                  onClick={() => history.push(navRoutes.global.planner.path())}
                  mx="auto"
                  size="lg"
                >
                  Try Again
                </Button>
              </Flex>
            ) : planLoading ? (
              <Flex>
                <Loading />
              </Flex>
            ) : (
              <Plan {...plan} />
            )}
          </Stack>
        </Container>
      </ScreenWrapper>
      <Footer />
    </Box>
  );
};

const mapStateToProps = (state: GlobalState, props: OwnProps) => {
  const { planSlug } = props.match.params;

  const {
    assistant: { coursePlans },
  } = state;

  return {
    planSlug,
    plan: coursePlans[planSlug],
  };
};

const connector = connect(mapStateToProps);
export default connector(CoursePlanScreen);
