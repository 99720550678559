import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { PLATFORM } from 'constants/env';

import { hooks, getParamFromUrl } from 'utils';

import { EditCard, LabelInput, LabelSelect } from 'components/Common';
import { Flex } from '@workshop/ui';

import { FurtherDetailsFormData, IClassType } from 'types/cms';

interface FurtherDetailsProps extends FurtherDetailsFormData {
  categories: { [key: string]: string };
  classTypes: { [key in IClassType]: string };
  onSave: (formData: FurtherDetailsFormData) => void;
  onCancel?: () => void;
  isDisabled?: boolean;
  isUpdating?: boolean;
  isLoading?: boolean;
  error?: boolean;
  category: number;
  classType: IClassType;
  showAdvancedFields?: boolean;
}

const noop = () => null;
const FurtherDetailsCard = ({
  categories,
  classTypes,
  onSave,
  onCancel = noop,
  courseDuration,
  accessDuration,
  price,
  category,
  classType,
  isDisabled = false,
  isUpdating = false,
  isLoading = false,
  showAdvancedFields = false,
}: FurtherDetailsProps) => {
  const location = useLocation();
  const focusParam = getParamFromUrl(location, 'focus');

  const {
    register,
    handleSubmit,
    errors,
    clearError,
    formState,
    reset,
    watch,
    setValue,
  } = useForm<FurtherDetailsFormData>();

  const onSubmit = handleSubmit((formData) => {
    onSave(formData);
  });

  useEffect(() => {
    // Reset the state as default after state change (successful update)
    reset({
      courseDuration,
      accessDuration,
      price: price ? `${parseInt(price)}` : undefined,
      category,
      classType,
    });
  }, [reset, courseDuration, accessDuration, price, category, classType]);

  hooks.useDeepEqualEffect(() => {
    // `categories` might not exist when first loaded, and since our selected
    // category is derived from `categories` we must therefore reset the form
    // when `catgories` changes or is loaded
    reset({ category });
  }, [categories]);

  const priceValue = watch('price') || price ? `${parseInt(price)}` : undefined;
  const priceError = price && !priceValue;

  return (
    <EditCard
      onSave={onSubmit}
      onCancel={() => {
        onCancel();
        clearError();
        // TODO: Reset Select
        reset({
          courseDuration,
          accessDuration,
          price: price ? `${parseInt(price)}` : undefined,
          category,
          classType,
        });
      }}
      saveDisabled={Boolean(
        !formState.dirty ||
          errors.price ||
          errors.courseDuration ||
          errors.classType ||
          priceError
      )}
      isDisabled={isDisabled}
      isUpdating={isUpdating}
    >
      <LabelSelect
        isDisabled={isDisabled}
        id="category"
        name="category"
        label="Category"
        error={!!errors.category}
        registerInputRef={register({
          required: true,
        })}
        defaultValue={categories[category]}
        options={categories}
        isLoading={isLoading}
        tooltip="course_category"
      />
      {showAdvancedFields && (
        <>
          <Flex>
            <LabelInput
              isDisabled={isDisabled}
              id="accessDuration"
              name="accessDuration"
              label="Access Duration"
              type="number"
              error={!!errors.accessDuration}
              errorMessage="Please enter a valid number less than 52"
              registerInputRef={register()}
              defaultValue={accessDuration}
              unit="weeks"
              isLoading={isLoading}
              tooltip="course_access_duration"
              helpText="The number of weeks that students have access to the course. Enter 0 to provide lifetime access."
            />
          </Flex>
        </>
      )}
      {PLATFORM === 'steppit' ? (
        <LabelSelect
          id="price"
          placeholder="-"
          name="price"
          label="Price"
          helpText="The price of places sold for this course in GBP (only applicable if you plan to sell places). Support for local currencies coming soon."
          isLoading={isLoading}
          defaultValue={priceValue}
          error={Boolean(errors.price || priceError)}
          errorMessage={
            priceError ? 'Your course price must be at least £5' : ''
          }
          // TODO: Move to BE, and support other currencies from pricing matrix
          options={{
            '5': '£5',
            '10': '£10',
            '15': '£15',
            '20': '£20',
            '25': '£25',
            '30': '£30',
            '35': '£35',
            '40': '£40',
            '45': '£45',
            '50': '£50',
            '60': '£60',
            '70': '£70',
            '80': '£80',
            '90': '£90',
            '100': '£100',
            '120': '£120',
            '140': '£140',
            '160': '£160',
            '180': '£180',
            '200': '£200',
            '220': '£220',
            '240': '£240',
            '260': '£260',
            '280': '£280',
            '300': '£300',
            '320': '£320',
            '340': '£340',
            '360': '£360',
            '380': '£380',
            '400': '£400',
            '420': '£420',
            '440': '£440',
            '460': '£460',
            '480': '£480',
            '500': '£500',
            '550': '£550',
            '600': '£600',
            '650': '£650',
            '700': '£700',
            '750': '£750',
            '800': '£800',
            '850': '£850',
            '900': '£900',
            '950': '£950',
            '1000': '£1000',
          }}
          registerInputRef={register()}
          unsorted
          autoFocus={focusParam === 'price'}
        />
      ) : (
        <LabelInput
          id="price"
          name="price"
          label="Price (£)"
          defaultValue={price}
          helpText="The price of places sold for this course"
          unit="GBP"
          isDisabled
          isLoading={isLoading}
        />
      )}
    </EditCard>
  );
};

export default FurtherDetailsCard;
